import React from "react";
import { makeStyles } from "@mui/styles";

const styles = makeStyles({
  button: {
    backgroundColor: "white",
    color: "#626B7E",
    fontSize: "14px",
    padding: "0.45rem 1rem",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
    border: "1px solid #D3DAE3",
    "&:hover": {
      color: "#ff4848",
      border: "1px solid #FF4848",
    },
    // height:"40px"
  },
});

const ResetBtn = ({ message, ...props }) => {
  const classes = styles();
  return (
    <button className={classes.button} {...props}>
      {message}
    </button>
  );
};

export default ResetBtn;

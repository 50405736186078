import React from 'react';
import { makeStyles } from "@mui/styles";
import ImageIcon from '../Icon/ImageIcon';

const styles = makeStyles({
    button:{
        backgroundColor:"white",
        border:"1px solid #d3dae3",
        borderRadius:'4px',
        fontWeight:"400",
        padding:"0.5rem 1rem",
        display:"flex",
        fontSize:'12px',
        marginLeft:"5px",
        color:"#626b7e",
        cursor:"pointer",
        alignSelf:"center"
    }
})

const ActionButton = ( { message, icon , secondIcon} ) => {

    const classes = styles();

    return <button className={ classes.button } >
        { icon && <ImageIcon  image={icon}   />}
        { message }
        { secondIcon ? <ImageIcon image={ secondIcon } /> : null }
    </button>
}

export default ActionButton;


import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SidebarTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }}  />
  ))(`
      color: lightblue;
      background-color: white;
      font-size: 1.5em;
      box-shadow: 0px 9px 66px #00000029;
      padding: 0;
      z-index:1;
      width:100%;
      min-width:12vw;
  `);
   
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Modal,
    Slide,
  } from "@mui/material";
  import React from "react";
  import { makeStyles } from "@mui/styles";
  import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
  import { RESET_ICON } from "../../utils/constants/assets";
  
  import ImageIcon from "../Icon/ImageIcon";
  import { RedShadowButton } from "..";
  import { CloseIcon } from "../Icon/MuiIcons";
  import LinearLoader from "../Loaders/LinearLoader";
  
  const styles = makeStyles({
    root: {
      width: "500px",
    },
   
    icon: {
      color: "#626B7E",
    },
    containCross: {
      display: "flex",
      justifyContent: "flex-end",
      cursor: "pointer",
    },
    texth4: {
      color: "#212833",
      fontSize: "16px",
      fontWeight: "600",
    },
    para: {
      fontSize: "12px",
      fontWeight: "300",
      letterSpacing: "0.6px",
    },
    containText: {
      padding: "0.4rem",
    },
    containBtns: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "1rem 0",
      paddingBottom: 0,
    },
    confirmTitle: {
      fontWeight: "600",
      letterSpacing: "0.6px",
      color: "#0F2F51",
      paddingBottom: "0.4rem",
      fontSize: "clamp(18px,1.042vw,22px)",
      paddingRight:"clamp(24px,2vw,40px)"
    },
    subTitle: {
      
       
      fontSize: "clamp(14px,0.833vw,18px)",
     color:"#626B7E",
     fontWeight:"400"
    },
    span: {
      alignSelf: "center",
      cursor: "pointer",
      fontSize: "14px",
      color: "#626B7E",
    },
  });
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide  direction="down" ref={ref} {...props} timeout={{ enter: 500, exit: 500 }}/>;
  });
  
  const ContactModal = (props) => {
    const classes = styles();
  
    return (
      <Dialog
        BackdropProps={{
          sx: { backgroundColor: "rgba(0,0,0,0.75)" },
        }}
        PaperProps={{ sx: { backgroundColor: "#fff", borderRadius:"12px"} }}
        open={props.open}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        sx={{p:0}}
      >
       
  
        <DialogContent sx={{  borderBottom: "1px solid rgba(0,0,0,.24)" }}>
          <DialogContentText component="div" id="alert-dialog-slide-description">
          {props.children}
           
          </DialogContentText>
        </DialogContent>
       
      </Dialog>
  
       
    );
  };
  
  export default ContactModal;
  
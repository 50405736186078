import { makeStyles } from "@mui/styles";
import {
  ACTIONS,
  BASE,
  BORDER,
  BRAND,
  GREY,
  SUCCESS,
} from "../../utils/constants/colors";
import {
  borderRadius,
  SCROLL_BAR,
  SHADOW,
} from "../../utils/constants/UI/uiConstants";
import { innerBorder } from "../../utils/helpers";

export const styles = {
  table: {
    position: "relative",
    borderCollapse: "collapse",
    height: "calc(100% - 60px)",
    ...SCROLL_BAR,
  },
  tableHeadContainer: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderWidth: "1px 0px 1px 0px",
    borderStyle: "solid",
    borderColor: ACTIONS.DEFAULT,
    // boxShadow: `inset -1px 0 0 ${ACTIONS.DEFAULT}, inset 0 -1px 0 ${ACTIONS.DEFAULT}, inset 1px 0 0 ${ACTIONS.DEFAULT}, inset 0 1px 0 ${ACTIONS.DEFAULT}`,
    position: "sticky",
    top: "0",
    zIndex: "10",
    height: "45px",
  },
  tableMainContainer: {
    borderRadius: "0px 0px 6px 6px",
    height: "100%",
  },
  tableHeadValue: {
    borderRight: `1px solid ${BORDER.DEFAULT}`,
    // borderBottom: `1px solid ${BORDER.DEFAULT}`,
    backgroundColor: ACTIONS.HOVER,
    padding: "11px",
    "&:hover": {
      backgroundColor: ACTIONS.ALTERNATE,
    },
  },
  initialTableHeadValue: {
    display: "inline-flex",
    alignItems: "center",
    columnGap: "12px",
    backgroundColor: ACTIONS.HOVER,
    borderRight: `1px solid ${BORDER.DEFAULT}`,
    "&:hover": {
      backgroundColor: ACTIONS.ALTERNATE,
    },
  },
  stickInitialHead: {
    position: "sticky",
    zIndex: "10",
    left: "0px",
    borderRight: "none",
    boxShadow: SHADOW.tableCells,
  },
  tableHeadCheckbox: {
    padding: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: `1px solid ${BORDER.DEFAULT}`,
  },
  tableHeadAllChecked: {
    padding: "8px 12px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "16px",
    boxShadow: SHADOW.md,
    position: "sticky",
    top: "0",
    left: "0",
    zIndex: "10",
    backgroundColor: BASE.WHITE,
  },
  tableBody: {
    height: "calc(100% - 50px)",
  },
  tableBodyRow: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
    height: "56px",
  },
  textCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px 17px 12px",
  },
  boldTextCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "24px",
    padding: "18px 12px 17px 12px",
  },
  userInfoCell: {
    display: "flex",
    alignItems: "center",
    background: BASE.WHITE,
    overflow: "hidden",
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
    "& #copiedTextContainer": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
      "& #copiedTextContainer": {
        display: "flex",
      },
    },
  },
  userInfoCellWoChkbox: {
    display: "flex",
    alignItems: "center",
    background: BASE.WHITE,
    overflow: "hidden",
    padding: "0px 11px",
    borderBottom: `1px solid ${BORDER.DEFAULT}`,
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  stickyUserInfoCell: {
    position: "sticky",
    left: "0",
    boxShadow: SHADOW.tableCells,
    zIndex: 9,
  },
  checkboxAvatarContainer: {
    display: "inline-flex",
    alignItems: "center",
    columnGap: "12px",
  },
  checkboxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
  },
  stickyRowCheckbox: {
    position: "sticky",
    left: "0px",
  },
  userName: {
    display: "flex",
    gap: "4px",
    width: "100%",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "17.5px 12px 17.5px 12px",
    overflow: "hidden",
  },
  companyURLCell: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  companyURLCellPadding: {
    backgroundColor: BASE.WHITE,
    padding: "8.5px 12px 8.5px 0px",
  },
  companyIcon: {
    height: "18px",
    width: "18px",
  },
  companyNameLink: {
    whiteSpace: "nowrap",
    textDecoration: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    cursor: "pointer",
    "& :hover": {
      color: BRAND.PRIMARY,
    },
  },
  meetingLinkIcon: {
    whiteSpace: "nowrap",
    textDecoration: "none",
    cursor: "pointer",
    "& :hover": {
      color: BRAND.PRIMARY,
    },
  },
  actionCellContainer: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "18px 12px",
    "& :hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
    "& :active": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  actionIconCellPaddingContainer: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px 12px",
    gap: "10px",
    "& :hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
    "& :active": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  sortIconStyle: {},
  headerLabelSortContainer: {
    display: "inline-flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    gap: "4px",
  },
  userInfoHeaderCellTotalPadding: {
    padding: "11px",
    width: "100%",
  },
  userInfoHeaderCellPadding: {
    padding: "11px",
    width: "100%",
  },
  emailCell: {
    padding: "12px",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  emailTextCell: {
    padding: "17.5px 12px 16.5px",
    "& #copiedTextContainer": {
      display: "none",
    },
    "&:hover": {
      "& #copiedTextContainer": {
        display: "flex",
      },
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  emailCopiedTextCellContainer: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    width: "100%",
  },
  copiedTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 4px",
    gap: "2px",
    borderRadius: borderRadius.tertiary,
    backgroundColor: SUCCESS.W_50,
  },
  marketingListCell: {
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  marketingListName: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "4px",
    width: "100%",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "18px 12px 18px 12px",
    overflow: "hidden",
  },
  statsDataCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px 18px 12px",
    cursor: "pointer",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  textButtonCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "10px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  buttonCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "14px 12px 13px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  statusCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "14px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  textURLCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  durationTextCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  taskListCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  userDetailCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
    cursor: "pointer",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  companyDetailCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "12px",
    padding: "12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  taskTypeCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  dueTimeStampCell: {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    padding: "18px 12px",
    "&:hover": {
      color: GREY.PRIMARY,
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  stepsButtonContainer: {
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  companyNameLabel: {
    textTransform: "capitalize",
  },
  taskListNameWithStatus: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "8px 12px ",
    overflow: "hidden",
  },
  taskListName: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "18px 12px ",
    overflow: "hidden",
  },
  leadStatusCell: {
    textTransform: "capitalize",
    padding: "0 48px 0 12px"
  },
  headerStripButton: {
    display: "flex",
    alignItems: "center",
    position: "sticky",
    left: "132px",
  },
  settingLead: {
    width: "13.3vw",
    marginTop: "3px",
    marginLeft: "13px",
    ...SCROLL_BAR,
  },
  selectedAllLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
    position: "sticky",
    left: "12px",
  },
  tabLineContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    paddingRight: "10px",
  },
  horizontalLine: {
    width: "0px",
    height: "20px",
  },
  sortImage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: borderRadius.primary,
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  actionIconsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    cursor: "pointer",
    boxShadow: innerBorder(BORDER.DARK),
    borderRadius: borderRadius.primary,
  },
  actionIconsDisabledContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    background: BORDER.DISABLED,
    boxShadow: innerBorder(BORDER.DISABLED),
    borderRadius: borderRadius.primary,
    cursor: "not-allowed",
  },
  emptyRecordContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  actionIconsDisabled: {
    cursor: "not-allowed",
  },
  comapnyCellPadding: {
    padding: "0px 12px",
  },

  // cell width according to ui_id of head. It is common for all table please do change ony if required.
  userInfoCellWidth: {
    width: "17.813vw",
    minWidth: "17.813vw",

  },
  companyInfoCellWidth: {
    width: "17.813vw",
    minWidth: "17.813vw",
  },
  textCellWidth: {
    width: "13.542vw",
    minWidth: "13.542vw",
  },
  textCellLargeWidth: {
    width: "16.542vw",
    minWidth: "16.542vw",
  },
  textCellXLargeWidth: {
    width: "20vw",
    minWidth: "20vw",
  },
  boldTextCellWidth: {
    width: "17.813vw",
    minWidth: "17.813vw",
  },
  boldTextCellLargeWidth: {
    width: "21.542vw",
    minWidth: "21.542vw",
  },
  boldTextCellXLargeWidth: {
    width: "30.818vw",
    minWidth: "30.818vw",
  },
  companyURLCellWidth: {
    width: "13.542vw",
    minWidth: "13.542vw",
  },
  emailCellWidth: {
    width: "20vw",
    minWidth: "20vw",
  },
  actionCellWidth: {
    width: "6.250vw",
    minWidth: "6.250vw",
  },
  actionCellLargeWidth: {
    width: "9.250vw",
    minWidth: "9.250vw",
  },
  actionCellXLargeWidth: {
    width: "14.25vw",
    minWidth: "14.25vw",
  },
  numberTextCellWidth: {
    width: "10.417vw",
    minWidth: "10.417vw",
  },
  marketingListCellWidth: {
    width: "17.813vw",
    minWidth: "17.813vw",
    position: "relative",
  },
  marketingListCellLargeWidth: {
    width: "20.813vw",
    minWidth: "20.813vw",
  },
  textButtonCellWidth: {
    width: "11.333vw",
    minWidth: "11.333vw",
  },
  statsDataCellWidth: {
    width: "11.771vw",
    minWidth: "11.771vw",
  },
  timeStampCellWidth: {
    width: "15.625vw",
    minWidth: "15.625vw",
  },
  timeStampCellLargeWidth: {
    width: "18.625vw",
    minWidth: "18.625vw",
  },
  buttonCellWidth: {
    width: "8.333vw",
    minWidth: "8.333vw",
  },
  statusCellWidth: {
    width: "15.104vw",
    minWidth: "15.104vw",
  },
  statsDataCellLargeWidth: {
    width: "16.333vw",
    minWidth: "16.333vw",
  },
  textURLCellWidth: {
    width: "20.625vw",
    minWidth: "20.625vw",
  },
  durationTextCellWidth: {
    width: "20.542vw",
    minWidth: "20.542vw",
  },
  taskListCellWidth: {
    width: "20.313vw",
    minWidth: "20.313vw",
  },
  userDetailCellWidth: {
    width: "14.583vw",
    minWidth: "14.583vw",
  },
  nameDetailCellWidth: {
    width: "25vw",
    minWidth: "25vw",
  },
  companyDetailCellWidth: {
    width: "14.583vw",
    minWidth: "14.583vw",
  },
  taskTypeCellWidth: {
    width: "8.333vw",
    minWidth: "8.333vw",
  },
  dueTimeStampCellWidth: {
    width: "14.583vw",
    minWidth: "14.583vw",
  },
  leadStatusCellWidth: {
    width: "18.104vw",
    minWidth: "18.104vw",
  },
  pinContainer: {
    position: "absolute",
    // padding:"2px 0"
  },
  pinIcon: {
    width: "18px",
    height: "18px",
  },
  amountCellUpdateWidth: {
    width: "8vw",
    minWidth: "8vw"
  },
  requestCellUpdateWidth: {
    width: "15vw",
    minWidth: "15vw"
  },
  approveCellUpdateWidth: {
    width: "14.583vw",
    minWidth: "14.583vw",
  },
  plainCellUpdateWidth: {
    width: "12vw",
    minWidth: "12vw"
  },
  approveDesign: {
    backgroundColor: "#D3DAE3",
    borderRadius: "50%",
    height: "30px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

  },
  approveOuter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    //paddingLeft:"1.042vw"
  },
  firstNameCellWidth: {
    // width: "342px"
    width: "13.542vw",
    minWidth: "13.542vw"
  },
  firstnameCell: {
    display: "flex",
    alignItems: "center",
    background: BASE.WHITE,
    overflow: "hidden",


    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  messageCellWidth: {
    // width: "342px"
    width: "20vw",
    minWidth: "20vw"
  },
  messageCell: {
    display: "flex",
    alignItems: "center",
    background: BASE.WHITE,
    overflow: "hidden",
    padding: "17.5px 12px",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
    },
  },
  rightStickyCell: {
    position: "sticky",
    right: "0",
    boxShadow: SHADOW.tags,
    zIndex: "9",
    background: "#fff"
  },
  rightStickyhead: {
    position: "sticky",
    right: "0",
    boxShadow: SHADOW.tags,
  }
};

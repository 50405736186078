import { CAMPAIGN_LIST_GET_FAIL, CAMPAIGN_LIST_GET_REQUEST, CAMPAIGN_LIST_GET_SUCCESS, SELECTED_CAMPAIGN_DETAILS_FAIL, SELECTED_CAMPAIGN_DETAILS_REQUEST, SELECTED_CAMPAIGN_DETAILS_SUCCESS } from "../constants/campaignConstants";

export const campaignsListFetchReducer = (state = {}, action) => {
    switch (action.type) {
      case CAMPAIGN_LIST_GET_REQUEST:
        return { loading: true };
  
      case CAMPAIGN_LIST_GET_SUCCESS:
        return { loading: false, payload: action.payload };
  
      case CAMPAIGN_LIST_GET_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export const selectedCampaignDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case SELECTED_CAMPAIGN_DETAILS_REQUEST:
        return { loading: true };
  
      case SELECTED_CAMPAIGN_DETAILS_SUCCESS:
        return { loading: false, payload: action.payload };
  
      case SELECTED_CAMPAIGN_DETAILS_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
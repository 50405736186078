import { COMPANY_BASIC_DETAILS_FAIL, COMPANY_BASIC_DETAILS_REQUEST, COMPANY_BASIC_DETAILS_SUCCESS } from "../constants/companyConstants";

export const companyBasicDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_BASIC_DETAILS_REQUEST:
      return { loading: true };

    case COMPANY_BASIC_DETAILS_SUCCESS:
      return { loading: false, columns: action.payload, totalRecords: action.totalRecords };

    case COMPANY_BASIC_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const companyDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_BASIC_DETAILS_REQUEST:
      return { loading: true };

    case COMPANY_BASIC_DETAILS_SUCCESS:
      return { loading: false, columns: action.payload, totalRecords: action.totalRecords };

    case COMPANY_BASIC_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};


import React from 'react';
import { Box } from '@mui/system';
import { styles } from './styles';
import { Icon } from '../../../../components';

const AccountDefault = () => {

    const classes = styles();

    return <Box className={ classes.container } >
        <Box className={ classes.accountDefaultContainer } >
            <Box className={ classes.accountDefaultFlex } >
                <h3 className={classes.title}>Account Defaults</h3>
                <p className={classes.accountDefaultPara} >To edit these settings, you must be an authorized user.</p>
            </Box>
            <Box className={ classes.containInputs } >
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >Account name </h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                        <Icon name="fa fa-question-circle-o" color="#ff4848" />
                    </Box>

                    <input type="text"  placeholder='Abc company' className={ classes.accountDefaultInput } />
                </Box>
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >Time zone </h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                        <Icon name="fa fa-question-circle-o" color="#ff4848" />
                    </Box>

                    <input type="text"  placeholder='UTC -04:00 East-Indiana' className={ classes.accountDefaultInput } />
                </Box>
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >Fiscal year </h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                        <Icon name="fa fa-question-circle-o" color="#ff4848" />
                    </Box>

                    <input type="text"  placeholder='January - December' className={ classes.accountDefaultInput } />
                </Box>
            </Box>

            <Box>
                <h3 className={ classes.subTitle } >Comapny Information</h3>
                <p className={ classes.notificationPara } >This information will be used as a default where needed. If you’re looking to update your company information for billing, visit <span className={ classes.redText }  >Account & Billing.</span> </p>
                <Box className={ classes.containInputs } >
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >Comapny name </h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                    </Box>

                    <input type="text"  placeholder='Abc company' className={ classes.accountDefaultInput } />
                </Box>
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >Comapny Domain </h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                    </Box>

                    <input type="text"  placeholder='www.resourcifi.com' className={ classes.accountDefaultInput } />
                </Box>
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >Company Address </h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                    </Box>

                    <input type="text"  placeholder='2500 Broadway' className={ classes.accountDefaultInput } />
                </Box>
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >Company address line 2 </h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                    </Box>

                    <input type="text"  placeholder='Ste - F 125' className={ classes.accountDefaultInput } />
                </Box>
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >City </h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                    </Box>

                    <input type="text"  placeholder='Los Angeles' className={ classes.accountDefaultInput } />
                </Box>
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >State</h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                    </Box>

                    <input type="text"  placeholder='CA' className={ classes.accountDefaultInput } />
                </Box>
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >Zip </h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                    </Box>

                    <input type="text"  placeholder='90404' className={ classes.accountDefaultInput } />
                </Box>
                <Box className={ classes.eachInputBox } >
                    <Box className={ classes.containInputTitle } >
                        <h4 className={ classes.inputTitle } >Country </h4>
                        {/* <HelpOutlineIcon className={ classes.inputIcon } /> */}
                    </Box>

                    <input type="text"  placeholder='US' className={ classes.accountDefaultInput } />
                </Box>

            </Box>
            </Box>
        </Box>
    </Box>
}
export default AccountDefault;
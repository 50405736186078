import { makeStyles } from "@mui/styles";
import {BACKGROUND_COLOR} from '../../theme/colors'

export const styles = makeStyles({
  flex:{
    display:'flex',
    paddingRight:"1rem"
  },
  container: {
    backgroundColor: BACKGROUND_COLOR,
    padding: 0,
    height: "100vh",
  },
  title: {
    fontWeight: "700",
    fontSize: "20px",
    letterSpacing: "0.6px",
    color: "#102F51",
    alignSelf:"center",
    whiteSpace:"nowrap",
    "@media (max-width: 768px)":{
      marginTop:"0.4rem"
    }
  },
  header: {
    background: "white",
    display: "flex",
    paddingLeft:'1.5rem',
    height:"4rem",
    "@media (max-width: 768px)":{
      flexDirection:"column",
    }
  },
  contentContainer:{
    paddingRight:"1.5rem",
    width:'100%'
  },
  settingContainer: {
    margin: "1.5rem",
    width: "20vw",
    background: "white",
    borderRadius: "5px",
    height: "100%",
    minWidth:"250px",
    "@media (max-width: 768px)":{
      alignSelf:'center',
      width:"70vw"
    }
  },
  settingsTitle: {
    color: "#102F51",
    background: "#d3dae3",
    padding: "0.8rem 1rem",
    fontWeight: "700",
    borderRadius: "5px 5px 0 0",

  },
  mainContainer: {
    display: "flex",
    "@media (max-width: 768px)":{
      flexDirection:"column"
    }
  },
  settingsHeading: {
    padding: "1.5rem 1rem 0rem 1rem",
    color: "#102F51",
    letterSpacing:"0.7px",
    fontWeight:"700"
  },
  eachPreference: {
    display: "flex",
    padding: "1rem",
    borderTop: "1px solid #d3dae3",
    background: "#D3DAE324",
    cursor: "pointer",
    fontWeight:"400"
  },
  containPreferences: {
    border: "1px solid #d3dae3",
    margin: "1rem",
    borderRadius: "4px",
    borderTop: "none",
  },
  selectedIcon: {
    color: "#ff4848",
  },
  icon: {
    color: "#626B7E",
  },
  selectedText: {
    color: "#ff4848",
    fontWeight: "500",
    alignSelf: "center",
    margin: "0 0.4rem",
    fontSize: "14px",
  },
  text: {
    color: "#626b7e",
    fontWeight: "600",
    alignSelf: "center",
    margin: "0 0.4rem",
    fontSize: "14px",
  },
  selectedEachPreference: {
    display: "flex",
    padding: "1rem",
    borderTop: "1px solid #d3dae3",
    background: "#FFE9E9",
    cursor: "pointer",
    borderRadius: "4px 4px 0 0",
    fontWeight:"400"
  },
  containGeneralTabs: {
    display: "flex",
    marginLeft: "10vw",
    marginTop:"1rem",
    "@media (max-width: 768px)":{
      margin:0,
      justifyContent:"center"
    }
    
  },
  selectedTab: {
    position: "relative !important",
    display: "flex",
    boxShadow: "1px 1px 2px #00000014",
    background: "#FF4848",
    padding: "1rem 2rem",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    cursor: "pointer",
    marginTop: "0.3rem",
    "&::before": {
      content: '""',
      boxShadow: "10px 0 0 0 #ff4848",
      opacity: 1,
      position: "absolute",
      left: "-20px",
      width: "20px",
      height: "10px",
      borderRadius: "0 0 10px 0",
      bottom: 0,
    },
    "&::after": {
      content: '""',
      boxShadow: "10px 0 0 0 #ff4848",
      opacity: 1,
      position: "absolute",
      right: "-20px",
      width: "20px",
      height: "10px",
      borderRadius: "0 10px",
      bottom: 0,
      transform: "rotate(180deg)",
    },
    "@media (max-width: 768px)":{
      padding:"0.5rem 1rem"
    }
  },
  tabText:{
      color:"white",
      fontSize:"12px",
      fontWeight:"400",
      alignSelf:'center'
  },
  unselectedTab:{
    position: "relative !important",
    display: "flex",
    boxShadow: "0px 0px 1px #d3dae3",
    padding: "1rem 2rem",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    cursor: "pointer",
    marginTop: "0.3rem",
    "&::before": {
      content: '""',
      opacity: 1,
      position: "absolute",
      left: "-20px",
      width: "20px",
      height: "10px",
      borderRadius: "0 0 10px 0",
      bottom: 0,
    },
    "&::after": {
      content: '""',
      opacity: 1,
      position: "absolute",
      right: "-20px",
      width: "20px",
      height: "10px",
      borderRadius: "0 10px",
      bottom: 0,
      transform: "rotate(180deg)",
    },
    "@media (max-width: 768px)":{
      padding:"0.8rem 1.5rem"
    }

  },
  unselectedTabText:{
      color:"#626B7E",
      fontSize:"12px",
      fontWeight:"400"
  },
  containAccountTabs:{
    display:"flex",
    justifyContent:"space-between",
    width:"100%"
  },
  notificationInput: {
    border: "none",
    background: BACKGROUND_COLOR,
    width: "100%",
    paddingLeft: "0.5rem",
    fontWeight: "300",
  },
  containNotificationInput: {
    background: BACKGROUND_COLOR,
    width: "15vw",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    padding: "0.5rem",
    display: "flex",
    justifyContent: "flex-start",
    alignSelf:'center',
    height:"33px"
  },
});

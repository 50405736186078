let liveHost =
  window.location.host.split(".")[window.location.host.split(".").length - 2] +
  "." +
  window.location.host.split(".")[window.location.host.split(".").length - 1];

// local host name here
let localHost = liveHost[1];

//Base URL
// export const BASE_URL = "https://beta-gateway.reachiq.io";
export const BASE_URL = process.env.REACT_APP_GATEWAY;
export const PORT = "";
export const HOSTNAME = localHost;
// const hosts = HOSTNAME.trim()?.split(".");
const host = "reachiq.io";
export const HOST_URL = host;
export const protocol = "http://";
// export const REGISTRATION_LINK = "http://beta-registration.reachiq.io"
export const REGISTRATION_LINK = process.env.REACT_APP_DEV_REGISTRATION_LINK;

//Endpoints

export const authEndpoints = {
  register: "auth/register",
  token: "admin-auth/get-admin-token",
  checktoken: "admin-auth/check-url-token",
  login: "admin-auth/login",
  verifyEmail: "auth/verify-email",
  verifyDomain: "auth/validate-subdomain",
  logout: "admin-auth/logout",
  checkLogin: "admin-auth/refresh-token",
  sendMailForResetPassword: "admin-auth/reset-password",
  verifyTokenForResetPassword: "/admin-auth/reset-password/verify-token",
  resetPassword: "/admin-auth/update-password",
  changePassword: "admin-auth/change-password",
  updateprofile: "admin-auth/update-profile",
  verifyUserEmail: "auth/verify-user-email",
  updateApiDetails: "auth/update-api-details",
  getApiKeyDetails: "auth/get-api-details",

  // admin routes
  userList: "auth/users-list",
  inviteUsers: "auth/invite-users",
  invitedUsersList: "auth/invited-users-list",
  deleteInvitation: "auth/delete-invitation",
  updateUserStatus: "auth/change-user-status",
  plateformuser: "account-user/user/list",
  userdetails: "account-user/user/detail",
  plateformuserflag: "account-user/user/change-flag",
  pluginchangeflag: "account-user/plugin-user/change-flag",
  pluginuser: "account-user/plugin-user/list",
  accountlist: "user/user-listing",
  accountStats: "account-user/account/change-status",
  accountflag: "account-user/account/change-flag",
  accountdetail: "account-user/account/detail",
  plugindetail: "account-user/plugin-user/detail",
  updateusercredit: "account-user/user/update-user-credit",
  updateplugincredit: "account-user/plugin-user/update-user-credit",
  accountstatuschange: "account-user/user/change-status",
  companyaccountstatuschange: "account-user/account/change-status",
  pluginchangestatus: "account-user/plugin-user/change-status",
  requestcredit: "account-user/plugin/req-credit",
  dataaccessreq: "account-user/data-access-request/list",
  dataaccessreqdetail: "account-user/data-access-request/detail",
  creditaccept: "account-user/plugin/req-credit-accept",
  creditdecline: "account-user/plugin/req-credit-decline",
  creditinfo: "account-user/credit-log/list",
  plugincreditinfo: "account-user/plugin-user-credit-log",
  pluginreferal: "account-user/plugin/referal",
  emailTemplatetest: "account-user/invoke-lamda",
  contentList: "content/content-listing",
  createContent: "content/content-create",
  updateContent: "content/content-update",
  deleteContent: "content/content-delete",
  changeStatus: "user/change-status",

  //////////////////EVENT/////////////////

  eventList: "event/event-listing",
  imageLocation: "/event/event-image",
  eventCreate: "event/event-create",
  eventUpdate: "event/event-update",
  eventDelete: "event/event-delete",
  countryList: "user/get-all-countries",
  stateList: "user/get-all-states",
  cityList:"user/get-all-cities",



  ///////////////CONTACT//////////
  contactList: "contact-us/listing",

  //////////TECHNOLOGY/////////////
  tecnologyList: "tech/tech-listing",
  technologyCreate: "tech/tech-create",
  technologyUpdate: "tech/tech-update",
  technologyDelete: "tech/tech-delete",

  companyList: "company/company-listing",
  companyDelete: "company/company-delete",

  ////////////BUSINESS///////////////////
  businessContent:"company/get-claim-company",
  statusApprove:"company/change-claim-status",
  







  //////////////// TEMPLATE ROUTES

  createTemplate: "account-user/template/create",
  templateList: "account-user/template-list",
  updateTemplate: "account-user/template/update",



};

export const widgetEndpoints = {
  Compnaysitedata: "admin-dashboard/company-website/count",
  Linkedinfeed: "admin-dashboard/linkedin-feed/count",
  Linkedincomapny: "admin-dashboard/linkedin-comapny/count",
  Linkedinpeople: "admin-dashboard/linkedin-people/count",
  dashboardpluginuser: "admin-dashboard/plugin-user/count",
  dashboardplateformuser: "admin-dashboard/main-user/count",
  gmailsignature: "admin-dashboard/gmail-signature/count",
};

export const leadsEndpoints = {
  // suggestions endpoints

  getJobFilters: "admin-leads/get-job-titles",
  getIndustryFilters: "admin-leads/get-industries",
  getLocationCityFilter: "admin-leads/get-person-city",
  getLocationStateFilter: "admin-leads/get-person-state",
  getLocationCountryFilter: "admin-leads/get-person-country",
  getCompanyTechnologiesFilter: "admin-leads/get-technologies",
  getCompanyName: "admin-leads/get-companies",

  // searching data endpoint
  search: "admin-leads/person/search",
  savedContactSearch: "admin-leads/person/saved-contacts-search",

  // people endpoints
  peopleBasic: "/admin-leads/person",
  userDetails: "/admin-leads/person/details",
  CompanyDetails: "/admin-leads/company/details",
  userDetailsflag: "/admin-leads/flag-person/detail",

  flaggedPerson: "admin-leads/person/flagged",
  personflagcount: "admin-leads/person/flag-count",
  //Quarantine
  flaggedUpdate: "admin-leads/quarantine/update",

  preApproveUpdate: "admin-leads/pre-approved/save-primary-record",

  // company endpoints
  companyBasic: "/admin-leads/company",
  companySearch: "admin-leads/company/search",
};

export const contactListEndPoints = {
  createList: "list/people/contactlist/create",
  getList: "admin-leads/get-contact-list",
  getAllList: "admin-leads/get-campaign-contact-list",
  getAllListCount: "admin-leads/get-contact-list-count",
  saveToList: "list/people/save-to-list",
  alreadySaveToList: "list/people/already-save-to-list",
  bulkAlreadySaveToList: "list/people/bulk-already-record-saved-to-list",
  bulkSaveToList: "list/people/bulk-new-record-saved-to-list",
  exportList: "list/people/save-to-list",
  bulkExportList: "list/people/bulk-new-record-saved-to-list",
  singleEmailUnlock: "list/people/get-user-email",
  advanceExport: "list/people/advance-people-bulk-export",

  // Compnay endpoints
  companyBulkExport: "list/company/bulk-new-record-saved-to-list",
  companybulkSaveToList: "list/company/bulk-new-record-saved-to-list",
  companyExportList: "list/company/save-to-list",
  companySaveToList: "list/company/save-to-list",
  companyCreateList: "list/company/contactlist/create",
  companyGetList: "admin-leads/get-account-list",

  // getCompanyList:"admin-leads/get-contact-list",
};

export const viewEndPoints = {
  createView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/view/create",
  getAllView: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/view-all",
  deleteView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/view/delete",
  pinView: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/view/pin",
  unpinView: process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/view/unpin",
  makeDefaultView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/view/make-default",
  updateView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "person/view/update",
  viewUpdate:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS +
    "person/view/update-property",

  // Company endpoints
  createCompanyView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "company/view/create",
  CompanyViewUpdate:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS +
    "/company/view/update-property",
  getCompanyViews:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "company/view-all",
  deleteCompanyView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "company/view/delete",
  pinCompanyView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/company/view/pin",
  unpinCompanyView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/company/view/unpin",
  makeCompanyDefaultView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/company/view/make-default",
  updateCompanyView:
    process.env.REACT_APP_ENDPOINT_PREFIX_LEADS + "/company/view/update",
};
export const sequenceEndPoints = {
  createSequence: "/sequence/create",
  listingSequence: "/sequence/list",

  // steps endpoints
  validateSequenceSteps: "/sequence/steps/",
  deleteStep: "/sequence/step/delete",
  createTodoStep: "/sequence/step/todo/create",
  createCallStep: "sequence/step/call/create",
  createAutomatedEmailStep: "/sequence/step/automated_mail/create",
  updateAutomatedEmailStep: "/sequence/step/automated_mail/update",
  updateCallStep: "/sequence/step/call/update",
  updateTodoStep: "/sequence/step/todo/update",
  sortSequence: "/sequence/sort",
  deleteSequece: "/sequence/delete",
  cloneSequence: "/sequence/clone",
  searchSequence: "/sequence/search",
  createFolder: "/sequence/folder/create",
  getFullFolderList: "sequence/folder/list",
  getFolder: "/sequence/folder/get",
  deleteFolder: "/sequence/folder/delete",
  moveSequence: "/sequence/move-sequence",
  updateFolder: "/sequence/folder/update",
};

export const campaignEndpoints = {
  sequenceList: "campaigns/list/sequences",
  contactlist: "campaigns/list/contactlist",
  createCampaign: "campaigns/create",
  getCampaignList: "campaigns/list",
  deleteCampaign: "campaigns/delete",
  getCampaignStatus: "campaigns/is-setup-completed",
  campaignOverviewSteps: "campaigns/overview/steps",
  campaignOverviewSequence: "campaigns/overview/sequence",
  activityLogs: "campaigns/activity-logs",
  campaignContactList: "campaigns/contacts",
  eachCampaignDetail: "campaigns/get",
};

export const QuarantineEndpoints = {
  QuarantineList: "admin-leads/quarantine/search",

  QuarantineDetail: "admin-leads/quarantine/details",
  QuarantineDetailFlag: "admin-leads/quarantine/flag-details",
  QuarantineflaggedCount: "admin-leads/quarantine/flag-count",
  //Filter
  QuarantineCompanySearch: "admin-leads/get-companies",

  quarantinegetJobFilters: "admin-leads/quarantine/get-job-titles",
  quarantinegetIndustries: "admin-leads/quarantine/get-industries",
  quarantinegetTechnologies: "admin-leads/quarantine/get-technologies",
  quarantinegetCity: "admin-leads/quarantine/get-person-city",
  quarantinegetState: "admin-leads/quarantine/get-person-state",
  quarantinegetCountry: "admin-leads/quarantine/get-person-country",
  quarantinegetcompanyCity: "admin-leads/quarantine/get-company-city",
  quarantinegetcompanyState: "admin-leads/quarantine/get-company-state",
  quarantinegetcompanyCountry: "admin-leads/quarantine/get-company-country",
  quarantinegetCompanies: "admin-leads/quarantine/get-companies",
  quarantinegetcompanyName: "admin-leads/quarantine/get-company-names",

}
export const PreApprovedQuarantineEndpoints = {

  //PreApproved Quarantine Data

  preApproveQuarantineData: "admin-leads/pre-approved/search",
  preApproveQuarantineDetail: "admin-leads/pre-approved/details",
  preApproveQuarantineDetailFlag: "admin-leads/pre-approved/flag-details",
  preApprovedVerified: "admin-leads/company/details",
  //PreApproved Quarantine Data Filter

  prequarantinegetJobFilters: "admin-leads/pre-approved/get-job-titles",
  prequarantinegetIndustries: "admin-leads/pre-approved/get-industries",
  prequarantinegetTechnologies: "admin-leads/pre-approved/get-technologies",
  prequarantinegetCity: "admin-leads/pre-approved/get-person-city",
  prequarantinegetState: "admin-leads/pre-approved/get-person-state",
  prequarantinegetCountry: "admin-leads/pre-approved/get-person-country",
  prequarantinegetcompanyCity: "admin-leads/pre-approved/get-company-city",
  prequarantinegetcompanyState: "admin-leads/pre-approved/get-company-state",
  prequarantinegetcompanyCountry: "admin-leads/pre-approved/get-company-country",
  prequarantinegetCompanies: "admin-leads/pre-approved/get-companies",
  prequarantinegetcompanyName: "admin-leads/pre-approved/get-company-names",
  PreapproveflaggedCount: "admin-leads/pre-approved/approve-count",
  PreapprovereFlag: "admin-leads/pre-approved/again-flag-detail",
  PreapprovereFlagCount: "admin-leads/pre-approved/again-flag-count",
  PreapprovereFlaggedUpdate: "admin-leads/pre-approved/flagged",

}

export const ContactEndpoints = {

  //PreApproved Quarantine Data

  contactlist: "account-user/contact-us",
  contactdetails: "account-user/contact-us-details",



}

export const EarlyaccessEndpoints = {

  //PreApproved Quarantine Data

  earlyaccesslist: "account-user/get-early-access",
  earlyaccessdetails: "account-user/get-early-access-detail",
  earlyaccessinvite: "account-user/beta-tenant-invitation"


}


export const dashboardEndpoints = {
  getDashboard: "admin-d3-dashboard/dashboard-list",
  createDashboard: "admin-d3-dashboard/create-dashboard",
  deleteDashboard: "admin-d3-dashboard/delete-dashboard",
  updateDashboard: "admin-d3-dashboard/update-dashboard",
  layoutDashboard: "admin-d3-dashboard/update-dashboard-layout",
  getSequenceList: "/campaign/list/sequences",
  getCampaignList: "/campaign/campaigns-suggestion-list",
  getInitialReport: "/admin-d3-dashboard/get-initial-report",
  cloneDashboard: "/admin-d3-dashboard/clone-dashboard",
  setDefaultDashboard: "/admin-d3-dashboard/mark-as-default",
  updateWidgetFilters: "/admin-d3-dashboard/update-widget-filters",
};

export const dashboardWidgetEndpoints = {
  getDashboardWidget: "admin-d3-dashboard/widgets-list",
  createDashboardWidget: "admin-d3-dashboard/create-widget",
  deleteDashboardWidget: "admin-d3-dashboard/delete-dashboard-widget",
  updateDashboardWidget: "admin-d3-dashboard/rename-dashboard-widget",
  cloneDashboardWidget: "admin-d3-dashboard/clone-dashboard-widget",
  getChartData: "admin-d3-dashboard/get-chart-data",
};

export const importEndpoints = {
  createDynamicField: "import/create-field",
  getDynamicField: "import/get-custom-fields",
  getFieldMapping: "import/get-fields-mapping",
  updateFieldMapping: "import/update-field-mapping",
  blackListFileFieldMapping: "/import/update-blacklist-field-mapping",
  domainFileFieldMapping: "/import/update-blacklist-domain-field-mapping",
  blackListContact: "import/block-emails",
  blackListDomain: "import/block-domains",
  uploadFileInitialEndpoint: "import/uploads/initializeMultipartUpload",
  uploadFileMultipartEndpoint: "import/uploads/getMultipartPreSignedUrls",
  uploadFilefinalEndpoint: "import/uploads/finalizeMultipartUpload",
  getImportedFiles: "import/get-imported-files",
  isBlackListDone: "import/is-blacklist",
  getBlackListEmail: "import/get-blacklist-emails",
  getBlackListDomains: "import/get-blacklist-domains",
  //Csv enrichment
  updateFieldCsvMapping: "import/update-csv-enrichment-feild-mapping",
  customField: "import/delete-field",
  getImport: "import/get-csv-data  ",
  getUser: "import/get-owner-name",
};


export const listEndPoints = {
  getFolderDetail:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/get-folder-detail",
  createFolder:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactfolder/create",
  showFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactlist/list",
  deleteFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactfolder/delete",
  updateFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactfolder/update",
  moveToFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/contactlist/move-list-to-folder",
  bulkDeleteList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST + "people/contactlist/delete",
  removeFolderList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/contactlist/remove-list-from-folder",
  countList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/contactfolder/contact-list-count",
  getMoveList:
    process.env.REACT_APP_ENDPOINT_PREFIX_LIST +
    "people/contactfolder/get-user-folder",
};

export const feedbackForm = {
  feedback: process.env.REACT_APP_ENDPOINT_PREFIX_AUTH + "contact-us",
};


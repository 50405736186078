import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DONE_IMAGE, FUTUNEAR_IMAGE, LOGIN_REACHIQ_LOGO } from "../../utils/constants/assets";
import { RED_COLOR } from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import { HOME_PATH } from "../../utils/constants/routes";
import { Anchor, RightsTag } from "../../components";
import { DO_NOT_SELL_LINK, PRIVACY_POLICY_LINK, TERMS_SERVICE_LINK } from "../../utils/constants";
import { Button } from "../../components/Buttons";

const styles = makeStyles({
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  containLogo: {
    display: "flex",
    justifyContent: "center",
    margin: "0 20vw",
    marginTop: "10vh",
  },
  logo: {
    width: "clamp(90px,11vw,220px)",
    height: "clamp(90px,11vh,220px)",
    objectFit: "contain",
    alignSelf: "center",
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "0, 1rem",
    paddingBottom: "15vh",
  },
  title: {
    color: "#102F51",
    fontWeight: "300",
    fontSize: "clamp(1.4rem, 2.5vw, 4.5rem)",
    letterSpacing: "1x",
    marginBottom: "2vh",
    textAlign: "center",
  },
  para: {
    color: "#626B7E",
    fontWeight: "400",
    fontSize: "clamp(0.9rem, 1vw, 2.5rem)",
    marginBottom: "4vh",
    textAlign: "center",
  },
  red: {
    color: "#0071E3",
  },
  button: {
    backgroundColor: RED_COLOR,
    color: "white",
    fontSize: "clamp(0.9rem, 1vw, 2.5rem)",
    padding: "1rem 4rem",
    border: "none",
    borderRadius: "8px",
    marginLeft: "0.6rem",
    cursor: "pointer",
    boxShadow: "0px 9px 20px #ff16543c",
    fontWeight: "500",
  },
  containFooter: {
    display: "flex",
    justifyContent: "space-around",
    padding: "4vh 0 0 0",
    borderTop: "1px solid #d3dae3",
  },
  policyPara: {
    color: "#626B7E",
    fontWeight: "400",
    fontSize: "clamp(0.8rem, 0.9vw, 2.5rem)",
    marginBottom: "4vh",
    textAlign: "center",
  },
  termsPara: {
    fontSize: "clamp(12px,0.8vw,36px)",
    color: "#626B7E",
    fontWeight: "500",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  privacyLink: {
    fontSize: "clamp(12px,0.8vw,36px)",
    color: "#626B7E",
    fontWeight: "500",
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    transition: "100ms",
    "&:hover": {
      color: "#ff4848"
    }
  },
});

const ThankYouScreen = ({ email, image, resend }) => {
  const classes = styles();
  const navigate = useNavigate();

  const handleNavigate = () => {
    resend ? window.location.reload() : navigate(HOME_PATH);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.containLogo}>
        <img src={FUTUNEAR_IMAGE} className={classes.logo} alt="" />
      </Box>
      <img src={image ? image : DONE_IMAGE} className={classes.logo} alt="" />

      <Box className={classes.content}>
        <h2 className={classes.title}>
          An email has been sent to <span className={classes.red}>{email}</span>
        </h2>

        <p className={classes.para}>
          We have sent a Reset Password link on{" "}
          <span className={classes.red}>{email}</span> to reset your password.
        </p>
        <div style={{ width: "140px" }}>
          <Button
            variant={"primary"}
            label={resend ? "Resend" : "Go Home"}
            size={"md40"}
            onClick={handleNavigate}
          />
        </div>
        {/* <button className={classes.button} onClick={handleNavigate}>
          {resend ? "Resend" : "Go Home"}
        </button> */}
      </Box>
      <Box className={classes.containFooter}>
        <RightsTag className={classes.termsPara} />
        <Box className={classes.policyPara}>
          <Anchor
            href={TERMS_SERVICE_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            Terms of Service |
          </Anchor>
          <Anchor
            href={PRIVACY_POLICY_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            Privacy Policy |
          </Anchor>
          <Anchor
            href={DO_NOT_SELL_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            Do not sell my information
          </Anchor>
        </Box>
      </Box>
    </Box>
  );
};

export default ThankYouScreen;

import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import { generateRandomColor } from "../../utils/helpers";

const styles = makeStyles({
  text: {
    alignSelf: "center",
    fontSize: "12px",
    display: "flex",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    lineHeight: "1.65",
    fontWeight: "400",
  },
});

const createInitials = (arr) => {
  let result = "";
  for (let i = 0; i < arr?.length; i++) {
    if (arr?.[i]?.[0]) {
      result += arr[i][0];
    }
  }
  return result?.slice(0, 2);
};

const InitialsIcon = ({
  name,
  color,
  background,
  size,
  fontWeight,
  padding,
  index,
  height,
  width,
  margin,
  borderRadius,
}) => {
  const classes = styles();
  const initialsArray = name?.trim()?.split(" ");
  const initials = createInitials(initialsArray);
  // const initials = (name?.trim()?.split(" ")[0][0] || "") + (name?.trim()?.split(" ")[1][0]?.toUpperCase() || "");

  const generateColor = () => {
    const colors = [
      "#7954F6",
      "#3ACAF9",
      "#F6DF5D",
      "#11D69F",
      "#EE3962",
      "#F77F53",
      "#663E70",
    ];
    return colors[index % 6];
  };

  return (
    <Fragment>
      <div
        style={{
          background: background
            ? background
            : index
            ? generateColor()
            : generateRandomColor(),
          display: "flex",
          justifyContent: "center",
          borderRadius: borderRadius ? borderRadius : "50%",
          alignSelf: "center",
          padding: padding ? padding : "0.5rem",
          margin: margin ? margin : "0 0.4rem 0 0.5rem",
        }}
      >
        <p
          style={{
            color: color ? color : "white",
            fontSize: size ? size : "13px",

            fontWeight: fontWeight ? fontWeight : "500",
            alignSelf: "center",
            alignItems: "center",
            padding: padding ? padding : "unset",
            height: height ? height : "20px",
            width: width ? width : "20px",
          }}
          className={classes.text}
        >
          {initials}
        </p>
      </div>
    </Fragment>
  );
};

export default InitialsIcon;

import { createData } from "../helpers";

// icons import
import UsersIcon from "../../components/Icon/StaticIcons/UsersIcon";
import KeyIcon from "../../components/Icon/StaticIcons/KeyIcon";

import {
  DashboardRoundedIcon,
  MonetizationOnIcon,
  LocationOnIcon,
  LanguageIcon,
  WorkIcon,
  ManageAccountsIcon,
  NotificationsIcon,
  GroupIcon,
  PersonIcon,
  CurrencyExchangeIcon,
  LogoutIcon,
  FiberManualRecordIcon,
  ContentCopyIcon,
  EventIcon,
  ContactsIcon,
  DevicesIcon,
  ApartmentIcon,
  BusinessIcon
} from "../../components/Icon/MuiIcons";

import Profile from "../../screens/ProfileScreen/components/Profile";

import Task from "../../screens/ProfileScreen/components/Task";
import Users from "../../screens/ProfileScreen/components/Users";
import Team from "../../screens/ProfileScreen/components/Team";
import UserTeams from "../../screens/ProfileScreen/components/Settings/UserTeams";
import AccountDefault from "../../screens/ProfileScreen/components/Settings/AccountDefault";

import {
  FilterTypeName,
  FilterTypeJobTitle,
  FilterTypeIndustry,
  FilterTypeSeniority,
  FilterTypeCompanySize,
  FilterTypeEmailVerification,
  FilterTypeEmailVerificationDate,
  FilterTypeCompanyRevenue,
  FilterTypeLocationCity,
  FilterTypeLocationState,
  FilterTypeLocationCountry,
  FilterTypeCompanyName,
  FilterTypeCompanyKeywords,
  FilterTypeCompanyTechnologies,
  FilterTypeCompanySeo,
} from "../../components/FilterTypes";
import {
  DASHBOARD_PATH,
  USER_PATH,
  CONTENT_PATH,
  EVENT_PATH,
  CONTACT_PATH,
  TECHNOLOGY_PATH,
  COMPANY_SCREEN_PATH,
  BUSINESS_PATH,
} from "./routes";

import ChangePassword from "../../screens/ProfileScreen/components/Settings/ChangePassword";
import { ICON_LABELS } from "./UI";
import { COMPANY_IMAGE, PEOPLE_IMAGE } from "./assets";

/****WIDGTES */

// Snackbar constants
export const AUTO_HIDE_DURATION = 5000;
export const EMITTER_SUCCESS = "alert_success";
export const EMITTER_WARNING = "alert_warning";
export const EMITTER_ERROR = "alert_error";
export const EMITTER_INFO = "alert_info";

export const SNACKBAR_CONFIG = {
  snackBarPlacement: {
    vertical: "top",
    horizontal: "center",
  },
};

// Laptop Size for media queries
export const MEDIA_QUERY_SIZE = "768";

export const CURRENT_YEAR = new Date().getFullYear();

export const EMAIL_ENCODING_SALT = "rnf@tech";

// Sidebar contants
export const DRAWER_WIDTH = 100;
export const DRAWER_CONTENT = [
  {
    text: "Dashboard",
    icon: DashboardRoundedIcon,
    toolTipElements: [],
    path: DASHBOARD_PATH,
  },
  {
    text: "Users",
    icon: GroupIcon,
    toolTipElements: [],
    path: USER_PATH,
  },
  {
    text: "Contents",
    icon: ContentCopyIcon,
    toolTipElements: [],
    path: CONTENT_PATH,
  },
  {
    text: "Events",
    icon: EventIcon,
    toolTipElements: [],
    path: EVENT_PATH,
  },
  {
    text: "Contact us",
    icon: ContactsIcon,
    toolTipElements: [],
    path: CONTACT_PATH,
  },
  {
    text: "Category",
    icon: DevicesIcon,
    toolTipElements: [],
    path: TECHNOLOGY_PATH,
  },
  {
    text: "Company",
    icon: ApartmentIcon,
    toolTipElements: [],
    path: COMPANY_SCREEN_PATH,
  },
  {
    text: "Claim Business",
    icon: BusinessIcon,
    toolTipElements: [],
    path: BUSINESS_PATH,
  },
]

export const PROFILE_HOVER_CONTENT = [
  { title: "Ayush Goyal", icon: PersonIcon },
  { title: "Your Credits", icon: CurrencyExchangeIcon },
  { title: "Notifications", icon: NotificationsIcon },
  { title: "Logout", icon: LogoutIcon },
];

//Temperory constants

export const PRIVACY_POLICY_LINK = "https://dev.reachiq.io/privacy-policy";
export const TERMS_SERVICE_LINK = "https://dev.reachiq.io/term-conditions";
export const DO_NOT_SELL_LINK = "https://dev.reachiq.io/privacy-manage-profile";

//Settings Constants

export const SETTINGS_GENERAL_TABS = [
  { tab: "Profile", component: Profile },

  { tab: "Task", component: Task },
];

export const SETTINGS_ACCOUNT_TABS = [
  { tab: "Users", component: Users },
  { tab: "Team", component: Team },
];

export const SETTINGS_PREFERENCES_LIST = [
  { icon: ICON_LABELS.PERSON, label: "My Profile", component: Profile },
  { icon: ICON_LABELS.KEY, label: "Change Password", component: ChangePassword },
  // { icon: NotificationsIcon, label: "Notification", component: Notifications },
  // { icon: SecurityIcon, label: "Security", component: Security },

];

export const SETTINGS_ACCOUNT_SETUP_LIST = [
  {
    icon: ManageAccountsIcon,
    label: "Account Defaults",
    component: AccountDefault,
  },
  { icon: GroupIcon, label: "User & Teams", component: UserTeams },
];

export const SETTINGS_NOTIFICATIONS_TOPICS = [
  {
    title: "Acadamy",
    description:
      " Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of content. ",
  },
  {
    title: "Contacts & Campaigns",
    description:
      " Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of content. ",
  },
  {
    title: "Dashboard",
    description:
      " Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of content. ",
  },
  {
    title: "Deals",
    description:
      " Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of content. ",
  },
];

export const USER_MODAL_COMPANY_DETAIL = [
  { icon: UsersIcon, text: "Employees", id: "number_of_employees" },
  { icon: WorkIcon, text: "", id: "industry" },
  {
    icon: KeyIcon,
    text: "",
    id: "company_keywords",
  },
  {
    icon: MonetizationOnIcon,
    text: "Annual Revenue",
    id: "annual_revenue",
  },
  {
    icon: LocationOnIcon,
    text: "",
    id: "company_address",
  },
  {
    icon: LanguageIcon,
    text: "",
    id: "company_website",
  },
];

export const TEMP_COMPANY_URL =
  "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/v1397188087/d49ae32b3869e61076d79695dc94096f.png";

export const EMAIL_VERIFICATION_TYPES = [
  { type: "Valid", isChecked: false, index: 1 },
  { type: "Invalid", isChecked: false, index: 2 },
  { type: "Accept-All", isChecked: false, index: 3 },
  { type: "Spam Trap", isChecked: false, index: 4 },
  { type: "Unknown", isChecked: false, index: 5 },
  { type: "Blank", isChecked: false, index: 6 },
];

export const DEFAULT_EMAIL_VERIFICATION_TYPES = [
  { type: "Valid", isChecked: false, index: 1 },
  { type: "Invalid", isChecked: false, index: 2 },
  { type: "Accept-All", isChecked: false, index: 3 },
  { type: "Spam Trap", isChecked: false, index: 4 },
  { type: "Unknown", isChecked: false, index: 5 },
  { type: "Blank", isChecked: false, index: 6 },
];

export const COMPANY_EMPLOYEES_COUNT = [
  { type: "1-10 employees", index: 1, isChecked: false },
  { type: "11-20 employees", index: 2, isChecked: false },
  { type: "21-50 employees", index: 3, isChecked: false },
  { type: "51-100 employees", index: 4, isChecked: false },
  { type: "101-200 employees", index: 5, isChecked: false },
  { type: "201-500 employees", index: 6, isChecked: false },
  { type: "501-1000 employees", index: 7, isChecked: false },
  { type: "1001-2000 employees", index: 8, isChecked: false },
  { type: "2001-5000 employees", index: 9, isChecked: false },
  { type: "5001-10000 employees", index: 10, isChecked: false },
  { type: "10000+ employees", index: 11, isChecked: false },
];

export const DEFAULT_COMPANY_EMPLOYEES_COUNT = [
  { type: "1-10 employees", index: 1, isChecked: false },
  { type: "11-20 employees", index: 2, isChecked: false },
  { type: "21-50 employees", index: 3, isChecked: false },
  { type: "51-100 employees", index: 4, isChecked: false },
  { type: "101-200 employees", index: 5, isChecked: false },
  { type: "201-500 employees", index: 6, isChecked: false },
  { type: "501-1000 employees", index: 7, isChecked: false },
  { type: "1001-2000 employees", index: 8, isChecked: false },
  { type: "2001-5000 employees", index: 9, isChecked: false },
  { type: "5001-10000 employees", index: 10, isChecked: false },
  { type: "10000+ employees", index: 11, isChecked: false },
];

export const SENIORIRT_TYPES = [
  { type: "owner", label: "Owner", isChecked: false },
  { type: "head", label: "Head", isChecked: false },
  { type: "founder", label: "Founder", isChecked: false },
  { type: "director", label: "Director", isChecked: false },
  { type: "cxo", label: "CXO", isChecked: false },
  { type: "manager", label: "Manager", isChecked: false },
  { type: "partner", label: "Partner", isChecked: false },
  { type: "entry_level", label: "Entry Level", isChecked: false },
  { type: "vp", label: "Vp", isChecked: false },
];

export const DEFAULT_SENIORIRT_TYPES = [
  { type: "owner", label: "Owner", isChecked: false },
  { type: "head", label: "Head", isChecked: false },
  { type: "founder", label: "Founder", isChecked: false },
  { type: "director", label: "Director", isChecked: false },
  { type: "cxo", label: "CXO", isChecked: false },
  { type: "manager", label: "Manager", isChecked: false },
  { type: "partner", label: "Partner", isChecked: false },
  { type: "entry_level", label: "Entry Level", isChecked: false },
  { type: "vp", label: "Vp", isChecked: false },
];

export const PERSONAL_INFO_FILTER_LIST = [
  // { filterName: "List", component: FilterTypeList },
  { filterName: "Name", component: FilterTypeName, companyFilter: false },
  {
    filterName: "Job Title",
    component: FilterTypeJobTitle,
    companyFilter: false,
  },
  {
    filterName: "Industry",
    component: FilterTypeIndustry,
    companyFilter: true,
  },
  {
    filterName: "Seniority",
    component: FilterTypeSeniority,
    companyFilter: false,
  },
  {
    filterName: "Company Size",
    component: FilterTypeCompanySize,
    companyFilter: true,
  },
  {
    filterName: "Email Verification",
    component: FilterTypeEmailVerification,
    companyFilter: false,
  },
  {
    filterName: "Email Verification Date",
    component: FilterTypeEmailVerificationDate,
    companyFilter: false,
  },
];

export const LOCATION_FILTER_LIST = [
  {
    filterName: "City",
    component: FilterTypeLocationCity,
    companyFilter: true,
  },
  {
    filterName: "State",
    component: FilterTypeLocationState,
    companyFilter: true,
  },
  {
    filterName: "Country",
    component: FilterTypeLocationCountry,
    companyFilter: true,
  },
];

export const COMPANY_INFO_FILTER_LIST = [
  {
    filterName: "Company Name",
    component: FilterTypeCompanyName,
    companyFilter: true,
  },
  {
    filterName: "Company Keywords",
    component: FilterTypeCompanyKeywords,
    companyFilter: true,
  },
  {
    filterName: "Company Technologies",
    component: FilterTypeCompanyTechnologies,
    companyFilter: true,
  },
  {
    filterName: "SEO Description",
    component: FilterTypeCompanySeo,
    companyFilter: true,
  },
  {
    filterName: "Revenue",
    component: FilterTypeCompanyRevenue,
    companyFilter: true,
  },
];

export const COLUMN_CONTACT_LIST = [
  { label: "Agency focus", isChecked: false },
  { label: "Alternate email address", isChecked: false },
  { label: "Annual revenue", isChecked: false },
  { label: "Associated company", isChecked: false },
  { label: "Associated deals", isChecked: false },
  { label: "BDE", isChecked: false },
  { label: "Became a lead date", isChecked: false },
  { label: "Became a marketing qualified lead date", isChecked: false },
  { label: "Became a sales qualified lead date", isChecked: false },
  { label: "Create Date", isChecked: true },
  { label: "Email", isChecked: true },
  { label: "Phone number", isChecked: true },
  { label: "Lead status", isChecked: true },
  { label: "School", isChecked: true },
  { label: "Agency", isChecked: true },
  { label: "Become an Evangalist date", isChecked: true },
];

export const SETTINGS_USERS_TABLE_HEADERS = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "role",
    label: "Role",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "available_credits",
    label: "Available Credits",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "account_status",
    label: "Account Status",
    isChecked: true,
    minWidth: "130px",
  },

  {
    id: "action",
    label: "Action",
    isChecked: true,
    minWidth: "130px",
  },
];

export const SETTINGS_USERS_DATA = [
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
  {
    name: "Bethany Sweat",
    Teams: "Client Servicing",
    Access: "Contacts, Reports, Sales, Pro",
    Action: "...",
  },
];

export const SETTINGS_TEAM_TABLE_HEADERS = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "Users",
    label: "Users",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "AdditionalUsers",
    label: "Additional Users",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "Action",
    label: "Action",
    isChecked: true,
    minWidth: "130px",
  },
];

export const INVITED_USERS_LIST_HEADERS = [
  {
    id: "email",
    label: "Email",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "created_at",
    label: "Invitation Sent at:",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "action",
    label: "Action",
    isChecked: true,
    minWidth: "130px",
  },
];

export const SETTINGS_TEAMS_DATA = [
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
  {
    name: "Business Development",
    Users: "0",
    AdditionalUsers: "1",
    Action: "...",
  },
];

export const COLUMNS_KEYS_VALUES = {
  first_name: "Name",
  job_title: "Job Title",
  company_name: "Company Name",
  number_of_employees: "Employees",
  email: "Email",
  industry: "Industry",
  company_keywords: "Keywords",

  company_address: "Company Address",
  annual_revenue: "Annual Revenue",
  company_city: "Company city",
  company_country: "Company Country",
  company_website: "Company Website",
  company_facebook_url: "Company Facebook URL",
  company_linkedin_url: "Company Linkedin URL",
  company_phone_number: "Company Phone Number",
  company_technologies: "Company Technologies",
  company_twitter_url: "Company Twitter URL",
  corporate_phone_number: "Corporate Phone Number",
  created_at: "Created_Date",
  direct_phone_number: "Direct Phone Number",
  email_verification_updated_at: "Email Verified At",
  person_city: "Contact City",
  person_state: "Contact State",
  seo_description: "Seo Description",
  last_name: "Last Name",
  person_country: "Contact Location",
  updated_at: "Last Updated Date",
};

export const tempHeadCells = [
  {
    id: "first_name",
    label: "Name",
    companyField: false,
    isChecked: true,
    ui_id: "user_info",
    width: "17.813vw",
    sort: true,
    copy: false,

  },

  {
    id: "email",
    label: "Email",
    companyField: false,
    isChecked: true,
    ui_id: "email",
    width: "20vw",

    sort: true,
    copy: false,
  },

  {
    id: "job_title",
    label: "Job Title",
    companyField: false,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_name",
    label: "Company Name",
    companyField: true,
    isChecked: true,
    ui_id: "company_info",
    width: "17.813vw",
    sort: true,
    copy: false,
  },
  {
    id: "number_of_employees",
    label: "Employees",
    companyField: true,
    isChecked: true,
    ui_id: "stats_data",
    width: "11.771vw",
    sort: true,
    copy: false,
  },
  {
    id: "industry",
    label: "Industry",
    companyField: true,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_keywords",
    label: "Keywords",
    companyField: true,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_address",
    label: "Company Address",
    companyField: true,
    isChecked: false,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "annual_revenue",
    label: "Annual Revenue",
    companyField: true,
    isChecked: false,
    ui_id: "stats_data",
    width: "11.771vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_city",
    label: "Company city",
    companyField: true,
    isChecked: false,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_country",
    label: "Company Country",
    companyField: true,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_facebook_url",
    label: "Company Facebook URL",
    companyField: true,
    isChecked: false,
    ui_id: "text_url",
    width: "20.625vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_linkedin_url",
    label: "Company Linkedin URL",
    companyField: true,
    isChecked: false,
    ui_id: "text_url",
    width: "20.625vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_phone_number",
    label: "Company Phone Number",
    companyField: true,
    isChecked: false,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_state",
    label: "Company State",
    companyField: true,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_technologies",
    label: "Company Technologies",
    companyField: true,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_twitter_url",
    label: "Company Twitter URL",
    companyField: true,
    isChecked: false,
    ui_id: "text_url",
    width: "20.625vw",
    sort: true,
    copy: false,
  },
  {
    id: "company_website",
    label: "Company Website",
    companyField: true,
    isChecked: false,
    ui_id: "text_url",
    width: "20.625vw",
    sort: true,
    copy: false,
  },
  {
    id: "contact_linkedin_url",
    label: "Contact Linkedin URL",
    companyField: false,
    isChecked: false,
    ui_id: "text_url",
    width: "20.625vw",
    sort: true,
    copy: false,
  },
  {
    id: "corporate_phone_number",
    label: "Corporate Phone Number",
    companyField: false,
    isChecked: false,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "created_at",
    label: "Created_Date",
    companyField: true,
    isChecked: false,
    ui_id: "time_stamp",
    width: "15.625vw",

    sort: true,
    copy: false,
  },
  {
    id: "direct_phone_number",
    label: "Direct_Phone_Number",
    companyField: false,
    isChecked: false,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "email_verification_updated_at",
    label: "Email Verified At",
    companyField: false,
    isChecked: false,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "person_city",
    label: "Contact City",
    companyField: false,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "person_state",
    label: "Contact State",
    companyField: false,
    isChecked: false,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "seo_description",
    label: "Seo_Description",
    companyField: true,
    isChecked: false,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },

  {
    id: "person_country",
    label: "Contact Location",
    companyField: false,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "updated_at",
    label: "Last Updated Date",
    companyField: true,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: false,
  },
  {
    id: "action",

    label: "Action",

    companyField: true,

    isChecked: true,
    ui_id: "button",
    width: "8.333vw",
    sort: true,
    copy: false,
  },
];


export const tempHeadCells1 = [{
  id: "first_name",
  label: "First Name",
  companyField: false,
  isChecked: true,
  ui_id: "user_info",
  width: "17.813vw",
  sort: true,
  copy: true,
},
{
  id: "last_name",
  label: "Last Name",
  companyField: false,
  isChecked: false,
  ui_id: "user_info",
  width: "17.813vw",
  sort: true,
  copy: true,
},
{
  id: "email",
  label: "Email",
  companyField: false,
  isChecked: true,
  ui_id: "email",
  width: "17.813vw",

  sort: true,
  copy: true,
},

{
  id: "job_title",
  label: "Job Title",
  companyField: false,
  isChecked: true,
  ui_id: "text",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "company_name",
  label: "Company Name",
  companyField: true,
  isChecked: true,
  ui_id: "company_detail",
  width: "14.583vw",
  sort: true,
  copy: true,
},
{
  id: "number_of_employees",
  label: "Employees",
  companyField: true,
  isChecked: true,
  ui_id: "number_text",
  width: "10.417vw",
  sort: true,
  copy: true,
},
{
  id: "industry",
  label: "Industry",
  companyField: true,
  isChecked: true,
  ui_id: "text",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "company_keywords",
  label: "Keywords",
  companyField: true,
  isChecked: true,
  ui_id: "company_detail",
  width: "14.583vw",
  sort: true,
  copy: true,
},
{
  id: "company_address",
  label: "Company Address",
  companyField: true,
  isChecked: false,
  ui_id: "company_info",
  width: "17.813vw",
  sort: true,
  copy: true,
},
{
  id: "annual_revenue",
  label: "Annual Revenue",
  companyField: true,
  isChecked: false,
  ui_id: "number_text",
  width: "10.417vw",
  sort: true,
  copy: true,
},
{
  id: "company_city",
  label: "Company city",
  companyField: true,
  isChecked: false,
  ui_id: "company_info",
  width: "17.813vw",
  sort: true,
  copy: true,
},
{
  id: "company_country",
  label: "Company Country",
  companyField: true,
  isChecked: true,
  ui_id: "company_info",
  width: "17.813vw",
  sort: true,
  copy: true,
},
{
  id: "company_facebook_url",
  label: "Company Facebook URL",
  companyField: true,
  isChecked: false,
  ui_id: "company_url",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "company_linkedin_url",
  label: "Company Linkedin URL",
  companyField: true,
  isChecked: false,
  ui_id: "company_url",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "company_phone_number",
  label: "Company Phone Number",
  companyField: true,
  isChecked: false,
  ui_id: "number_text",
  width: "10.417vw",
  sort: true,
  copy: true,
},
{
  id: "company_state",
  label: "Company State",
  companyField: true,
  isChecked: true,
  ui_id: "company_info",
  width: "17.813vw",
  sort: true,
  copy: true,
},
{
  id: "company_technologies",
  label: "Company Technologies",
  companyField: true,
  isChecked: true,
  ui_id: "company_info",
  width: "17.813vw",
  sort: true,
  copy: true,
},
{
  id: "company_twitter_url",
  label: "Company Twitter URL",
  companyField: true,
  isChecked: false,
  isChecked: false,
  ui_id: "company_url",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "company_website",
  label: "Company Website",
  companyField: true,
  isChecked: false,
  isChecked: false,
  ui_id: "company_url",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "contact_linkedin_url",
  label: "Contact Linkedin URL",
  companyField: false,
  isChecked: false,
  isChecked: false,
  ui_id: "company_url",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "corporate_phone_number",
  label: "Corporate Phone Number",
  companyField: false,
  isChecked: false,
  ui_id: "number_text",
  width: "10.417vw",
  sort: true,
  copy: true,
},

{
  id: "direct_phone_number",
  label: "Direct Phone Number",
  companyField: false,
  isChecked: false,
  ui_id: "number_text",
  width: "10.417vw",
  sort: true,
  copy: true,
},

{
  id: "person_city",
  label: "Contact City",
  companyField: false,
  isChecked: true,
  ui_id: "text",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "person_state",
  label: "Contact State",
  companyField: false,
  isChecked: false,
  ui_id: "text",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "seo_description",
  label: "Seo Description",
  companyField: true,
  isChecked: false,
  ui_id: "text",
  width: "13.542vw",
  sort: true,
  copy: true,
},

{
  id: "person_country",
  label: "Contact Location",
  companyField: false,
  isChecked: true,
  ui_id: "text",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "company_website",
  label: "Original Company website",
  companyField: false,
  isChecked: true,
  ui_id: "text",
  width: "13.542vw",
  sort: true,
  copy: true,
},
{
  id: "action",

  label: "Action",

  companyField: true,

  isChecked: true,
  ui_id: "button",
  width: "8.333vw",
  sort: true,
  copy: true,
},
];

export const tempHeadCellsqmodal = [
  {
    id: "first_name",
    label: "First Name",
    companyField: false,
    isChecked: true,
    flagreason: "first_name_flag_reason",
  },
  {
    id: "last_name",
    label: "Last Name",
    companyField: false,
    isChecked: false,
    flagreason: "last_name_flag_reason",
  },
  {
    id: "email",
    label: "Email",
    companyField: false,
    isChecked: true,
    flagreason: "email_flag_reason",
  },

  {
    id: "job_title",
    label: "Job Title",
    companyField: false,
    isChecked: true,
    flagreason: "job_title_flag_reason",
  },
  {
    id: "corporate_phone_number",
    label: "Corporate Phone Number",
    companyField: false,
    isChecked: false,
    flagreason: "contact_linkedin_url_flag_reason",
  },
  {
    id: "direct_phone_number",
    label: "Direct Phone Number",
    companyField: false,
    isChecked: false,
    flagreason: "direct_phone_number_flag_reason",
  },
  {
    id: "person_city",
    label: "Contact City",
    companyField: false,
    isChecked: true,
    flagreason: "person_city_flag_reason",
  },
  {
    id: "person_state",
    label: "Contact State",
    companyField: false,
    isChecked: false,
    flagreason: "person_state_flag_reason",
  },

  {
    id: "person_country",
    label: "Contact Location",
    companyField: false,
    isChecked: true,
    flagreason: "person_country_flag_reason",
  },
  {
    id: "contact_linkedin_url",
    label: "Contact Linkedin URL",
    companyField: false,
    isChecked: false,
    flagreason: "contact_linkedin_url_flag_reason",
  },
  {
    id: "company_name",
    label: "Name",
    companyField: true,
    isChecked: true,
    flagreason: "company_name_flag_reason",
  },
  {
    id: "number_of_employees",
    label: "Employees",
    companyField: true,
    isChecked: true,
    flagreason: "number_of_employees_flag_reason",
  },
  {
    id: "industry",
    label: "Industry",
    companyField: true,
    isChecked: true,
    flagreason: "industry_flag_reason",
  },

  {
    id: "company_keywords",
    label: "Keywords",
    companyField: true,
    isChecked: true,
    flagreason: "company_keywords_flag_reason",
  },
  {
    id: "company_address",
    label: "Address",
    companyField: true,
    isChecked: false,
    flagreason: "company_address_flag_reason",
  },
  {
    id: "annual_revenue",
    label: "Annual Revenue",
    companyField: true,
    isChecked: false,
    flagreason: "annual_revenue_flag_reason",
  },
  {
    id: "company_city",
    label: "city",
    companyField: true,
    isChecked: false,
    flagreason: "company_city_flag_reason",
  },
  {
    id: "company_country",
    label: "Country",
    companyField: true,
    isChecked: true,
    flagreason: "company_country_flag_reason",
  },
  {
    id: "company_facebook_url",
    label: "Facebook URL",
    companyField: true,
    isChecked: false,
    flagreason: "company_facebook_url_flag_reason",
  },
  {
    id: "company_linkedin_url",
    label: "Linkedin URL",
    companyField: true,
    isChecked: false,
    flagreason: "company_linkedin_url_flag_reason",
  },
  {
    id: "company_phone_number",
    label: "Phone Number",
    companyField: true,
    isChecked: false,
    flagreason: "company_phone_number_flag_reason",
  },
  {
    id: "company_state",
    label: "State",
    companyField: true,
    isChecked: true,
    flagreason: "company_state_flag_reason",
  },
  {
    id: "company_technologies",
    label: "Technologies",
    companyField: true,
    isChecked: true,
    flagreason: "company_technologies_flag_reason",
  },
  {
    id: "company_twitter_url",
    label: "Twitter URL",
    companyField: true,
    isChecked: false,
    flagreason: "company_twitter_url_flag_reason",
  },
  {
    id: "company_website",
    label: "Website",
    companyField: true,
    isChecked: false,
    flagreason: "company_website_flag_reason",
  },

  {
    id: "seo_description",
    label: "Seo Description",
    companyField: true,
    isChecked: false,
    flagreason: "seo_description_flag_reason",
  },

  // {
  //   id: "original_company_website",
  //   label: "Original Company website",
  //   companyField: false,
  //   isChecked: true,
  //   flagreason: "company_website_flag_reason",
  // },
];

export const platformheadcell = [
  { id: "name", label: "Name", isChecked: true },

  {
    id: "company_name",
    label: "Company Name",

    isChecked: true,
  },

  {
    id: "email",
    label: "Email",

    isChecked: true,
  },
  {
    id: "location",
    label: "Location",

    isChecked: true,
  },
  {
    id: "is_online",
    label: "Online User",

    isChecked: true,
  },
  {
    id: "account_status",
    label: "Account Status",

    isChecked: true,
  },
  {
    id: "last_login_date_time",

    isChecked: true,
    label: "Last Login",
  },
  {
    id: "action",
    label: "Flag",

    isChecked: true,
  },
];

export const LEADS_TABLE_LINKS_IDS = [
  "company_name",
  "company_facebook_url",
  "company_linkedin_url",
  "company_twitter_url",
  "company_website",
  "contact_linkedin_url",
];

export const tempHeadCells2 = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "300px",
  },
  {
    id: "jobTitle",
    label: "Job title",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "company",
    label: "Company",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "actions",
    label: "Actions",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "contactLocation",
    label: "Contact location",
    isChecked: true,
    minWidth: "160px",
  },
  {
    id: "employees",
    label: "Employees",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "industry",
    label: "Industrys",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "keywords",
    label: "Keywords",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "email",
    label: "Email",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "Agency focus",
    label: "Agency focus",
    isChecked: false,
    minWidth: "150px",
  },
  {
    id: "Alternate email address",
    label: "Alternate email address",
    isChecked: false,
    minWidth: "210px",
  },
  {
    id: "Annual revenue",
    label: "Annual revenue",
    isChecked: false,
    minWidth: "155px",
  },
  {
    id: "associatedComapny",
    label: "Associated company",
    isChecked: false,
    minWidth: "190px",
  },
];

export const defaultHeadCells = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "jobTitle",
    label: "Job title",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "company",
    label: "Company",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "actions",
    label: "Actions",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "contactLocation",
    label: "Contact location",
    isChecked: true,
    minWidth: "160px",
  },
  {
    id: "employees",
    label: "Employees",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "industry",
    label: "Industrys",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "keywords",
    label: "Keywords",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "email",
    label: "Email",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "Agency focus",
    label: "Agency focus",
    isChecked: false,
    minWidth: "150px",
  },
  {
    id: "Alternate email address",
    label: "Alternate email address",
    isChecked: false,
    minWidth: "210px",
  },
  {
    id: "Annual revenue",
    label: "Annual revenue",
    isChecked: false,
    minWidth: "155px",
  },
  {
    id: "associatedComapny",
    label: "Associated company",
    isChecked: false,
    minWidth: "190px",
  },
];

export const defaultHeadCells1 = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "jobTitle",
    label: "Job title",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "company",
    label: "Company",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "actions",
    label: "Actions",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "contactLocation",
    label: "Contact location",
    isChecked: true,
    minWidth: "160px",
  },
  {
    id: "employees",
    label: "Employees",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "industry",
    label: "Industrys",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "keywords",
    label: "Keywords",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "email",
    label: "Email",
    isChecked: true,
    minWidth: "150px",
  },
  {
    id: "Agency focus",
    label: "Agency focus",
    isChecked: false,
    minWidth: "150px",
  },
  {
    id: "Alternate email address",
    label: "Alternate email address",
    isChecked: false,
    minWidth: "210px",
  },
  {
    id: "Annual revenue",
    label: "Annual revenue",
    isChecked: false,
    minWidth: "155px",
  },
  {
    id: "associatedComapny",
    label: "Associated company",
    isChecked: false,
    minWidth: "190px",
  },
];

export const rows = [
  createData(
    "Targeraen Gin",
    "CEO && Founder",
    "Cnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "King Slayer",
    "BEO && Founder",
    "Bnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Bargeraen Gmb",
    "CEO && Founder",
    "Cnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Ding Slayer",
    "DEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Chadnler bing",
    "EEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Fling Slayer",
    "FEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Lannisters King",
    "GEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Starks Arya",
    "HEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "Zing Slayer",
    "CEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
  createData(
    "jargeraen and",
    "CEO && Founder",
    "Rnf Technologies",
    "Chicago illinois",
    1000,
    "IT Company",
    "Architectural Design",
    "ayush.goyal@res.com",
    "Associated dummy"
  ),
];

export const DOMAINS_EMAILS_ADDRESS = [
  "*@agilistechlabs.com",
  "*@bistoneseo.com",
  "*@ezmarketingtech.co.uk",
  "*@ezmarketingtech.com",
  "*@integratedmarketingone.com",
  "*@prismevents.co",
];

export const DROPDOWN_EMAIL_VIEWS = ["All", "Everyone", "Any"];

//Dropdowns

export const DROPDOWN_PROFILE_EMAIL_DOMAIN = [
  "Domain and Emails",
  "Domain",
  "Emails",
];

export const DROPDOWN_PEOPLE_HEADER_RELEVENCE = ["Relevance", "Irrevelant"];

export const DROPDOWN_PEOPLE_HEADER_SORTING = [
  { id: "asc", label: "Ascending" },
  { id: "desc", label: "Descending" },
];
export const DROPDOWN_FLAGGED_HEADER = [
  { id: "", label: " Select" },

  { id: "invalid first_name", label: "invalid first name" },
  { id: "invalid last_name", label: "invalid last name" },
  { id: "invalid revenue", label: "invalid revenue" },
  { id: "invalid email", label: "invalid email" },
  { id: "invalid job title", label: "invalid job title" },
  { id: "others", label: "others" },
];

export const DROPDOWN_QUARANTINE_HEADER = [
  { id: "", label: " Select" },

  { id: "Spex", label: "Spex" },
  { id: "Cognizant", label: "Cognizant" },
  { id: "HCLLL", label: "HCLLL" },
  { id: "abc_technologies", label: "abc_technologies" },
  { id: "Ted_Consulting", label: "Ted_Consulting" },
];

export const ONLINE_QUARANTINE = [
  { id: "asc", label: "Aijaz" },
  { id: "desc", label: "Vernika" },
];
export const DROPDOWN_ACCOUNT_STATUS = [
  { id: "active", label: "Active" },
  { id: "inactive", label: "Inactive" },
  { id: "blocked", label: "Blocked" },
  { id: "pending", label: "Pending" },
];

export const DROPDOWN_PROFILE_TASK_DUEDATE = [
  "In 3 business days",
  "In 7 business days",
  "In 10 business days",
  "In a month",
];

export const DROPDOWN_PROFILE_TASK_DUETIME = ["8 AM", "9 AM", "10 AM", "11 AM"];

export const DROPDOWN_PROFILE_TASK_REMINDER = ["No Reminder", "Set Reminder"];

export const STEPS_HEADER_ACTIONS = [
  { field: "Overview", path: "" },
  { field: "Activity Log", path: "" },
];

export const initialDnDState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: [],
};

export const LOCAL_STORAGE_ADVANCE_EXPORT = "advance_export";

export const STEP_THREADS = [
  { type: "Reply to previous thread", key: 1 },
  { type: "New thread", key: 0 },
];

// Steps Constants---------------

export const STEP_TYPES = {
  1: "Automated Mail",
  2: "Todo",
  3: "Call",
};

export const STEP_TYPE_COLORS = {
  1: "#fad9cc",
  2: "#e7d2f9",
  3: "#ddf2f3",
};

export const STEPS_PRIORITY_FIELDS = [
  { id: "1", label: "Low" },
  { id: "2", label: "High" },
];

export const STEPS_THREAD_FIELDS = [
  { id: "1", label: "New Thread" },
  { id: "2", label: "Reply to previous thread" },
];

export const STEPS_INTERVAL_FIELDS = [
  { id: "1", label: "1" },
  { id: "2", label: "2" },
  { id: "3", label: "3" },
  { id: "4", label: "4" },
  { id: "5", label: "5" },
  { id: "6", label: "6" },
  { id: "7", label: "7" },
  { id: "8", label: "8" },
  { id: "9", label: "9" },
  { id: "10", label: "10" },
  { id: "11", label: "11" },
  { id: "12", label: "12" },
  { id: "13", label: "13" },
  { id: "14", label: "14" },
  { id: "15", label: "15" },
  { id: "16", label: "16" },
  { id: "17", label: "17" },
  { id: "18", label: "18" },
  { id: "19", label: "19" },
  { id: "20", label: "20" },
  { id: "21", label: "21" },
  { id: "22", label: "22" },
  { id: "23", label: "23" },
  { id: "24", label: "24" },
  { id: "25", label: "25" },
  { id: "26", label: "26" },
  { id: "27", label: "27" },
  { id: "28", label: "28" },
  { id: "29", label: "29" },
  { id: "30", label: "30" },
];

export const TIME_LIST = [
  { id: "1", label: 1 },
  { id: "2", label: 2 },
  { id: "3", label: 3 },
  { id: "4", label: 4 },
  { id: "5", label: 5 },
  { id: "6", label: 6 },
  { id: "7", label: 7 },
  { id: "8", label: 8 },
  { id: "9", label: 9 },
  { id: "10", label: 10 },
  { id: "11", label: 11 },
  { id: "12", label: 12 },
];

export const TIME_TYPE = [
  { id: "AM", label: "AM" },
  { id: "PM", label: "PM" },
];

export const CAMPAIGN_CREATE_DAYS_LIST = [
  { day: "sunday", id: 0 },
  { day: "monday", id: 1 },
  { day: "tuesday", id: 2 },
  { day: "wednesday", id: 3 },
  { day: "thursday", id: 4 },
  { day: "friday", id: 5 },
  { day: "saturday", id: 6 },
];

export const CAMPAIGNS_STATUS_LIST = [
  { label: "All", icon: FiberManualRecordIcon, color: "#FF4848" },
  { label: "Active", icon: FiberManualRecordIcon, color: "#80F2BA" },
  { label: "Scheduled", icon: FiberManualRecordIcon, color: "#FFC87F" },
  { label: "Paused", icon: FiberManualRecordIcon, color: "#E0A9FF" },
  { label: "Completed", icon: FiberManualRecordIcon, color: "#95F5FC" },
];

export const MONTH_INDEX_PAIR = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const CAMPAIGN_TABLE_HEADS = [
  { id: "name", label: "Name", isChecked: true, minWidth: "280px" },
  { id: "total_steps", label: "Steps", isChecked: true },
  // { id: "owner", label: "Owner", isChecked: true },
  { id: "total_enrolled", label: "Total Enrolled", isChecked: true },
  { id: "sent", label: "Sent", isChecked: true },
  { id: "deliver", label: "Delivered", isChecked: true },
  { id: "open", label: "Opens", isChecked: true },
  { id: "click", label: "Clicks", isChecked: true },
  { id: "reply", label: "Replies", isChecked: true },
  { id: "unsubscribe", label: "Unsubscribes", isChecked: true },
  { id: "state", label: "Status", isChecked: true },
  { id: "actions", label: "Actions", isChecked: true },
];

export const LIST_TABLE_HEADS = [
  { id: "list_name", label: "List Name", isChecked: true },
  { id: "user", label: "Owner", isChecked: true },
  { id: "list_type", label: "List Type", isChecked: true },
  { id: "number_of_contacts", label: "Number of contacts", isChecked: true },
  { id: "created_at", label: "Created At", isChecked: true },
  { id: "updated_at", label: "updated_at", isChecked: true },
  { id: "actions", label: "Actions", isChecked: true },
];

export const CAMPAIGN_FINAL_STATES = [
  "interrupted",
  "active",
  "scheduled",
  "completed",
];
export const POSSIBLE_STATES_OF_CAMPAIGN = [
  { label: "interrupted", textColor: "#FF3B3B", bgColor: "#FFEDED" },
  { label: "active", textColor: "#13CA7A", bgColor: "#E7FFF3" },
  { label: "completed", textColor: "#13CA7A", bgColor: "#E7FFF3" },
  { label: "entry", textColor: "#FFA033", bgColor: "#FFF3E2" },
  { label: "scheduled", textColor: "#13CA7A", bgColor: "#E7FFF3" },
  { label: "Scheduling", textColor: "#13CA7A", bgColor: "#E7FFF3" },
  { label: "setting up", textColor: "#13CA7A", bgColor: "#E7FFF3" },
  { label: "interrupted", textColor: "#FF3B3B", bgColor: "#FFEDED" },
];

export const CAMPAIGN_HEADERS_NAVIGATION_LIST = [
  { label: "Overview", path: "", isSelected: true },
  { label: "Contacts", path: "/contacts", isSelected: false },
  { label: "Activity Log", path: "/activity", isSelected: false },
  { label: "Settings", path: "/settings", isSelected: false },
];

export const TIMEZONE_LIST = [
  // {"id":-330,label:"America/Puerto_Rico"},
  // {"id":-330,label:"Asia/Kolkata"},
  { id: "Asia/Kolkata", label: "Asia/Kolkata (UTC+05:30)", offset: -330 },
  { id: "America/Chicago", label: "Chicago (Central)" },
  { id: "America/Denver", label: "Denver (Mountain)" },
  { id: "America/Phoenix", label: "Phoenix (MST)" },
  { id: "America/Los_Angeles", label: "Los Angeles (Pacific)" },
  { id: "America/Anchorage", label: "Anchorage (Alaska)" },
  { id: "Pacific/Honolulu", label: "Honolulu (Hawaii)" },
  { id: "America/Adak", label: "Adak" },
  { id: "America/Anguilla", label: "Anguilla" },
  { id: "America/Antigua", label: "Antigua" },
  { id: "America/Araguaina", label: "Araguaina" },
  { id: "America/Argentina/Buenos_Aires", label: "Argentina - Buenos Aires" },
  { id: "America/Argentina/Catamarca", label: "Argentina - Catamarca" },
  {
    id: "America/Argentina/ComodRivadavia",
    label: "Argentina - ComodRivadavia",
  },
  { id: "America/Argentina/Cordoba", label: "Argentina - Cordoba" },
  { id: "America/Argentina/Jujuy", label: "Argentina - Jujuy" },
  { id: "America/Argentina/La_Rioja", label: "Argentina - La Rioja" },
  { id: "America/Argentina/Mendoza", label: "Argentina - Mendoza" },
  { id: "America/Argentina/Rio_Gallegos", label: "Argentina - Rio Gallegos" },
  { id: "America/Argentina/Salta", label: "Argentina - Salta" },
  { id: "America/Argentina/San_Juan", label: "Argentina - San Juan" },
  { id: "America/Argentina/San_Luis", label: "Argentina - San Luis" },
  { id: "America/Argentina/Tucuman", label: "Argentina - Tucuman" },
  { id: "America/Argentina/Ushuaia", label: "Argentina - Ushuaia" },
  { id: "America/Aruba", label: "Aruba" },
  { id: "America/Asuncion", label: "Asuncion" },
  { id: "America/Atikokan", label: "Atikokan" },
  { id: "America/Atka", label: "Atka" },
  { id: "America/Bahia", label: "Bahia" },
  { id: "America/Barbados", label: "Barbados" },
  { id: "America/Belem", label: "Belem" },
  { id: "America/Belize", label: "Belize" },
  { id: "America/Blanc-Sablon", label: "Blanc-Sablon" },
  { id: "America/Boa_Vista", label: "Boa Vista" },
  { id: "America/Bogota", label: "Bogota" },
  { id: "America/Boise", label: "Boise" },
  { id: "America/Buenos_Aires", label: "Buenos Aires" },
  { id: "America/Cambridge_Bay", label: "Cambridge Bay" },
  { id: "America/Campo_Grande", label: "Campo Grande" },
  { id: "America/Cancun", label: "Cancun" },
  { id: "America/Caracas", label: "Caracas" },
  { id: "America/Catamarca", label: "Catamarca" },
  { id: "America/Cayenne", label: "Cayenne" },
  { id: "America/Cayman", label: "Cayman" },
  { id: "America/Chihuahua", label: "Chihuahua" },
  { id: "America/Coral_Harbour", label: "Coral Harbour" },
  { id: "America/Cordoba", label: "Cordoba" },
  { id: "America/Costa_Rica", label: "Costa Rica" },
  { id: "America/Cuiaba", label: "Cuiaba" },
  { id: "America/Curacao", label: "Curacao" },
  { id: "America/Danmarkshavn", label: "Danmarkshavn" },
  { id: "America/Dawson", label: "Dawson" },
  { id: "America/Dawson_Creek", label: "Dawson Creek" },

  { id: "Asia/Aden", label: "Aden" },
  { id: "Asia/Almaty", label: "Almaty" },
  { id: "Asia/Amman", label: "Amman" },
  { id: "Asia/Anadyr", label: "Anadyr" },
  { id: "Asia/Aqtau", label: "Aqtau" },
  { id: "Asia/Aqtobe", label: "Aqtobe" },
  { id: "Asia/Ashgabat", label: "Ashgabat" },
  { id: "Asia/Ashkhabad", label: "Ashkhabad" },
  { id: "Asia/Baghdad", label: "Baghdad" },
  { id: "Asia/Bahrain", label: "Bahrain" },
  { id: "Asia/Baku", label: "Baku" },
  { id: "Asia/Bangkok", label: "Bangkok" },
  { id: "Asia/Beirut", label: "Beirut" },
  { id: "Asia/Bishkek", label: "Bishkek" },
  { id: "Asia/Brunei", label: "Brunei" },
  { id: "Asia/Calcutta", label: "Calcutta" },
  { id: "Asia/Choibalsan", label: "Choibalsan" },
  { id: "Asia/Chongqing", label: "Chongqing" },
  { id: "Asia/Chungking", label: "Chungking" },
  { id: "Asia/Colombo", label: "Colombo" },
  { id: "Asia/Dacca", label: "Dacca" },
  { id: "Asia/Damascus", label: "Damascus" },
  { id: "Asia/Dhaka", label: "Dhaka" },
  { id: "Asia/Dili", label: "Dili" },
  { id: "Asia/Dubai", label: "Dubai" },
  { id: "Asia/Dushanbe", label: "Dushanbe" },
  { id: "Asia/Gaza", label: "Gaza" },
  { id: "Asia/Harbin", label: "Harbin" },
  { id: "Asia/Ho_Chi_Minh", label: "Ho Chi Minh" },
  { id: "Asia/Hong_Kong", label: "Hong Kong" },
  { id: "Asia/Hovd", label: "Hovd" },
  { id: "Asia/Irkutsk", label: "Irkutsk" },
  { id: "Asia/Istanbul", label: "Istanbul" },
  { id: "Asia/Jakarta", label: "Jakarta" },
  { id: "Asia/Jayapura", label: "Jayapura" },
  { id: "Asia/Jerusalem", label: "Jerusalem" },
  { id: "Asia/Kabul", label: "Kabul" },
  { id: "Asia/Kamchatka", label: "Kamchatka" },
  { id: "Asia/Karachi", label: "Karachi" },
  { id: "Asia/Kashgar", label: "Kashgar" },
  { id: "Asia/Kathmandu", label: "Kathmandu" },
  { id: "Asia/Katmandu", label: "Katmandu" },
  { id: "Asia/Kolkata", label: "Kolkata" },
  { id: "Asia/Krasnoyarsk", label: "Krasnoyarsk" },
  { id: "Asia/Kuala_Lumpur", label: "Kuala Lumpur" },
  { id: "Asia/Kuching", label: "Kuching" },
  { id: "Asia/Kuwait", label: "Kuwait" },
  { id: "Asia/Macao", label: "Macao" },
  { id: "Asia/Macau", label: "Macau" },
  { id: "Asia/Magadan", label: "Magadan" },
  { id: "Asia/Makassar", label: "Makassar" },
  { id: "Asia/Manila", label: "Manila" },
  { id: "Asia/Muscat", label: "Muscat" },
  { id: "Asia/Nicosia", label: "Nicosia" },
  { id: "Asia/Novokuznetsk", label: "Novokuznetsk" },
  { id: "Asia/Novosibirsk", label: "Novosibirsk" },
  { id: "Asia/Omsk", label: "Omsk" },
  { id: "Asia/Oral", label: "Oral" },
  { id: "Asia/Phnom_Penh", label: "Phnom Penh" },
  { id: "Asia/Pontianak", label: "Pontianak" },
  { id: "Asia/Pyongyang", label: "Pyongyang" },
  { id: "Asia/Qatar", label: "Qatar" },
  { id: "Asia/Qyzylorda", label: "Qyzylorda" },
  { id: "Asia/Rangoon", label: "Rangoon" },
  { id: "Asia/Riyadh", label: "Riyadh" },
  { id: "Asia/Saigon", label: "Saigon" },
  { id: "Asia/Sakhalin", label: "Sakhalin" },
  { id: "Asia/Samarkand", label: "Samarkand" },
  { id: "Asia/Seoul", label: "Seoul" },
  { id: "Asia/Shanghai", label: "Shanghai" },
  { id: "Asia/Singapore", label: "Singapore" },
  { id: "Asia/Taipei", label: "Taipei" },
  { id: "Asia/Tashkent", label: "Tashkent" },
  { id: "Asia/Tbilisi", label: "Tbilisi" },
  { id: "Asia/Tehran", label: "Tehran" },
  { id: "Asia/Tel_Aviv", label: "Tel Aviv" },
  { id: "Asia/Thimbu", label: "Thimbu" },
  { id: "Asia/Thimphu", label: "Thimphu" },
  { id: "Asia/Tokyo", label: "Tokyo" },
  { id: "Asia/Ujung_Pandang", label: "Ujung Pandang" },
  { id: "Asia/Ulaanbaatar", label: "Ulaanbaatar" },
  { id: "Asia/Ulan_Bator", label: "Ulan Bator" },
  { id: "Asia/Urumqi", label: "Urumqi" },
  { id: "Asia/Vientiane", label: "Vientiane" },
  { id: "Asia/Vladivostok", label: "Vladivostok" },
  { id: "Asia/Yakutsk", label: "Yakutsk" },
  { id: "Asia/Yekaterinburg", label: "Yekaterinburg" },
  { id: "Asia/Yerevan", label: "Yerevan" },
];

export const contactHeadCells = [
  {
    id: "first_name",
    label: "First Name",
    isChecked: true,

    ui_id: "single_name",
    width: "13.542vw",
    sort: true,
    copy: true,
  },
  {
    id: "last_name",
    label: "Last Name",
    isChecked: true,

    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: true,
  },
  {
    id: "job_title",
    label: "Job Title",
    isChecked: true,

    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: true,
  },
  {
    id: "email",
    label: "Business Email",
    isChecked: true,

    ui_id: "email",
    width: "20vw",

    sort: true,
    copy: true,
  },
  {
    id: "phone",
    label: "Phone Number",
    isChecked: true,

    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: true,
  },
  {
    id: "message",
    label: "Message",
    isChecked: true,

    ui_id: "message_text",
    width: "20vw",
    sort: true,
    copy: true,
  },
  {
    id: "created_at",
    label: "Date",
    isChecked: true,

    ui_id: "time_stamp",
    width: "15.625vw",

    sort: true,
    copy: true,
  },
  {
    id: "action",
    label: "Actions",
    isChecked: true,

    ui_id: "button",
    width: "8.333vw",
    sort: true,
    copy: true,
  },
];

export const PluginUserhead = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },

  {
    id: "company_name",
    label: "Company",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "email",
    label: "Email",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "location",
    label: "Location",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "account_status",
    label: "Account Status",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "last_active",
    label: "Last Activity",
    isChecked: true,
    minWidth: "130px",
  },

  {
    id: "reported_data",
    label: "Reported Data",
    isChecked: true,
    minWidth: "130px",
  },
];

export const earlyaccessHeadCells = [
  {
    id: "first_name",
    label: "First Name",
    isChecked: true,
    ui_id: "single_name",
    width: "13.542vw",
    sort: true,
    copy: true,
  },
  {
    id: "last_name",
    label: "Last Name",
    isChecked: true,

    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: true,
  },
  {
    id: "job_title",
    label: "Job Title",
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: true,
  },
  {
    id: "email",
    label: "Business Email",
    isChecked: true,
    ui_id: "email",
    width: "20vw",

    sort: true,
    copy: true,
  },
  {
    id: "phone_number",
    label: "Phone Number",
    isChecked: true,

    ui_id: "number_text",
    width: "10.417vw",
    sort: true,
    copy: true,
  },
  {
    id: "linkedin_profile",
    label: "LinkedIn Profile",
    isChecked: true,
    minWidth: "130px",
    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: true,
  },
  {
    id: "company_name",
    label: "Company Name",
    isChecked: true,

    ui_id: "company_info",
    width: "17.813vw",
    sort: true,
    copy: true,
  },
  {
    id: "company_website",
    label: "Company Website",
    isChecked: true,

    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: true,
  },
  {
    id: "email_sent_count",
    label: "Invitation Sent",
    isChecked: true,

    ui_id: "text",
    width: "13.542vw",
    sort: true,
    copy: true,
  },

  {
    id: "created_at",
    label: "Date",
    isChecked: true,

    ui_id: "time_stamp",
    width: "15.625vw",

    sort: true,
    copy: true,
  },
  {
    id: "action",
    label: "Actions",
    isChecked: true,
    ui_id: "action",
    width: "11.333vw",
    sort: true,
    copy: true,
  },
];

export const STATS_KEY_VALUES = {
  total_enrolled: "Total Enrolled",
  deliver: "Delivered",
  open: "Opens",
  sent: "Email Sent",
  click: "Clicks",
  bounce: "Bounce",
  reply: "Replies",
  email: "Emails",
  call: "Calls",
  todo: "To Do",
  meeting_click: "Meetings",
  complete: "Complete",
  inComplete: "Incomplete",
};

export const WIDGET_TABLE_UNSUBSCRIBE_LIST = [
  {
    id: "name",
    label: "Campaign",
    isChecked: true,
    width: "14vw",
  },
  {
    id: "total_enrolled",
    label: "Total Enrolled",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "unsubscribe",
    label: "Unsubscribe",
    isChecked: true,
    width: "12vw",
  },
];

export const WIDGET_TABLE_LEADS_LIST = [
  {
    id: "name",
    label: "Campaign",
    isChecked: true,
    width: "14vw",
  },
  {
    id: "total_enrolled",
    label: "Total Enrolled",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "lead",
    label: "Leads",
    isChecked: true,
    width: "12vw",
  },
];

export const WIDGET_BOX_KEY_VALUES = {
  total_enrolled: "Total Enrolled",
  deliver: "Email Delivered",
  open: "Email Open",
  sent: "Email Sent",
  click: "Email Click",
  bounce: "Email Bounce",
  reply: "Email Replies",
  meeting_click: "Meetings",
};

export const Acconutlisthead = [
  {
    id: "company_name",
    label: "Company Name",
    isChecked: true,
    minWidth: "130px",
  },

  {
    id: "owner_name",
    label: "Owner Name",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "location",
    label: "Location",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "sign_up_date",
    label: "Sign up Date",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "existing_users",
    label: "Existing Users",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "available_credits",
    label: "Available Credits",
    isChecked: true,
    minWidth: "130px",
  },

  {
    id: "status",
    label: "Status",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "action",
    label: "Flag",
    isChecked: true,
    minWidth: "130px",
  },
];

export const Userdatahead = [
  {
    id: "first_name",
    label: "Name",
    ui_id: "user_detail",
    width: "15vw",
    sort: true,

  },

  {
    id: "email",
    label: "Email",
    ui_id: "email",
    width: "15vw",
    sort: true,
  },
  {
    id: "reason",
    label: "Reason",
    minWidth: "130px",
    ui_id: "reason_text",
    width: "48.35vw",
    sort: true,
  },
  {
    id: "created_at",
    label: "Created at",
    ui_id: "time_stamp",
    width: "15vw",
    sort: true,
  },
];
export const Userremovedatahead = [
  {
    id: "first_name",
    label: "Name",
    ui_id: "user_detail",
    width: "15vw",
    sort: true,

  },

  {
    id: "email",
    label: "Email",
    ui_id: "email",
    width: "15vw",
    sort: true,
  },
  {
    id: "reason",
    label: "Reason",
    minWidth: "130px",
    ui_id: "text",
    width: "48.35vw",
    sort: true,
  },
  {
    id: "created_at",
    label: "Created at",
    ui_id: "time_stamp",
    width: "15vw",
    sort: true,
  },
];

export const requestcreditHead = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },

  {
    id: "email",
    label: "Email",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "available_credits",
    label: "Available Credits",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "request_status",
    label: "Request Status",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "request_at",
    label: "Request at",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "action",
    label: "Action",
    isChecked: true,
    minWidth: "130px",
  },
];

export const referalhead = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },

  {
    id: "invited_by",
    label: "Invited By",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "invited_user",
    label: "Invited User",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "date",
    label: "Date",
    isChecked: true,
    minWidth: "130px",
  },
];

export const credithead = [
  {
    id: "name",
    label: "Name",
    isChecked: true,
    minWidth: "130px",
  },

  {
    id: "description",
    label: "Description",
    isChecked: true,
    minWidth: "130px",
  },

  {
    id: "date",
    label: "Date",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "credit",
    label: "Credit/Debit",
    isChecked: true,
    minWidth: "130px",
  },
  {
    id: "credit_available",
    label: "Credit Available",
    isChecked: true,
    minWidth: "130px",
  },
];
export const MONTH_INDEX = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};
export const PROFILE_MENU_CONTENT = [
  { title: "Settings", id: "name", icon: ICON_LABELS.SETTINGS },
  { title: "Logout", id: "logout", icon: ICON_LABELS.LOGOUT },
];

export const tempHeadLeadCells = [
  {
    id: "first_name",
    label: "Name",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "user_info",
    width: "17.813vw",
    copy: true,
  },
  {
    id: "lead_date_time",
    label: "Lead Generated",
    companyField: false,
    isChecked: true,
    ui_id: "time_stamp",
    width: "15.625vw",

  },

  {
    id: "campaign_name",
    label: "Campaign Name",
    companyField: false,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "sequence_id",
    label: "Sequence Used",
    companyField: false,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "job_title",
    label: "Job Title",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: false,
  },
  {
    id: "company_name",
    label: "Company Name",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "company_info",
    width: "17.813vw",
    copy: true,
  },
  {
    id: "number_of_employees",
    label: "Employees",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "stats_data",
    width: "11.771vw",
    copy: false,
  },
  {
    id: "email",
    label: "Email",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "email",
    width: "20vw",

    copy: true,
  },
  {
    id: "industry",
    label: "Industry",
    companyField: true,
    isChecked: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
    sort: true,
  },
  {
    id: "company_keywords",
    label: "Keywords",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_address",
    label: "Company Address",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "annual_revenue",
    label: "Annual Revenue",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_city",
    label: "Company city",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_country",
    label: "Company Country",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_facebook_url",
    label: "Company Facebook URL",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "url",
    copy: true,
  },
  {
    id: "company_linkedin_url",
    label: "Company LinkedIn URL",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "url",
    copy: true,
  },
  {
    id: "company_phone_number",
    label: "Company Phone Number",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_state",
    label: "Company State",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_technologies",
    label: "Company Technologies",
    companyField: true,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "company_twitter_url",
    label: "Company Twitter URL",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "url",
    copy: true,
  },
  {
    id: "company_website",
    label: "Company Website",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "url",
    copy: true,
  },
  {
    id: "contact_linkedin_url",
    label: "Contact Linkedin URL",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "url",
    copy: true,
  },
  {
    id: "corporate_phone_number",
    label: "Corporate Phone Number",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "created_at",
    label: "Created Date",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "time_stamp",
    width: "15.625vw",

    copy: true,
  },
  {
    id: "direct_phone_number",
    label: "Direct Phone Number",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "email_verification_updated_at",
    label: "Email Verified At",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "time_stamp",
    width: "15.625vw",

    copy: true,
  },
  {
    id: "person_city",
    label: "Contact City",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "person_state",
    label: "Contact State",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "seo_description",
    label: "Company Description",
    companyField: true,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "last_name",
    label: "Last Name",
    companyField: false,
    isChecked: false,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  {
    id: "person_country",
    label: "Contact Country",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "13.542vw",
    copy: true,
  },
  // {
  //   id: "updated_at",
  //   label: "Last Updated Date",
  //   companyField: true,
  //   isChecked: true,
  //   sort: true,
  //   ui_id: "time_stamp",
  //width: "17.813vw",

  //   copy: true,
  // },
];

export const EMAIL_MARKETING_HEAD_CONTENT = [
  {
    id: "name",
    sort: true,
    label: "Template Name",
    ui_id: "text",
    width: "15vw",
  },
  {
    id: "template_id",
    sort: true,
    label: "Template ID",
    ui_id: "text",
    width: "10vw",
  },
  {
    id: "subject",
    sort: true,
    label: "Subject",
    ui_id: "text",
    width: "20vw",
  },
  {
    id: "description",
    sort: true,
    label: "Description",
    ui_id: "text",
    width: "20vw",
  },
  {
    id: "created_at",
    sort: true,
    label: "Created at",
    ui_id: "time_stamp",
    width: "10vw",

  },
  {
    id: "updated_at",
    sort: true,
    label: "Updated at",
    ui_id: "time_stamp",
    width: "10vw",

  },

  { id: "action", sort: false, label: "Action", ui_id: "action", width: "8.35vw", },
];

export const PLATFORM_HEAD_CONTENT = [
  {
    id: "first_name",
    sort: true,
    label: "Name",
    ui_id: "user_detail",
    width: "14.063vw",
  },
  {
    id: "company_name",
    sort: true,
    label: "Tenant Name",
    ui_id: "company_info",
    width: "17.813vw",
  },
  {
    id: "email",
    sort: true,
    label: "Email",
    ui_id: "email",
    width: "20vw",

  },
  {
    id: "location",
    sort: true,
    label: "Location",
    ui_id: "text",
    width: "13.542vw",
  },
  {
    id: "is_online",
    sort: false,
    label: "Availability Status",
    ui_id: "status",
    width: "15.104vw",
  },
  {
    id: "account_status",
    sort: true,
    label: "Account Status",
    ui_id: "lead_status",
    width: "18.104vw",
  },
  {
    id: "last_login_date_time",
    sort: true,
    label: "Last Login",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
  {
    id: "created_at",
    sort: true,
    label: "Created At",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
  {
    id: "is_flagged",
    sort: false,
    label: "Flag",
    ui_id: "flag_button",
    width: "8.333vw",
  },
];
export const ACCOUNT_HEAD_CONTENT = [
  {
    id: "first_name",
    sort: true,
    label: "First Name",
    ui_id: "text",
    width: "17.813vw",
  },
  {
    id: "last_name",
    sort: true,
    label: "Last Name",
    ui_id: "text",
    width: "13.542vw",
  },
  {
    id: "email",
    label: "Email",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "email",
    width: "20vw",

    copy: true,
  },
  {
    id: "status",
    sort: true,
    label: "User Status",
    ui_id: "lead_status",
    width: "18.104vw",
  },
  {
    id: "created_at",
    sort: true,
    label: "Created at",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
  {
    id: "updated_at",
    sort: true,
    label: "Updated at",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
];
export const CONTENT_HEAD = [
  {
    id: "title",
    sort: true,
    label: "Title",
    ui_id: "marketing_list",
    width: "17.813vw",
  },
  // {
  //   id: "slug",
  //   sort: true,
  //   label: "Slug",
  //   ui_id: "text",
  //   width: "13.542vw",
  // },
  {
    id: "heading",
    label: "Heading",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "20vw",

    copy: true,
  },
  {
    id: "status",
    sort: true,
    label: "Status",
    ui_id: "text",
    width: "18.104vw",
  },
  {
    id: "created_at",
    sort: true,
    label: "Created at",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
  {
    id: "updated_at",
    sort: true,
    label: "Updated at",
    ui_id: "time_stamp",
    width: "15.625vw",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    ui_id: "action",
    width: "8.35vw",
  },

];
export const CONTACT_HEAD = [
  {
    id: "first_name",
    sort: true,
    label: "First Name",
    ui_id: "marketing_list",
    width: "17.813vw",
  },
  {
    id: "last_name",
    sort: true,
    label: "Last Name",
    ui_id: "text",
    width: "17.813vw",
  },
  {
    id: "email",
    label: "Email",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "email",
    width: "22vw",

    copy: true,
  },
  {
    id: "phone_number",
    sort: true,
    label: "Phone Number",
    ui_id: "text",
    width: "18.104vw",
  },
  // {
  //   id: "message",
  //   sort: true,
  //   label: "Message",
  //   ui_id: "text",
  //   width: "21vw",
  // },
  {
    id: "created_at",
    sort: true,
    label: "Created at",
    ui_id: "time_stamp",
    width: "18vw",

  },

];
export const TECHNOLOGY_HEAD = [
  {
    id: "name",
    sort: true,
    label: "Name",
    ui_id: "marketing_list",
    width: "17.813vw",
  },
  {
    id: "description",
    label: "Description",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "text",
    width: "25vw",
  },
  {
    id: "status",
    sort: true,
    label: "Status",
    ui_id: "text",
    width: "18.104vw",
  },
  {
    id: "created_at",
    sort: true,
    label: "Created at",
    ui_id: "time_stamp",
    width: "18vw",

  },
  {
    id: "action",
    sort: false,
    label: "Action",
    ui_id: "action",
    width: "15vw",
  },

];

export const BUSINESS_HEAD = [
  {
    id: "company_name",
    sort: true,
    label: "Company Name",
    ui_id: "marketing_list",
    width: "17.813vw",
  },
  {
    id: "company_email",
    label: "Company Email",
    companyField: false,
    isChecked: true,
    sort: true,
    ui_id: "email",
    width: "22vw",

    copy: true,
  },
  {
    id: "phone_number",
    sort: true,
    label: "Phone Number",
    ui_id: "text",
    width: "18.104vw",
  },
  {
    id: "created_at",
    sort: true,
    label: "Created at",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
  {
    id: "action",
    sort: false,
    label: "Action",
    ui_id: "action",
    width: "15.625vw",
  },

];

export const COMPANY_HEAD = [
  {
    id: "company_name",
    sort: true,
    label: "Company Name",
    ui_id: "marketing_list",
    width: "21vw",
  },
  {
    id: "company_website",
    sort: false,
    label: "Website",
    ui_id: "text",
    width: "13.542vw",
    isNotCapitalize: true,
  },
  {
    id: "company_phone_number",
    sort: true,
    label: "Phone Number",
    ui_id: "text",
    width: "15.625vw",

  },
  {
    id: "Category",
    sort: true,
    label: "category",
    ui_id: "text",
    width: "15.625vw",

  },
  {
    id: "company_email",
    sort: true,
    label: "Company Email",
    ui_id: "text",
    width: "18.104vw",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    ui_id: "action",
    width: "8.35vw",
  },

];
export const EVENT_HEAD = [
  {
    id: "name",
    sort: true,
    label: "Event Name",
    ui_id: "marketing_list",
    width: "21vw",
  },
  {
    id: "location",
    sort: true,
    label: "Location",
    ui_id: "text",
    width: "13.542vw",
  },
  {
    id: "start_date",
    sort: true,
    label: "Start Date",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
  {
    id: "end_date",
    sort: true,
    label: "End Date",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
  {
    id: "status",
    sort: true,
    label: "Status",
    ui_id: "text",
    width: "18.104vw",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    ui_id: "action",
    width: "8.35vw",
  },

];

export const REQUEST_HEAD_CONTENT = [
  {
    id: "name",
    sort: true,
    label: "Name",
    ui_id: "user_detail",
    width: "14.063vw",
  },
  {
    id: "email",
    sort: true,
    label: "Email",
    ui_id: "email",
    width: "22vw",

  },
  {
    id: "available_credits",
    sort: false,
    label: "Available Credits",
    ui_id: "plain_text",
    width: "12vw",
  },
  {
    id: "status",
    sort: false,
    label: "Request Status",
    ui_id: "request_status",
    width: "15vw",
  },
  {
    id: "created_at",
    sort: true,
    label: "Request at",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
  {
    id: "status",
    sort: false,
    label: "Action",
    ui_id: "approve_request",
    width: "14.583vw",
  },
];

export const CSV_HEADERS = [
  {
    id: "file_name",
    ui_id: "marketing_list",
    width: "17.813vw",
    sort: true,
    label: "File Name",
  },

  {
    id: "owner",
    label: "Uploaded by",
    ui_id: "text",
    width: "13.542vw",
    sort: false,
  },

  {
    id: "total_records",
    ui_id: "text",
    width: "13.542vw",
    sort: false,

    label: "Total Records",
  },

  {
    id: "total_matched",
    ui_id: "number_text",
    width: "9.3vw",
    sort: false,
    label: "Total Matched",
  },

  {
    id: "created_at",
    ui_id: "time_stamp",
    width: "15.625vw",

    sort: true,
    label: "Uploaded At",
  },

  {
    id: "status",
    ui_id: "status",
    width: "15.104vw",
    sort: true,
    label: "Status",
  },
  {
    id: "export",
    ui_id: "button",
    width: "8.333vw",
    sort: false,
    label: "Action",
  },
];

export const IMPORT_COLUMN_PEOPLE_PROPERTY_COLUMNS = [
  { id: "first_name", label: "First Name" },
  { id: "last_name", label: "Last Name" },
  { id: "job_title", label: "Job Title" },
  { id: "company_name", label: "Company Name" },
  { id: "company_city", label: "Company City" },
  { id: "company_state", label: "Company State" },
  { id: "company_country", label: "Company Country" },
  { id: "person_city1", label: "person City1" },
  { id: "person_stat1e", label: "person Stat1e" },
  { id: "person_coun1try", label: "person Coun1try" },
];

export const ALL_COLUMNS = [
  {
    column: "Linkedin Profile ",
    columnLable: "Linkedin Profile",
    label: "contact_linkedin_url",
    property_type: "people",
    property_value: "",
    value: "",
    selected: true,
  },

  {
    column: "Email",
    columnLable: "Email",
    label: "email",
    property_value: "",
    property_type: "people",
    value: "",
    selected: true,
  },
];

export const ALL_COLUMNS_COMPANY_CSV = [
  {
    column: "Company Linkedin Url ",
    columnLable: "Company Linkedin Url",
    label: "company_linkedin_url",
    property_type: "company",
    property_value: "",
    value: "",
    selected: true,
  },

  {
    column: "Company Website",
    columnLable: "Company Website",
    label: "company_website",
    property_value: "",
    property_type: "company",
    value: "",
    selected: true,
  },
];

export const CSV_STEP_TYPES = [
  {
    title: "Enrich People",
    description: "Select a CSV of people and enrich their information.",
    bgColor: "#FFF3E2",
    iconComponent: PEOPLE_IMAGE,
    // component: CreateAutomatedEmail,
  },
  {
    title: "Enrich Companies",
    description: "Select a CSV of companies and enrich their information.",
    bgColor: "#E4FDFF",
    iconComponent: COMPANY_IMAGE,
    // component: CreateCall,
  },
];

export const REFERRAL_HEAD_CONTENT = [
  {
    id: "name",
    sort: true,
    label: "Name",
    ui_id: "name_detail",
    width: "25vw",
  },
  {
    id: "invited_by",
    sort: true,
    label: "Invited By",
    ui_id: "email",
    width: "25vw",

  },
  {
    id: "invited_user",
    sort: true,
    label: "Invited User",
    ui_id: "email",
    width: "25vw",

  },
  {
    id: "created_at",
    sort: true,
    label: "Date",
    ui_id: "time_stamp",
    width: "18.3vw",

  },
];

export const DialogHeader = [
  {
    id: "amount",
    sort: false,
    label: "Credits",
    ui_id: "amount_update",
    width: "16vw",
  },
  {
    id: "user_available_credits",
    sort: false,
    label: "Available Credits",
    ui_id: "plain_text",
    width: "14vw",
  },
  {
    id: "created_at",
    sort: false,
    label: "Date & Time",
    ui_id: "time_stamp",
    width: "14vw",

  },
  {
    id: "description",
    sort: false,
    label: "Description",
    ui_id: "text",
    width: "14vw",
  },
];
export const PLUGIN_HEAD_CONTENT = [
  {
    id: "name",
    sort: true,
    label: "Name",
    ui_id: "user_detail",
    width: "14.063vw",
  },
  {
    id: "email",
    sort: true,
    label: "Email",
    ui_id: "email",
    width: "20vw",

  },
  {
    id: "is_online",
    sort: false,
    label: "Availability Status",
    ui_id: "status",
    width: "15.104vw",
  },
  {
    id: "account_status",
    sort: true,
    label: "Account Status",
    ui_id: "lead_status",
    width: "18.104vw",

  },
  {
    id: "last_login_date_time",
    sort: true,
    label: "Last Login",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
  {
    id: "created_at",
    sort: true,
    label: "Created At",
    ui_id: "time_stamp",
    width: "15.625vw",

  },
  {
    id: "is_flagged",
    sort: false,
    label: "Flag",
    ui_id: "flag_button",
    width: "8.333vw",
  },
];
export const DIALOG_TABLE_HEAD = [
  {
    id: "name",
    sort: true,
    label: "Credits",
    ui_id: "text",
    width: "13.542vw",
  },
  {
    id: "date",
    sort: true,
    label: "Date & Time",
    ui_id: "text",
    width: "13.542vw",
  },
];
export const COUNTRY_LIST = [
  { id: "Afghanistan", label: "Afghanistan" },
  { id: "Åland Islands", label: "Åland Islands" },
  { id: "Albania", label: "Albania" },
  { id: "Algeria", label: "Algeria" },
  { id: "American Samoa", label: "American Samoa" },
  { id: "AndorrA", label: "AndorrA" },
  { id: "Angola", label: "Angola" },
  { id: "Anguilla", label: "Anguilla" },
  { id: "Antarctica", label: "Antarctica" },
  { id: "Antigua and Barbuda ", label: "Antigua and Barbuda" },
  { id: "Argentina", label: "Argentina" },
  { id: "Armenia", label: "Armenia" },
  { id: "Aruba", label: "Aruba" },
  { id: "Australia", label: "Australia" },
  { id: "Austria", label: "Austria" },
  { id: "Azerbaijan", label: "Azerbaijan" },
  { id: "Bahamas", label: "Bahamas" },
  { id: "Bahrain", label: "Bahrain" },
  { id: "Bangladesh", label: "Bangladesh" },
  { id: "Barbados", label: "Barbados" },
  { id: "Belarus", label: "Belarus" },
  { id: "Belgium", label: "Belgium" },
  { id: "Belize", label: "Belize" },
  { id: "Benin", label: "Benin" },
  { id: "Bermuda", label: "Bermuda" },
  { id: "Bhutan", label: "Bhutan" },
  { id: "Bolivia", label: "Bolivia" },
  { id: "Bosnia nd Herzegovina", label: "Bosnia and Herzegovina" },
  { id: "Botswana", label: "Botswana" },
  { id: "Bouvet Island", label: "Bouvet Island" },
  { id: "Brazil", label: "Brazil" },
  {
    id: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
  },
  { id: "Brunei Darussalam", label: "Brunei Darussalam" },
  { id: "Bulgaria", label: "Bulgaria" },
  { id: "Burkina Faso", label: "Burkina Faso" },
  { id: "Burundi", label: "Burundi" },
  { id: "Cambodia", label: "Cambodia" },
  { id: "Cameroon", label: "Cameroon" },
  { id: "Canada", label: "Canada" },
  { id: "Cape Verde", label: "Cape Verde" },
  { id: "Cayman Islands", label: "Cayman Islands" },
  { id: "Central African Republic", label: "Central African Republic" },
  { id: "Chad", label: "Chad" },
  { id: "Chile", label: "Chile" },
  { id: "China", label: "China" },
  { id: "Christma", label: "Christmas Island" },
  { id: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
  { id: "Colombia ", label: "Colombia" },
  { id: "Comoros", label: "Comoros" },
  { id: "Congo", label: "Congo" },
  {
    id: "Congo, The Democratic Republic of the",
    label: "Congo, The Democratic Republic of the",
  },
  { id: "Cook Islands", label: "Cook Islands" },
  { id: "Costa Rica", label: "Costa Rica" },
  { id: "Cote D'Ivoire", label: "Cote D'Ivoire" },
  { id: "Croatia", label: "Croatia" },
  { id: "Cuba", label: "Cuba" },
  { id: "Cyprus", label: "Cyprus" },
  { id: "Czech Republic", label: "Czech Republic" },
  { id: "Denmark", label: "Denmark" },
  { id: "Djibouti", label: "Djibouti" },
  { id: "Dominica", label: "Dominica" },
  { id: "Dominican Republic", label: "Dominican Republic" },
  { id: "Ecuador", label: "Ecuador" },
  { id: "Egypt", label: "Egypt" },
  { id: "El Salvador", label: "El Salvador" },
  { id: "Equatori Guinea", label: "Equatorial Guinea" },
  { id: "Eritrea", label: "Eritrea" },
  { id: "Estonia", label: "Estonia" },
  { id: "Ethiopia", label: "Ethiopia" },
  { id: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)" },
  { id: "Faroe Islands", label: "Faroe Islands" },
  { id: "Fiji", label: "Fiji" },
  { id: "Finland", label: "Finland" },
  { id: "France", label: "France" },
  { id: "French Guiana", label: "French Guiana" },
  { id: "French Polynesia", label: "French Polynesia" },
  { id: "French Southern Territories", label: "French Southern Territories" },
  { id: "Gabon", label: "Gabon" },
  { id: "Gambia", label: "Gambia" },
  { id: "Georgia", label: "Georgia" },
  { id: "Germany", label: "Germany" },
  { id: "Ghana", label: "Ghana" },
  { id: "Gibraltar", label: "Gibraltar" },
  { id: "Greece", label: "Greece" },
  { id: "Greenland", label: "Greenland" },
  { id: "Grenada", label: "Grenada" },
  { id: "Guadeloupe", label: "Guadeloupe" },
  { id: "Guam", label: "Guam" },
  { id: "Guatemala", label: "Guatemala" },
  { id: "Guernsey", label: "Guernsey" },
  { id: "Guinea", label: "Guinea" },
  { id: "Guinea-Bissau", label: "Guinea-Bissau" },
  { id: "Guyana", label: "Guyana" },
  { id: "Haiti", label: "Haiti" },
  {
    id: "Heard Island and Mcdonald Islands",
    label: "Heard Island and Mcdonald Islands",
  },
  {
    id: "Holy See (Vatican City State)",
    label: "Holy See (Vatican City State)",
  },
  { id: "Honduras", label: "Honduras" },
  { id: "Hong Kong", label: "Hong Kong" },
  { id: "Hungary", label: "Hungary" },
  { id: "Iceland", label: "Iceland" },
  { id: "India", label: "India" },
  { id: "Indonesia", label: "Indonesia" },
  { id: "Iran, Islamic Republic Of", label: "Iran, Islamic Republic Of" },
  { id: "Iraq", label: "Iraq" },
  { id: "Ireland", label: "Ireland" },
  { id: "Isle of Man", label: "Isle of Man" },
  { id: "Israel", label: "Israel" },
  { id: "Italy", label: "Italy" },
  { id: "Jamaica", label: "Jamaica" },
  { id: "Japan", label: "Japan" },
  { id: "Jersey", label: "Jersey" },
  { id: "Jordan", label: "Jordan" },
  { id: "Kazakhstan", label: "Kazakhstan" },
  { id: "Kenya", label: "Kenya" },
  { id: "Kiribati", label: "Kiribati" },
  {
    id: "Korea,  Democratic People'S Republic of",
    label: "Korea, Democratic People'S Republic of",
  },
  { id: "Korea, Republic of", label: "Korea, Republic of" },
  { id: "Kuwait", label: "Kuwait" },
  { id: "Kyrgyzstan", label: "Kyrgyzstan" },
  {
    id: "Lao People'S Democratic Republic",
    label: "Lao People'S Democratic Republic",
  },
  { id: "Latvia", label: "Latvia" },
  { id: "Lebanon", label: "Lebanon" },
  { id: "Lesotho", label: "Lesotho" },
  { id: "Liberia", label: "Liberia" },
  { id: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
  { id: "Liechtenstein", label: "Liechtenstein" },
  { id: "Lithuania", label: "Lithuania" },
  { id: "Luxembourg", label: "Luxembourg" },
  { id: "Macao", label: "Macao" },
  {
    id: "Macedoni, The Former Yugoslav Republic of",
    label: "Macedonia, The Former Yugoslav Republic of",
  },
  { id: "Madagascar", label: "Madagascar" },
  { id: "Malawi", label: "Malawi" },
  { id: "Malaysia", label: "Malaysia" },
  { id: "Maldives", label: "Maldives" },
  { id: "Mali", label: "Mali" },
  { id: "Malta", label: "Malta" },
  { id: "Marshall Islands", label: "Marshall Islands" },
  { id: "Martinique", label: "Martinique" },
  { id: "Mauritania", label: "Mauritania" },
  { id: "Mauritius", label: "Mauritius" },
  { id: "Mayotte", label: "Mayotte" },
  { id: "Mexico", label: "Mexico" },
  {
    id: "Micrones, Federated States of",
    label: "Micronesia, Federated States of",
  },
  { id: "Moldova, Republic of", label: "Moldova, Republic of" },
  { id: "Monaco", label: "Monaco" },
  { id: "Mongolia", label: "Mongolia" },
  { id: "Montserrat", label: "Montserrat" },
  { id: "Morocco", label: "Morocco" },
  { id: "Mozambique", label: "Mozambique" },
  { id: "Myanmar", label: "Myanmar" },
  { id: "Namibia", label: "Namibia" },
  { id: "Nauru", label: "Nauru" },
  { id: "Nepal", label: "Nepal" },
  { id: "Netherlands", label: "Netherlands" },
  { id: "Netherlands Antilles", label: "Netherlands Antilles" },
  { id: "New Caledonia", label: "New Caledonia" },
  { id: "New Zealand", label: "New Zealand" },
  { id: "Nicaragua", label: "Nicaragua" },
  { id: "Niger", label: "Niger" },
  { id: "Nigeria", label: "Nigeria" },
  { id: "Niue", label: "Niue" },
  { id: "Norfolk Island", label: "Norfolk Island" },
  { id: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { id: "Norway", label: "Norway" },
  { id: "Oman", label: "Oman" },
  { id: "Pakistan", label: "Pakistan" },
  { id: "Palau", label: "Palau" },
  {
    id: "Palestinan Territory, Occupied",
    label: "Palestinian Territory, Occupied",
  },
  { id: "Panama", label: "Panama" },
  { id: "Papua New Guinea", label: "Papua New Guinea" },
  { id: "Paraguay", label: "Paraguay" },
  { id: "Peru", label: "Peru" },
  { id: "Philippines", label: "Philippines" },
  { id: "Pitcairn", label: "Pitcairn" },
  { id: "Poland", label: "Poland" },
  { id: "Portugal", label: "Portugal" },
  { id: "Puerto Rico", label: "Puerto Rico" },
  { id: "Qatar", label: "Qatar" },
  { id: "Reunion", label: "Reunion" },
  { id: "Romania", label: "Romania" },
  { id: "Russian Federation", label: "Russian Federation" },
  { id: "RWANDA", label: "RWANDA" },
  { id: "Saint Helena", label: "Saint Helena" },
  { id: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { id: "Saint Lucia", label: "Saint Lucia" },
  { id: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  {
    id: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { id: "Samoa", label: "Samoa" },
  { id: "San Mari ", label: "San Marino" },
  { id: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { id: "Saudi Arabia", label: "Saudi Arabia" },
  { id: "Senegal", label: "Senegal" },
  { id: "Serbia and Montenegro", label: "Serbia and Montenegro" },
  { id: "Seychelles", label: "Seychelles" },
  { id: "Sierra Leone", label: "Sierra Leone" },
  { id: "Singapore", label: "Singapore" },
  { id: "Slovakia", label: "Slovakia" },
  { id: "Slovenia", label: "Slovenia" },
  { id: "Solomon Islands", label: "Solomon Islands" },
  { id: "Somalia", label: "Somalia" },
  { id: "South Africa", label: "South Africa" },
  {
    id: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  { id: "Spain", label: "Spain" },
  { id: "Sri Lanka", label: "Sri Lanka" },
  { id: "Sudan", label: "Sudan" },
  { id: "Suriname", label: "Suriname" },
  { id: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { id: "Swaziland", label: "Swaziland" },
  { id: "Sweden", label: "Sweden" },
  { id: "Switzerland", label: "Switzerland" },
  { id: "Syrian Arab Republic", label: "Syrian Arab Republic" },
  { id: "Taiwan, Province of China", label: "Taiwan, Province of China" },
  { id: "Tajikistan", label: "Tajikistan" },
  {
    id: "Tanzania , United Republic of",
    label: "Tanzania, United Republic of",
  },
  { id: "Thailand", label: "Thailand" },
  { id: "Timor-Leste", label: "Timor-Leste" },
  { id: "Togo", label: "Togo" },
  { id: "Tokelau", label: "Tokelau" },
  { id: "Tonga", label: "Tonga" },
  { id: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { id: "Tunisia", label: "Tunisia" },
  { id: "Turkey", label: "Turkey" },
  { id: "Turkmenistan", label: "Turkmenistan" },
  { id: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { id: "Tuvalu", label: "Tuvalu" },
  { id: "Uganda", label: "Uganda" },
  { id: "Ukraine", label: "Ukraine" },
  { id: "United Arab Emirates", label: "United Arab Emirates" },
  { id: "United Kingdom", label: "United Kingdom" },
  { id: "United States", label: "United States" },
  {
    id: "United  States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
  },
  { id: "Uruguay", label: "Uruguay" },
  { id: "Uzbekist ", label: "Uzbekistan" },
  { id: "Vanuatu", label: "Vanuatu" },
  { id: "Venezuel", label: "Venezuela" },
  { id: "Viet Nam", label: "Viet Nam" },
  { id: "Virgin Islands, British", label: "Virgin Islands, British" },
  { id: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
  { id: "Wallis and Futuna", label: "Wallis and Futuna" },
  { id: "Western", label: "Western Sahara" },
  { id: "Yemen", label: "Yemen" },
  { id: "Zambia", label: "Zambia" },
  { id: "Zimbabwe ", label: "Zimbabwe" },
];
export const DEBOUNCE_DELAY = 300;
export const EACH_BATCH_SIZE = 512 * 10 * 10 * 5;
export const IMPORT_BLACKLIST_FILES_HEADS = [
  { id: "file_name", label: "File Name", isChecked: true, width: "12vw" },
  {
    id: "error_count",
    label: "Invalid Records",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "total_records",
    label: "Total Records",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "total_valid_record",
    label: "Total Valid",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "insert_count",
    label: "Total Inserted",
    isChecked: true,
    width: "12vw",
  },
  {
    id: "created_at",
    label: "Uploaded at",
    isChecked: true,
    width: "12vw",
    type: "date",
  },
  {
    id: "updated_at",
    label: "Last updated at",
    isChecked: true,
    width: "12vw",
    type: "date",
  },
  { id: "status", label: "Status", isChecked: true, width: "11rem" },
  {
    id: "processing_count",
    label: "Process Count",
    isChecked: true,
    width: "9rem",
  },

  { id: "data_for", label: "Source Type", isChecked: true, width: "11rem" },

  { id: "action", label: "Action", isChecked: true, width: "9rem" },
];

export const IMPORT_EMPTY_FIELD_OPTIONS = [
  // { id: 'true', label: "Use the value from ReachIQ Database" },
  { id: "false", label: "Leave Empty" },
];

export const STATIC_COLUMN_ORDER = [
  "first_name",
  "last_name",
  "job_title",
  "email",
  "mobile_number",
  "direct_phone_number",
  "company_phone_number",
  "person_city",
  "person_state",
  "person_country",
  "contact_linkedin_url",
  "company_linkedin_url",
  "company_facebook_url",
  "company_twitter_url",
  "company_name",
  "company_website",
  "industry",
  "number_of_employees",
  "annual_revenue",
  "company_city",
  "company_state",
  "company_country",
  "company_address",
  "company_technologies",
  "company_keywords",
  "seo_description",
];
export const IMPORT_CREATE_TYPE = [
  { id: "create_only", label: "Create Only" },
  { id: "update_only", label: "Update Only" },
  { id: "create_and_update", label: "Create and Update both" },
];
export const IMPORT_COLUMN_PROPERTY_TYPES = [
  { id: "people", label: "People" },
  { id: "company", label: "Company" },
];

export const DYNAMIC_TAGS = [
  {
    id: "first_name",
    label: "First Name",
    companyField: false,
    isChecked: true,
    dummyValue: "John",
  },
  {
    id: "last_name",
    label: "Last Name",
    companyField: false,
    isChecked: false,
    dummyValue: "Doe",
  },
  {
    id: "job_title",
    label: "Job Title",
    companyField: false,
    isChecked: true,
    dummyValue: "Manager",
  },
  {
    id: "company_name",
    label: "Company Name",
    companyField: true,
    isChecked: true,
    dummyValue: "RNF Technologies",
  },
  {
    id: "number_of_employees",
    label: "Number of Employees",
    companyField: true,
    isChecked: true,
    dummyValue: "100",
  },
  {
    id: "email",
    label: "Email",
    companyField: false,
    isChecked: true,
    dummyValue: "John@rnf.com",
  },
  {
    id: "industry",
    label: "Industry",
    companyField: true,
    isChecked: true,
    dummyValue: "IT",
  },
  {
    id: "company_keywords",
    label: "Keywords",
    companyField: true,
    isChecked: true,
    dummyValue: "SEO, IT",
  },
  {
    id: "company_address",
    label: "Company Address",
    companyField: true,
    isChecked: false,
    dummyValue: "Noida, 62",
  },
  {
    id: "annual_revenue",
    label: "Annual Revenue",
    companyField: true,
    isChecked: false,
    dummyValue: "$1000",
  },
  {
    id: "company_city",
    label: "Company city",
    companyField: true,
    isChecked: false,
    dummyValue: "Noida",
  },
  {
    id: "company_country",
    label: "Company Country",
    companyField: true,
    isChecked: true,
    dummyValue: "India",
  },
  {
    id: "company_facebook_url",
    label: "Company Facebook URL",
    companyField: true,
    isChecked: false,
    dummyValue: "www.facebook.com",
  },
  {
    id: "company_linkedin_url",
    label: "Company LinkedIn URL",
    companyField: true,
    isChecked: false,
    dummyValue: "www.linkedin.com",
  },
  {
    id: "company_phone_number",
    label: "Company Phone Number",
    companyField: true,
    isChecked: false,
    dummyValue: "9999999999",
  },
  {
    id: "company_state",
    label: "Company State",
    companyField: true,
    isChecked: true,
    dummyValue: "UP",
  },
  {
    id: "company_technologies",
    label: "Company Technologies",
    companyField: true,
    isChecked: true,
    dummyValue: "React, NODE",
  },
  {
    id: "company_twitter_url",
    label: "Company Twitter URL",
    companyField: true,
    isChecked: false,
    dummyValue: "www.twitter.com",
  },
  {
    id: "company_website",
    label: "Company Website",
    companyField: true,
    isChecked: false,
    dummyValue: "www.rnftechnologies.com",
  },
  {
    id: "contact_linkedin_url",
    label: "Contact Linkedin URL",
    companyField: false,
    isChecked: false,
    dummyValue: "www.linkedin.com",
  },
  {
    id: "corporate_phone_number",
    label: "Corporate Phone Number",
    companyField: false,
    isChecked: false,
    dummyValue: "9999999999",
  },
  // {
  //   id: "created_at",
  //   label: "Created At",
  //   companyField: true,
  //   isChecked: false,
  //   dummyValue: "20 August",
  // },
  {
    id: "direct_phone_number",
    label: "Direct Phone Number",
    companyField: false,
    isChecked: false,
    dummyValue: "9999999999",
  },
  // {
  //   id: "email_verification_updated_at",
  //   label: "Email Verified Updated At",
  //   companyField: false,
  //   isChecked: false,
  //   dummyValue: "20 August",
  // },
  {
    id: "person_city",
    label: "Person City",
    companyField: false,
    isChecked: true,
    dummyValue: "Noida",
  },
  {
    id: "person_state",
    label: "Person State",
    companyField: false,
    isChecked: false,
    dummyValue: "UP",
  },
  {
    id: "company_description",
    label: "Company Description",
    companyField: true,
    isChecked: false,
    dummyValue: "SEO, IT",
  },
  {
    id: "person_country",
    label: "Person Country",
    companyField: false,
    isChecked: true,
    dummyValue: "India",
  },
  // {
  //   id: "updated_at",
  //   label: " Updated At",
  //   companyField: true,
  //   isChecked: true,
  //   dummyValue: "20 August",
  // },
];

export const EDITOR_IMAGE_LIMIT = 1498 * 500;

export const qarHeadCells = [
  {
    id: "first_name",
    label: "Field Name",
    companyField: false,
    isChecked: false,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "value",
    label: "Value",
    companyField: false,
    isChecked: true,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "reason",
    label: "Reason",
    companyField: false,
    isChecked: true,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "remark",
    label: "Remark",
    companyField: false,
    isChecked: true,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "action",
    label: "Action",
    companyField: false,
    isChecked: true,
    ui_id: "button",
    width: "8.333vw",
    sort: false,
  },
];

export const flagHeadCells = [
  {
    id: "first_name",
    label: "Field Name",
    companyField: false,
    isChecked: false,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "value",
    label: "Value",
    companyField: false,
    isChecked: true,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "reason",
    label: "Reason",
    companyField: false,
    isChecked: true,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "remark",
    label: "Remark",
    companyField: false,
    isChecked: true,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },

];

export const preqarHeadCells = [
  {
    id: "first_name",
    label: "Field Name",
    companyField: false,
    isChecked: false,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "value",
    label: "Old Value",
    companyField: false,
    isChecked: true,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "updated_value",
    label: "Updated Value",
    companyField: false,
    isChecked: true,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },

  {
    id: "reason",
    label: "Flag Reasons",
    companyField: false,
    isChecked: true,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "remark",
    label: "Remark",
    companyField: false,
    isChecked: true,
    ui_id: "status",
    width: "15.104vw",
    sort: false,
  },
  {
    id: "action",
    label: "Action",
    companyField: false,
    isChecked: true,
    ui_id: "button",
    width: "8.333vw",
    sort: false,
  },
];


export const tempHeadCellsqmodalcompany = [


  {
    id: "company_name",
    label: "Name",
    companyField: true,
    isChecked: true,
    flagreason: "company_name_flag_reason",
  },
  {
    id: "number_of_employees",
    label: "Employees",
    companyField: true,
    isChecked: true,
    flagreason: "number_of_employees_flag_reason",
  },
  {
    id: "industry",
    label: "Industry",
    companyField: true,
    isChecked: true,
    flagreason: "industry_flag_reason",
  },

  {
    id: "company_keywords",
    label: "Keywords",
    companyField: true,
    isChecked: true,
    flagreason: "company_keywords_flag_reason",
  },
  {
    id: "company_address",
    label: "Address",
    companyField: true,
    isChecked: false,
    flagreason: "company_address_flag_reason",
  },
  {
    id: "annual_revenue",
    label: "Annual Revenue",
    companyField: true,
    isChecked: false,
    flagreason: "annual_revenue_flag_reason",
  },
  {
    id: "company_city",
    label: "city",
    companyField: true,
    isChecked: false,
    flagreason: "company_city_flag_reason",
  },
  {
    id: "company_country",
    label: "Country",
    companyField: true,
    isChecked: true,
    flagreason: "company_country_flag_reason",
  },
  {
    id: "company_facebook_url",
    label: "Facebook URL",
    companyField: true,
    isChecked: false,
    flagreason: "company_facebook_url_flag_reason",
  },
  {
    id: "company_linkedin_url",
    label: "Linkedin URL",
    companyField: true,
    isChecked: false,
    flagreason: "company_linkedin_url_flag_reason",
  },
  {
    id: "company_phone_number",
    label: "Phone Number",
    companyField: true,
    isChecked: false,
    flagreason: "company_phone_number_flag_reason",
  },
  {
    id: "company_state",
    label: "State",
    companyField: true,
    isChecked: true,
    flagreason: "company_state_flag_reason",
  },
  {
    id: "company_technologies",
    label: "Technologies",
    companyField: true,
    isChecked: true,
    flagreason: "company_technologies_flag_reason",
  },
  {
    id: "company_twitter_url",
    label: "Twitter URL",
    companyField: true,
    isChecked: false,
    flagreason: "company_twitter_url_flag_reason",
  },
  {
    id: "company_website",
    label: "Website",
    companyField: true,
    isChecked: false,
    flagreason: "company_website_flag_reason",
  },

  {
    id: "seo_description",
    label: "Seo Description",
    companyField: true,
    isChecked: false,
    flagreason: "seo_description_flag_reason",
  },

  {
    id: "original_company_website",
    label: "Original Company website",
    companyField: false,
    isChecked: true,
    flagreason: "company_website_flag_reason",
  },
];



export const colorsArray = [
  "#000000",
  "#444444",
  "#666666",
  "#999999",
  "#cccccc",
  "#eeeeee",
  "#f3f3f3",
  "#ffffff",
  "#ff0000",
  "#ff9900",
  "#ffff00",
  "#00ff00",
  "#00ffff",
  "#0000ff",
  "#9900ff",
  "#ff00ff",
  "#f4cccc",
  "#fce5cd",
  "#fff2cc",
  "#d9ead3",
  "#d0e0e3",
  "#cfe2f3",
  "#d9d2e9",
  "#ead1dc",
  "#ea9999",
  "#f9cb9c",
  "#ffe599",
  "#b6d7a8",
  "#a2c4c9",
  "#9fc5e8",
  "#b4a7d6",
  "#d5a6bd",

  "#e06666",
  "#f6b26b",
  "#ffd966",
  "#93c47d",
  "#76a5af",
  "#6fa8dc",
  "#8e7cc3",
  "#c27ba0",

  "#cc0000",
  "#e69138",
  "#f1c232",
  "#6aa84f",
  "#45818e",
  "#3d85c6",
  "#674ea7",
  "#a64d79",

  "#990000",
  "#b45f06",
  "#bf9000",
  "#38761d",
  "#134f5c",
  "#0b5394",
  "#351c75",
  "#741b47",

  "#660000",
  "#783f04",
  "#7f6000",
  "#274e13",
  "#0c343d",
  "#073763",
  "#20124d",
  "#4c1130",
];

export const EDITOR_FONT_SIZE_OPTIONS = [
  { id: "2", label: "Normal" },
  { id: "1", label: "Small" },
  { id: "3", label: "Medium" },
  { id: "4", label: "Large" },
  { id: "5", label: "X-Large" },
  { id: "6", label: "Huge" },
];
export const EDITOR_FONT_FAMILY_OPTIONS = [
  { id: "Inter", label: "Inter" },
  { id: "Sans Serif", label: "Sans Serif" },
  { id: "Arial", label: "Arial" },
  { id: "Serif", label: "Serif" },
  { id: "Fixed Width", label: "Fixed Width" },
  { id: "Wide", label: "Wide" },
  { id: "Narrow", label: "Narrow" },
  { id: "Comic Sans MS", label: "Comic Sans MS" },
  { id: "Garamond", label: "Garamond" },
  { id: "Georgia", label: "Georgia" },
  { id: "Tahoma", label: "Tahoma" },
  { id: "Trebuchet MS", label: "Trebuchet MS" },
  { id: "Verdana", label: "Verdana" },
];
import { borderRadius } from "../../utils/constants/UI/uiConstants";

export const styles = {
  container: {
    border: "1px solid #d3dae3",
    borderTop: "none",
    marginBottom: "1rem",
    borderBottomLeftRadius: borderRadius.primary,
    borderBottomRightRadius: borderRadius.primary,
  },
  inputContainer: {
    padding: "0.8rem",
  },
  sliderContainer: {
    padding: "1rem 1rem",
  },
  sliderLabel: {
    fontSize: "12px",
    fontWeight: "400",
    alignSelf: "center",
  },
  containTooltipTitles: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    borderBottom: "1px solid #D3DAE3",
  },
  tooltipTitle: {
    padding: "12px",
    textAlign: "center",
    cursor: "pointer",
  },
  selectedTooltipTitle: {
    padding: "12px",
    textAlign: "center",
    borderBottom: "1px solid #ff4848",
  },
  toolTipContainer: {
    minWidth: "250px",
    background: "white",
    boxShadow: "0px 9px 66px #00000029",
    paddingBottom: "1rem",
    borderRadius: "5px",
  },
  containAllFilter: {
    marginBottom: "0.4rem",
    borderRadius: "4px",
    padding: "0.5rem ",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  jobTitleHead: {
    display: "flex",
  },
  filterContainer: {
    padding: "0.5rem 0.8rem",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  filterText: {
    fontWeight: "500",
    color: "#616A7D",
    fontSize: "12px",
    paddingRight: 3,
    alignSelf: "center",
    cursor: "pointer",
  },
  flex: {
    display: "flex",
  },
  dateLable: {
    fontWeight: "500",
    fontSize: "13px",
  },
  dateInput: {
    border: "1px solid #d3dae3",
    padding: "6px 3px",
    borderRadius: "5px",
    width: "100%",
    fontWeight: "500",
    fontSize: "12px",
    marginRight: "2px",
  },
  slider: {
    color: "#ff4848 !important",
  },
  containDateInput: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  containRevenueInputs: {
    marginBottom: "1rem",
    display: "flex",
    gap: "4px",
  },
  containBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
    columnGap: "10px",
  },
  containBtnUpper: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "10px",
  },

  cancelBtn: {
    background: "#ff4848",
    color: "white",
    padding: "6px 18px",
    borderRadius: "4px",
    fontSize: "14px",
    border: "none",
    cursor: "pointer",
    fontWeight: "500",
  },
  submitBtn: {
    background: "#ff4848",
    color: "white",
    padding: "6px 18px",
    borderRadius: "4px",
    fontSize: "14px",
    border: "none",
    marginLeft: "10px",
    cursor: "pointer",
    fontWeight: "500",
  },
};

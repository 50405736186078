import { EVENT_TABLE_DATA_FETCH_ERROR, EVENT_TABLE_DATA_FETCH_REQUEST, EVENT_TABLE_DATA_FETCH_SUCCESS } from "../constants/eventConstants";

export const eventlistReducer = (state = {}, action) => {
    switch (action.type) {
      case EVENT_TABLE_DATA_FETCH_REQUEST:
        return { loading: true };
  
      case EVENT_TABLE_DATA_FETCH_SUCCESS:
        return { loading: false, columns: action.payload, totalRecords: action.totalRecords };
  
      case EVENT_TABLE_DATA_FETCH_ERROR:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  }; 
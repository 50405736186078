import { TECHNOLOGY_TABLE_DATA_FETCH_ERROR, TECHNOLOGY_TABLE_DATA_FETCH_REQUEST, TECHNOLOGY_TABLE_DATA_FETCH_SUCCESS } from "../constants/technologyConstant";

export const technologylistReducer = (state = {}, action) => {
    switch (action.type) {
      case TECHNOLOGY_TABLE_DATA_FETCH_REQUEST:
        return { loading: true };
  
      case TECHNOLOGY_TABLE_DATA_FETCH_SUCCESS:
        return { loading: false, columns: action.payload, totalRecords: action.totalRecords };
  
      case TECHNOLOGY_TABLE_DATA_FETCH_ERROR:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  }; 
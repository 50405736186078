import { makeStyles } from "@mui/styles";
import { LOGIN_BACKGROUND } from "../../utils/constants/assets";

export const styles = makeStyles({
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    height: "100vh",
  },
  topContainer: {
    height: "50vh",
    backgroundSize: "cover",
    backgroundBlendMode: "multiply",
    background: `url(${LOGIN_BACKGROUND}) 0 0 no-repeat #ffeeee`,
    position: "relative",
  },
  bottomContainer: {
    height: "50vh",
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem",
    flexDirection: "column",
  },
  reachiq_logo: {
    width: "clamp(7rem,12vw,15rem)",
    objectFit: "cover",
  },
  containLogo: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10vh",
  },
  modal: {
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, 0)",
    background: "white",
    padding: "clamp(1.5rem, 2.5vw, 4rem) clamp(3rem, 4vw, 9rem)",
    boxShadow: "0px 3px 10px rgb(0 0 0 / 8%)",
    borderRadius: "8px",
    "@media (max-width: 768px)": {
      width: "90%",
    },
    "@media (min-width: 1024px)": {
      width: "40%",
    },
  },
  policyPara: {
    textAlign: "center",
    fontWeight: "500",
    color: "#626B7E",
    fontSize: "14px",
  },
  resetTitle: {
    textAlign: "center",
    fontSize: "clamp(1.2rem, 1.8vw,3rem)",
    fontWeight: "400",
    color: "#212833",
  },
  containInstructions: {
    padding: "clamp(1rem, 2vw, 3rem)",
    
  },
  containInput: {
    display: "flex",
    flexDirection: "column",
    background: "#fafbfc",
    padding: "0.8rem clamp(1rem, 2vw, 3rem)",
    border: "1px solid #dee2e6",
    borderRadius: "4px",
  },
  input: {
    background: "unset",
    border: "none",
    fontWeight: "700",
    fontSize: "14px",
    marginTop: "5px",
    color: "#1d2029",
  },
  inputLable: {
    fontWeight: "500",
    fontSize: "12px",
  },
  submitButton: {
    background: "#ff4848",
    border: "none",
    color: "white",
    boxShadow: "0px 9px 20px #ff16543c",
    fontWeight: "600",
    width: "40%",
    padding: "1.2rem",
    marginTop: "clamp(1.5rem,4vh, 4rem)",
    borderRadius: "8px",
    fontSize: "14px",
    cursor: "pointer",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
  },
  termsPara: {
    fontSize: "clamp(12px,0.8vw,36px)",
    color: "#626B7E",
    fontWeight: "500",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  privacyLink: {
    fontSize: "clamp(12px,0.8vw,36px)",
    fontWeight: "500",
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: "#626B7E",
    transition:"100ms",
    "&:hover":{
      color:"#ff4848"
    }
  },
  mTop:{
    marginTop:"8px"
  },
  m4Top:{
    marginTop:"4px"
  },
});

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Anchor } from "..";
import { paginationDataAction } from "../../redux/actions/customTableActions";
import {
  ASCENDING_SORT,
  DESCENDING_SORT,
  DUMMY_COMPANY_IMAGE,
  NO_SORT,
  PIN_ICON,
} from "../../utils/constants/assets";
import {
  BORDER,
  BRAND,
  GREY,
  INTER_DIMENSIONAL_BLUE,
  PRIMARY,
  SUCCESS,
  WARNING,
} from "../../utils/constants/colors";
import { fontSize, fontWeight, ICON_LABELS } from "../../utils/constants/UI";
import {
  createCompanyURL,
  textEllipsesHandler,
  timeStampToDate,
  timeStampToDateTime,
  timeStampToTime,
} from "../../utils/helpers";
import AvatarName from "../Avatar/AvatarName";
import AvatarOutlined from "../Avatar/AvatarOutlined";
import Checkbox from "../Buttons/Checkbox/Checkbox";
import IconComponent from "../Icon/IconComponent";
import Image from "../Image/Image";
import SkeletonLoader from "../Loaders/SkeletonLoader";
import TypographyInline from "../Typography/TypographyInline";
import CustomPagination from "./CustomPagination";
import { STRINGS } from "./strings";
import { styles } from "./style";
import { Button } from "../Buttons";
import StatusChip from "../StatusChip/StatusChip";
import CustomMenu from "../Menu/CustomMenu";
import FilterChip from "../FilterChip/FilterChip";
import CustomTooltip from "../Tooltip/CustomTooltip";
import classes from "./Table.module.css";
import AvatarNameWithLink from "./AvatarNameWithLink";
import FlagButton from "../Buttons/FlagButton";
// import { UserReason } from "../../screens/UserdataScreen/components/UserReason";

const filterTableOperations = (operations, current_row, current_head) => {
  try {
    // filter operations based on folder type.
    operations = operations.filter(
      (item) =>
        (item.id === "clone" && current_row?.type !== "folder") ||
        item.id !== "clone"
    );
    if (Object.keys(current_row) && Object.keys(current_head)) {
      // if state is interrupted, remove resume option.
      if (current_row?.state === "interrupted") {
        const new_operations = operations.filter(
          (item) => !(item?.id?.toLowerCase() === "resume")
        );
        // check for pin and unpin label.
        if (Object.keys(current_row).includes("pin")) {
          return new_operations.map((item) => {
            return {
              ...item,
              label:
                item?.label === "Pin"
                  ? parseInt(current_row?.pin)
                    ? "Unpin"
                    : "Pin"
                  : item?.label,
            };
          });
        }
        return new_operations;
      }

      // if state is paused, remove pause option.
      else if (current_row?.state === "paused") {
        const new_operations = operations.filter(
          (item) => !(item?.id?.toLowerCase() === "pause")
        );
        // check for pin and unpin label.
        if (Object.keys(current_row).includes("pin")) {
          return new_operations.map((item) => {
            return {
              ...item,
              label:
                item?.label === "Pin"
                  ? parseInt(current_row?.pin)
                    ? "Unpin"
                    : "Pin"
                  : item?.label,
            };
          });
        }
        return new_operations;
      }

      // if state is active/ scheduled, remove resume option.
      else {
        const new_operations = operations.filter(
          (item) => !(item?.id?.toLowerCase() === "resume")
        );
        // check for pin and unpin label.
        if (Object.keys(current_row).includes("pin")) {
          return new_operations.map((item) => {
            return {
              ...item,
              label:
                item?.label === "Pin"
                  ? parseInt(current_row?.pin)
                    ? "Unpin"
                    : "Pin"
                  : item.label,
            };
          });
        }

        return new_operations;
      }
    }

    return operations;
  } catch (error) {
    console.log("error");
    return operations;
  }
};

const CustomTable = ({ tableData = {}, headerStrip = [] }) => {
  const dispatch = useDispatch();
  const emptyBodyHeads = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {},{},{},{},{},{},{}];
  const tableHeads = tableData?.heads || [];
  const tableBody = tableData?.isLoading
    ? emptyBodyHeads
    : tableData?.body || [];
  const tableActions = tableData?.actions || [];
  const tableStausList = tableData?.status_list || [];
  const tableHeaderStripValue = tableData?.header_strip_selected_value;
  const disableKey = tableData?.disable_key;
  const selectionPermission = tableData?.is_selection_not_allowed;
  const buttonText = tableData?.buttonText
  const paginationDataSelector = useSelector(
    (state) => state.getPaginationQuery
  );

  const [checkedValue, setCheckedValue] = useState(
    paginationDataSelector?.data?.checkedRow || []
  );
  const [isCopied, setIsCopied] = useState("");
  const [isActionMenuVisible, setIsActionMenuVisible] = useState(false);
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [leadStatusMenuAnchor, setLeadStatusMenuAnchor] = useState(null);
  const [clickedRowData, setClickedRowData] = useState({});
  const [clickedHeadData, setClickedHeadData] = useState({});

  const tableOperations = filterTableOperations(
    tableData?.operations || [],
    clickedRowData,
    clickedHeadData
  );

  React.useEffect(() => {
    setCheckedValue(paginationDataSelector?.data?.checkedRow || []);
  }, [paginationDataSelector?.data?.checkedRow]);

  // Storing id in redux for single checkbox.
  const checkedHandler = (e) => {

    let index = checkedValue.indexOf(e.target.value);
    let checkedArr = [...checkedValue];

    if (index === -1) {
      setCheckedValue([...checkedValue, e.target.value]);
      checkedArr.push(e.target.value);
      dispatch(
        paginationDataAction({
          ...paginationDataSelector?.data,
          checkedRow: checkedArr,
        })
      );
    } else {
      checkedValue.splice(index, 1);
      setCheckedValue([...checkedValue]);
      checkedArr.splice(index, 1);
      dispatch(
        paginationDataAction({
          ...paginationDataSelector?.data,
          checkedRow: checkedArr,
        })
      );
    }
  };
  // Storing id in redux for single checkbox.
  // Storing all ids in redux for all checkbox.
  const checkedAllHandler = () => {
    const all_operations_ids = disableKey?.length
      ? tableBody
        .filter((item) => {
          if (item[disableKey]) {
            return item._id;
          }
        })
        ?.map((item) => {
          return item?._id;
        })
      : tableBody.map((item) => item._id);
    const all_ids = [...all_operations_ids, ...checkedValue];
    const all_unique = [...new Set(all_ids)];

    if (checkedValue.length !== tableBody.length) {
      if (all_operations_ids?.length === checkedValue?.length) {
        dispatch(
          paginationDataAction({
            ...paginationDataSelector?.data,
            checkedRow: [],
          })
        );
        setCheckedValue([]);
      } else {
        dispatch(
          paginationDataAction({
            ...paginationDataSelector?.data,
            checkedRow: all_unique,
          })
        );
        setCheckedValue(all_unique);
      }
    } else {
      dispatch(
        paginationDataAction({
          ...paginationDataSelector?.data,
          checkedRow: [],
        })
      );
      setCheckedValue([]);
    }
  };
  // Storing all ids in redux for all checkbox.
  // Copy Text Function.
  const copyTextToClipboard = (row, head, copyText) => {
    navigator?.clipboard?.writeText(copyText?.length ? copyText : row[head.id]);
    setIsCopied(copyText?.length ? copyText : row[head.id]);
    setTimeout(() => {
      setIsCopied("");
    }, 2000);
  };
  // Copy Text Function.

  // Storing id and sort direction to redux.
  const sortHandler = (head, index) => {
    dispatch(
      paginationDataAction({
        ...paginationDataSelector.data,
        checkedRow: [],
        query: {
          ...paginationDataSelector.data?.query,
          sort: {
            id: head.id,
            direction:
              paginationDataSelector.data?.query?.sort?.direction === "asc"
                ? "desc"
                : "asc",
          },
        },
      })
    );
  };
  // Storing id and sort direction to redux.

  // Returns duration label as time in booked meeting timecell.
  const getBookedDurationLabel = (duration) => {
    console.log("Duration___ ", duration);
    if (parseInt(duration) === 60) {
      return "1 hr";
    } else if (parseInt(duration) === 90) {
      return "1hrs 30min";
    } else if (parseInt(duration) === 120) {
      return "2hrs";
    } else {
      return `${parseInt(duration)} min`;
    }
  };
  // Returns duration label as time in booked meeting timecell.

  // Returns duration label as multiple or time in meeting timecell.
  const getDurationLabel = (row) => {
    let durationLength = row?.duration_options?.length;
    if (durationLength) {
      if (row?.duration_options.length > 1) {
        return STRINGS.MULTIPLE;
      } else {
        return row?.duration_options[0]?.label;
      }
    } else {
      return `${row?.duration_options
        ? `${row?.duration_options} min`
        : getBookedDurationLabel(row?.booked_duration)
        } `;
    }
  };
  // Returns duration label as multiple or time in meeting timecell.

  // Action Icon Function opens menu having all operations.
  const actionIconHandler = (event, row, head) => {
    setIsActionMenuVisible(true);
    setActionMenuAnchor(event.currentTarget);
    setClickedRowData(row);
    setClickedHeadData(head);
  };
  // Action Icon Function opens menu having all operations.

  // Action Icon Function opens menu having all operations.
  const leadStatusHandler = (event, row, head) => {
    setLeadStatusMenuAnchor(event.currentTarget);
    setClickedRowData(row);
    setClickedHeadData(head);
  };
  // Action Icon Function opens menu having all operations.

  // Used to get task status.
  const getTaskStatusLabel = (row) => {
    if (row?.status && row?.is_lead) {
      return "Marked as Completed | Marked as Lead";
    } else if (row?.is_lead) {
      return "Marked as Lead";
    } else if (row?.status) {
      return "Marked as Completed";
    }
  };
  // Used to get task status.

  // Get time stamp according to id.
  const getTimeStampLabel = (row, head) => {
    if (head?.label === "Date") {
      return timeStampToDate(
        row?.event_startDateTime ||
        row?.created_at ||
        row?.updated_at ||
        row?.lead_date_time
      );
    } else if (head?.label === "Time") {
      return timeStampToTime(
        row?.event_startDateTime ||
        row?.created_at ||
        row?.updated_at ||
        row?.lead_date_time
      );
    } else {
      return timeStampToDateTime(
        row?.[head?.id] ||
        row?.event_startDateTime ||
        row?.created_at ||
        row?.updated_at ||
        row?.lead_date_time
      );
    }
  };
  // Get time stamp according to id.

  // Table Designs
  const textDesign = (row, head, index) => {
    return (
      <CustomTooltip
        key={index}
        title={
          !tableData?.isLoading &&
          (window.innerWidth < 1250 || row[head.id]?.length > 20
            ? row[head.id]
            : "")
        }
      >
        <div
          key={index}
          onClick={() => {
            if (!(row[head.id]?.toString().length && tableData?.isFlag)) {
              tableActions[head.ui_id]?.function(row, head)
            }
          }
          }

          // style={`${styles.textCell} ${styles[cellWidth[head?.ui_id]]}`}
          className={classes.textCell}
          style={{
            ...styles.textCell, width: head?.width,
            minWidth: head?.width, textTransform: head?.isNotCapitalize ? "unset" : "capitalize"
          }}
        >
          {tableData?.isLoading ? (
            <SkeletonLoader
              type="rounded"
              skeletonWidth={"60%"}
              skeletonHeight={20}
            />
          ) : (
            <>
              <TypographyInline
                isEllipses
                label={row[head.id]?.toString().length ? row[head.id] : "--"}
                size="sm"
                color={GREY.SECONDARY}
                fontWeight={fontWeight.REGULAR}
              />
              {row[head.id]?.toString().length && tableData?.isFlag ? (

                <IconComponent
                  id={classes.copiedTextContainer}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.FLAG}
                  onClick={() => {
                    tableActions[head.ui_id]?.function(row, head);
                  }}
                />

              ) : ""}
            </>
          )}

          {/* copy icon code */}
          {row[head.id]?.toString().length && head?.copy ? (
            isCopied === row[head.id] ? (
              <div
                id={classes.copiedTextContainer}
                style={styles.copiedTextContainer}
              >
                <IconComponent
                  fontSize={fontSize.SM}
                  color={SUCCESS.W_500}
                  iconLabel={ICON_LABELS.DONE}
                />
                <TypographyInline
                  isEllipses
                  color={SUCCESS.W_500}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.COPIED_LABEL}
                  size={"xs"}
                />
              </div>
            ) : (
              <>
                {row[head.id]?.toString().length && head?.copy ? (
                  <IconComponent
                    id={classes.copiedTextContainer}
                    color={GREY.TERTIARY}
                    fontSize={fontSize.MD}
                    iconLabel={ICON_LABELS.CONTENT_COPY}
                    onClick={() => copyTextToClipboard(row, head)}
                  />
                ) : null}
                {row[head.id]?.length && head?.flag ? (

                  <IconComponent
                    id={classes.copiedTextContainer}
                    color={GREY.TERTIARY}
                    fontSize={fontSize.MD}
                    iconLabel={ICON_LABELS.FLAG}
                    onClick={() => {
                      tableActions[head.ui_id]?.function(row, head);
                    }}
                  />

                ) : ""}
              </>
            )
          ) : null}

          {/* copy icon code */}
        </div>
      </CustomTooltip>
    );
  };

  const boldTextDesign = (row, head, index) => {
    return (
      <CustomTooltip
        key={index}
        title={
          !tableData?.isLoading &&
          (window.innerWidth < 1250 || row[head.id]?.length > 20
            ? row[head.id]
            : "")
        }
      >
        <div
          key={index}
          onClick={tableActions[head.ui_id]?.function(row, head)}
          style={{
            ...styles.boldTextCell,
            width: head?.width,
            minWidth: head?.width,
          }}
        >
          {!selectionPermission && (
            <Checkbox
              checked={checkedValue?.indexOf(row._id) > -1}
              value={row._id}
              disabled={
                !(
                  !disableKey?.length ||
                  (disableKey?.length && row[disableKey])
                )
              }
              onChange={checkedHandler}
            />
          )}
          {tableData?.isLoading ? (
            <SkeletonLoader
              type="rounded"
              skeletonWidth={"60%"}
              skeletonHeight={20}
            />
          ) : (
            <TypographyInline
              isEllipses
              label={row[head.id] || "--"}
              size="sm"
              color={GREY.SECONDARY}
              fontWeight={fontWeight.SEMIBOLD}
            />
          )}

          {/* copy icon code */}
          {row[head.id]?.toString()?.length && head?.copy ? (
            isCopied === row[head.id] ? (
              <div
                id={classes.copiedTextContainer}
                style={styles.copiedTextContainer}
              >
                <IconComponent
                  fontSize={fontSize.SM}
                  color={SUCCESS.W_500}
                  iconLabel={ICON_LABELS.DONE}
                />
                <TypographyInline
                  isEllipses
                  color={SUCCESS.W_500}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.COPIED_LABEL}
                  size={"xs"}
                />
              </div>
            ) : (
              <>
                {row[head.id]?.toString()?.length && head?.copy ? (
                  <IconComponent
                    id={classes.copiedTextContainer}
                    color={GREY.TERTIARY}
                    fontSize={fontSize.MD}
                    iconLabel={ICON_LABELS.CONTENT_COPY}
                    onClick={() => copyTextToClipboard(row, head)}
                  />
                ) : null}
              </>
            )
          ) : null}
          {/* copy icon code */}
        </div>
      </CustomTooltip>
    );
  };
  const timeStampDesign = (row, head, index) => {
    return (
      <CustomTooltip
        title={
          !tableData?.isLoading &&
          (window.innerWidth < 1250 || getTimeStampLabel(row, head)?.length > 20
            ? getTimeStampLabel(row, head)
            : "")
        }
      >
        <div
          key={index}
          className={classes.textCell}
          onClick={() => tableActions[head.ui_id]?.function(row, head)}
          style={{
            ...styles.textCell, width: head?.width,
            minWidth: head?.width
          }}
        >
          {tableData?.isLoading ? (
            <SkeletonLoader
              type="rounded"
              skeletonWidth={"60%"}
              skeletonHeight={20}
            />
          ) : (
            <TypographyInline
              isEllipses
              label={getTimeStampLabel(row, head)}
              size="sm"
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
            />
          )}

          {/* copy icon code */}
          {getTimeStampLabel(row, head)?.length && head?.copy ? (
            isCopied === row[head.id] ? (
              <div
                id={classes.copiedTextContainer}
                style={styles.copiedTextContainer}
              >
                <IconComponent
                  fontSize={fontSize.SM}
                  color={SUCCESS.W_500}
                  iconLabel={ICON_LABELS.DONE}
                />
                <TypographyInline
                  isEllipses
                  color={SUCCESS.W_500}
                  fontWeight={fontWeight.MEDIUM}
                  label={STRINGS.COPIED_LABEL}
                  size={"xs"}
                />
              </div>
            ) : (
              <>
                {getTimeStampLabel(row, head)?.length && head?.copy ? (
                  <IconComponent
                    id={classes.copiedTextContainer}
                    color={GREY.TERTIARY}
                    fontSize={fontSize.MD}
                    iconLabel={ICON_LABELS.CONTENT_COPY}
                    onClick={() => copyTextToClipboard(row, head)}
                  />
                ) : null}
              </>
            )
          ) : null}
          {/* copy icon code */}
        </div>
      </CustomTooltip>
    );
  };
  const userInfoDesign = (row, head, index) => {
    return (
      <div
        key={index}
        className={classes.userInfoCell}
        style={{
          ...{
            ...(selectionPermission
              ? {
                ...styles.userInfoCellWoChkbox,
                width: head?.width,
                minWidth: head?.width,
              }
              : {
                ...styles.userInfoCell, width: head?.width,
                minWidth: head?.width
              }),
          },
          ...(tableData?.isSticky && styles.stickyUserInfoCell),
        }}
      >
        <div style={styles.checkboxAvatarContainer}>
          {!selectionPermission && (
            <div
              // style={` ${styles.checkboxContainer} ${tableData.isSticky && styles.stickyRowCheckbox
              //   }`}
              style={
                tableData?.isSticky
                  ? { ...styles.stickyRowCheckbox, ...styles.checkboxContainer }
                  : { ...styles.checkboxContainer }
              }
            >
              <Checkbox
                checked={
                  tableHeaderStripValue?.length ||
                  checkedValue?.indexOf(row._id) > -1
                }
                value={row._id}
                disabled={
                  !(
                    !disableKey?.length ||
                    (disableKey?.length && row[disableKey])
                  )
                }
                onChange={checkedHandler}
              />
            </div>
          )}

          {tableData?.isLoading ? (
            <SkeletonLoader
              type="circular"
              skeletonWidth={32}
              skeletonHeight={32}
            />
          ) : (
            <>
              <AvatarNameWithLink row={row} />
            </>
          )}
        </div>
        <CustomTooltip
          title={
            !tableData?.isLoading &&
            (window.innerWidth < 1250 ||
              `${row?.first_name} ${row?.last_name}`?.length > 20
              ? `${row?.first_name} ${row?.last_name}`
              : "")
          }
        >
          <div style={styles.userName}>
            {tableData?.isLoading ? (
              <SkeletonLoader
                type="rounded"
                skeletonWidth={"60%"}
                skeletonHeight={20}
              />
            ) : (
              <TypographyInline
                isEllipses
                label={
                  row?.first_name?.length && row?.last_name?.length
                    ? `${row?.first_name} ${row?.last_name}`
                    : "--"
                }
                size="sm"
                fontWeight={fontWeight.SEMIBOLD}
                color={GREY.SECONDARY}
                onClick={() => {
                  tableActions[head.ui_id]?.function(row, head, index);
                }}
              />
            )}

            {/* copy icon code */}
            {row?.first_name?.length && row?.last_name?.length && head?.copy ? (
              isCopied === `${row?.first_name} ${row?.last_name}` ? (
                <div
                  id={classes.copiedTextContainer}
                  style={styles.copiedTextContainer}
                >
                  <IconComponent
                    fontSize={fontSize.SM}
                    color={SUCCESS.W_500}
                    iconLabel={ICON_LABELS.DONE}
                  />
                  <TypographyInline
                    isEllipses
                    color={SUCCESS.W_500}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.COPIED_LABEL}
                    size={"xs"}
                  />
                </div>
              ) : (
                <>
                  {row?.first_name?.length &&
                    row?.last_name?.length &&
                    head?.copy ? (
                    <IconComponent
                      id={classes.copiedTextContainer}
                      color={GREY.TERTIARY}
                      fontSize={fontSize.MD}
                      iconLabel={ICON_LABELS.CONTENT_COPY}
                      onClick={() =>
                        copyTextToClipboard(
                          row,
                          head,
                          `${row?.first_name} ${row?.last_name}`
                        )
                      }
                    />
                  ) : null}
                </>
              )
            ) : null}
            {/* copy icon code */}
          </div>
        </CustomTooltip>
      </div>
    );
  };
  const companyInfoDesign = (row, head, index) => {
    return (
      <div
        key={index}
        // style={`${selectionPermission
        //   ? styles.userInfoCellWoChkbox
        //   : styles.userInfoCell
        //   } ${styles[cellWidth[head.ui_id]]}  ${tableData.isSticky && !index && styles.stickyUserInfoCell
        //   } ${index && styles.comapnyCellPadding} `}
        className={classes.userInfoCell}
        // onClick={tableActions[head.ui_id]?.function(row, head)}
        style={{
          ...{
            ...(selectionPermission
              ? {
                ...styles.userInfoCellWoChkbox,
                width: head?.width,
                minWidth: head?.width,
              }
              : {
                ...styles.userInfoCell, width: head?.width,
                minWidth: head?.width
              }),
          },
          ...(tableData?.isSticky && !index && styles.stickyUserInfoCell),
          ...(index && styles.comapnyCellPadding),
        }}
      >
        <div style={styles.checkboxAvatarContainer}>
          {!selectionPermission && !index && (
            <div
              // style={` ${styles.checkboxContainer} ${tableData.isSticky && styles.stickyRowCheckbox
              //   }`}
              style={
                tableData?.isSticky
                  ? { ...styles.stickyRowCheckbox, ...styles.checkboxContainer }
                  : { ...styles.checkboxContainer }
              }
            >
              <Checkbox
                checked={
                  tableHeaderStripValue?.length ||
                  checkedValue?.indexOf(row._id) > -1
                }
                value={row._id}
                disabled={
                  !(
                    !disableKey?.length ||
                    (disableKey?.length && row[disableKey])
                  )
                }
                onChange={checkedHandler}
              />
            </div>
          )}

          {tableData?.isLoading ? (
            <SkeletonLoader
              type="circular"
              skeletonWidth={32}
              skeletonHeight={32}
            />
          ) : (
            <AvatarOutlined size={"small"}>
              <Image
                onError={(e) => {
                  e.target.src = DUMMY_COMPANY_IMAGE;
                }}
                src={createCompanyURL(
                  row["company_website"] || DUMMY_COMPANY_IMAGE
                )}
                style={styles.companyIcon}
                alt=""
              />
            </AvatarOutlined>
          )}
        </div>
        <CustomTooltip
          title={
            !tableData?.isLoading &&
            (window.innerWidth < 1250 || `${row[head.id]}`?.length > 20
              ? `${row[head.id]}`
              : "")
          }
        >
          <div style={styles.userName}>
            {tableData?.isLoading ? (
              <SkeletonLoader
                type="rounded"
                skeletonWidth={"60%"}
                skeletonHeight={20}
              />
            ) : (
              <>
                {!index ? (
                  <TypographyInline
                    isEllipses
                    label={row[head.id]?.length ? `${row[head.id]}` : "--"}
                    size="sm"
                    fontWeight={fontWeight.SEMIBOLD}
                    color={GREY.SECONDARY}
                    onClick={() => {
                      tableActions[head.ui_id]?.function(row, head, index);
                    }}
                  />
                ) : (
                  <Anchor
                    href={row["company_website"]}
                    target="_blank"
                    style={styles.companyNameLink}
                  >
                    <TypographyInline
                      isEllipses
                      label={
                        row[head.id]?.length
                          ? textEllipsesHandler(row[head.id], 15)
                          : "--"
                      }
                      color={GREY.SECONDARY}
                      size={"sm"}
                      fontWeight={fontWeight.REGULAR}
                    />
                  </Anchor>
                )}
              </>
            )}

            {/* copy icon code */}
            {row[head.id]?.length && head?.copy ? (
              isCopied === row[head.id] ? (
                <div
                  id={classes.copiedTextContainer}
                  style={styles.copiedTextContainer}
                >
                  <IconComponent
                    fontSize={fontSize.SM}
                    color={SUCCESS.W_500}
                    iconLabel={ICON_LABELS.DONE}
                  />
                  <TypographyInline
                    isEllipses
                    color={SUCCESS.W_500}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.COPIED_LABEL}
                    size={"xs"}
                  />

                </div>
              ) : (
                <>
                  {row[head.id]?.length && head?.copy ? (
                    <IconComponent
                      id={classes.copiedTextContainer}
                      color={GREY.TERTIARY}
                      fontSize={fontSize.MD}
                      iconLabel={ICON_LABELS.CONTENT_COPY}
                      onClick={() => copyTextToClipboard(row, head)}
                    />
                  ) : null}

                </>
              )
            ) : null}
            {row[head.id]?.length && tableData?.isFlag ? (

              <IconComponent
                id={classes.copiedTextContainer}
                color={GREY.TERTIARY}
                fontSize={fontSize.MD}
                iconLabel={ICON_LABELS.FLAG}
                onClick={() => {
                  tableActions[head.ui_id]?.function(row, head);
                }}
              />

            ) : ""}

            {/* copy icon code */}
          </div>
        </CustomTooltip>
      </div>
    );
  };
  const urlDesign = (row, head, index) => {
    return (
      <Anchor
        href={row[head.id]}
        target="_blank"
        style={styles.companyNameLink}
        className={classes.companyNameLink}
        key={index}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <TypographyInline
            isEllipses
            label={textEllipsesHandler(row[head.id], 25)}
            color={GREY.SECONDARY}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
          />
        )}

        {/* copy icon code */}
        {row[head.id]?.length && head?.copy ? (
          isCopied === row[head.id] ? (
            <div
              id={classes.copiedTextContainer}
              style={styles.copiedTextContainer}
            >
              <IconComponent
                fontSize={fontSize.SM}
                color={SUCCESS.W_500}
                iconLabel={ICON_LABELS.DONE}
              />
              <TypographyInline
                isEllipses
                color={SUCCESS.W_500}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COPIED_LABEL}
                size={"xs"}
              />
            </div>
          ) : (
            <>
              {row[head.id]?.length && head?.copy ? (
                <IconComponent
                  id={classes.copiedTextContainer}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.CONTENT_COPY}
                  onClick={() => copyTextToClipboard(row, head)}
                />
              ) : null}
            </>
          )
        ) : null}
        {/* copy icon code */}
      </Anchor>
    );
  };
  const amountUpdateDesign = (row, head, index) => {
    // console.log("Row",row,"HEad",head)
    return (
      <div
        key={index}
        onClick={() => tableActions[head.ui_id]?.function(row, head)}
        style={{
          ...styles.textCell, width: head?.width,
          minWidth: head?.width
        }}
      // className={`${classes.textCell} ${classes[cellWidth[head?.ui_id]]}`}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={25}
          />
        ) : (
          <TypographyInline
            isEllipses
            label={row[head.id]?.toString().length ? row[head.id] : "--"}
            size="sm"
            color={row.is_credit ? SUCCESS.W_500 : PRIMARY.W_500}
            fontWeight={fontWeight.REGULAR}
          />
        )}
      </div>
    );
  };

  const compnayURLDesign = (row, head, index) => {
    return (
      <div
        key={index}
        // className={`${classes.companyURLCell} ${classes.companyURLCellWidth}`}
        style={{
          ...styles.companyURLCell, width: head?.width,
          minWidth: head?.width
        }}
        onClick={() => tableActions[head.ui_id]?.function(row, head)}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="circular"
            skeletonWidth={40}
            skeletonHeight={40}
          />
        ) : (
          <AvatarOutlined size={"small"}>
            <Image
              onError={(e) => (e.target.src = DUMMY_COMPANY_IMAGE)}
              src={
                createCompanyURL(row["company_website"]) || DUMMY_COMPANY_IMAGE
              }
              className={classes.companyIcon}
              alt="Company Logo"
            />
          </AvatarOutlined>
        )}

        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <Anchor
            href={row["company_website"]}
            target="_blank"
            className={classes.companyNameLink}
          >
            <TypographyInline
              isEllipses
              label={textEllipsesHandler(row[head.id], 15)}
              color={GREY.SECONDARY}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
            />
          </Anchor>
        )}
      </div>
    );
  };
  const requestStatusDesign = (row, head, index) => {
    // console.log("Row",row,"HEad",head)
    return (
      <div
        key={index}
        onClick={() => tableActions[head.ui_id]?.function(row, head)}
        style={{
          ...styles.textCell, width: head?.width,
          minWidth: head?.width
        }}
      // className={`${classes.textCell} ${classes[cellWidth[head?.ui_id]]}`}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={25}
          />
        ) : (
          <TypographyInline
            isEllipses
            label={
              row[head.id] === 1
                ? "200 sent"
                : row[head.id] === 2
                  ? "200 decline"
                  : "200 credits"
            }
            size="sm"
            color={row[head.id] === 1 ? SUCCESS.W_500 : PRIMARY.W_500}
            fontWeight={fontWeight.REGULAR}
          />
        )}
      </div>
    );
  };

  const approveRequestDesign = (row, head, index) => {
    // console.log("Row",row,"HEad",head)
    return (
      <div
        key={index}
        onClick={() => tableActions[head.ui_id]?.function(row, head)}
        style={{
          ...styles.textCell, width: head?.width,
          minWidth: head?.width
        }}
      // className={`${classes.textCell} ${classes[cellWidth[head?.ui_id]]}`}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={25}
          />
        ) : row[head.id] === null ? (
          <div style={{ ...styles.approveOuter }}>
            <div style={{ ...styles.approveDesign }}
              onClick={() => {
                tableActions["approve_request1"]?.function(row, head, true)
              }
              }
            >

              <Button
                LeftIcon={ICON_LABELS.DONE}
                //label={STRINGS.STEPS}
                size={"sm36"}
                variant={"ghost"}

              />
            </div>
            <div style={{ ...styles.approveDesign }}
              onClick={() => tableActions["approve_request1"]?.function(row, head, false)}
            >

              <Button
                LeftIcon={ICON_LABELS.CLOSE}
                //label={STRINGS.STEPS}
                size={"sm36"}
                variant={"primary"}

              />

            </div>
          </div>
        ) : (
          <TypographyInline
            isEllipses
            label={row[head.id] === 1 ? "Request Accepted" : "Request Declined"}
            size="sm"
            color={row[head.id] === 1 ? SUCCESS.W_500 : PRIMARY.W_500}
            fontWeight={fontWeight.REGULAR}
          />
        )}
      </div>
    );
  };

  const plainTextDesign = (row, head, index) => {
    // console.log("Row",row,"HEad",head)
    return (
      <div
        key={index}
        onClick={() => tableActions[head.ui_id]?.function(row, head)}
        style={{
          ...styles.textCell, width: head?.width,
          minWidth: head?.width
        }}
      // className={`${classes.textCell} ${classes[cellWidth[head?.ui_id]]}`}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={25}
          />
        ) : (
          <TypographyInline
            isEllipses
            label={row[head.id]?.toString().length ? row[head.id] : "--"}
            size="sm"
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
          />
        )}
      </div>
    );
  };

  const actionIconDesign = (row, head, index) => {
    return (
      <>
        {tableOperations?.length < 3 ? (
          <div
            key={index}
            style={{
              ...styles.actionIconCellPaddingContainer,
              width: head?.width,
              minWidth: head?.width,
            }}
          >
            {tableOperations?.map((item, index) => {
              return (
                <>
                  {tableData?.isLoading ? (
                    <SkeletonLoader
                      type="rounded"
                      skeletonWidth={36}
                      skeletonHeight={36}
                      stackWidth={36}
                    />
                  ) : (
                    <CustomTooltip title={!tableData?.isLoading && item.label}>
                      <div
                        key={index}
                        onClick={() =>
                          (!disableKey?.length ||
                            (disableKey?.length && row[disableKey])) &&
                          item?.function(row, head)
                        }
                        style={
                          disableKey?.length && !row[disableKey]
                            ? styles.actionIconsDisabledContainer
                            : styles.actionIconsContainer
                        }
                      >
                        <IconComponent
                          disabled={disableKey?.length && !row[disableKey]}
                          color={GREY.TERTIARY}
                          fontSize={fontSize.MD}
                          iconLabel={item?.iconLabel}
                        />
                      </div>
                    </CustomTooltip>
                  )}
                </>
              );
            })}
          </div>
        ) : (
          <div
            key={index}
            // style={`${styles.actionCellContainer} ${styles[cellWidth[head?.ui_id]]
            //   } ${disableKey?.length && !row[disableKey]
            //     ? styles.actionIconsDisabled
            //     : ""
            //   }`}
            style={
              disableKey?.length && !row[disableKey]
                ? {
                  ...styles.actionIconsDisabled,
                  ...styles.actionCellContainer,
                  width: head?.width,
                  minWidth: head?.width,
                }
                : {
                  ...styles.actionCellContainer,
                  width: head?.width,
                  minWidth: head?.width,
                }
            }
            onClick={(event) =>
              (!disableKey?.length ||
                (disableKey?.length && row[disableKey])) &&
              actionIconHandler(event, row, head)
            }
          >
            {tableData?.isLoading ? (
              <SkeletonLoader
                type="rounded"
                skeletonWidth={5}
                skeletonHeight={15}
                stackWidth={5}
              />
            ) : (
              <IconComponent
                iconLabel={ICON_LABELS.MORE_VERT}
                color={
                  disableKey?.length && !row[disableKey]
                    ? BORDER.LIGHT
                    : GREY.SECONDARY
                }
                fontSize={"20px"}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const firstNameDesign = (row, head, index) => {
    return (
      <div
        key={index}
        className={classes.firstnameCell}
        style={{
          width: head?.width,
          minWidth: head?.width, ...styles.firstnameCell
        }}
      >
        {!selectionPermission && (
          <div
            // style={` ${styles.checkboxContainer} ${tableData.isSticky && styles.stickyRowCheckbox
            //   }`}
            style={tableData?.isSticky ? { ...styles.stickyRowCheckbox, ...styles.checkboxContainer } : { ...styles.checkboxContainer }}
          >
            <Checkbox
              checked={
                tableHeaderStripValue?.length ||
                checkedValue?.indexOf(row._id) > -1
              }
              value={row._id}
              disabled={
                !(
                  !disableKey?.length ||
                  (disableKey?.length && row[disableKey])
                )
              }
              onChange={checkedHandler}
            />
          </div>
        )}

        {tableData?.isLoading ? (
          <SkeletonLoader
            type="circular"
            skeletonWidth={32}
            skeletonHeight={32}
          />
        ) : (
          <AvatarName
            name={`${row?.first_name} ${row?.last_name}`}
            size="small"
          />
        )}

        <CustomTooltip
          title={
            window.innerWidth < 1250 || `${row?.first_name}`?.length > 20
              ? `${row?.first_name}`
              : ""
          }
        >
          <div
            style={styles.userName}
            onClick={() => {
              tableActions[head.ui_id]?.function(row, head);
            }}
          >
            {tableData?.isLoading ? (
              <SkeletonLoader
                type="rounded"
                skeletonWidth={"60%"}
                skeletonHeight={20}
              />
            ) : (
              <TypographyInline
                isEllipses
                label={`${row?.first_name}`}
                size="sm"
                fontWeight={fontWeight.SEMIBOLD}
                color={GREY.SECONDARY}
              />
            )}
          </div>
        </CustomTooltip>
      </div>
    );
  };

  const messageDesign = (row, head, index) => {
    return (

      <div
        key={index}
        className={classes.messageCell}

        style={{
          width: head?.width,
          minWidth: head?.width, ...styles.messageCell
        }}
      >

        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <>

            <TypographyInline
              isEllipses
              label={row[head.id]?.toString().length ? row[head.id] : "--"}
              size="sm"
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
            />
            <TypographyInline
              className="more"
              label={row[head.id]?.toString().length >= 20 ? "More" : ""}
              size="sm"
              color={BRAND.PRIMARY}
              fontWeight={fontWeight.REGULAR}
              onClick={() => {
                tableActions[head.ui_id]?.function(row, head);
              }}
            />
          </>
        )}
      </div>
    );
  };

  const defaultAction = (row, head, index) => {
    return <div key={index}></div>;
  };

  const emailDesign = (row, head, index, rowIndex) => {
    return (
      <div
        key={index}
        // style={`${row[head.id] === false ? styles.emailCell : styles.emailTextCell
        //   } ${styles.emailCellWidth}`}
        className={classes.emailCell}
        style={
          row[head.id] === false
            ? {
              ...styles.emailCell, width: head?.width,
              minWidth: head?.width
            }
            : {
              ...styles.emailTextCell, width: head?.width,
              minWidth: head?.width
            }
        }
      >
        {row[head.id] === false ? (
          <AvatarOutlined
            onClick={() => {
              // setClickedEmailUnlock(rowIndex);
              tableActions[head.ui_id]?.function(row, head, index, rowIndex);
            }}
          >
            {tableActions[head.id]?.loading === row?._id ? (
              <SkeletonLoader
                type="circular"
                skeletonHeight="32px"
                skeletonWidth="32px"
              />
            ) : (
              <IconComponent
                iconLabel={"mail_lock"}
                color={GREY.TERTIARY}
                fontSize={"18px"}
              />
            )}
          </AvatarOutlined>
        ) : (
          <CustomTooltip
            title={
              !tableData?.isLoading &&
              (window.innerWidth < 1250 || row[head.id]?.length > 20
                ? row[head.id]
                : "")
            }
          >
            <div style={styles.emailCopiedTextCellContainer}>
              {tableData?.isLoading ? (
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"60%"}
                  skeletonHeight={16}
                />
              ) : (
                <TypographyInline
                  isEllipses
                  label={row[head.id] || "--"}
                  size="sm"
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                />
              )}

              {/* copy icon code */}
              {isCopied === row[head.id] && head?.copy ? (
                <div
                  id={classes.copiedTextContainer}
                  style={styles.copiedTextContainer}
                >
                  <IconComponent
                    fontSize={fontSize.SM}
                    color={SUCCESS.W_500}
                    iconLabel={ICON_LABELS.DONE}
                  />
                  <TypographyInline
                    isEllipses
                    color={SUCCESS.W_500}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.COPIED_LABEL}
                    size={"xs"}
                  />
                </div>
              ) : (
                <>
                  {row[head.id] && head?.copy && (
                    <IconComponent
                      id={classes.copiedTextContainer}
                      color={GREY.TERTIARY}
                      fontSize={fontSize.MD}
                      iconLabel={ICON_LABELS.CONTENT_COPY}
                      onClick={() => copyTextToClipboard(row, head)}
                    />
                  )}
                  {row[head.id] && tableData?.isFlag && (
                    <IconComponent
                      id={classes.copiedTextContainer}
                      color={GREY.TERTIARY}
                      fontSize={fontSize.MD}
                      iconLabel={ICON_LABELS.FLAG}
                      onClick={() => {
                        tableActions[head.ui_id]?.function(row, head);
                      }}
                    />
                  )}
                </>
              )}
              {/* copy icon code */}
            </div>
          </CustomTooltip>
        )}
      </div>
    );
  };

  const marketingListDesign = (row, head, index) => {
    return (
      <CustomTooltip
        title={
          !tableData?.isLoading &&
          (window.innerWidth < 1250 || row[head.id]?.length > 20
            ? row[head.id]
            : "")
        }
      >
        <div
          key={index}
          className={classes.marketingListCell}
          style={{
            ...styles.marketingListCell,
            width: head?.width,
            minWidth: head?.width,
          }}
        >
          {parseInt(row?.pin) ? (
            <div style={styles.pinContainer}>
              <Image style={styles.pinIcon} src={PIN_ICON} />
            </div>
          ) : null}
          {!selectionPermission && (
            <div style={styles.checkboxContainer}>
              <Checkbox
                checked={checkedValue.indexOf(row._id) > -1}
                value={row._id}
                disabled={
                  !(
                    !disableKey?.length ||
                    (disableKey?.length && row[disableKey])
                  )
                }
                onChange={checkedHandler}
              />
            </div>
          )}
          <div
            style={styles.marketingListName}
            onClick={() => {
              tableActions[head.ui_id]?.function(row, head);
            }}
          >
            {row.type === "folder" && (
              <>
                {tableData?.isLoading ? (
                  <SkeletonLoader
                    type="rounded"
                    skeletonWidth={18}
                    skeletonHeight={18}
                  />
                ) : (
                  <IconComponent
                    color={WARNING.W_500}
                    fontSize={fontSize.LG}
                    iconLabel={ICON_LABELS.FOLDER}
                  />
                )}
              </>
            )}

            {tableData?.isLoading ? (
              <SkeletonLoader
                type="rounded"
                skeletonWidth={"60%"}
                skeletonHeight={20}
              />
            ) : (
              <TypographyInline
                isEllipses
                label={row[head.id]}
                size="sm"
                fontWeight={fontWeight.SEMIBOLD}
                color={GREY.SECONDARY}
              />
            )}

            {/* copy icon code */}
            {row[head.id]?.length && head?.copy ? (
              isCopied === row[head.id] ? (
                <div
                  id={classes.copiedTextContainer}
                  style={styles.copiedTextContainer}
                >
                  <IconComponent
                    fontSize={fontSize.SM}
                    color={SUCCESS.W_500}
                    iconLabel={ICON_LABELS.DONE}
                  />
                  <TypographyInline
                    isEllipses
                    color={SUCCESS.W_500}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.COPIED_LABEL}
                    size={"xs"}
                  />
                </div>
              ) : (
                <>
                  {row[head.id]?.length && head?.copy ? (
                    <IconComponent
                      id={classes.copiedTextContainer}
                      color={GREY.TERTIARY}
                      fontSize={fontSize.MD}
                      iconLabel={ICON_LABELS.CONTENT_COPY}
                      onClick={() => copyTextToClipboard(row, head)}
                    />
                  ) : null}
                </>
              )
            ) : null}
            {/* copy icon code */}
          </div>
        </div>
      </CustomTooltip>
    );
  };

  const textButtonDesign = (row, head, index) => {
    return (
      <div
        key={index}
        // style={`${row?.type === "file" && styles.textButtonCell} ${styles.textButtonCellWidth
        //   }`}
        className={classes.textButtonCell}
        style={
          row?.type === "file"
            ? {
              ...styles.textButtonCell, width: head?.width,
              minWidth: head?.width
            }
            : {
              width: head?.width,
              minWidth: head?.width
            }
        }
      >
        {row?.type === "file" && (
          <>
            <div style={styles.stepsButtonContainer}>
              {tableData?.isLoading ? (
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"60%"}
                  skeletonHeight={20}
                />
              ) : (
                <TypographyInline
                  isEllipses
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"sm"}
                  label={`${row?.total_steps} Steps`}
                />
              )}
            </div>

            {tableData?.isLoading ? (
              <SkeletonLoader
                type="rounded"
                skeletonWidth={"60%"}
                skeletonHeight={36}
              />
            ) : (
              <Button
                LeftIcon={ICON_LABELS.ADD}
                label={STRINGS.STEPS}
                size={"sm36"}
                variant={"ghost"}
                onClick={() => {
                  tableActions[head.ui_id]?.function(row, head);
                }}
              />
            )}
          </>
        )}
      </div>
    );
  };

  const statsDataDesgin = (row, head, index) => {
    return (
      <div
        key={index}
        className={classes.statsDataCell}
        style={{
          ...styles.statsDataCell, width: head?.width,
          minWidth: head?.width
        }}
        onClick={() => {
          if (!(row[head.id]?.toString().length && tableData?.isFlag)) {
            tableActions[head.ui_id]?.function(row, head)
          }
        }
        }
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <>
            <TypographyInline
              isEllipses
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              size={"sm"}
              label={
                row?.type === "folder"
                  ? STRINGS.DOTS
                  : row[head.id]?.toString().length
                    ? row[head.id]
                    : "--"
              }

            />
            {tableData?.isFlag ? (

              <IconComponent
                id={classes.copiedTextContainer}
                color={GREY.TERTIARY}
                fontSize={fontSize.MD}
                iconLabel={ICON_LABELS.FLAG}
                onClick={() => {
                  tableActions[head.ui_id]?.function(row, head);
                }}
              />

            ) : ""}
          </>
        )}

        {/* copy icon code */}
        {row[head.id]?.length && head?.copy ? (
          isCopied === row[head.id] ? (
            <div
              id={classes.copiedTextContainer}
              style={styles.copiedTextContainer}
            >
              <IconComponent
                fontSize={fontSize.SM}
                color={SUCCESS.W_500}
                iconLabel={ICON_LABELS.DONE}
              />
              <TypographyInline
                isEllipses
                color={SUCCESS.W_500}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COPIED_LABEL}
                size={"xs"}
              />
            </div>
          ) : (
            <>
              {row[head.id]?.length && head?.copy ? (
                <IconComponent
                  id={classes.copiedTextContainer}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.CONTENT_COPY}
                  onClick={() => copyTextToClipboard(row, head)}
                />
              ) : null}

            </>
          )
        ) : null}

        {/* copy icon code */}
      </div>
    );
  };

  const flagButton = (row, head, index) => {
    return (
      <div
        key={index}
        onClick={() => tableActions[head.ui_id]?.function(row, head)}
        style={{
          ...styles.textCell, width: head?.width,
          minWidth: head?.width
        }}
      // className={`${classes.textCell} ${classes[cellWidth[head?.ui_id]]}`}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"30%"}
            skeletonHeight={25}
          />
        ) : (
          <FlagButton
            iconLabel={ICON_LABELS.FLAG}
            background={row.is_flagged}
          />
        )}
      </div>
    );
  };
  const buttonDesign = (row, head, index) => {
    return (
      <div
        key={index}
        className={classes.buttonCell}

        style={
          tableData?.isSticky && head?.id === "action"
            ? { ...styles.buttonCell, width: head?.width, ...styles.rightStickyCell }
            : { ...styles.buttonCell, minWidth: head?.width }
        }
      >
        {head?.id === "export" ? (
          <>
            {tableData?.isLoading ? (
              <SkeletonLoader
                type="rounded"
                skeletonWidth={"60%"}
                skeletonHeight={20}
              />
            ) : (
              <Button
                LeftIcon={ICON_LABELS.FILE_UPLOAD}
                label={STRINGS.EXPORT}
                size={"xs28"}
                disabled={row[disableKey] !== "completed"}
                variant="ghost"
                onClick={() => {
                  tableActions[head.ui_id]?.function(row, head);
                }}
              />
            )}
          </>
        ) : (
          <>
            {tableData?.isLoading ? (
              <SkeletonLoader
                type="rounded"
                skeletonWidth={"60%"}
                skeletonHeight={20}
              />
            ) : (
              <Button
                label={tableData?.buttonText ? tableData?.buttonText : "--"}
                size={"xs28"}
                variant={"ghost"}
                onClick={() => {
                  tableActions[head.ui_id]?.function(row, head);
                }}
              />
            )}
          </>
        )}
      </div>
    );
  };
  const statusDesign = (row, head, index) => {
    return (
      <CustomTooltip title={!tableData?.isLoading && row?.state_message}>
        <div
          key={index}
          className={classes.statusCell}
          style={{ ...styles.statusCell, ...styles.statusCellWidth }}
        >
          {tableData?.isLoading ? (
            <SkeletonLoader
              type="rounded"
              skeletonWidth={"60%"}
              skeletonHeight={20}
            />
          ) : (
            <StatusChip
              label={row[head?.id] || "--"}
              variant={statusVariant[row[head?.id]]}
            />
          )}
        </div>
      </CustomTooltip>
    );
  };
  const durationTextDesign = (row, head, index) => {
    return (
      <div
        key={index}
        className={classes.durationTextCell}
        style={{
          ...styles.durationTextCell,
          width: head?.width,
          minWidth: head?.width,
        }}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <TypographyInline
            isEllipses
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            size={"sm"}
            label={getDurationLabel(row)}
          />
        )}

        {/* copy icon code */}
        {getDurationLabel(row)?.length && head?.copy ? (
          isCopied === getDurationLabel(row) ? (
            <div
              id={classes.copiedTextContainer}
              style={styles.copiedTextContainer}
            >
              <IconComponent
                fontSize={fontSize.SM}
                color={SUCCESS.W_500}
                iconLabel={ICON_LABELS.DONE}
              />
              <TypographyInline
                isEllipses
                color={SUCCESS.W_500}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COPIED_LABEL}
                size={"xs"}
              />
            </div>
          ) : (
            <>
              {getDurationLabel(row)?.length && head?.copy ? (
                <IconComponent
                  id={classes.copiedTextContainer}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.CONTENT_COPY}
                  onClick={() => copyTextToClipboard(row, head)}
                />
              ) : null}
            </>
          )
        ) : null}
        {/* copy icon code */}
      </div>
    );
  };
  const textURLDesign = (row, head, index) => {
    return (
      <div
        key={index}
        className={classes.textURLCell}
        style={{
          ...styles.textURLCell, width: head?.width,
          minWidth: head?.width
        }}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <>
            <TypographyInline
              isEllipses
              label={row[head.id]?.length ? row[head.id] : "--"}
              color={GREY.SECONDARY}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
            />
            {row[head.id]?.length ? (
              <Anchor
                href={
                  row?.schedule_page_link
                    ? `http://${row.schedule_page_link}`
                    : row[head.id]
                }
                target="_blank"
                style={styles.meetingLinkIcon}
                key={index}
              >
                <IconComponent
                  iconLabel={"launch"}
                  color={PRIMARY.W_500}
                  fontSize={fontSize.SM}
                />
              </Anchor>
            ) : (
              ""
            )}

            {/* copy icon code */}
            {row[head.id]?.length && head?.copy ? (
              isCopied === row[head.id] ? (
                <div
                  id={classes.copiedTextContainer}
                  style={styles.copiedTextContainer}
                >
                  <IconComponent
                    fontSize={fontSize.SM}
                    color={SUCCESS.W_500}
                    iconLabel={ICON_LABELS.DONE}
                  />
                  <TypographyInline
                    isEllipses
                    color={SUCCESS.W_500}
                    fontWeight={fontWeight.MEDIUM}
                    label={STRINGS.COPIED_LABEL}
                    size={"xs"}
                  />
                </div>
              ) : (
                <>
                  {row[head.id]?.length && head?.copy ? (
                    <IconComponent
                      id={classes.copiedTextContainer}
                      color={GREY.TERTIARY}
                      fontSize={fontSize.MD}
                      iconLabel={ICON_LABELS.CONTENT_COPY}
                      onClick={() => copyTextToClipboard(row, head)}
                    />
                  ) : null}

                </>
              )
            ) : null}
            {/* copy icon code */}
          </>
        )}
      </div>
    );
  };
  const taskListDesign = (row, head, index) => {
    return (
      <CustomTooltip
        title={
          !tableData?.isLoading &&
          (window.innerWidth < 1250 || row[head.id]?.length > 20
            ? row[head.id]
            : "")
        }
      >
        <div
          key={index}
          className={classes.taskListCell}
          style={{
            ...styles.taskListCell,
            width: head?.width,
            minWidth: head?.width,
          }}
        >
          <div style={styles.checkboxContainer}>
            <Checkbox
              checked={checkedValue.indexOf(row._id) > -1}
              value={row._id}
              disabled={
                !(
                  !disableKey?.length ||
                  (disableKey?.length && row[disableKey])
                )
              }
              onChange={checkedHandler}
            />
          </div>
          <div
            style={
              row?.status || row?.is_lead
                ? styles.taskListNameWithStatus
                : styles.taskListName
            }
          >
            {tableData?.isLoading ? (
              <SkeletonLoader
                type="circular"
                skeletonWidth={18}
                skeletonHeight={18}
              />
            ) : (
              <IconComponent
                color={row?.status ? SUCCESS.W_500 : BORDER.DARK}
                fontSize={fontSize.LG}
                iconLabel={
                  row?.status
                    ? ICON_LABELS.CHECK_CIRCLE
                    : ICON_LABELS.RADIO_BUTTON_UNCHECKED
                }
                onClick={() => {
                  tableActions[head.ui_id]?.icon?.function(row, head);
                }}
              />
            )}
            <div>
              {tableData?.isLoading ? (
                <SkeletonLoader
                  type="rounded"
                  skeletonWidth={"60%"}
                  skeletonHeight={20}
                />
              ) : (
                <TypographyInline
                  isEllipses
                  label={textEllipsesHandler(row[head.id], 15)}
                  size="sm"
                  fontWeight={fontWeight.SEMIBOLD}
                  color={GREY.SECONDARY}
                  onClick={() => {
                    tableActions[head.ui_id]?.label?.function(row, head);
                  }}
                />
              )}
              {!row?.status && !row?.is_lead ? null : (
                <TypographyInline
                  isEllipses
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  size={"sm"}
                  label={getTaskStatusLabel(row)}
                />
              )}

              {/* copy icon code */}
              {row[head.id]?.length && head?.copy ? (
                isCopied === row[head.id] ? (
                  <div
                    id={classes.copiedTextContainer}
                    style={styles.copiedTextContainer}
                  >
                    <IconComponent
                      fontSize={fontSize.SM}
                      color={SUCCESS.W_500}
                      iconLabel={ICON_LABELS.DONE}
                    />
                    <TypographyInline
                      isEllipses
                      color={SUCCESS.W_500}
                      fontWeight={fontWeight.MEDIUM}
                      label={STRINGS.COPIED_LABEL}
                      size={"xs"}
                    />
                  </div>
                ) : (
                  <>
                    {row[head.id]?.length && head?.copy ? (
                      <IconComponent
                        id={classes.copiedTextContainer}
                        color={GREY.TERTIARY}
                        fontSize={fontSize.MD}
                        iconLabel={ICON_LABELS.CONTENT_COPY}
                        onClick={() => copyTextToClipboard(row, head)}
                      />
                    ) : null}
                  </>
                )
              ) : null}
              {/* copy icon code */}
            </div>
          </div>
        </div>
      </CustomTooltip>
    );
  };
  const userDetailDesign = (row, head, index) => {
    let capitalizeFirstName =
      row?.first_name?.charAt(0).toUpperCase() + row?.first_name?.slice(1);
    let capitalizeLastName =
      row?.last_name?.charAt(0).toUpperCase() + row?.last_name?.slice(1);
    return (
      <div
        key={index}
        className={classes.userDetailCell}
        style={{
          ...styles.userDetailCell,
          width: head?.width,
          minWidth: head?.width,
        }}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="circular"
            skeletonWidth={32}
            skeletonHeight={32}
            stackWidth={32}
          />
        ) : (
          row?.first_name?.length &&
          row?.last_name?.length && (
            <AvatarName
              name={`${capitalizeFirstName} ${capitalizeLastName}`}
              size={"small"}
            />
          )
        )}
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <TypographyInline
            isEllipses
            label={
              row?.first_name?.length && row?.last_name?.length
                ? `${capitalizeFirstName} ${capitalizeLastName}`
                : "--"
            }
            onClick={() => {
              tableActions[head.ui_id]?.function(row, head, index);
            }}
            color={GREY.SECONDARY}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
          />
        )}

        {/* copy icon code */}
        {row?.first_name?.length && row?.last_name?.length && head?.copy ? (
          isCopied === `${capitalizeFirstName} ${capitalizeLastName}` ? (
            <div
              id={classes.copiedTextContainer}
              style={styles.copiedTextContainer}
            >
              <IconComponent
                fontSize={fontSize.SM}
                color={SUCCESS.W_500}
                iconLabel={ICON_LABELS.DONE}
              />
              <TypographyInline
                isEllipses
                color={SUCCESS.W_500}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COPIED_LABEL}
                size={"xs"}
              />
            </div>
          ) : (
            <>
              {row?.first_name?.length &&
                row?.last_name?.length &&
                head?.copy ? (
                <IconComponent
                  id={classes.copiedTextContainer}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.CONTENT_COPY}
                  onClick={() => copyTextToClipboard(row, head)}
                />
              ) : null}
            </>
          )
        ) : null}
        {/* copy icon code */}
      </div>
    );
  };
  const nameDetailDesign = (row, head, index) => {
    let capitalizeFirstName =
      row?.first_name?.charAt(0).toUpperCase() + row?.first_name?.slice(1);
    let capitalizeLastName =
      row?.last_name?.charAt(0).toUpperCase() + row?.last_name?.slice(1);
    return (
      <div
        key={index}
        className={classes.userDetailCell}
        style={{
          ...styles.userDetailCell,
          width: head?.width,
          minWidth: head?.width,
        }}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="circular"
            skeletonWidth={32}
            skeletonHeight={32}
            stackWidth={32}
          />
        ) : (
          row?.name?.length && (
            <AvatarName
              name={`${row?.name}`}
              size={"small"}
            />
          )
        )}
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <TypographyInline
            isEllipses
            label={
              row?.name?.length
                ? `${row?.name}`
                : "--"
            }
            onClick={() => {
              tableActions[head.ui_id]?.function(row, head, index);
            }}
            color={GREY.SECONDARY}
            size={"sm"}
            fontWeight={fontWeight.REGULAR}
          />
        )}

        {/* copy icon code */}
        {row?.first_name?.length && row?.last_name?.length && head?.copy ? (
          isCopied === `${capitalizeFirstName} ${capitalizeLastName}` ? (
            <div
              id={classes.copiedTextContainer}
              style={styles.copiedTextContainer}
            >
              <IconComponent
                fontSize={fontSize.SM}
                color={SUCCESS.W_500}
                iconLabel={ICON_LABELS.DONE}
              />
              <TypographyInline
                isEllipses
                color={SUCCESS.W_500}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COPIED_LABEL}
                size={"xs"}
              />
            </div>
          ) : (
            <>
              {row?.first_name?.length &&
                row?.last_name?.length &&
                head?.copy ? (
                <IconComponent
                  id={classes.copiedTextContainer}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.CONTENT_COPY}
                  onClick={() => copyTextToClipboard(row, head)}
                />
              ) : null}
            </>
          )
        ) : null}
        {/* copy icon code */}
      </div>
    );
  };
  const companyDetailDesign = (row, head, index) => {
    return (
      <div
        key={index}
        style={{
          ...styles.companyDetailCell,
          width: head?.width,
          minWidth: head?.width,
        }}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={32}
            skeletonHeight={32}
          />
        ) : (
          <AvatarOutlined size={"small"}>
            <Image
              src={DUMMY_COMPANY_IMAGE}
              style={styles.companyIcon}
              alt="Company Logo"
            />
          </AvatarOutlined>
        )}
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <TypographyInline
            isEllipses
            label={row?.company_name?.length ? row?.company_name : "--"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            size={"sm"}
          />
        )}

        {/* copy icon code */}
        {row?.company_name?.length && head?.copy ? (
          isCopied === row?.company_name ? (
            <div
              id={classes.copiedTextContainer}
              style={styles.copiedTextContainer}
            >
              <IconComponent
                fontSize={fontSize.SM}
                color={SUCCESS.W_500}
                iconLabel={ICON_LABELS.DONE}
              />
              <TypographyInline
                isEllipses
                color={SUCCESS.W_500}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COPIED_LABEL}
                size={"xs"}
              />
            </div>
          ) : (
            <>
              {row?.company_name?.length && head?.copy ? (
                <IconComponent
                  id={classes.copiedTextContainer}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.CONTENT_COPY}
                  onClick={() => copyTextToClipboard(row, head)}
                />
              ) : null}
            </>
          )
        ) : null}
        {/* copy icon code */}
      </div>
    );
  };
  const taskTypeDesign = (row, head, index) => {
    return (
      <div
        key={index}
        style={{
          ...styles.taskTypeCell, width: head?.width,
          minWidth: head?.width
        }}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <>
            <IconComponent
              iconLabel={TASK_TYPE[row?.step_type]?.icon}
              fontSize={fontSize.LG}
              color={TASK_TYPE[row?.step_type]?.iconColor}
            />
            <TypographyInline
              isEllipses
              label={TASK_TYPE[row?.step_type]?.label}
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              size={"sm"}
            />
          </>
        )}

        {/* copy icon code */}
        {TASK_TYPE[row?.step_type]?.label?.length && head?.copy ? (
          isCopied === TASK_TYPE[row?.step_type]?.label ? (
            <div
              id={classes.copiedTextContainer}
              style={styles.copiedTextContainer}
            >
              <IconComponent
                fontSize={fontSize.SM}
                color={SUCCESS.W_500}
                iconLabel={ICON_LABELS.DONE}
              />
              <TypographyInline
                isEllipses
                color={SUCCESS.W_500}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COPIED_LABEL}
                size={"xs"}
              />
            </div>
          ) : (
            <>
              {TASK_TYPE[row?.step_type]?.label?.length && head?.copy ? (
                <IconComponent
                  id={classes.copiedTextContainer}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.CONTENT_COPY}
                  onClick={() => copyTextToClipboard(row, head)}
                />
              ) : null}
            </>
          )
        ) : null}
        {/* copy icon code */}
      </div>
    );
  };
  const dueTimeStampDesign = (row, head, index) => {
    let presentTime = new Date().getTime();

    let processingTime = row?.show_time_stamp
      ? parseInt(row?.show_time_stamp)
      : new Date(row?.show_date_time).getTime();
    let isOverdue = processingTime < presentTime;
    let processingTimeStamp = timeStampToDateTime(processingTime);
    return (
      <div
        key={index}
        className={classes.dueTimeStampCell}
        style={{
          ...styles.dueTimeStampCell,
          width: head?.width,
          minWidth: head?.width,
        }}
      >
        {isOverdue && (row?.show_time_stamp || row?.show_date_time) && (
          <TypographyInline
            isEllipses
            color={PRIMARY.W_500}
            fontWeight={fontWeight.REGULAR}
            label={`${STRINGS.OVERDUE_TEXT}: `}
            size={"sm"}
          />
        )}
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <TypographyInline
            isEllipses
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
            label={
              row?.show_time_stamp || row?.show_date_time
                ? processingTimeStamp
                : "--"
            }
            size={"sm"}
          />
        )}

        {/* copy icon code */}
        {processingTimeStamp?.length && head?.copy ? (
          isCopied === processingTimeStamp ? (
            <div
              id={classes.copiedTextContainer}
              style={styles.copiedTextContainer}
            >
              <IconComponent
                fontSize={fontSize.SM}
                color={SUCCESS.W_500}
                iconLabel={ICON_LABELS.DONE}
              />
              <TypographyInline
                isEllipses
                color={SUCCESS.W_500}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.COPIED_LABEL}
                size={"xs"}
              />
            </div>
          ) : (
            <>
              {processingTimeStamp?.length && head?.copy ? (
                <IconComponent
                  id={classes.copiedTextContainer}
                  color={GREY.TERTIARY}
                  fontSize={fontSize.MD}
                  iconLabel={ICON_LABELS.CONTENT_COPY}
                  onClick={() => copyTextToClipboard(row, head)}
                />
              ) : null}
            </>
          )
        ) : null}
        {/* copy icon code */}
      </div>
    );
  };
  const leadStatusDesign = (row, head, index) => {
    return (
      <div
        key={index}
        className={classes.leadStatusCell}
        style={{
          ...styles.leadStatusCell,
          width: head?.width,
          minWidth: head?.width,
        }}
        onClick={(event) => {
          leadStatusHandler(event, row, head);
          tableActions[head.ui_id]?.function(row, head, index);
        }}
      >
        {tableData?.isLoading ? (
          <SkeletonLoader
            type="rounded"
            skeletonWidth={"60%"}
            skeletonHeight={20}
          />
        ) : (
          <FilterChip
            label={row?.status || row[head?.id] || "--"}
            variant={statusVariant[row?.status]}
          />
        )}
      </div>
    );
  };

  // Table Designs

  // Object calling designs according to ui_id.
  const tableBodyObj = {
    text: textDesign,
    bold_text: boldTextDesign,
    number_text: textDesign,
    user_info: userInfoDesign,
    company_info: companyInfoDesign,
    url: urlDesign,
    action: actionIconDesign,
    company_url: compnayURLDesign,
    email: emailDesign,
    default: defaultAction,
    marketing_list: marketingListDesign,
    text_button: textButtonDesign,
    stats_data: statsDataDesgin,
    time_stamp: timeStampDesign,
    status: statusDesign,
    button: buttonDesign,
    flag_button: flagButton,
    duration_text: durationTextDesign,
    text_url: textURLDesign,
    task_list: taskListDesign,
    user_detail: userDetailDesign,
    name_detail: nameDetailDesign,
    company_detail: companyDetailDesign,
    task_type: taskTypeDesign,
    due_time_stamp: dueTimeStampDesign,
    lead_status: leadStatusDesign,
    amount_update: amountUpdateDesign,
    request_status: requestStatusDesign,
    approve_request: approveRequestDesign,
    plain_text: plainTextDesign,
    single_name: firstNameDesign,
    message_text: messageDesign,

  };
  // Object calling designs according to ui_id.

  // Object calling styles according to ui_id.
  const cellWidth = {
    user_info: "userInfoCellWidth",
    company_info: "companyInfoCellWidth",
    text:
      tableHeads?.length > 7
        ? "textCellWidth"
        : tableHeads?.length > 5
          ? "textCellLargeWidth"
          : "textCellXLargeWidth",
    bold_text:
      tableHeads?.length > 7
        ? "boldTextCellWidth"
        : tableHeads?.length >= 5
          ? "boldTextCellLargeWidth"
          : "boldTextCellXLargeWidth",
    company_url: "companyURLCellWidth",
    email: "emailCellWidth",
    action:
      tableHeads?.length > 7
        ? "actionCellWidth"
        : tableHeads?.length <= 5
          ? "actionCellXLargeWidth"
          : "actionCellLargeWidth",
    number_text: "numberTextCellWidth",
    marketing_list:
      tableHeads?.length > 7
        ? "marketingListCellWidth"
        : "marketingListCellLargeWidth",
    text_button: "textButtonCellWidth",
    stats_data:
      tableHeads?.length <= 5
        ? "statsDataCellLargeWidth"
        : "statsDataCellWidth",
    time_stamp:
      tableHeads?.length >= 7
        ? "timeStampCellWidth"
        : "timeStampCellLargeWidth",
    button: "buttonCellWidth",
    flag_button: "buttonCellWidth",
    status: "statusCellWidth",
    text_url: "textURLCellWidth",
    amount_update: "amountCellUpdateWidth",
    request_status: "requestCellUpdateWidth",
    approve_request: "approveCellUpdateWidth",
    plain_text: "plainCellUpdateWidth",
    duration_text: "durationTextCellWidth",
    task_list: "taskListCellWidth",
    user_detail: "userDetailCellWidth",
    name_detail: "nameDetailCellWidth",
    company_detail: "companyDetailCellWidth",
    task_type: "taskTypeCellWidth",
    due_time_stamp: "dueTimeStampCellWidth",
    lead_status: "leadStatusCellWidth",
    single_name: "firstNameCellWidth",
    message_text: "messageCellWidth",
    reason_text: "reasonCellWidth",

  };
  // Object calling styles according to ui_id.

  // Status Variant used for status chip in Campaigns.
  const statusVariant = {
    "setting up": "",
    scheduled: "purple",
    active: "green",
    inactive: "red",
    pending: "yellow",
    blocked: "error",
    interrupted: "red",
    completed: "green",
    paused: "yellow",
    offline: "red",
    online: "green",
  };
  // Status Variant used for status chip in Campaigns.

  // Task Type Object having iconLabel, icon and Color of icon.
  const TASK_TYPE = {
    1: { icon: ICON_LABELS.EMAIL, label: "Email", iconColor: PRIMARY.W_500 },
    2: {
      icon: ICON_LABELS.INSERT_DRIVE_FILE,
      label: "To do",
      iconColor: INTER_DIMENSIONAL_BLUE.W_400,
    },
    3: {
      icon: ICON_LABELS.CALL,
      label: "Call",
      iconColor: SUCCESS.W_500,
    },
  };
  // Task Type Object having iconLabel, icon and Color of icon.

  // Returns sort Image according to direction.
  const sortImageHandler = (head) => {
    if (
      paginationDataSelector?.data?.query?.sort?.direction === "asc" &&
      head?.id === paginationDataSelector?.data?.query?.sort?.id
    ) {
      return ASCENDING_SORT;
    } else if (
      paginationDataSelector?.data?.query?.sort?.direction === "desc" &&
      head?.id === paginationDataSelector?.data?.query?.sort?.id
    ) {
      return DESCENDING_SORT;
    } else {
      return NO_SORT;
    }
  };
  // Returns sort Image according to direction.

  return (
    <div style={styles.tableMainContainer}>
      {/* Table */}
      <div id="modal_body" style={styles.table}>
        {/* Table Head */}
        {paginationDataSelector?.data?.checkedRow?.length > 0 &&
          headerStrip?.length ? (
          //  Table Head when all checkbox are checked.
          <div style={styles.tableHeadAllChecked}>
            <div style={styles.selectedAllLabel}>
              {!selectionPermission && (
                <Checkbox
                  partial={
                    tableHeaderStripValue?.length
                      ? false
                      : paginationDataSelector?.data?.checkedRow?.length > 0 &&
                      paginationDataSelector?.data?.checkedRow?.length <
                      tableBody?.length
                  }
                  checked={
                    tableHeaderStripValue?.length ||
                    paginationDataSelector?.data?.checkedRow?.length ===
                    tableBody?.length
                  }
                  onChange={checkedAllHandler}
                />
              )}

              <TypographyInline
                isEllipses
                color={PRIMARY.W_500}
                fontWeight={fontWeight.MEDIUM}
                label={`${tableHeaderStripValue?.length
                  ? tableHeaderStripValue
                  : paginationDataSelector?.data?.checkedRow?.length
                  } Selected`}
                size="sm"
              />
            </div>
            <div style={styles.headerStripButton}>
              {headerStrip?.map((head, index) => {
                return (
                  <div style={styles.tabLineContainer} key={index}>
                    {head}
                    {index + 1 !== headerStrip?.length && (
                      <hr color={BORDER.LIGHT} style={styles.horizontalLine} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          //  Table Head when all checkbox are checked.
          // Table head when all are not checked.
          <div style={styles.tableHeadContainer}>
            {tableHeads.map((head, index) => {
              return (
                <div
                  //     style={`${index === 0
                  //       ? styles.initialTableHeadValue
                  //       : styles.tableHeadValue
                  //       } ${styles[cellWidth[head.ui_id]]}
                  // ${index === 0 && tableData.isSticky
                  //         ? styles.stickInitialHead
                  //         : ""
                  //       }  `}
                  style={{
                    ...{
                      ...(index === 0
                        ? {
                          ...styles.initialTableHeadValue,
                          width: head?.width,
                          minWidth: head?.width,
                        }
                        : {
                          ...styles.tableHeadValue,
                          width: head?.width,
                          minWidth: head?.width,
                        }),
                    },
                    ...(index === 0 && tableData?.isSticky
                      ? { ...styles.stickyUserInfoCell }
                      : tableData?.isSticky && index === tableHeads?.length - 1 ? { ...styles.rightStickyhead } : ""),
                  }}
                  key={index}
                >
                  {index === 0 && !selectionPermission && head.id !== "amount" && (
                    <div style={styles.tableHeadCheckbox}>
                      <Checkbox
                        checked={
                          checkedValue?.length ===
                          paginationDataSelector.data?.checkedRow?.length &&
                          tableBody?.length ===
                          paginationDataSelector.data?.checkedRow?.length
                        }
                        partial={
                          paginationDataSelector.data?.checkedRow?.length > 0 &&
                          paginationDataSelector.data?.checkedRow?.length <
                          tableBody?.length
                        }
                        disabled={!tableBody?.length || tableData?.isLoading}
                        onChange={checkedAllHandler}
                      />
                    </div>
                  )}
                  <div
                    // style={`${styles.headerLabelSortContainer} ${index === 0 &&
                    //   !selectionPermission &&
                    //   styles.userInfoHeaderCellPadding
                    //   } ${selectionPermission && index === 0
                    //     ? styles.userInfoHeaderCellTotalPadding
                    //     : ""
                    //   }`}
                    style={{
                      ...{
                        ...(index === 0 && !selectionPermission
                          ? {
                            ...styles.userInfoHeaderCellPadding,
                            ...styles.headerLabelSortContainer,
                          }
                          : { ...styles.headerLabelSortContainer }),
                      },
                      ...(selectionPermission && index === 0
                        ? styles.userInfoHeaderCellTotalPadding
                        : ""),
                    }}
                  >
                    <TypographyInline
                      isEllipses
                      label={head.label}
                      color={GREY.PRIMARY}
                      size="sm"
                      fontWeight={fontWeight.MEDIUM}
                    />
                    {head.sort && (
                      <div style={styles.sortImage}>
                        <Image
                          width={fontSize.MD}
                          height={fontSize.MD}
                          src={sortImageHandler(head)}
                          onClick={() => sortHandler(head, index)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {/* Table Head */}
        {/* Table Body */}
        <div style={styles.tableBody}>
          {!tableData?.isLoading && !tableBody?.length ? (
            <div style={styles.emptyRecordContainer}>
              <TypographyInline
                color={GREY.SECONDARY}
                fontWeight={fontWeight.SEMIBOLD}
                label={STRINGS.NO_RECORD_FOUND}
                size={"lg"}
              />
            </div>
          ) : (
            tableBody?.map((row, index) => {
              return (
                <div style={styles.tableBodyRow} key={index}>
                  {tableHeads.map((head, i) => {
                    return (
                      <>
                        {tableBodyObj[head?.ui_id || "default"](
                          row,
                          head,
                          i,
                          index
                        )}{" "}
                      </>
                    );
                  })}
                </div>
              );
            })
          )}
        </div>

        {/* Action Icon Menu  */}
        <CustomMenu
          items={tableOperations}
          anchor={actionMenuAnchor}
          open={isActionMenuVisible}
          onClose={() => {
            setIsActionMenuVisible(false);
            setActionMenuAnchor(null);
          }}
          rowData={clickedRowData}
          headData={clickedHeadData}
        />
        {/* Action Icon Menu  */}

        {/* Lead Status Menu */}
        <CustomMenu
          items={tableStausList}
          anchor={leadStatusMenuAnchor}
          open={Boolean(leadStatusMenuAnchor)}
          onClose={() => {
            setLeadStatusMenuAnchor(null);
          }}
          paperMenuClass={true}
          rowData={clickedRowData}
          headData={clickedHeadData}
        />
        {/* Lead Status Menu */}

        {/* Table Body */}
      </div>
      {/* Pagination */}
      <CustomPagination total_records={tableData.total_records} />
    </div>
  );
};

export default React.memo(CustomTable);

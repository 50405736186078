export const STRINGS = {
  CANCEL: "Cancel",
  RESET: "Reset",
  RESET_COLUMNS: "Reset the Columns",
  DEFAULT_COLUMN_STATE:
    "Resetting current columns, will restore the default column state.",
  DELETE: "Delete",
  SAVE: "Save",
  REPORT: "Report a Bug",
};

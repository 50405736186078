import {
  FILTERS_FAIL,
  FILTERS_SUCCESS,
  PAGINATION_FAIL,
  PAGINATION_SUCCESS,
} from "../constants/customTableContants";

export const paginationDataAction = (sentQuery) => async (dispatch) => {
  try {
    dispatch({
      type: PAGINATION_SUCCESS,
      payload: sentQuery,
    });
  } catch (err) {
    dispatch({ type: PAGINATION_FAIL, payload: err });
  }
};

export const tableFilters = (sentQuery) => async (dispatch) => {
  try {
    dispatch({
      type: FILTERS_SUCCESS,
      payload: sentQuery,
    });
  } catch (err) {
    dispatch({ type: FILTERS_FAIL, payload: err });
  }
};

// import { getRequest } from "../../Apis";
// import { leadsEndpoints } from "../../utils/constants/httpConstants";

import { createSearchParams} from "react-router-dom";
import { DEFAULT_COMPANY_EMPLOYEES_COUNT, DEFAULT_EMAIL_VERIFICATION_TYPES, DEFAULT_SENIORIRT_TYPES } from "../../utils/constants";
import { allFilters, filterHeaderCompanyCity, filterHeaderCompanyCountry, filterHeaderCompanyKeywords, filterHeaderCompanyName, filterHeaderCompanySeo, filterHeaderCompanyState, filterHeaderCompanyTechnologies, filterHeaderEmailVerification, filterHeaderPersonCity, filterHeaderPersonCountry, filterHeaderPersonState, filterHeaderSearchCompany, filterHeaderSearchIndustry, filterHeaderSearchJobTitle, filterHeaderSearchSeniority } from "../actions/filterActions";
import { initialFilterPayload, initialQueryPayload } from "./storeConstants";
import { paginationDataAction } from "../actions/customTableActions";

export const getCheckEmployeeCount = (current_count, selectedCount) => {
  const result = [];
  selectedCount = selectedCount?.map((item)=> item?.toString())
  for (let i = 0; i < current_count?.length; i++) {
    if (selectedCount?.indexOf(current_count[i].index?.toString()) === -1) {
      result.push(current_count[i]);
    } else {
      result.push({ ...current_count[i], isChecked: true });
    }
  }
  return result;
}

export const getSelectedSeniority = (current_count, selectedCount) => {
  const result = [];
  for (let i = 0; i < current_count?.length; i++) {
    if (selectedCount?.indexOf(current_count[i].type) === -1) {
      result.push(current_count[i]);
    } else {
      result.push({ ...current_count[i], isChecked: true });
    }
  }
  return result;
};

export const checkExistFields = (query, filter) => {
  let isKnown = false;
  let isUnknown = false;
  let anyOf = false;
  let notAnyOf = false;
      
  if ( query?.exist_fields?.length && query?.exist_fields?.indexOf(filter) !== -1) {
    isKnown = true;
    isUnknown = false;
  }
  if (query?.not_exist_fields?.length && query?.not_exist_fields?.indexOf(filter) !== -1) {
    isUnknown = true;
    isKnown = false;
  }
  if( query?.[filter]?.length > 0 ){
    anyOf = true;
  }
  if( query?.[`${filter}_not`]?.length > 0 ){
    notAnyOf = true;
  }


  return { payload:{ anyOf, notAnyOf, isKnown, isUnknown } }
};


export const checkExistFieldsCompanyName = async (query, filter, callApi= false) => {
  let isKnown = false;
  let isUnknown = false;
  let anyOf = false;
  let notAnyOf = false;
 
  if ( query?.exist_fields?.length && query?.exist_fields?.indexOf(filter) !== -1) {

    isKnown = true;
    isUnknown = false;
  }

  if (query?.not_exist_fields?.length && query?.not_exist_fields?.indexOf(filter) !== -1) {
    
    isUnknown = true;
    isKnown = false;

  }
  if( query?.['company_ids']?.length > 0 ){
    anyOf = true;
  }
  if( query?.[`${'company_ids'}_not`]?.length > 0 ){
    notAnyOf = true;
  }

  if( callApi && (anyOf || notAnyOf) ){
    // try{
    //   const response = await getRequest(()=>{},leadsEndpoints.getCompanyName, payload )
    // }catch(error){

    // }
  }


  return { payload:{ anyOf, notAnyOf, isKnown, isUnknown } }
};

export const HandleClearAllFilterFromStore = (dispatch, location, navigate) => {
  // for company name filter
  // localStorage.clear();

  dispatch(
    filterHeaderSearchCompany(
      JSON.parse(JSON.stringify(DEFAULT_COMPANY_EMPLOYEES_COUNT))
    )
  );
  dispatch(
    filterHeaderSearchSeniority(
      JSON.parse(JSON.stringify(DEFAULT_SENIORIRT_TYPES))
    )
  );
  dispatch(
    filterHeaderEmailVerification(
      JSON.parse(JSON.stringify(DEFAULT_EMAIL_VERIFICATION_TYPES))
    )
  );
  dispatch(filterHeaderSearchJobTitle(initialFilterPayload));
  dispatch(filterHeaderSearchIndustry(initialFilterPayload));
  dispatch(filterHeaderCompanyKeywords(initialFilterPayload));
  dispatch(filterHeaderCompanyTechnologies(initialFilterPayload));
  dispatch(filterHeaderCompanySeo(initialFilterPayload));
  dispatch(filterHeaderPersonCity(initialFilterPayload));
  dispatch(filterHeaderPersonState(initialFilterPayload));
  dispatch(filterHeaderPersonCountry(initialFilterPayload));
  dispatch(filterHeaderCompanyCity(initialFilterPayload));
  dispatch(filterHeaderCompanyState(initialFilterPayload));
  dispatch(filterHeaderCompanyCountry(initialFilterPayload));
  dispatch(filterHeaderCompanyName(initialFilterPayload));
  dispatch(allFilters(initialQueryPayload, true));
  dispatch(paginationDataAction({}));
  navigate({
    pathname: location.pathname,
    search: createSearchParams(initialQueryPayload).toString(),
  });
};

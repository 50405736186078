import { Box } from "@mui/material";
import React, { useState } from "react";
import ImageIcon from "../../../components/Icon/ImageIcon";
import {
  AUTO_THEME_IMAGE,
  DARK_THEME_IMAGE,
  EDIT_ICON,
  LIGHT_THEME_IMAGE,
  THEME_ICON,
  USER_ICON,
} from "../../../utils/constants/assets";
import { styles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { changeMode } from "../../../redux/actions/modeActions";
import {
  CircularLoader,
  CustomDropDown,
  NationalityDropdown,
} from "../../../components";
import { BACKGROUND_COLOR } from "../../../theme/colors";
import { nameValidation } from "../../../utils/validations";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import { authEndpoints } from "../../../utils/constants/httpConstants";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../utils/constants";
import axios from "axios";
import { useEffect } from "react";
import { updateProfile } from "../../../redux/actions/authActions";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { fontWeight } from "../../../utils/constants/UI";
import { GREY } from "../../../utils/constants/colors";
import { STRINGS } from "../strings";
import InputField from "../../../components/InputFields/InputField";
import { Button } from "../../../components/Buttons";

const Profile = () => {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state?.modeChange?.mode);
  const [loading, setLoading] = useState(false);
  const classes = styles();
  //   const [userImage, setUserImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(USER_ICON);
  const usertoken = useSelector((state) => state.checkLogin);

  const username = usertoken?.payload?.data?.name || "";
  const useremail = usertoken?.payload?.data?.email || "";
  console.log("usertoken", username);
  const [name, setName] = useState();
  const [validations, setValidations] = useState({
    name: "",
  });

  //Handle Image upload
  const handleImageUpload = (e) => {
    if (e?.target?.files) {
      //   setUserImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSelectTheme = (theme) => {
    dispatch(changeMode(theme));
  };

  const updateProfilehandler = async (e) => {
    e.preventDefault();
    const validatedName = nameValidation(name);
    if (validatedName.isValid) {
      setValidations({
        name: "",
      });

      dispatch(updateProfile({ name: name }));
    } else {
      setValidations({
        name: validatedName.message,
      });
    }
  };

  useEffect(() => {
    setName(username);
  }, [username]);
  return (
    <Box className={classes.container}>
      <Box className={classes.scrollingContainer} sx={{ background: "#fff" }}>
        <div
          className={classes.title}
          style={{ padding: "1.5rem 2rem", borderBottom: "1px solid #D3DAE3" }}
        >
         <TypographyInline
              label={STRINGS.MY_PROFINE}
              size={"lg"}
              fontWeight={fontWeight.SEMIBOLD}
              color={GREY.PRIMARY}
            />
        </div>
       
        <Box
          component="form"
          className={classes.componentContainer}
          onSubmit={updateProfilehandler}
        >
          <Box className={classes.containFileUpload}>
            <Box className={classes.userImageContainer}>
              <ImageIcon image={imageUrl} width="100%" height="100%" />
            </Box>
            <div className={classes.fileInputIconContainer}>
              <input
                accept="image/*"
                // className={classes.input}
                className={classes.hiddenInput}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="raised-button-file">
                
                  <ImageIcon image={EDIT_ICON} />
                
                
              </label>
            </div>
          </Box>
          <Box className={classes.containNameInputss}>
            <Box className={classes.containInput}>
              {/* <h5 className={classes.inputTitle}>First Name</h5> */}
              {/* <input
                className={classes.input}
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ textTransform: "capitalize" }}
              /> */}
              <InputField
            type="text"
            placeholder="Name"
            value={name}
            size={"lg44"}
            onChange={(e) => setName(e.target.value)}
            variant={validations.name ? "error" : "default"}
            errormessage={validations.name ?  validations.name : ""}
          />
            </Box>
            {/* {validations.name && <ErrorMessage message={validations.name} />} */}
            <Box className={classes.containInput}>
              {/* <h5 className={classes.inputTitle}>Last Name</h5> */}
              {/* <input
                type="email"
                className={classes.input}
                placeholder="Email"
                disabled
                value={useremail}
              /> */}
              <InputField
            type="text"
            placeholder="Email"
            value={useremail}
            size={"lg44"}
            variant={"disabled"}
              
          />
            </Box>
            <Box>
              {loading ? (
                <CircularLoader />
              ) : (
                <div className={classes.logoutBtn}>
                <Button
            //LeftIcon={ICON_LABELS.DOWNLOAD}
            label={STRINGS.SAVE_PROFILE}
            size={"sm36"}
            variant="primary"
            //onClick={handleEdit}
          /></div>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;

import {
  CREADIT_INFO_ERROR,
  CREADIT_INFO_REQUEST,
  CREADIT_INFO_SUCCESS,
  PLUGIN_REFERAL_REQUEST,
  PLUGIN_REFERAL_SUCCESS,
  PLUGIN_REFERAL_ERROR,
} from "../constants/creditinfoConstant";

export const creditInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case CREADIT_INFO_REQUEST:
      return { loading: true };

    case CREADIT_INFO_SUCCESS:
      return { loading: false, columns: action.payload?.data,totoalcredit: action.payload.tenant_available_credits,usedcredit: action.payload.tenant_credit_used
, totalRecords: action.totalRecords};

    case CREADIT_INFO_ERROR:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const pluginreferalReducer = (state = {}, action) => {
  switch (action.type) {
    case PLUGIN_REFERAL_REQUEST:
      return { loading: true };

    case PLUGIN_REFERAL_SUCCESS:
      return { loading: false, columns: action.payload, totalRecords: action.totalRecords};

    case PLUGIN_REFERAL_ERROR:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

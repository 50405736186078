import React from "react";
import { makeStyles } from "@mui/styles";
import { filterItemsForSorting, findSortedValue } from "../../utils/helpers";
import { IMAGE_BASE_URL } from "../../utils/constants/assets";

const styles = makeStyles({
  dropdown: {
    appearance: "none",
    border: "1px solid #D3DAE3",
    padding: "0 0.8rem",
    borderRadius: "4px",
    color: "#626B7E",
    width: "100%",
    fontWeight: "400",
    fontSize: "13px",
    background: `transparent url('${IMAGE_BASE_URL}/assets/icons/Polygon.svg') no-repeat 95%`,
    objectFit: "cover",
    minHeight: "5vh",
    height: "100%",
    margin: 0,
    "@media (max-width: 768px)": {
      minWidth: "20vw",
    },
  },
  newdrp: {},
});

const accountCustomDropDown = ({
  value,
  items,
  backgroundColor,
  fontWeight,
  iconPercent,
  height,
  margin,
  minHeight,
  ...props
}) => {
  const classes = styles();

  const resultedValue = findSortedValue(value, items);
  const resultedItems = filterItemsForSorting(items, value);
 
  return (
    <select {...props}>
      {value ? (
        <option value={resultedValue?.id}>{resultedValue?.label || value}</option>
      ) : null}
      {resultedItems?.length
        ? resultedItems?.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item?.label}
              </option>
            );
          })
        : null}
    </select>
  );
};

export default accountCustomDropDown;

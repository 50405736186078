export const STRINGS = {
    BUSINESS:"Business",
    SEARCH:"Search",
    CREATE:"Create",
    CANCEL:"Cancel",
    TITLE:"Title",
    HEADING:"Heading",
    DESCRIPTION:"Description",
    ENTER:"Enter",
    EDITOR:"editor",
    SOMETHING:"Something went wrong",
    SMALL_DELETE:"delete",
    DELETE:"Delete",
    SMALL_EDIT:"edit",
    EDIT:"Edit",
    UPDATE:"Update",
    CONFIRMATION:"Are you sure you want to delete",
    ACCOUNTS:"Accounts",
    ACCOUNT_STATUS:"Account Status",
    LOCATION:"Location",
    DATE:"Date",
    OWNER:"Owner",
    CLEARALL:"Clear all",
    FILTER:"Filter",
    REQUEST_RATE:"Request Rate",
    SIGNUP_DATE:"Sign up Date",
    CONTACTNO:"Contact No.",
    EMAIL_ADDRESS:"Email Address",
    WEBSITE:"Website",
    TENANT_URL:"Tenant URL",
    ADDRESS:"Address",
    CITY:"City",
    STATE:"State",
    COUNTRY:"Country",
    ZIPCODE:"Zip Code",
    TIMEZONE:"Timezone",
    USER_INFORMATION:"User Information",
    CREDIT_USAGE:"Credit Usage",
    VIEW_CREDIT_INFO:"View Credit info",
    TOTAL:"Total Credits",
    USED_CREDIT:"Credits Used",
    AVAILABLE:"Available Credits",
    CURRENT_SUBS:"Current Subscription Plan",
    TOTAL_USER:"Total User",
    COMPANY_PLUS:"Company Plus",
    NEXT_BILLING_DUE:"Next Billing Due",
    REMINDER_EMAIL:"Reminder Email",
    LAST_LOGIN:"Last Login",
    ROLE:"Role",
    CREDIT_USED:"Credit Used",
    REMAINING_CREDIT:"Remaining Credit",
    SEARCH:"Search",
    AVAILABLE_CREDIT:"Available Credit",
    ACCOUNT_STATUS:"Account Status",
    SMALL_ACTIVE:"active",
    SMALL_INACTIVE:"inactive",
    SMALL_BLOCKED:"blocked",
    SMALL_PENDING:"pending",
    CLEAR:"Clear",
    APPLY:"Apply",
    START_DATE:"Start Date",
    END_DATE:"End Date",
    CLEARALL:"Clear all",
    UNFLAG_USER:"Unflag Account",
    DO_YOU:"Do you want to unflag account?",
    FLAG_USER:"Flag Account",
    STATUS_CONFIRM:"Are you sure you want to change the status?",
    CANCEL:"No",
    UPDATE:"Yes",
    AVAILABLE_CREDITS:"Available Credits",
    CREDIT_HISTORY:"Credit History",
    FILTER_DATE:"Filter Date",
    RESET:"Reset",
    CLOSE:"Close",
    USERS:"Users",
    SMALL_USERS:"users",
    USER_STATUS:"Business Status",
    CONFIRM:"Confirm",
    APPROVE:"Approve",
    DISAPPROVE:"Disapprove",
    COMPANY_NAME:"Company Name",
    COMPANY_EMAIL:"Company Email",
    PHONE_NUMBER: "Phone Number",
    DOCUMENTS_LINK: "Documents Link",
    



}
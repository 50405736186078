import {
  FILTERS_FAIL,
  FILTERS_REQUEST,
  FILTERS_SUCCESS,
  PAGINATION_FAIL,
  PAGINATION_REQUEST,
  PAGINATION_SUCCESS,
} from "../constants/customTableContants";

export const paginationDataReducer = (state = {}, action) => {
  switch (action.type) {
    case PAGINATION_REQUEST:
      return { loading: true };
    case PAGINATION_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case PAGINATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const tableFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTERS_REQUEST:
      return { loading: true };
    case FILTERS_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case FILTERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import React from "react";
import { makeStyles } from "@mui/styles";
import { INDIA_FLAG,  USA_FLAG } from "../../utils/constants/assets";
import { FormControl,  MenuItem, Select } from "@mui/material";

const styles = makeStyles({
  dropdown: {
    appearance: "none",
    border: "1px solid #D3DAE3",
    borderRight: "none",
    padding: "0.2rem 0.8rem",
    borderRadius: "4px 0 0 4px",
    color: "#626B7E",
    width: "100%",
    fontWeight: "400",
    fontSize: "13px",
    background: "#F9FAFB",
    objectFit: "cover",
    height: "100%",
    margin: 0,
    "@media (max-width: 768px)": {
      minWidth: "20vw",
    },
    "&:before": {
      border: "none !important",
    },
  },
  flagIcon:{
    height: "40px", 
    objectFit: "cover"
  },
  FormControl:{
    width: "30%" 
  }
});

const NationalityDropdown = ({
  items,
  backgroundColor,
  fontWeight,
  iconPercent,
  ...props
}) => {
  const classes = styles();
  const value = ""

  return (
  
    <FormControl variant="standard" className={ classes.FormControl } >
      <Select
        {...props}
        className={classes.dropdown}
        //   value={age}
        //   onChange={handleChange}
        displayEmpty
        renderValue={
          value !== ""
            ? undefined
            : () => (
                <img
                  src={INDIA_FLAG}
                  className={ classes.flagIcon }
                  alt=""
                />
              )
        }
        //   inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value={10}>
          <img src={INDIA_FLAG} className={ classes.flagIcon } alt=""/> +91
        </MenuItem>
        <MenuItem value={10}>
          <img src={USA_FLAG} className={ classes.flagIcon } alt="" /> +61
        </MenuItem>

      </Select>
    </FormControl>
  );
};

export default NationalityDropdown;

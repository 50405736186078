import React from "react";
import { makeStyles } from "@mui/styles";
import { RED_COLOR } from "../../theme/colors";

const styles = makeStyles({
  button: {
    backgroundColor: RED_COLOR,
    color: "white",
    fontSize: "14px",
    padding: "0.4rem 1rem",
    border: "none",
    borderRadius: "4px",
    marginLeft: "0.6rem",
    cursor: "pointer",
    fontWeight: "400",
    "&:hover": {
      background: "#0F2F51",
    },
  },
});

const ApplyBtn = ({ message, icon, ...props }) => {
  const classes = styles();
  return (
    <button className={classes.button} {...props}>
      {icon}
      {message}
    </button>
  );
};

export default ApplyBtn;

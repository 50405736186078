import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import CustomIcon from "../../../components/Icon/Icon";
import InitialsIcon from "../../../components/Icon/InitialsIcon";
import { useDispatch, useSelector } from "react-redux";
import TableSaveBtn from "../../../components/Buttons/TableSaveBtn";
import { CircularLoader, RedCheckBox } from "../../../components";
import { CustomizedTooltip } from "../../../components/Tooltip/Tooltip";
import { putRequest } from "../../../Apis/apiWrapper";
import { authEndpoints } from "../../../utils/constants/httpConstants";
import { useNavigate } from "react-router-dom";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../utils/constants";
import { usersList } from "../../../redux/actions/authActions";

const styles = makeStyles({
  container: {
    width: "100%",
    padding: "1rem",
    paddingTop: "0",
  },
  paperContainer: {
    width: "100%",
    marginBottom: 2,
    overflowX: "auto",
    boxShadow: "none",
    border: "1px solid #d3dae3",
  },
  tableContainer: {
    maxHeight: "50vh",
    maxWidth: "92vw",
    position: "relative",
  },
  tableHeadings: {
    color: "#102F51",
    fontWeight: "600",
    fontSize: "16px",
    background: "white",
    alignSelf: "center",
    whiteSpace: "nowrap",
  },
  dataText: {
    color: "#626B7E",
    fontWeight: "400",
    fontSize: "13px",
    alignSelf: "center",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
  },
  email: {
    color: "#626B7E",
    fontWeight: "400",
    fontSize: "12px",
    alignSelf: "center",
    textOverflow: "ellipsis",
    paddingLeft: "5px",
  },
  dataTextName: {
    color: "#102F51",
    fontWeight: "600",
    fontSize: "14px",
    alignSelf: "center",
    paddingLeft: "5px",
    cursor: "pointer",
  },
  headIcons: {
    cursor: "pointer",
    paddingLeft: "5px",
  },
  selectedIcons: {
    alignSelf: "center",
    margin: "5px",
    color: "#626B7E",
  },
  comapnyImage: {
    width: "30px",
    height: "30px",
    objectFit: "contain",
    padding: "2px",
    border: "1px solid #D1D1D1",
    borderRadius: "50%",
    marginRight: "5px",
    alignSelf: "center",
  },
  whiteBackground: {
    background: "white",
  },
  nameHeader: {
    display: "flex",
    padding: "1rem",
    boxShadow: "2px 1px 6px #0000000F",
  },
  otherHeader: {
    display: "flex",
    padding: "1rem",
  },
  flex: {
    display: "flex",
  },
  toolTipContainer: {
    background: "white",
    boxShadow: "0px 9px 66px #00000029",
    borderRadius: "4px",
    zIndex: 1,
    padding: "1rem",
  },
  accountLabel: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#626b7e",
    padding: "5px 0",
    transition: "100ms",
    "&:hover": {
      fontWeight: "500",
    },
  },
  selectedStatus: {
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "capitalize",
    cursor: "pointer",
    paddingLeft: "1rem",
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const classes = styles();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    rows,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {rows.map((headCell) => {
          return headCell.isChecked ? (
            <TableCell
              key={headCell.id}
              className={[
                headCell.id === "name" ? classes.sticky : null,
                classes.tableHeadings,
              ]}
              align={"left"}
              sx={{ padding: 0 }}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <div
                className={
                  headCell.id === "name"
                    ? classes.nameHeader
                    : classes.otherHeader
                }
              >
                {headCell.id === "name" && (
                  <RedCheckBox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                )}
                <p className={classes.tableHeadings}>{headCell.label}</p>
                <div
                  className={classes.flex}
                  onClick={createSortHandler(headCell.id)}
                >
                  <CustomIcon name="fa fa-sort" />
                </div>
              </div>
            </TableCell>
          ) : null;
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const account_status_list = [
  { id: "not-approved", color: "orange", label: "Not Approved" },
  { id: "active", color: "green", label: "Active" },
  { id: "inactive", color: "red", label: "Not Active" },
];

const UserTeamsTable = ({ heads, data }) => {
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const selector = useSelector((state) => state.ApplyUpdateColumn);
  const [columns] = useState(selector?.columns || heads);
  const [statusChanging, setStatusChanging] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeStatus = async (status, id) => {
    try {
      const payload = { account_status: status, user_id: id };
      setStatusChanging(true);

      const response = await putRequest(
        navigate,
        authEndpoints.updateUserStatus,
        payload
      );
      EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      setStatusChanging(false);
      dispatch(usersList(navigate));
    } catch (error) {
      setStatusChanging(false);

      EventEmitter.emit(EMITTER_ERROR, error?.data?.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box className={classes.container}>
      <Paper className={classes.paperContainer}>
        <TableContainer className={classes.tableContainer}>
          <Table
            stickyHeader={true}
            sx={{
              overflowX: "auto",
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              rows={selector?.columns ? selector?.columns : columns}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let dataColumns = selector?.columns
                    ? selector?.columns
                    : columns;

                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      {dataColumns.map((column, index) => {
                        const value = row[column.id];
                        if (column.id === "name") {
                          return column.isChecked ? (
                            <TableCell
                              key={column.id}
                              sx={{ padding: 0 }}
                              className={[
                                classes.sticky,
                                !isItemSelected && classes.whiteBackground,
                              ]}
                            >
                              <div className={classes.otherHeader}>
                                <RedCheckBox
                                  onClick={(event) =>
                                    handleClick(event, row.name)
                                  }
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                                <InitialsIcon name={value} index={index + 1} />

                                <Box>
                                  <p className={classes.dataTextName}>
                                    {value}
                                  </p>
                                  <p className={classes.email}>{row.email}</p>
                                </Box>
                              </div>
                            </TableCell>
                          ) : null;
                        } else if (column.id === "account_status") {
                          return column.isChecked ? (
                            <TableCell align="left" key={column.id}>
                              <CustomizedTooltip
                                // placement="bottom-start"
                                hightZIndex={true}
                                title={
                                  <Box className={classes.toolTipContainer}>
                                    {statusChanging ? (
                                      <CircularLoader />
                                    ) : (
                                      account_status_list?.map((account) => {
                                        return (
                                          account.id !== value && (
                                            <p
                                              onClick={() =>
                                                handleChangeStatus(
                                                  account.id,
                                                  row._id
                                                )
                                              }
                                              className={classes.accountLabel}
                                              style={{
                                                cursor:
                                                  account.id === value
                                                    ? "no-drop"
                                                    : "pointer",
                                              }}
                                            >
                                              {account.label}
                                            </p>
                                          )
                                        );
                                      })
                                    )}
                                  </Box>
                                }
                              >
                                <p
                                  className={classes.selectedStatus}
                                  style={{
                                    color: account_status_list?.filter(
                                      (item) => item.id === value
                                    )[0]?.color,
                                  }}
                                >
                                  {value}
                                </p>
                              </CustomizedTooltip>
                            </TableCell>
                          ) : null;
                        } else if (column.id === "action") {
                          return column.isChecked ? (
                            <TableCell
                              align="left"
                              sx={{ padding: "0.8rem 0rem" }}
                              key={column.id}
                            >
                              <TableSaveBtn message={`${value || "..."}`} />
                            </TableCell>
                          ) : null;
                        } else if (column.isChecked) {
                          return (
                            <TableCell
                              key={column.id}
                              sx={{ minWidth: column.minWidth }}
                            >
                              <p className={classes.dataText}>{value}</p>
                            </TableCell>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{
            paddingRight: "40%",
            color: "#6E6D7A",
            fontWeight: "400",
          }}
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default React.memo(UserTeamsTable);

import {
  PLATEFORM_USER_TABLE_DATA_FETCH_ERROR,
  PLATEFORM_USER_TABLE_DATA_FETCH_REQUEST,
  PLATEFORM_USER_TABLE_DATA_FETCH_SUCCESS,
  PLUGIN_USER_TABLE_DATA_FETCH_ERROR,
  PLUGIN_USER_TABLE_DATA_FETCH_REQUEST,
  PLUGIN_USER_TABLE_DATA_FETCH_SUCCESS,
  USER_DEATIL_DATA_FETCH_REQUEST,
  USER_DETAIL_DATA_FETCH_SUCCESS,
  USER_DETAIL_DATA_FETCH_ERROR,
} from "../constants/usertableConstant";

export const plateformuserReducer = (state = {}, action) => {
  switch (action.type) {
    case PLATEFORM_USER_TABLE_DATA_FETCH_REQUEST:
      return { loading: true };

    case PLATEFORM_USER_TABLE_DATA_FETCH_SUCCESS:
      return { loading: false, columns: action.payload, totalRecords: action.totalRecords };

    case PLATEFORM_USER_TABLE_DATA_FETCH_ERROR:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const pluginuserReducer = (state = {}, action) => {
  switch (action.type) {
    case PLUGIN_USER_TABLE_DATA_FETCH_REQUEST:
      return { loading: true };

    case PLUGIN_USER_TABLE_DATA_FETCH_SUCCESS:
      return { loading: false, payload: action.payload };

    case PLUGIN_USER_TABLE_DATA_FETCH_ERROR:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userdetailInfoDataReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DEATIL_DATA_FETCH_REQUEST:
      return { loading: true };

    case USER_DETAIL_DATA_FETCH_SUCCESS:
      return { loading: false, payload: action.payload };

    case USER_DETAIL_DATA_FETCH_ERROR:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

import React from "react";

const Anchor = ({ ...props}) => {
  return (
    <a rel="noreferrer" {...props}>
      {props.children}
    </a>
  );
};

export default Anchor;

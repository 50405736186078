export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_URL_REQUEST = "REGISTER_REQUEST";
export const LOGIN_URL_SUCCESS = "REGISTER_SUCCESS";
export const LOGIN_URL_FAIL = "REGISTER_FAIL";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const VERIFY_DOMAIN_REQUEST = "VERIFY_DOMAIN_REQUEST";
export const VERIFY_DOMAIN_SUCCESS = "VERIFY_DOMAIN_SUCCESS";
export const VERIFY_DOMAIN_FAIL = "VERIFY_DOMAIN_FAIL";

export const CHECK_LOGIN_REQUEST = "CHECK_LOGIN_REQUEST";
export const CHECK_LOGIN_SUCCESS = "CHECK_LOGIN_SUCCESS";
export const CHECK_LOGIN_FAIL = "CHECK_LOGIN_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

// Forgot password constants
export const RESET_PASSWORD_MAIL_SEND_REQUEST =
  "RESET_PASSWORD_MAIL_SEND_REQUEST";
export const RESET_PASSWORD_MAIL_SEND_SUCCESS =
  "RESET_PASSWORD_MAIL_SEND_SUCCESS";
export const RESET_PASSWORD_MAIL_SEND_FAIL = "RESET_PASSWORD_MAIL_SEND_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

// User Invitation and users routes
export const INVITED_USERS_LIST_REQUEST = "INVITED_USERS_LIST_REQUEST";
export const INVITED_USERS_LIST_SUCCESS = "INVITED_USERS_LIST_SUCCESS";
export const INVITED_USERS_LIST_FAIL = "INVITED_USERS_LIST_FAIL";

export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_FAIL = "USERS_LIST_FAIL";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../redux/actions/authActions";
import { HandleClearAllFilterFromStore } from "../../redux/store/storeHelper";
import { PROFILE_MENU_CONTENT } from "../../utils/constants";
import { BASE, BORDER, GREY, PRIMARY } from "../../utils/constants/colors";
import { LOGIN_PATH, PROFILE_PATH } from "../../utils/constants/routes";
import { fontSize, fontWeight } from "../../utils/constants/UI";
import AvatarName from "../Avatar/AvatarName";
import CustomBreadcrumbs from "../Breadcrumbs/Breadcrumbs";
import IconComponent from "../Icon/IconComponent";
import CustomStyledMenu from "../Menu/CustomStyledMenu";
import { innerBottomBorder, innerLeftBorder } from "../../utils/helpers";
import ContactFormModal from "../Modals/ContactFormModal";
import { Mixpanel } from "../../Apis/Mixpanel";
import { Box } from "@mui/material";
import TypographyInline from "../Typography/TypographyInline";
import { checkLogin } from "../../redux/actions/authActions";
const styles = {
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    boxShadow: innerBottomBorder(BORDER.LIGHT),
    backgroundColor: BASE.WHITE,
    height: "64px"
  },
  bugReportContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "4px",
  },
  headerRightContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
  },
  userContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    paddingLeft: "12px",
    borderLeft: `1px solid ${BORDER.LIGHT}`,
  },
  labelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
    padding: "12px 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
      boxShadow: innerLeftBorder(PRIMARY.W_500),
    },
  },
  creditContainer: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
};

const CustomHeader = ({ items, description }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = location?.pathname?.trim()?.split("/")[1];
  const usertoken = useSelector((state) => state.checkLogin);
    console.log("USRTOK",usertoken)
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const userDetails = userDetailsSelector?.payload;
   
  const [checkLoginDone, setCheckLoginDone] = useState(false);
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [profileAnchor, setProfileAnchor] = useState(null);

  const handleProfileNavigation = async () => {
    sessionStorage.clear();
    HandleClearAllFilterFromStore(dispatch, location, navigate);
    navigate(`/${token}${PROFILE_PATH}`);
  };

  const handleLogout = async (each) => {
    if (each?.title === "Logout") {
      await dispatch(logoutUser());

      Mixpanel.track(" logout Successfully");
      navigate(`/${token}${LOGIN_PATH}`);
    }

    if (each?.title === "Settings") {
      handleProfileNavigation();
    }
  };

  useEffect(() => {
    

    if (!checkLoginDone) {
      setCheckLoginDone(true);
      dispatch(checkLogin());
    }
  }, [checkLoginDone, dispatch]);

  const toolTipTitle = (
    <div>
      {PROFILE_MENU_CONTENT.map((each, index) => {
        if (each.id === "available_credits") {
          return (
            <Box key={index} sx={styles.labelContainer}>
              <IconComponent
                iconLabel={each?.icon}
                color={GREY.SECONDARY}
                fontSize={fontSize.LG}
              />
              <div style={styles.creditContainer}>
                <TypographyInline
                  color={GREY.SECONDARY}
                  fontWeight={fontWeight.REGULAR}
                  label={each?.title}
                  size="sm"
                />
                <TypographyInline
                  color={PRIMARY.W_500}
                  fontWeight={fontWeight.REGULAR}
                  label={userDetails?.[each?.id] || 0}
                  size="sm"
                />
              </div>
            </Box>
          );
        }
        return (
          <Box
            onClick={() => handleLogout(each)}
            key={each.title}
            sx={styles.labelContainer}
          >
            <IconComponent
              iconLabel={each?.icon}
              color={GREY.SECONDARY}
              fontSize={fontSize.LG}
            />
            <TypographyInline
              color={GREY.SECONDARY}
              fontWeight={fontWeight.REGULAR}
              label={each?.title}
              size="sm"
            />
          </Box>
        );
      })}
    </div>
  );

  const handleProfileClick = (event) => {
    setProfileAnchor(event.currentTarget);
    setIsProfileClicked(true);
  };

  const handleProfileCloseHandler = () => {
    setIsProfileClicked(false);
    setProfileAnchor(null);
  };

  return (
    <div style={styles.headerContainer}>
      {/* Header Left Part */}
      <div>
        <CustomBreadcrumbs items={items} />
        {description?.length && (
          <TypographyInline
            label={description}
            size={"xs"}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
          />
        )}
      </div>
      {/* Header Left Part */}
      {/* Header Right Part */}
      <div style={styles.headerRightContainer}>
        <div style={styles.userContainer} onClick={handleProfileClick}>
          <AvatarName
            size={"small"}
            name={
              usertoken
                ? usertoken?.payload?.data?.name
                : "Loading"
            }
          />
        </div>
        <CustomStyledMenu
          open={isProfileClicked}
          anchorEl={profileAnchor}
          onClose={handleProfileCloseHandler}
          noMargin
        >
          {toolTipTitle}
        </CustomStyledMenu>

        <ContactFormModal
          open={openConfirmation}
          setOpen={setOpenConfirmation}
        />
      </div>
      {/* Header Right Part */}
    </div>
  );
};

export default CustomHeader;

// ------------- NOTE ----------------
// Props Description:-
// items :- It should be array of object and every object should have three keys - id, label and path. If you want to display only label then don't pass path in the object only pass id and label for that.
// description :- Label you want to display under header Heading.

import React from "react";

const CustomIcon = ({ name, color = "#BFBFC5" , fontSize, margin,rotate=false, ...props}) => {
  return (
    <i
      className={name}
      aria-hidden="true"
      style={{
        color: color,
        alignSelf: "center",
        fontSize: fontSize ? fontSize : 16,
        margin: margin ? margin : "0 5px",
        cursor: "pointer",
        transition:"200ms",
        transform: rotate && "rotate(180deg)"
      }}
      {...props}
    ></i>
  );
};

export default CustomIcon;

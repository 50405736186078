import {  TextareaAutosize } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const styles = makeStyles({
  textArea:{
    width:'100%',
    border:'1px solid #d3dae3',
    borderRadius:'4px',
    padding:'1rem',
    "&:hover":{
      border:'0.1rem solid #ff4848'
    }
  }
})

const CustomTextArea = ({ ...props }) => {

  const classes = styles();

  return <TextareaAutosize  { ...props } minRows={4} className={ classes.textArea } />
};

export default CustomTextArea;

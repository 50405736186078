import {
    LIST_FOLDER_REQUEST,LIST_FOLDER_SUCCESS,LIST_FOLDER_FAIL
} from "../constants/listFolderConstants"
export const getFolderListReducer=(state={},action)=>{
    switch(action.type){
        case LIST_FOLDER_REQUEST:
            return {loading:true}
        case LIST_FOLDER_SUCCESS:
            return {loading:false,payload:action.payload,error:null}
        case LIST_FOLDER_FAIL:
            return {loading:false,error:action.payload}
        default:
            return state;
    }

}
import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  
  SETTINGS_ACCOUNT_TABS,
  SETTINGS_GENERAL_TABS,
  SETTINGS_PREFERENCES_LIST,
} from "../../utils/constants";
import { styles } from "./styles";
// import General from "./Settings/General";
import SearchIcon from "@mui/icons-material/Search";
import ActionButton from "../../components/Buttons/ActionButton";
import General from "./components/Settings/General";
import { Header } from "../../components";
import { Grid } from "@mui/material";
import CustomHeader from "../../components/Header/CustomHeader";
import { STRINGS } from "./strings";
import TypographyInline from "../../components/Typography/TypographyInline";
import { BRAND, ERROR, GREY } from "../../utils/constants/colors";
import { ICON_LABELS, fontSize, fontWeight } from "../../utils/constants/UI";
import IconComponent from "../../components/Icon/IconComponent";
 
 

const ProfileScreen = () => {

  const classes = styles();
   
  const [selectedPreference, setSelectedPreference] = useState("General");
  const [selectedGeneralTab, setSelectedGeneralTab] = useState( SETTINGS_GENERAL_TABS[0] );
  const [selectedAccountTab, setSelectedAccountTab] = useState( SETTINGS_ACCOUNT_TABS[0] );
  const [renderedComponent, setRenderedComponent] = useState({ component: General });
   

  const handlePreferenceSelection = (preference, component) => {

    setRenderedComponent({ component: component });
    setSelectedPreference(preference);
  };

  

  const handleAccountTabSelection = (tab) => {
    setSelectedAccountTab(tab);
  };
 
  const headerItems = [
    {
      id: STRINGS.PROFILE_SETTING,
      label: STRINGS.PROFILE_SETTING,
    },
  ];

  return (
    <>
   
    <Box className={classes.container}>
      {/* <Header title="Profile Setting" /> */}
      <CustomHeader items={headerItems} />
      <Grid item md={10} xl={9} sx={{mx:"auto"}}>
      <Box className={classes.mainContainer}>
        <Box className={classes.settingContainer}>
         
          <Box className={classes.containPreferences}>
            {SETTINGS_PREFERENCES_LIST.map((item) => {
              return (
                <Box
                  key={item.label}
                  className={
                    selectedPreference === item.label
                      ? classes.selectedEachPreference
                      : classes.eachPreference
                  }
                  onClick={() =>
                    handlePreferenceSelection(item.label, item.component)
                  }
                >
                  <div>
                  
                 <IconComponent
                color={selectedPreference === item.label ? BRAND.PRIMARY :  GREY.TERTIARY }
                fontSize={fontSize.MD}
                iconLabel={item.icon}
              />
                   
                  </div>
                  <div
                    className={
                      selectedPreference === item.label
                        ? classes.selectedText
                        : classes.text
                    }
                  >
                    
                    <TypographyInline
              label={item.label}
              size={"sm"}
              fontWeight={fontWeight.REGULAR}
              color={selectedPreference === item.label ? BRAND.PRIMARY :  GREY.PRIMARY }
            />
                  </div>
                </Box>
              );
            })}
          </Box>

          
          
        </Box>
        <Box className={ classes.contentContainer } >
          
          {selectedPreference === "General" ? (
            <selectedGeneralTab.component />
          ) : selectedPreference === "User & Teams" ? (
            <selectedAccountTab.component />
          ) : (
            <renderedComponent.component />
          )}
         
        </Box>
      </Box>
      </Grid>
    </Box>
    
    </>
  );
};

export default ProfileScreen;

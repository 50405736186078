import React, { useEffect, useState } from 'react'
import { STRINGS } from './strings';
import { Styles } from "./Styles";
import CustomHeader from '../../components/Header/CustomHeader';
import { useDispatch, useSelector } from 'react-redux';
import { contentListData } from '../../redux/actions/contentAction';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import SearchField from '../../components/InputFields/SearchField';
import CustomTable from '../../components/Table/CustomTable';
import { BUSINESS_HEAD, CONTENT_HEAD, DEBOUNCE_DELAY } from '../../utils/constants';
import { ICON_LABELS } from '../../utils/constants/UI';
import { Button } from '../../components/Buttons';
// import ContentCreateModal from './components/ContentCreateModal';
// import DeleteContentModal from './components/DeleteContentModal';
import { debounceHelper } from '../../utils/helpers';
import { businessListData } from '../../redux/actions/businessAction';
import StatusConfirmationModal from './components/StatusConfirmationModal';
import ViewBusinessModal from './components/ViewBusinessModal';

const BusinessScreen = () => {
  const classes = Styles();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [data,setData] = useState({})
  const [value,setValue] = useState("")
  const [preview, setPreview] = useState(false)
  const [statusOpen, setStatusOpen] = useState(false)
  const [listStats, setListStats] = useState([
    {
          id: "inactive",
          label: "Inactive",
          color: "#FF4848",
          iconLabel: ICON_LABELS.FIBER_MANUAL_RECORD,
          function: (a, b) => handleChangeStatus(a, "inactive"),
        },
  ]);
  const [openEdit,setOpenEdit] = useState(false);
  const [openDelete,setOpenDelete] = useState(false);
  const [data,setData]= useState({})
  const [openCreateModal,setOpenCreateModal] = useState(false)
  const contentList = useSelector((state) => state.businesslist)
  const paginationSelector = useSelector((state)=>state.getPaginationQuery)

  const handleChangeStatus = async (row, head) => {
    
    setData(row)
    setValue(head)
    setStatusOpen(true)
    // setAccountstatus(head)
    
    // setTenantid(row.tenant_id)
  };


  const handleStatusShow = (a, b) => {
      setListStats([
        {
          id: "active",
          label: "Active",
          color: "#01A63E",
          iconLabel: ICON_LABELS.FIBER_MANUAL_RECORD,
          function: (row, head) => handleChangeStatus(row, "active"),
        },
        {
          id: "inactive",
          label: "Inactive",
          color: "#FF4848",
          iconLabel: ICON_LABELS.FIBER_MANUAL_RECORD,
          function: (a, b) => handleChangeStatus(a, "inactive"),
        }
        
      ]);
  };

  // const openCreate = ()=>{
  //   setOpenCreateModal(true)
  // }
  
  const headerItems = [
    {
      id: STRINGS.BUSINESS,
      label: STRINGS.BUSINESS,
    },
  ];
  const taskTableData = {
    heads: BUSINESS_HEAD,
    body: contentList?.columns?.records || [],
    status_list: listStats,
    operations: [
      {
        id: STRINGS.SMALL_EDIT,
        label: STRINGS.APPROVE,
        iconLabel: ICON_LABELS.CHECK,
        function: (row, head) => {
          if(row.status !== "approved"){
            handleChangeStatus(row,"approved")
          }          
        },
      },
      {
        id: STRINGS.SMALL_DELETE,
        label: STRINGS.DISAPPROVE,
        iconLabel: ICON_LABELS.CLOSE,
        variant: "error",
        function: (row, head) => {
          if(row.status === "approved"){
            handleChangeStatus(row,"disapproved")
          }
          
        },
      },
    ],
    noCheck: true,
    actions: {
      lead_status: {
        function: (row, head) => handleStatusShow(row, head),
      },
    marketing_list: {
          function: (a, b) => {
            setData(a)
            setPreview(true)
          }
        }
    },
    is_selection_not_allowed:true,
    isLoading: contentList?.loading,
    total_records: contentList?.columns?.total_records || 0,
  };

  const handleSearch = (event) =>{
    const val = {
      ...paginationSelector?.data?.query,
      search: event.target.value,
    };
    try {
      dispatch(contentListData(val, navigate));
    } catch (e) {
    }
  }

  const debounceSearch1 = debounceHelper(handleSearch, DEBOUNCE_DELAY);
  useEffect(() => {
    (async () => {
      await dispatch(businessListData({ ...paginationSelector?.data?.query }, navigate))
    })()
  }, [navigate,paginationSelector?.data?.query])
  return (
    <>
      <div className={classes.container}>
        <CustomHeader items={headerItems} description={contentList.loading ? "loading..." :  (contentList?.columns?.total_records !== undefined ?  `${contentList?.columns?.total_records} contents` : "0 content" ) }/>
        <Box className={classes.tableBoxheder}>
          <Box component="form" className={classes.searchData}>
            <SearchField
              size={"sm36"}
              placeholder={STRINGS.SEARCH}
              onChange={debounceSearch1}
            />
          </Box>
          {/* <div>
            <Button
              label={`${STRINGS.CREATE} ${STRINGS.BUSINESS}`}
              LeftIcon={ICON_LABELS.ADD}
              size={"sm36"}
              variant="primary"
              onClick={openCreate}
            />
          </div> */}
        </Box>
        <Box className={classes.searchAndTableContainer}>
          <div className={classes.tableStyle}>
            <CustomTable tableData={taskTableData} />
          </div>
        </Box>
      </div>
      {
        statusOpen && (
          <StatusConfirmationModal
            open = {statusOpen}
            setOpen = {setStatusOpen}
            data = {data}
            value = {value}  
          />
        )
      }
      {
        preview && (
          <ViewBusinessModal
            open={preview}
            setOpen={setPreview}
            data={data}
          />
        )
      }
    </>
  )
}

export default BusinessScreen

import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { styles } from "./styles";
import { SETTINGS_USERS_TABLE_HEADERS } from "../../../utils/constants";
import UserTeamsTable from "./UserTeamsTable";
import { useNavigate} from 'react-router-dom'
import { CircularLoader } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { usersList } from "../../../redux/actions/authActions";



const Users = () => {

  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userListSelector = useSelector(state=> state.usersList);
  const { loading, payload } = userListSelector;

  useEffect(()=>{
    dispatch( usersList( navigate ) );
  },[navigate, dispatch]);

  return (
    <Box className={classes.container}>
      <Box className={classes.componentContainer}>
        <Box className={classes.flexBetween}>
          <Box>
            <h3 className={classes.mainTitle}>Users</h3>
            <p className={classes.callingPara}>
              Create new users, customize user permissions, and remove users
              from your account.
            </p>
          </Box>
          {/* <Box className={classes.flex}>
            <h5 className={ classes.alignSelfCenter } >Status: </h5>
            <div className={classes.dropdownAllContain}>
              <CustomDropDown items={DROPDOWN_EMAIL_VIEWS} />
            </div>
          </Box> */}
        </Box>
        <Box className={ classes.justifyContentCenter } >
          { loading ? <CircularLoader /> :  <UserTeamsTable
            heads={SETTINGS_USERS_TABLE_HEADERS}
            data={payload?.data?.users || [] }
            imageTpye={"image"}
          /> }
        </Box>
      </Box>
    </Box>
  );
};

export default Users;

import { GET_CHART_DATA_FAIL, GET_CHART_DATA_REQUEST, GET_CHART_DATA_SUCCESS, GET_CURRENT_DASHBOARD, GET_DASHBOARD_LIST_FAIL, GET_DASHBOARD_LIST_REQUEST, GET_DASHBOARD_LIST_SUCCESS, GET_DASHBOARD_WIDGET_LIST_FAIL, GET_DASHBOARD_WIDGET_LIST_REQUEST, GET_DASHBOARD_WIDGET_LIST_SUCCESS, GET_WIDGET_COORDINATES, GET_WIDGET_COORDINATES_DONE } from "../constants/dashboardConstants";

export const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DASHBOARD_LIST_REQUEST:
      return { loading: true };
    case GET_DASHBOARD_LIST_SUCCESS:
      return { loading: false, payload: action.payload };
    case GET_DASHBOARD_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const dashboardWidgetsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DASHBOARD_WIDGET_LIST_REQUEST:
      return { loading: true };
    case GET_DASHBOARD_WIDGET_LIST_SUCCESS:
      return { loading: false, payload: action.payload };
    case GET_DASHBOARD_WIDGET_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const chartDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHART_DATA_REQUEST:
      return { loading: true };
    case GET_CHART_DATA_SUCCESS:
      return { loading: false, payload: action.payload };
    case GET_CHART_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const widgetCoordinatesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WIDGET_COORDINATES:
      return { active: true, payload: action.payload };
    default:
      return state;
  }
}

export const currentDashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_DASHBOARD:
      return { active: true, payload: action.payload };
    default:
      return state;
  }
}
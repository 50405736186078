import { QUARANTINE_DETAILS_FAIL, QUARANTINE_DETAILS_REQUEST, QUARANTINE_DETAILS_SUCCESS, QUARANTINE_LIST_GET_FAIL, QUARANTINE_LIST_GET_REQUEST, QUARANTINE_LIST_GET_SUCCESS,} from "../constants/QuarantineConstatnts";

  
  export const QuarantineBasicDetail = (state = {}, action) => {
    switch (action.type) {
      case QUARANTINE_LIST_GET_REQUEST:
        return { loading: true };
  
      case QUARANTINE_LIST_GET_SUCCESS:
        return { loading: false, columns: action.payload, totalRecords: action.totalRecords };

      case QUARANTINE_LIST_GET_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  

  export const quarantineUserDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case QUARANTINE_DETAILS_REQUEST:
        return { loading: true };
  
      case QUARANTINE_DETAILS_SUCCESS:
        return { loading: false, payload: action.payload, error: null };
  
      case QUARANTINE_DETAILS_FAIL:
        return { loading: false, error: action.payload, payload: null };
  
      default:
        return state;
    }
  };
 

  
import { RED_BOX_SHADOW_COLOR } from "../colors";

export const commonStyles = {
  largeTitle: {
    color: "#ff4848",
    fontWeight: "700",
    fontSize: "clamp(2rem,2.2vw,6rem)",
    textAlign: "center",
    letterSpacing: "0",
    margin: "0.5rem 1rem",
    marginTop: 0,
  },

  largePara: {
    textAlign: "center",
    fontWeight: "400",
    color: "#212833",
    alignSelf: "center",
    fontSize: "clamp(1rem,1vw,3rem)",
    width: "80%",
    paddingTop: "0.5rem",
  },

  redButtonWithShadow: {
    background: "#0071E3",
    minWidth: "200px",
    padding: "12px",
    // boxShadow: `0px 9px 20px ${RED_BOX_SHADOW_COLOR}`,
    border: "none",
    borderRadius: "30px",

    color: "white",
    fontSize: "18px",

    // fontSize: "clamp(1rem,1vw,3rem)",
    margin: "1rem 0",
    cursor: "pointer",
  },
  flex: {
    display: "flex",
  },
  flexWithJustifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexWithJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },

  centerModal: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    background: "white",
  },
};

import { Password } from "@mui/icons-material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { postRequest } from "../../../../Apis";
import { CircularLoader } from "../../../../components";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../../../utils/constants";
import { authEndpoints } from "../../../../utils/constants/httpConstants";
import EventEmitter from "../../../../utils/emitter/EventEmitter";
import {
  confirmPasswordvalidation,
  passwordValidation,
} from "../../../../utils/validations";
import { styles } from "./styles";
import TypographyInline from "../../../../components/Typography/TypographyInline";
import { fontWeight } from "../../../../utils/constants/UI";
import { GREY } from "../../../../utils/constants/colors";
import { STRINGS } from "../../strings";
import InputField from "../../../../components/InputFields/InputField";
import { Button } from "../../../../components/Buttons";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validations, setValidations] = useState({
    password: "",
    newpassword: "",
    confirmPassord: "",
  });
  const classes = styles();

  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    const validatedPassword = passwordValidation(password);
    const validatednewPassword = passwordValidation(newpassword);
    const validatedComfirmPassword = confirmPasswordvalidation(
      newpassword,
      confirmPassword
    );
    console.log("response----");
    if (
      validatedPassword.isValid &&
      validatednewPassword.isValid &&
      validatedComfirmPassword.isValid
    ) {
      setValidations({ password: "", newpassword: "", confirmPassord: "" });

      console.log(Password);
      // const formdata = {

      //   password: password,
      // };
      // await dispatch(resetPassword(formdata));
      // navigate(LOGIN_PATH);
      try {
        const response = await axios.post(authEndpoints.changePassword, {
          old_password: password,
          password: newpassword,
        });
        console.log("response", response);
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
      } catch (error) {
        console.log("passerrorr", error?.response?.data?.data?.errors?.password);
        EventEmitter.emit(
          EMITTER_ERROR,
          error?.response?.data?.data?.errors?.password
        );
      }
    } else {
      setValidations({
        password: validatedPassword.message,
        newpassword: validatedPassword.message,
        confirmPassord: validatedComfirmPassword.message,
      });
    }
  };

  return (
    <Box className={classes.container} sx={{ background: "#fff" }}>
      <h3
        className={classes.title}
        style={{
          padding: "1.5rem",

          borderBottom: "1px solid #D3DAE3",
        }}
      >
         <TypographyInline
              label={STRINGS.CHNAGE_PASSWORD}
              size={"lg"}
              fontWeight={fontWeight.SEMIBOLD}
              color={GREY.PRIMARY}
            />
      </h3>
      <Box
        className={classes.security}
        component="form"
        onSubmit={handleUpdatePassword}
      >
        {/* <input /> */}
        <Box className={classes.containKeysInput}>
          {/* <input
            type="password"
            className={classes.keysInput}
            value={password}
            placeholder="Old Password"
            onChange={(e) => setPassword(e.target.value)}
          /> */}
          <InputField
            type="password"
            placeholder="Old Password"
            value={password}
            size={"md40"}
            onChange={(e) => setPassword(e.target.value)}
            variant={validations.password ? "error" : "default"}
            errormessage={validations.password ?  validations.password : ""}
          />
        </Box>
        {/* {validations.password && (
          <ErrorMessage message={validations.password} />
        )} */}
        <Box className={classes.containKeysInput}>
          {/* <input
            type="password"
            className={classes.keysInput}
            value={newpassword}
            placeholder="New Password"
            onChange={(e) => setNewpassword(e.target.value)}
          /> */}
           <InputField
            type="password"
            placeholder="New Password"
            value={newpassword}
            size={"md40"}
            onChange={(e) => setNewpassword(e.target.value)}
            variant={validations.password ? "error" : "default"}
            errormessage={validations.newpassword ?  validations.newpassword : ""}
          />
        </Box>
        {/* {validations.newpassword && (
          <ErrorMessage message={validations.newpassword} />
        )} */}
        <Box className={classes.containKeysInput}>
          {/* <input
            type="password"
            className={classes.keysInput}
            value={confirmPassword}
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          /> */}
           <InputField
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            size={"md40"}
            onChange={(e) => setConfirmPassword(e.target.value)}
            variant={validations.password ? "error" : "default"}
            errormessage={validations.confirmPassord ?  validations.confirmPassord : ""}
          />
        </Box>
        {/* {validations.confirmPassord && (
          <ErrorMessage message={validations.confirmPassord} />
        )} */}
        <Box>
          {loading ? (
            <CircularLoader />
          ) : (
           <div className={classes.logoutBtn}>
            <Button
            //LeftIcon={ICON_LABELS.DOWNLOAD}
            label={STRINGS.SAVE}
            size={"sm36"}
            variant="primary"
            //onClick={handleEdit}
          />
          </div>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;

import React from "react";
import { makeStyles } from "@mui/styles";
import { RED_COLOR } from "../../theme/colors";

const styles = makeStyles({
  button: {
    backgroundColor: RED_COLOR,
    color: "white",
    fontSize: "14px",
    padding: "0.5rem 1.2rem",
    border: "none",
    borderRadius: "8px",
    // marginLeft: "0.6rem",
    cursor: "pointer",
    // boxShadow:"0px 9px 20px #ff16543c",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: "#0F2F51",
      boxShadow: "0px 4px 14px rgba(15, 47, 81, 0.27)",
    },
  },
});

const RedShadowButton = ({ message, ...props }) => {
  const classes = styles();
  return (
    <button
      style={{ marginLeft: props?.marginLeft ? props?.marginLeft : "0.6rem" }}
      className={classes.button}
      {...props}
    >
      {message}
    </button>
  );
};

export default RedShadowButton;

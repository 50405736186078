import {
  LIST_TABLE_REQUEST,
  LIST_TABLE_SUCCESS,
  LIST_TABLE_FAIL,
} from "../constants/listTableConstants";
export const getSequenceListReducer = (state = {}, action) => {
  switch (action.type) {
    case LIST_TABLE_REQUEST:
      return { loading: true };
    case LIST_TABLE_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case LIST_TABLE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import React from "react";
import { Box } from "@mui/material";
import { styles } from "./styles";
import CommonTable from "../../../components/Table/CommonTable";
import {
  DROPDOWN_EMAIL_VIEWS,
  SETTINGS_TEAMS_DATA,
  SETTINGS_TEAM_TABLE_HEADERS,
} from "../../../utils/constants";
import { CustomDropDown } from "../../../components";

const Team = () => {

  const classes = styles();

  return (
    <Box className={classes.container}>
      <Box className={classes.componentContainer}>
        <Box className={classes.flexBetween}>
          <Box>
            <h3 className={classes.mainTitle}>Team</h3>
            <p className={classes.callingPara}>
              Organize your users into groups for organizational, reporting, and
              partitioning purposes.
            </p>
          </Box>
          <Box className={classes.flex}>
            <h5 className={ classes.alignSelfCenter } >Status: </h5>
            <div className={classes.dropdownAllContain}>
              <CustomDropDown items={DROPDOWN_EMAIL_VIEWS} />
            </div>
          </Box>
        </Box>
        <Box>
          <CommonTable
            heads={SETTINGS_TEAM_TABLE_HEADERS}
            data={SETTINGS_TEAMS_DATA}
            imageTpye="initials"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Team;

import { ACCOUNT_DATA_FETCH_ERROR, ACCOUNT_DATA_FETCH_REQUEST, ACCOUNT_DATA_FETCH_SUCCESS, ACCOUNT_TABLE_DATA_FETCH_ERROR, ACCOUNT_TABLE_DATA_FETCH_REQUEST, ACCOUNT_TABLE_DATA_FETCH_SUCCESS } from "../constants/accountConstant";

export const accountlistReducer = (state = {}, action) => {
    switch (action.type) {
      case ACCOUNT_TABLE_DATA_FETCH_REQUEST:
        return { loading: true };
  
      case ACCOUNT_TABLE_DATA_FETCH_SUCCESS:
        return { loading: false, columns: action.payload, totalRecords: action.totalRecords };
  
      case ACCOUNT_TABLE_DATA_FETCH_ERROR:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  }; 

  export const companyinfodataReducer = (state = {}, action) => {
    switch (action.type) {
      case ACCOUNT_DATA_FETCH_REQUEST:
        return { loading: true };
  
      case ACCOUNT_DATA_FETCH_SUCCESS:
        return { loading: false, payload: action.payload };
  
      case ACCOUNT_DATA_FETCH_ERROR:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { RED_COLOR } from '../../../theme/colors';

const SwitchButton = styled(Switch)(({ theme }) => ({
  width: 65,
  height: 35,
  padding: 7,
  alignSelf: "center",
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(30px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: "ON",
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: "#FFE9E9",
        border: `1px solid ${RED_COLOR}`,
      },
    },
  },
  '& .MuiSwitch-thumb': {

    backgroundColor: theme.palette.mode === 'dark' ? RED_COLOR : RED_COLOR,
    width: 28,
    height: 28,
    marginTop: "2px",
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      background: "ON"
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: "#FFE9E9",
    border: `1px solid ${RED_COLOR}`,
    borderRadius: 20 / 2,
  },
}));


export default SwitchButton;
import React from 'react';

const ErrorMessage = ({ message, marginBottom }) => {
    return <p style={{
        fontSize:"12px",
        fontWeight:"600",
        color:"#ff4848",
        marginBottom: marginBottom ? marginBottom : "1rem",
        padding:"3px",
        paddingBottom:0
    }} >
    {message}
    </p>
}
export default ErrorMessage;
import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CustomIcon from "../Icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import { FormControl, MenuItem, Select } from "@mui/material";


const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  justifyContent: "center",
});

const styles = makeStyles({
  navaigationBtn: {
    padding: "10px",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    cursor: "pointer",
    background: "white",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  selectedDot: {
    color: "#ff4848",
    borderRadius: "50%",
    border: "none",
    background: "#FFE9E9",
    width: "32px",
    height: "32px",
    margin: "0 5px ",
    cursor: "pointer",
    fontWeight: "700",
  },
  normalDot: {
    background: "transparent",
    border: "none",
    color: "#212833",
    margin: "0 8px",
    cursor: "pointer",
    fontWeight: "700",
  },
  selectTag: {
    margin: "5px 10px 5px 10px",
  },
  nav: {
    padding: "2px 0",
    position:"absolute",
    width:"calc(100% - 6rem)",
    bottom:"0",
    background:"#f7f9fb",
    '@media only screen and (max-width: 1400px)': {
      bottom:"-15px",
    },

  },
  MenuItem: {
    fontWeight: "400",
  },
});

const getPaginationCount = (records, recordPerPage = 10) => {
  return Math.ceil(records / recordPerPage);
};

export default function GlobalPagination({ defaultPage, totalRecords, recordPerPage,type, action  }) {
  
  const count = totalRecords ? getPaginationCount(totalRecords,recordPerPage) : 10;
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [age, setAge] = React.useState(recordPerPage);

  
  const handleChange = (event) => {
    
    
    setAge(event.target.value);
    dispatch(action(type || {},{limit: event?.target?.value , page:1}));
   
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...type,
        limit: event?.target?.value, page:1
      }).toString(),
    });
  };


   

  const { items } = usePagination({
    page: Number( defaultPage ) || 2,
    count: count,
    defaultPage: Number(defaultPage) || 1,
    boundaryCount: 2,
  });
  
  

  const handleChangePage = (page, event) => {
    if (event) {
      event();
    }
    
    dispatch(action(type || {},{limit: age, page: Number(page) }));
   
   
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
       ...type,
        page: page,
      }).toString(),
    });
  };

 


  return (
    <nav className={ classes.nav } >
      <List>
        {/* here dropdown */}
        
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                // className={classes.countBtn}
                style={{
                  fontWeight: selected  ? "bold" : undefined,
                  color: selected ? "#ff4848" : undefined,
                }}
                className={ selected ? classes.selectedDot : classes.normalDot }
                {...item}
                onClick={() => handleChangePage(page)}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button
                type="button"
                {...item}
                style={{
                  cursor:
                    type === "previous" && defaultPage === 1
                      ? "no-drop"
                      : undefined,
                }}
                className={classes.navaigationBtn}
                onClick={() => handleChangePage(page, item?.onClick)}
              >
                {type === "previous" ? (
                  <CustomIcon
                    name="fa fa-chevron-left"
                    color="#626b7e"
                    fontSize="12px"
                  />
                ) : (
                  <CustomIcon
                    name="fa fa-chevron-right"
                    color="#626b7e"
                    fontSize="12px"
                  />
                )}
              </button>
            );
          }

          return (
            <li key={index} className={ classes.flexAlignCenter } >
              {children}
            </li>
          );
        })}
         <FormControl size="small" >
          <Select
            className={classes.selectTag}
            value={age}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={10} > <p className={ classes.MenuItem } >10</p> </MenuItem>
            <MenuItem value={25}><p className={ classes.MenuItem } >25</p></MenuItem>
            <MenuItem value={50}><p className={ classes.MenuItem } >50</p></MenuItem>
            <MenuItem value={100}><p className={ classes.MenuItem } >100</p></MenuItem>
          </Select>
        </FormControl>
      </List>
    </nav>
  );
}

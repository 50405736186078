import Router from "../Router";
import { ThemeProvider } from "@mui/private-theming";
import { Theme } from "../theme";
import { SnackbarProvider } from "notistack";
import { AUTO_HIDE_DURATION, SNACKBAR_CONFIG } from "../utils/constants";
import { SNACKBAR_ERROR_ICON, SNACKBAR_SUCCESS_ICON } from "../utils/constants/assets";
import { SnackbarIcon } from "../components/Snackbar";
import { useStyles } from "./styles";
import { BrowserRouter } from "react-router-dom";

export const Container = () => {

  const theme = Theme();
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        classes={{
          variantError: classes.error,
          variantSuccess: classes.success,
        }}
        anchorOrigin={SNACKBAR_CONFIG.snackBarPlacement}
        autoHideDuration={AUTO_HIDE_DURATION}
        iconVariant={{
          success: <SnackbarIcon src={SNACKBAR_SUCCESS_ICON} />,
          error: <SnackbarIcon src={SNACKBAR_ERROR_ICON} />,
        }}
      >
        <BrowserRouter>
          <Router />
        </BrowserRouter>

      </SnackbarProvider>
    </ThemeProvider>
  );
};

import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import {

  FUTUNEAR_IMAGE,
} from "../../utils/constants/assets";
import { styles } from "./styles";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import {
  DASHBOARD_PATH,
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  USER_PATH,


} from "../../utils/constants/routes";
import { emailValidation, passwordValidation } from "../../utils/validations";
import { useLocation } from "react-router-dom";
import {

  CircularLoader,
  CustomTextField,


} from "../../components";
import {
  authEndpoints,

} from "../../utils/constants/httpConstants";
import ErrorMessage from "../../components/Messages/ErrorMessage";
import { useSelector, useDispatch } from "react-redux";
import {

  userDetails,

} from "../../redux/actions/authActions";
import {
  EMITTER_ERROR,
  EMITTER_SUCCESS,
  LOCAL_STORAGE_ADVANCE_EXPORT,
} from "../../utils/constants";

import { decryptEmail, filterQuery } from "../../utils/helpers";
import { POST } from "../../Apis/apiWrapper";
import EventEmitter from "../../utils/emitter/EventEmitter";
import axios from "axios";
import { STRINGS } from "./strings";
import TypographyInline from "../../components/Typography/TypographyInline";
import { GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import InputField from "../../components/InputFields/InputField";
import { Button } from "../../components/Buttons";


const LoginScreen = () => {
  const params = useParams();
  const classes = styles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = location?.pathname?.trim()?.split("/")[1];


  const [loading, setLoading] = useState(false);
  let defaultEmail = params?.email;
  const allFiltersSelector = useSelector((state) => state.allFilters?.payload);
  const filteredQuery = filterQuery(allFiltersSelector);

  if (defaultEmail) {
    const decryptedEmail = decryptEmail(params?.email?.trim());
    defaultEmail = decryptedEmail;
  }
  const [email, setEmail] = useState(defaultEmail ? defaultEmail : "");
  const [password, setPassword] = useState("");
  const [validations, setValidations] = useState({ email: "", password: "" });


  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validatedEmail = emailValidation(email);
    const validatedPassword = passwordValidation(password);
    if (validatedEmail.isValid && validatedPassword.isValid) {
      //Here trigger the event
      setValidations({
        email: "",
        password: "",
      });
      setLoading(true);

      let formdata = {
        email: email,
        password: password,
      };
      try {
        const response = await POST(authEndpoints.login, formdata);
        if (response?.status === 200) {
          EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
          dispatch(userDetails(response?.data?.data));
          localStorage.setItem(
            LOCAL_STORAGE_ADVANCE_EXPORT,
            response?.data?.data?.advance_export || false
          );
          navigate({
            pathname: `/${token}${USER_PATH}`,
            search: createSearchParams({
              ...filteredQuery,
            }).toString(),
          });
        }
      } catch (err) {
        EventEmitter.emit(
          EMITTER_ERROR,
          err?.data?.message || "Something went wrong!"
        );
        setLoading(false);
        // here error;
      }
    } else {
      setValidations({
        email: validatedEmail.message,
        password: validatedPassword.message,
      });
    }
  };

  const GetToken = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios
          .post(authEndpoints.checktoken, {
            _token: token,
          })

          .then((res) => {


            resolve(res.data.status);
          });
      } catch (e) {
        reject(false);
      }
    });
  };

  useEffect(() => {

    GetToken(token)
      .then((res) => { })
      .catch((err) => {

        navigate("/");
      });
  }, []);

  return (
    <Box component="main" className={classes.container}>
      <Box sx={{ width: 1 }} className={classes.leftContainer}>
        <Box sx={{ width: 1 }}>
          <div className={classes.containLogo}>
            <img src={FUTUNEAR_IMAGE} className={classes.logo} alt="" />
          </div>
          {/* Main auth form here */}
          <Box className={classes.card}>
            <h2 className={classes.login}> {STRINGS.LOGIN_IN_ACCOUNT}  </h2>
            <Box component="form" onSubmit={handleSubmit}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.EMAIL}
              />
              <div className={classes.m4Top}>
                <InputField
                  value={email}
                  size={"md40"}
                  variant={validations.email ? "error" : "default"}
                  errormessage={validations.email}
                  placeholder={STRINGS.ENTER_EMAIL}
                  onChange={(e) => handleEmailChange(e)}
                />
              </div>
              <div className={classes.mTop}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.PASSWORD}
              />
              <div className={classes.m4Top}>
                <InputField
                  value={password}
                  size={"md40"}
                  type="password"
                  variant={validations.password ? "error" : "default"}
                  errormessage={validations.password}
                  placeholder={STRINGS.ENTER_PASSWORD}
                  onChange={(e) => handlePasswordChange(e)}
                />
              </div>
              </div>

              <Box className={classes.containForgotPassword}>
                <span
                  className={classes.forgotPassword}
                  onClick={() => handleNavigate(`/${token}${FORGOT_PASSWORD_PATH}`)}
                >
                  {STRINGS.FORGOT_PASSWORD}
                </span>
              </Box>
              <Box className={classes.containLoginBtn}>
                <div style={{ width: "120px" }}>
                  <Button
                    variant={"primary"}
                    label={STRINGS.LOGIN}
                    size={"md40"}
                    isLoading={loading}
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginScreen;

import {QUARANTINEPREAPPROVE_GET_SUCCESS,QUARANTINEPREAPPROVE_GET_FAIL,QUARANTINEPREAPPROVE_GET_REQUEST, PREQUARANTINEPREAPPROVE_GET_REQUEST, PREQUARANTINEPREAPPROVE_GET_SUCCESS} from "../constants/QuarantinePreApproveConstatnts";

// import  from "../constants/QuarantinePreApproveConstatnts";
// import  from "../constants/QuarantinePreApproveConstatnts";

  
  export const QuarantinePreApproveDetail = (state = {}, action) => {
    switch (action.type) {
      case QUARANTINEPREAPPROVE_GET_REQUEST:
      return { loading: true };
  
      case QUARANTINEPREAPPROVE_GET_SUCCESS:

        return { loading: false,columns: action.payload, totalRecords: action.totalRecords };
       
  
      case QUARANTINEPREAPPROVE_GET_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };

  export const prequarantineUserDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case PREQUARANTINEPREAPPROVE_GET_REQUEST:
        return { loading: true };
  
      case PREQUARANTINEPREAPPROVE_GET_SUCCESS:
        return { loading: false, payload: action.payload, error: null };
  
      case PREQUARANTINEPREAPPROVE_GET_REQUEST:
        return { loading: false, error: action.payload, payload: null };
  
      default:
        return state;
    }
  };
  

 
 
  
import {
  CHECK_LOGIN_FAIL,
  CHECK_LOGIN_REQUEST,
  CHECK_LOGIN_SUCCESS,
  INVITED_USERS_LIST_FAIL,
  INVITED_USERS_LIST_REQUEST,
  INVITED_USERS_LIST_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_MAIL_SEND_FAIL,
  RESET_PASSWORD_MAIL_SEND_REQUEST,
  RESET_PASSWORD_MAIL_SEND_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  USERS_LIST_FAIL,
  USERS_LIST_REQUEST,
  USERS_LIST_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  LOGIN_URL_REQUEST,
  LOGIN_URL_SUCCESS,
  LOGIN_URL_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
} from "../constants/authConstants";
import { axios } from "../../config";
import {
  authEndpoints,
  REGISTRATION_LINK,
} from "../../utils/constants/httpConstants";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, EMITTER_SUCCESS } from "../../utils/constants";
import { DEFAULT_ERROR_MESSAGE } from "../../utils/constants/messages";
import { POST, getRequest } from "../../Apis/apiWrapper";
import { LOGIN_PATH } from "../../utils/constants/routes";

export const registerUser = (formdata) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_REQUEST });
    let response = await axios.post(`/${authEndpoints.register}`, formdata);
    dispatch({ type: REGISTER_SUCCESS, payload: response?.data });
  } catch (err) {
    err?.response?.status >= 500 &&
      CustomEventEmitter.emit(
        EMITTER_ERROR,
        err?.response?.data?.message || err?.message || "server error"
      );
    dispatch({ type: REGISTER_FAIL, payload: err?.response?.data });
  }
};

export const tokenUrl = (formdata) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_URL_REQUEST });
    let response = await axios.post(`/${authEndpoints.token}`, formdata);
    CustomEventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

    dispatch({ type: LOGIN_URL_SUCCESS, payload: response });
  } catch (err) {
    dispatch({ type: LOGIN_URL_FAIL, payload: err?.response?.data });
    CustomEventEmitter.emit(EMITTER_ERROR, err?.response?.data.message);
  }
};

export const loginUser = (formdata) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    let response = await axios.post(`/${authEndpoints.login}`, formdata);

    dispatch({ type: LOGIN_SUCCESS, payload: response?.data });
    CustomEventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
  } catch (err) {
    CustomEventEmitter.emit(
      EMITTER_ERROR,
      err?.response?.data?.message || err?.message || "Sever error"
    );

    dispatch({ type: LOGIN_FAIL, payload: err?.response?.data });
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOGOUT_REQUEST });

    let response = await axios.post(`/${authEndpoints.logout}`);

    dispatch({ type: LOGOUT_SUCCESS, payload: response?.data });
    dispatch({
      type: LOGIN_FAIL,
      payload: { status: false, message: "User logged out" },
    });
    dispatch({
      type: CHECK_LOGIN_FAIL,
      payload: { status: false, message: "User logged out." },
    });

    CustomEventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
  } catch (err) {
    // err?.response?.status === 500 &&
    CustomEventEmitter.emit(
      EMITTER_ERROR,
      err?.response?.data?.message || err?.message || "Server error"
    );

    dispatch({ type: LOGOUT_FAIL, payload: err?.response?.data });
  }
};

// Refresh Token
export const checkLogin = () => async (dispatch) => {
  try {
    dispatch({ type: CHECK_LOGIN_REQUEST });

    // endpoint request here
    const response = await POST(authEndpoints.checkLogin);
    dispatch({ type: USER_DETAILS_SUCCESS, payload: response?.data?.data });
    dispatch({ type: CHECK_LOGIN_SUCCESS, payload: response.data });
  } catch (err) {
    // if called from login page, then don't emit this message.
    // CustomEventEmitter.emit(
    //   EMITTER_ERROR,
    //   err?.response?.data?.message || err?.message || DEFAULT_ERROR_MESSAGE
    // );
    dispatch({ type: CHECK_LOGIN_FAIL, payload: err });
  }
};

export const userDetails = (payload) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: payload });
  } catch (err) {
    dispatch({ type: USER_DETAILS_FAIL, payload: err });
  }
};

export const verifyDomain = (subDomain) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    // endpoint request here
    const response = await axios.post(authEndpoints.verifyDomain, {
      sub_domain: subDomain,
    });
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
  } catch (err) {
    CustomEventEmitter.emit(
      EMITTER_ERROR,
      err?.response?.data?.message || err?.message || DEFAULT_ERROR_MESSAGE
    );
    if (err?.response?.status === 422) {
      setTimeout(() => {
        window.location.href = REGISTRATION_LINK;
      }, 1000);
    }
    dispatch({ type: RESET_PASSWORD_FAIL, payload: err?.response?.data });
  }
};
// Forgot password action

export const resetPasswordMailSend = (email) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_MAIL_SEND_REQUEST });
    let response = await axios.post(authEndpoints.sendMailForResetPassword, {
      email: email,
    });
    dispatch({
      type: RESET_PASSWORD_MAIL_SEND_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: RESET_PASSWORD_MAIL_SEND_FAIL,
      payload: err?.response?.data,
    });
  }
};

export const resetPassword = (formdata) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    // endpoint request here
    const response = await axios.post(authEndpoints.resetPassword, formdata);
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response });
    CustomEventEmitter.emit(
      EMITTER_SUCCESS,
      response?.data?.message || "password updated"
    );
  } catch (err) {
    // if(  )
    CustomEventEmitter.emit(
      EMITTER_ERROR,
      err?.response?.data?.data?.errors?.password || err?.message
    );

    dispatch({ type: RESET_PASSWORD_FAIL, payload: err?.response?.data });
  }
};

// User Invitaion and users actions

export const invitedUsersList = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: INVITED_USERS_LIST_REQUEST });
    const response = await getRequest(navigate, authEndpoints.invitedUsersList);

    dispatch({
      type: INVITED_USERS_LIST_SUCCESS,
      payload: response?.data,
    });
  } catch (err) {
    if (err?.status === 401) {
      // navigate(LOGIN_PATH);
    }

    dispatch({ type: INVITED_USERS_LIST_FAIL, payload: err });
  }
};

export const usersList = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: USERS_LIST_REQUEST });
    const response = await getRequest(navigate, authEndpoints.userList);

    dispatch({
      type: USERS_LIST_SUCCESS,
      payload: response?.data,
    });
  } catch (err) {
    if (err?.status === 401) {
      // navigate(LOGIN_PATH);
    }

    dispatch({ type: USERS_LIST_FAIL, payload: err });
  }
};

export const updateProfile = (formdata) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    let response = await axios.post(
      `/${authEndpoints.updateprofile}`,
      formdata
    );
    CustomEventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);

    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: response });
  } catch (err) {
    dispatch({ type: UPDATE_PROFILE_FAIL, payload: err?.response?.data });
    CustomEventEmitter.emit(EMITTER_ERROR, err?.response?.data.message);
  }
};

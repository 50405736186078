import React, { useEffect, useState } from "react";
import { BASE, BORDER, ERROR, GREY } from "../../utils/constants/colors";
import { fontSize, fontWeight, lineHeight, SHADOW } from "../../utils/constants/UI";
import { borderRadius } from "../../utils/constants/UI/uiConstants";
import IconComponent from "../Icon/IconComponent";
import Typography from "../Typography/Typography";
import { STRINGS } from "./strings";
import classes from './Input.module.css'

const SIZE_MAPPING = {
  xs34: {
    padding: "8.5px 10px 8.5px 0px",
    fontSize: fontSize.XS,
    fontWeight: fontWeight.REGULAR,
    lineHeight: lineHeight.XS,
    iconSize: fontSize.MD,
  },
  xs36: {
    padding: "9.5px 10px 9.5px 0px",
    fontSize: fontSize.XS,
    fontWeight: fontWeight.REGULAR,
    lineHeight: lineHeight.XS,
    iconSize: fontSize.MD,
  },
  sm36: {
    padding: "8.5px 10px 8.5px 0px",
    fontSize: fontSize.SM,
    fontWeight: fontWeight.REGULAR,
    lineHeight: lineHeight.SM,
    iconSize: fontSize.MD,
  },
  md40: {
    padding: "10.5px 10px 10.5px 0px",
    fontSize: fontSize.SM,
    fontWeight: fontWeight.REGULAR,
    lineHeight: lineHeight.SM,
    iconSize: fontSize.MD,
  },
  lg44: {
    padding: "11px 10px 11px 0px",
    fontSize: fontSize.MD,
    fontWeight: fontWeight.REGULAR,
    lineHeight: lineHeight.MD,
    iconSize: fontSize.XL,
  },
  default: {
    backgroundColor: BASE.WHITE,
    border: `1px solid ${BORDER.HOVER}`,
  },
  focused: {
    backgroundColor: BASE.WHITE,
    border: `1px solid ${GREY.PRIMARY}`,
    boxShadow: SHADOW.input,
  },
  filled: {
    boxShadow: "",
    backgroundColor: BASE.WHITE,
    border: `1px solid ${BORDER.DARK}`,
  },
  error: {
    boxShadow: "",
    backgroundColor: BASE.WHITE,
    border: `1px solid ${ERROR.W_500}`,
  },
  disabled: {
    backgroundColor: BORDER.DISABLED,
    border: `1px solid ${BASE.TRANSPARENT}`,
  },
};

const InputField = ({ type, size, LeftIcon, RightIcon, variant,disabled, errormessage = "", ...props }) => {

  const [inputState, setInputState] = useState(variant || "default");
  const [errorMessage, setErrorMessage] = useState("");
  // const classes = styles({ inputState, size, LeftIcon, RightIcon, ...props });

  const styles = {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      width: "100%",
    },
    inputField: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      border: "none",
      height: "100%",
      borderRadius: borderRadius.primary,
      color: GREY.SECONDARY,
      fontWeight: SIZE_MAPPING[size]?.fontWeight,
      fontSize: SIZE_MAPPING[size]?.fontSize,
      lineHeight: SIZE_MAPPING[size]?.lineHeight,
      padding: SIZE_MAPPING[size]?.padding,
      fontStyle: "normal",
      cursor: inputState === "disabled" && "not-allowed",
      textTransform: (props?.iscapitalize ? "capitalize" : "none"),
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
      "&::placeholder": {
        color: GREY.TERTIARY,
      },
    },
    iconStyle: {
      fontSize: `${SIZE_MAPPING[size]?.iconSize}`,
      color: ( disabled ? GREY.DISABLED : GREY.TERTIARY),
    },
    rightIconStyle: {
      fontSize: `${SIZE_MAPPING[size]?.iconSize}`,
      color: ( disabled ? GREY.DISABLED : GREY.SECONDARY),
    },
    xs34: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      borderRadius: borderRadius.primary,
      padding: (props?.isDomain ? "0px 0px 0px 10px" : "0px 10px"),
      boxShadow: inputState === "focused" &&
        SIZE_MAPPING[inputState].boxShadow,
      border: SIZE_MAPPING[inputState]?.border,
      cursor: inputState === "disabled" ? "not-allowed" : "pointer",
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
     
    },
    xs36: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      borderRadius: borderRadius.primary,
      padding: (props?.isDomain ? "0px 0px 0px 10px" : "0px 10px"),
      boxShadow: inputState === "focused" &&
        SIZE_MAPPING[inputState].boxShadow,
      cursor: inputState === "disabled" ? "not-allowed" : "pointer",
      border: SIZE_MAPPING[inputState]?.border,
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
     
    },
    sm36: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      borderRadius: borderRadius.primary,
      padding: (props?.isDomain ? "0px 0px 0px 10px" : "0px 10px"),
      boxShadow: inputState === "focused" &&
        SIZE_MAPPING[inputState].boxShadow,
      cursor: inputState === "disabled" ? "not-allowed" : "pointer",
      border: SIZE_MAPPING[inputState]?.border,
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
     
    },
    md40: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      borderRadius: borderRadius.primary,
      padding: (props?.isDomain ? "0px 0px 0px 10px" : "0px 10px"),
      boxShadow: inputState === "focused" &&
        SIZE_MAPPING[inputState].boxShadow,
      border: SIZE_MAPPING[inputState]?.border,
      cursor: inputState === "disabled" ? "not-allowed" : "pointer",
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
     
    },
    lg44: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      borderRadius: borderRadius.primary,
      padding: (props?.isDomain ? "0px 0px 0px 10px" : "0px 10px"),
      boxShadow: inputState === "focused" &&
        SIZE_MAPPING[inputState].boxShadow,
      border: SIZE_MAPPING[inputState]?.border,
      cursor: inputState === "disabled" ? "not-allowed" : "pointer",
      backgroundColor: SIZE_MAPPING[inputState]?.backgroundColor,
     
    },
    domainLabelContainer: {
      padding: "8px 10px",
      backgroundColor: BASE.BACKGROUND_LIGHT,
      borderRadius: `${borderRadius.none} ${borderRadius.primary} ${borderRadius.primary} ${borderRadius.none}`,
    },
  };

  useEffect(() => {
    setInputState(variant || "default");
  }, [variant]);

  useEffect(() => {
    if (errormessage?.length) {
      setErrorMessage(errormessage);
    }
  }, [errormessage]);

  const inputHandler = (variantVal, inputValue) => {
    if (variantVal === "blurred" && variant !== "error") {
      if (inputValue) {
        setInputState("filled");
      } else {
        setInputState("default");
      }
    } else {
      if (variant !== "error") {
        setInputState(variantVal);
      }
    }
  };

  return (
    <div style={styles.mainContainer}>
      <div style={styles[size]} className={ classes[inputState] } >
        {LeftIcon && (
          <IconComponent
            iconLabel={LeftIcon}
            style={styles.iconStyle}
            onClick={props?.leftOnClick}
          />
        )}
        <input
          autoComplete={type === 'text' ? "off" : "on"}
          id="inputField"
          type={type || "text"}
          style={styles.inputField}
          onFocus={() => inputHandler("focused")}
          onBlur={(event) => inputHandler("blurred", event.target.value)}
          disabled={variant === "disabled"}
          {...props}
        />
        {RightIcon && (
          <IconComponent
            iconLabel={RightIcon}
            style={styles.rightIconStyle}
            onClick={props?.rightOnClick}
          />
        )}
        {props?.isDomain && (
          <div style={styles.domainLabelContainer}>
            <Typography
              color={GREY.TERTIARY}
              fontWeight={fontWeight.REGULAR}
              size={"sm"}
              label={STRINGS.DOMAIN_LABEL}
            />
          </div>
        )}
      </div>
      {variant === "error" && errorMessage && (
        <Typography
          label={errorMessage}
          color={ERROR.W_500}
          size={"xs"}
          fontWeight={fontWeight.REGULAR}
        />
      )}
    </div>
  );
};

export default InputField;

// ------------- NOTE ----------------
// This component should only be used for text, passowrd and email type.
// If there is need to add color or any other attribute in InputField then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props Description:
// type :- This is used for type of input, default input state is text.
// size :- This is used for size of input mentioned in Figma.
// LeftIcon :- Icon displayed on the left of input field.
// RightIcon :- Icon displayed on the right of input field.
// variant :- This will mainly used for only two states of input. And this two states are error and disabled state.
// leftOnClick : This prop will contain onClick Function on the left Icon if needed.
// rightOnClick : This prop will contain onClick Function on the right Icon if needed.
// errorMessage: Error message you want to display.
// isDomain: Used for dispaying domain name at the end of the field.

import { createTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { darkModePalette, lightModePalette } from "./Palette";

export const Theme = () => {
  const mode = useSelector((state) => state?.modeChange?.mode);

  return createTheme({
    palette: mode === "dark" ? darkModePalette : lightModePalette,
  });
};

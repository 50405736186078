import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { FUTUNEAR_IMAGE } from "../../utils/constants/assets";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";

import { emailValidation } from "../../utils/validations";
import {
  CircularLoader,
  CustomTextField,
} from "../../components";

import ErrorMessage from "../../components/Messages/ErrorMessage";
import { useSelector, useDispatch } from "react-redux";
import { tokenUrl } from "../../redux/actions/authActions";
import { STRINGS } from "./strings";
import TypographyInline from "../../components/Typography/TypographyInline";
import { GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import InputField from "../../components/InputFields/InputField";
import { Button } from "../../components/Buttons";

const LoginToken = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [validations, setValidations] = useState({ email: "" });
  const token = useSelector((state) => state.token);
  const { loading } = token;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validatedEmail = emailValidation(email);

    if (validatedEmail.isValid) {
      //Here trigger the event
      setValidations({
        email: "",
      });


      let formdata = {
        email: email,
      };
      try {
        dispatch(tokenUrl(formdata));
        setEmail("")

      } catch (err) {


      }
    } else {
      setValidations({
        email: validatedEmail.message,
      });
    }
  };

  return (
    <Box component="main" className={classes.container}>
      <Box sx={{ width: 1 }} className={classes.leftContainer}>
        <Box sx={{ width: 1 }}>
          <div className={classes.containLogo}>
            <img src={FUTUNEAR_IMAGE} className={classes.logo} alt="" />
          </div>
          {/* Main auth form here */}
          <Box className={classes.card}>
            <Box component="form" onSubmit={handleSubmit}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={STRINGS.EMAIL}
              />
              <div className={classes.m4Top}>
                <InputField
                  value={email}
                  size={"md40"}
                  variant={validations.email ? "error" : "default"}
                  errormessage={validations.email}
                  placeholder={STRINGS.ENTER_EMAIL}
                  onChange={(e) => handleEmailChange(e)}
                />
              </div>

              <Box className={classes.containLoginBtn}>
                <div style={{ width: "120px" }}>
                  <Button
                    variant={"primary"}
                    label={STRINGS.GET_URL}
                    size={"md40"}
                    isLoading={loading}
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginToken;

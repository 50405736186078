import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  Slide,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RESET_ICON } from "../../utils/constants/assets";

import ImageIcon from "../Icon/ImageIcon";
import { RedShadowButton } from "..";
import { CloseIcon } from "../Icon/MuiIcons";
import LinearLoader from "../Loaders/LinearLoader";

const styles = makeStyles({
  root: {
    width: "500px",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    backgroundColor: "white",
    border: "none",
    width: "25vw",
    borderRadius: "4px",
    padding: "1rem",
  },
  icon: {
    color: "#626B7E",
  },
  containCross: {
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  texth4: {
    color: "#212833",
    fontSize: "16px",
    fontWeight: "600",
  },
  para: {
    fontSize: "12px",
    fontWeight: "300",
    letterSpacing: "0.6px",
  },
  containText: {
    padding: "0.4rem",
  },
  containBtns: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem 0",
    paddingBottom: 0,
  },
  confirmTitle: {
    fontWeight: "600",
    letterSpacing: "0.6px",
    color: "#0F2F51",
    paddingBottom: "0.4rem",
    fontSize: "clamp(18px,1.042vw,22px)",
    paddingRight:"clamp(24px,2vw,40px)"
  },
  subTitle: {
    
     
    fontSize: "clamp(14px,0.833vw,18px)",
   color:"#626B7E",
   fontWeight:"400"
  },
  span: {
    alignSelf: "center",
    cursor: "pointer",
    fontSize: "14px",
    color: "#626B7E",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide  direction="down" ref={ref} {...props} timeout={{ enter: 500, exit: 500 }}/>;
});

const GlobalModal = (props) => {
  const classes = styles();

  return (
    <Dialog
      BackdropProps={{
        sx: { backgroundColor: "rgba(0,0,0,0.75)" },
      }}
      PaperProps={{ sx: { backgroundColor: "#fff" } }}
      open={props.open}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"lg"}
    >
      <IconButton
        aria-label="close"
        onClick={props.close}
        sx={{
          position: "absolute",
          right: 8,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon sx={{ width: "20px", height: "20px" }} />
      </IconButton>

      <DialogContent sx={{ minWidth: "500px", borderBottom: "1px solid rgba(0,0,0,.24)" }}>
        <DialogContentText id="alert-dialog-slide-description">
          <h4 className={classes.confirmTitle}> {props.detail}</h4>
          <p className={classes.subTitle}>{props.subdetail}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ py: 2.5 }}>
        <Button
          onClick={props.close}
          sx={{
            color: "#626B7E",
            ml: 2,
            py: 1.5,
            fontFamily:"inter",
            px: 2,
            width: "111px",
            height: "41px",
            textTransform: "none",
            border: "1px solid #626B7E",
            borderRadius: "6px",
            "&:hover": { backgroundColor: "#ccc" },
          }}
        >
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: "#ff4848",
            color: "#fff",
            fontFamily:"inter",
            width: "111px",
            height: "41px",
            ml: 2,
            py: 1.5,
            px: 2,
            textTransform: "none",
            borderRadius: "6px",
            "&:hover": { backgroundColor: "#ff4848" },
          }}
          onClick={props.update}
        >
        Update
        </Button>
      </DialogActions>
    </Dialog>

    // <Modal
    //   open={props.open}
    //   onClose={props.handleClose}
    //   TransitionComponent={Transition}
    // >
    //   <div className={classes.modal}>
    //     {/* <div className={classes.containCross}>
    //       <CloseOutlinedIcon onClick={props.close} className={classes.icon} />
    //     </div> */}
    //     {/* <div className={classes.containResetIcon}>
    //       <ImageIcon image={RESET_ICON} />
    //     </div> */}

    //     <div className={classes.containText}>
    //       <h4 className={classes.confirmTitle}> {props.detail}</h4>

    //       {/* <p className={classes.para}>{props.detail}</p> */}
    //     </div>
    //     <div className={classes.containBtns}>
    //       <Button
    //         onClick={props.close}
    //         type="reset"
    //         sx={{
    //           backgroundColor: "#ccc",
    //           color: "#626B7E",
    //           ml: 2,
    //           py: 1,
    //           px: 2,
    //           width: "111px",
    //           height: "38px",
    //           textTransform: "none",
    //           "&:hover": { backgroundColor: "#ccc" },
    //         }}
    //       >
    //         Cancel{" "}
    //       </Button>
    //       <div>
    //         {props.update ? (
    //           <Button
    //             type="submit"
    //             sx={{
    //               backgroundColor: "#ff4848",
    //               color: "#fff",
    //               width: "111px",
    //               height: "38px",
    //               ml: 2,
    //               py: 0,
    //               px: 2,
    //               textTransform: "none",
    //               "&:hover": { backgroundColor: "#ff4848" },
    //             }}
    //             onClick={props.update}
    //           >
    //             Update
    //           </Button>
    //         ) : (
    //           ""
    //         )}
    //         {/* <RedShadowButton message={"Update"} onClick={props.update} /> */}
    //       </div>
    //     </div>
    //   </div>
    // </Modal>
  );
};

export default GlobalModal;

import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { BASE, BRAND, GREY } from "../../utils/constants/colors";
import { SHADOW } from "../../utils/constants/UI";
import {
  borderRadius,
  fontSize,
  fontWeight,
  lineHeight,
} from "../../utils/constants/UI/uiConstants";

const SIZE_MAPPING = {
  bottom: {
    // top: "0px",
    // left: "-10.094vw",
    margin: "0px !important",
  },
  light: {
    backgroundColor: `${BASE.WHITE} !important`,
    color: `${GREY.PRIMARY} !important`,
  },
  dark: {
    backgroundColor: `${BRAND.SECONDARY} !important`,
    color: `${BASE.WHITE} !important`,
  },
};

const styles = makeStyles({
  arrow: {
    color: (props) => SIZE_MAPPING[props?.theme]?.backgroundColor,
    "&:before": {
      boxShadow: `${SHADOW.md} !important`,
    },
  },
  popper: {
    zIndex: (props) => (props?.noZindex ? "20 !important" : null),
    "& .MuiTooltip-tooltip": {
      // position: "absolute",
      top: (props) => SIZE_MAPPING[props?.variant]?.top,
      left: (props) => SIZE_MAPPING[props?.variant]?.left,
      margin: (props) => SIZE_MAPPING[props?.variant]?.margin,
    },
  },
  tooltip: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "normal",
    wordBreak: "break-word",
    backgroundColor: (props) => SIZE_MAPPING[props?.theme]?.backgroundColor,
    boxShadow: `${SHADOW.md} !important`,
    color: (props) => SIZE_MAPPING[props?.theme]?.color,
    borderRadius: `${borderRadius.primary} !important`,
    padding: "8px 12px !important",
    fontSize: `${fontSize.XS} !important`,
    lineHeight: lineHeight.XS,
    fontWeight: `${fontWeight.MEDIUM} !important`,
    maxWidth: "15.625vw !important",
  },
});

const CustomTooltip = ({ theme = "dark", variant = "bottom", ...props }) => {
  const classes = styles({ theme, variant, props });
  return (
    <Tooltip
      arrow
      placement={variant}
      classes={{
        arrow: classes.arrow,
        popper: classes.popper,
        tooltip: classes.tooltip,
      }}
      {...props}
    />
  );
};

export default CustomTooltip;

// ------------- NOTE ----------------
// If there is need to add color or any other attribute in tooltip then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props description :-
// variant :- these are following values 'bottom-end'| 'bottom-start'| 'bottom'| 'left-end'| 'left-start'| 'left'| 'right-end'| 'right-start'| 'right'| 'top-end'| 'top-start'| 'top'.
// theme :- these also have two values either light or dark.

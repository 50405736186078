export const ICON_LABELS = {
  GOOGLE:"google",
  CONTENT_COPY_1: "content_copy",
  TODAY: "today",
  HOUR_GLASS_BOTTOM: "hourglass_bottom",
  SCHEDULE: "schedule",
  PUBLIC: "public",
  TIMER: "timer",
  CONTENT_COPY: "content_copy_rounded",
  // DONE: "done_rounded",
  FLAG:"flag",
  ADD: "add_rounded",
  FOLDER: "folder_rounded",
  MOVE: "drive_file_move_rounded",
  DELETEICON: "delete_outline_rounded",
  VIEW_ICON: "sort_rounded",
  ADD_WIDGET: "dashboard_customize_rounded",
  ACTION_ARROW: "keyboard_arrow_down_rounded",
  PERSON: "person_rounded",
  EVENT_NOTE: "event_note_rounded",
  SKIP_NEXT: "skip_next_rounded",
  CHECK_CIRCLE: "check_circle_rounded",
  ARROW_BACK: "arrow_back_rounded",
  VIEW_WEEK: "view_week_rounded",
  CHECK: "check_rounded",
  CLOUD_DOWNLOAD: "cloud_download_rounded",
  MAIL_LOCK: "mail_lock_rounded",
  LINK: "link_rounded",
  PUSH_PIN: "push_pin_rounded",
  DELETE: "delete_rounded",
  PAUSE: "pause",
  PLAY_ARROW: "play_arrow",
  DELETE_OUTLINE: "delete_outline_rounded",
  INSERT_DRIVE_FILE: "insert_drive_file_rounded",
  CALL: "call_rounded",
  EMAIL: "email_rounded",
  INSERT_LINK: "insert_link_rounded",
  RADIO_BUTTON_UNCHECKED: "radio_button_unchecked_rounded",
  NAVIGATE_NEXT: "navigate_next_rounded",
  HELP_OUTLINE: "help_outline_rounded",
  NOTIFICATIONS_NONE: "notifications_none_rounded",
  SETTINGS: "settings_rounded",
  CURRENCY_EXCHANGE: "currency_exchange_rounded",
  LOGOUT: "logout_rounded",
  LOGOUTS: "logout",
  ACCESS_TIME: "access_time_rounded",
  VISIBILITY_OFF: "visibility_off_rounded",
  VISIBILITY_ON: "visibility_on_rounded",
  BOOKMARK: "bookmark_rounded",
  SAVE: "save_rounded",
  CONTACT_PAGE: "contact_page_rounded",
  FILTER_ALT: "filter_alt_rounded",
  CLOSE_BUTTON: "close_button_rounded",
  DOWNLOAD: "download_rounded",
  VIEW_WEEK_ROUNDED: "view_week_rounded",
  ADD_CIRCLE_OUTLINE: "add_circle_outline_rounded",
  DRIVE_FILE_MOVE: "drive_file_move",
  SORT: "sort_rounded",
  DASHBOARD_CUSTOMIZE: "dashboard_customize",
  KEYBOARD_ARROW_DOWN: "keyboard_arrow_down",
  PERSON: "person",
  EVENT_NOTE: "event_note",
  MORE_VERT:"more_vert",
  SKIP_NEXT: "skip_next",
  CHECK_CIRCLE: "check_circle",
  ARROW_BACK: "arrow_back",
  VIEW_WEEK: "view_week",
  CHECK: "check",
  CLOUD_DOWNLOAD: "cloud_download",
  MAIL_LOCK: "mail_lock",
  KEYBOARD_ARROW_RIGHT: "keyboard_arrow_right_rounded",
  EDIT: "edit_rounded",
  FILE_UPLOAD: "file_upload_rounded",
  FACT_CHECK: "fact_check_rounded",
  TASK: "task_rounded",
  DNS_ROUNDED: "dns_rounded",
  DRAG_INDICATOR_ROUNDED: "drag_indicator_rounded",
  FILTER_LIST:"filter_list",
  FLAG:"flag", 
  GRID_VIEW: "grid_view_rounded",
  COMPLETED: "check_circle",
  DESCRIPTION: "description",
  DOUBLE_ARROW: "double_arrow",
  LIST_ALT: "list_alt",
  APARTMENT: "apartment_rounded",
  REFRESH: "refresh_rounded",
  MANAGE_SEARCH: "manage_search",
  CAMPAIGN: "campaign",
  TRENDING_UP: "trending_up",
  UPLOAD_FILE: "upload_file",
  VIDEO_CHAT_ROUDED: "video_chat_rounded",
  EXTENSION: "extension",
  TUNE: "tune_rounded",
  MORE_VERT: "more_vert_rounded",
  REMOVE_CIRCLE: "remove_circle_rounded",
  FIBER_MANUAL_RECORD: "fiber_manual_record_rounded",
  CREATE_NEW_FOLDER: "create_new_folder",
  CLEAR_ALL: "clear_all_rounded",
  BUG_REPORT: "bug_report",
  CLOSE: "close_rounded",
  BUILD_CIRCLE: "build",
  VIEW_COLUMN: "view_column_rounded",
  PLAYLIST_ADD: "playlist_add_rounded",
  POST_ADD: "post_add_rounded",
  DOWNLOAD_FOR_ONLINE: "download_for_offline_rounded",
  RESTART_ALT: "restart_alt_rounded",
  ARROW_UP: "arrow_upward",
  ARROW_DOWN: "arrow_downward",
  CANCEL: "cancel_rounded",
  DONE: "done",
  STICKY_NOTE: "sticky_note_2",
  REPLY: "reply",
  LINKEDIN: "linked_in",
  LOCK_PERSON: "lock_person_rounded",
  FACEBOOK: "facebook_rounded",
  TWITTER: "twitter",
  GROUPS: "groups_rounded",
  WORK: "work_rounded",
  KEY: "key_rounded",
  MONETIZATION_ON: "monetization_on_rounded",
  LOCATION_ON: "location_on_rounded",
  LANGUAGE: "language_rounded",
  VIEW:"visibility",
  ADD_USER:"personAdd",
  MAIL_ICON:"mail",
  COMPANY:"apartment",
  KEYBOARD_ARROW_UP: "keyboard_arrow_up",
  DONE:""
  
};

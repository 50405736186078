import React from 'react'
import { Styles } from '../Styles';
import CustomModal from '../../../components/Modals/CustomModal'
import { STRINGS } from '../strings';
import TypographyInline from '../../../components/Typography/TypographyInline';
import InputField from '../../../components/InputFields/InputField';
import { GREY } from '../../../utils/constants/colors';
import { fontSize, fontWeight, ICON_LABELS } from '../../../utils/constants/UI';
import parse from "html-react-parser";
import IconComponent from '../../../components/Icon/IconComponent';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';

const ViewBusinessModal = ({ open, setOpen, data }) => {
    const classes = Styles();

    const modalHeaderData = {
        heading: {
            id: STRINGS.TITLE,
            label: `Claim Business`,
        },
    };

    const modalBodyData = (
        <div className={classes.headerPreview}>
            <div className={classes.displayData}>
                <div className={classes.widthData}>
                    <TypographyInline
                        size={"sm"}
                        color={GREY.QUATINARY}
                        fontWeight={fontWeight.SEMIBOLD}
                        label={`${STRINGS.COMPANY_NAME}:`}
                    />
                </div>
                <div className={classes.widthData1}>
                    <InputField
                        size={"sm36"}
                        value={data?.company_name}
                        variant={"disabled"}
                    />
                </div>
            </div>
            <div className={classes.displayData}>
                <div className={classes.widthData}>
                    <TypographyInline
                        size={"sm"}
                        color={GREY.QUATINARY}
                        fontWeight={fontWeight.SEMIBOLD}
                        label={`${STRINGS.COMPANY_EMAIL}:`}
                    />
                </div>
                <div className={classes.widthData1}>
                    <InputField
                        size={"sm36"}
                        value={data?.company_email || ""}
                        variant={"disabled"}
                    />
                </div>
            </div>
            <div className={classes.displayData}>
                <div className={classes.widthData}>
                    <TypographyInline
                        size={"sm"}
                        color={GREY.QUATINARY}
                        fontWeight={fontWeight.SEMIBOLD}
                        label={`${STRINGS.PHONE_NUMBER}:`}
                    />
                </div>
                <div className={classes.widthData1}>
                    <InputField
                        size={"sm36"}
                        value={data?.phone_number}
                        variant={"disabled"}
                    />
                </div>
            </div>
            <div className={classes.displayData}>
                <div className={classes.widthData}>
                    <TypographyInline
                        size={"sm"}
                        color={GREY.QUATINARY}
                        fontWeight={fontWeight.SEMIBOLD}
                        label={`${STRINGS.DOCUMENTS_LINK}:`}
                    />
                </div>
                <div className={classes.widthData12}>
                    {
                        data?.files?.map((itm, indx) => {
                            return (
                                <div className={classes.docContainer}>
                                    <a href={itm}>
                                        <IconComponent
                                            iconLabel={ICON_LABELS.CLOUD_DOWNLOAD}
                                            color={GREY.TERTIARY}
                                            fontSize={fontSize.DLG}
                                        />
                                    </a>
                                    <CustomTooltip
                                        key={indx}
                                        title={itm}
                                    >
                                    <div className={classes.linkTextStyle}>
                                        {`${itm.substring(0, 12)}...`}
                                    </div>
                                </CustomTooltip>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )

    return (
        <CustomModal
            open={open}
            close={() => setOpen(false)}
            header={modalHeaderData}
            children={modalBodyData}
            width={"70vw"}
            isBackground={true}
        // remove_padding={true}
        />
    )
}

export default ViewBusinessModal
import { REQUEST_TEMPLATE_ERROR, REQUEST_TEMPLATE_REQUEST, REQUEST_TEMPLATE_SUCCESS } from "../constants/templateListConstant";

export const templateListReducer = (state = {}, action) => {
    switch (action.type) {
      case REQUEST_TEMPLATE_REQUEST:
        return { loading: true };
  
      case REQUEST_TEMPLATE_SUCCESS:
        return { loading: false, columns: action.payload, totalRecords: action.totalRecords };
  
      case REQUEST_TEMPLATE_ERROR:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
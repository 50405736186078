export const filterKeys = {
    job_title: "job_title",
    job_title_not:"job_title_not",
    industry:"industry",
    industry_not:"industry_not",
    employee_number:"employee_number",
    seniority:'seniority',
    company_keywords:"company_keywords",
    company_keywords_not:"company_keywords_not",
    company_technologies:"company_technologies",
    company_technologies_not:"company_technologies_not",
    seo_description:"seo_description",
    seo_description_not:'seo_description_not',
    email_verification:"email_verification",
    exist_fields:"exist_fields",
    not_exist_fields:"not_exist_fields",
    company_ids:"company_ids",
    company_ids_not:"company_ids_not"
}


export const filterQuarantineKey ={
    company_name :"company_name",
    job_title: "job_title",
    job_title_not:"job_title_not",
    industry:"industry",
    industry_not:"industry_not",
    employee_number:"employee_number",
    seniority:'seniority',
    company_keywords:"company_keywords",
    company_keywords_not:"company_keywords_not",
    company_technologies:"company_technologies",
    company_technologies_not:"company_technologies_not",
    seo_description:"seo_description",
    seo_description_not:'seo_description_not',
    email_verification:"email_verification",
    exist_fields:"exist_fields",
    not_exist_fields:"not_exist_fields",
    company_ids:"company_ids",
    company_ids_not:"company_ids_not"
}

// Path constants
// const token = window.location.pathname?.trim()?.split("/")[1]
// console.log("tokentokentoken",token);
export const HOME_PATH = "/";

export const LOGIN_PATH = "/login";
export const LOGIN_PATH_WITH_EMAIL = "/auth/login/:email";
export const REGISTER_PATH_WITH_INVITE = "/auth/register/:id";
export const SIGNUP_PATH = "/auth/signup";
export const FORGOT_PASSWORD_PATH = "/forgot_password";
export const EMAIL_VERIFICATION_PATH = "/auth/email_verify/:id";
export const FORGOT_PASSWORD_MAIL_SEND_PATH = "/resetpassword/:id";

export const PROFILE_PATH = "/user/profile";

export const PEOPLE_SCREEN_PATH = "/user/people";
export const COMPANY_SCREEN_PATH = "/user/company";
export const DASHBOARD_PATH = "/user/dashboard";
export const CONTENT_PATH = "/user/content";
export const USER_PATH = "/user";
export const EVENT_PATH = "/user/event";
//export const COMPANY_PATH = "/user/company";
export const CONTACT_PATH = "/user/contact";
export const TECHNOLOGY_PATH = "/user/technology";
export const TECHNOLOGY_WITHIN_PATH = "/user/technology/:id";
export const BUSINESS_PATH = "/user/business";


// Users routes
export const PLATEFORM_USER_PATH = "/user/platformuser";
export const PLATEFORM_USER_DEATIL_PATH = "/user/plateformuserdetail/:userid";
export const ACCOUNT_DETAIL_PATH = "/accountdetail/:accountid";
export const PLUGIN_USER_PATH = "/user/pluginuser";
export const PLATFORM_USER_DATA_PATH = "/user/:platformid";
export const PLUGIN_USER_DATA_PATH = "/user/plugin/:plugin";
export const PLUGIN_PATH = "/plugin";
export const ACCOUNT_PATH = "/account";
export const SEQUENCE_PATH = "/user/sequence";
export const QUARANTINE_PATH = "/quarantinedata";
export const QUARANTINE_COMPANY_PATH = "/quarantinecompanydata";
export const QUARANTINE_PREAPPROVED_PATH = "/quarantinepreapprovedata";

export const REQUESTCREDIT_PATH = "/requestcredit";
export const REQUESTPLUGINCREDIT_PATH = "/requestplugincredit";
export const REQUESTCREDIT_DATA_PATH = "/requestcredit/:requestdata";
export const REQUESTPLUGINCREDIT_DATA_PATH = "/requestcredit/requestplugincredit/:requestdata";
export const USERDATA_ACCESS_PATH = "/userdata/access";
export const USERDATA_REMOVE_PATH = "/userdata/remove";
export const CREDIT_DETAIL_PATH = "/creditdetail/:id";
export const PLUGIN_REFERAL_PATH = "/pluginreferal";
export const EMAIL_MARKETING_SCREEN_PATH = "/marketing";
export const CONTACT_LIST_PATH = "/contact";
export const EARLY_ACCESS_PATH = "/early-access-user";
export const FEED_FORM_PATH = "/feedback";
export const CSV_ENRICHMENT = "/csvenrichment";
import { axios } from "../config";
import { EMITTER_ERROR } from "../utils/constants";
import { authEndpoints } from "../utils/constants/httpConstants";
import EventEmitter from "../utils/emitter/EventEmitter";
import { LOGIN_PATH } from "../utils/constants/routes";

// Get Request
export const GET = async (url) => {
  try {
    return await axios.get(url);
  } catch (error) {
    throw error?.response;
  }
};
// Post request
export const POST = async (url, payload) => {
  try {

    return await axios.post(url, payload);
  } catch (error) {

    throw error?.response;
  }
};


// Protected Api's Wrapper
export const getRequest = async (navigate, url) => {
  const  token = window.location?.pathname?.trim()?.split("/")[1];
  let response;
  try {
    response = await axios.get(url);
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      try {
        await axios.post(authEndpoints.checkLogin);
        const res = await axios.get(url);
        return res;
      } catch (err) {
        if (err?.response?.status === 401) {
          // now user is logout;
          EventEmitter.emit( EMITTER_ERROR, (err?.response?.data?.message || err?.message))
          navigate(`/${token}${LOGIN_PATH}`);
        }
        throw err?.response;
      }
    }else{
      throw error?.response;
    }
  }
};

export const postRequest = async (navigate, url, payload) => {

const  token = window.location?.pathname?.trim()?.split("/")[1];
 
  let response;
  try {
    
    response = await axios.post(url, payload);
    return response;
  } catch (error) {
    console.log("update___",error)
    if (error?.response?.status === 401) {
      try   {
        await axios.post(authEndpoints.checkLogin);
        const res = await axios.post(url, payload);
        return res;
      } catch (err) {
        if (err?.response?.status === 401) {
          
          // now user is logout;
          EventEmitter.emit( EMITTER_ERROR, (err?.response?.data?.message || err?.message))
          navigate(`/${token}${LOGIN_PATH}`);
        }
        throw error?.response;
      }
    }else{
        throw error?.response;
    }
  }
}

export const putRequest = async (navigate, url, payload) => {

  const  token = window.location?.pathname?.trim()?.split("/")[1];

  let response;
  try {
    
    response = await axios.put(url, payload);
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      try   {
        await axios.post(authEndpoints.checkLogin);
        const res = await axios.put(url, payload);
        return res;
      } catch (err) {
        if (err?.response?.status === 401) {
          
          // now user is logout;
          EventEmitter.emit( EMITTER_ERROR, (err?.response?.data?.message || err?.message))
          navigate(`/${token}${LOGIN_PATH}`);
        }
        throw error?.response;
      }
    }else{
        throw error?.response;
    }
  }
}
export const patchRequest = async (navigate, url, payload) => {

  const  token = window.location?.pathname?.trim()?.split("/")[1];

  let response;
  try {
    
    response = await axios.patch(url, payload);
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      try   {
        await axios.post(authEndpoints.checkLogin);
        const res = await axios.patch(url, payload);
        return res;
      } catch (err) {
        if (err?.response?.status === 401) {
          
          // now user is logout;
          EventEmitter.emit( EMITTER_ERROR, (err?.response?.data?.message || err?.message))
          navigate(`/${token}${LOGIN_PATH}`);
        }
        throw error?.response;
      }
    }else{
        throw error?.response;
    }
  }
}

export const deleteRequest = async (url,payload) => {

  let response;
  try {
    response = await axios.delete(url, {data : payload});
    return response;
    
  } catch (error) {
    if (error?.response?.status === 401) {
      try   {
        await axios.post(authEndpoints.checkLogin);
        const res = await axios.delete(url, {data : payload});
        return res;
      } catch (err) {
        if (err?.response?.status === 401) {
          // now user is logout;
          EventEmitter.emit( EMITTER_ERROR, (err?.response?.data?.message || err?.message))
        }
        throw error?.response;
      }
    }else{
      throw error?.response;
    }
  }
}

import React from "react";
import { Container } from "./App/container";
import store from "./App/redux/store";
import { Provider } from "react-redux";
// lzsjdgsrghdfkjg
function App() {

  React.useEffect(()=>{

    // validate the refresh. 
    // window.onbeforeunload = function () {  return ''  } ;
  },[])

  return ( 
    <Provider className="App" store={store}>
      <Container />
    </Provider>
  );
}

export default App;

import axios from "axios";
import { EMITTER_ERROR } from "../../utils/constants";
import { authEndpoints } from "../../utils/constants/httpConstants";
import {
  ACCOUNT_DATA_FETCH_ERROR,
  ACCOUNT_DATA_FETCH_REQUEST,
  ACCOUNT_DATA_FETCH_SUCCESS,
  ACCOUNT_TABLE_DATA_FETCH_ERROR,
  ACCOUNT_TABLE_DATA_FETCH_REQUEST,
  ACCOUNT_TABLE_DATA_FETCH_SUCCESS,
} from "../constants/accountConstant";
import { postRequest } from "../../Apis";

export const accountListdata =  (sentQuery, navigate,noRefresh=false) => async (dispatch) => {
    try {
      dispatch({ type: ACCOUNT_TABLE_DATA_FETCH_REQUEST });
      const response = await postRequest(
        navigate,
        authEndpoints.accountlist,
        sentQuery,
       
      );
      dispatch({
        type: ACCOUNT_TABLE_DATA_FETCH_SUCCESS,
        payload: response?.data?.data,
        totalRecords: response?.data?.total_records,
      });
  
      
    } catch (err) {
      if (err?.status === 401) {
        
      }
    
      dispatch({ type: ACCOUNT_TABLE_DATA_FETCH_ERROR, payload: err });
    }
  }

export const companyinfoData = (tenantid) => async (dispatch) => {
  try {
    dispatch({ type: ACCOUNT_DATA_FETCH_REQUEST });

    // endpoint request here
    const response = await axios.get(
      `${authEndpoints.accountdetail}?tenant_id=${tenantid}`
    );
    dispatch({ type: ACCOUNT_DATA_FETCH_SUCCESS, payload: response });
  } catch (err) {
    dispatch({
      type: ACCOUNT_DATA_FETCH_ERROR,
      payload: err?.response?.data,
    });
  }
};

import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import { SHADOW } from "../../utils/constants/UI";
import { BORDER } from "../../utils/constants/colors";

const CustomStyledMenu = styled(({ marginTop, ...props }) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: props?.isRightAlign ? "left" : "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: props?.isRightAlign ? "left" : "right",
    }}
    onKeyDown={(e) => e.stopPropagation()}
    PaperProps={{
      sx: {
        width: props?.width,
        boxShadow: `${props?.shadow} !important` || `${SHADOW.tags} !important`,
        borderWidth: props?.arrow === true ? "1px" : "",
        borderStyle: props?.arrow === true ? "solid" : "",
        borderColor: props?.arrow === true ? BORDER.DEFAULT : "",
        marginTop:
          marginTop ||
          (props.isPeople && "32px !important") ||
          (props?.noMargin && "0px !important"),
        marginLeft:
          (props.isPeople && "60px !important") ||
          (props?.noMargin && "0px !important") ||
          (props?.isLeftStart && "-9rem !important"),
        overflow: props?.arrow === true ? "visible" : "hidden",
        "&:before": props?.arrow === true && {
          content: '""',
          display: "block",
          position: "absolute",
          top: 0,
          left: 14,
          width: 16,
          height: 16,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 1,
          borderWidth: "1px 0px 0px 1px",
          borderStyle: "solid",
          borderColor: BORDER.DEFAULT,
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    marginLeft: "5rem",
    boxShadow: SHADOW.tags,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],

    "& .MuiMenu-list": {
      padding: "0px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default CustomStyledMenu;

// ------------- NOTE ----------------
// Props description :-
// 1. width: used for menu width and initially it is set to 18vw.
// 2. shadow: used for boxShadow of menu initially it is set to SHADOW.lg
// 3. arrow: used to display arrow on top of menu.

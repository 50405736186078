import axios from "axios";
import {  BASE_URL, PORT } from "../../utils/constants/httpConstants";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_URL+PORT

// var numberOfAjaxCAllPending = 0;

// Add a request interceptor
// axios.interceptors.request.use(function (config) {
//     if( config.url === authEndpoints.checkLogin ){
//         numberOfAjaxCAllPending++;
//     }
//     console.log('Starting Request', numberOfAjaxCAllPending)
//     if( numberOfAjaxCAllPending >= 3 ){
//         const controller = new AbortController();
//         controller.abort('cancel this manually')
//         return { ...config, signal: controller.signal }
//     }
//     // show loader
//     return config;
// }, function (error) {
//     return Promise.reject(error);
// });

// // Add a response interceptor
// axios.interceptors.response.use(function (response) {
//     if( response?.config?.url  === authEndpoints.checkLogin ){
//         numberOfAjaxCAllPending--;
//     }
//     return response;
// }, function (error) {
//     if( error?.response?.config?.url === authEndpoints.checkLogin ){
//         numberOfAjaxCAllPending--;
//     }
//     if (numberOfAjaxCAllPending == 0) {
//         //hide loader
//     }
//     return Promise.reject(error);
// });

export default axios;

import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { styles } from "./styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { allFilters } from "../../redux/actions/filterActions";
import InputField from "../InputFields/InputField";
import { Button } from "../Buttons";
import { STRINGS } from "./strings";

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;

const FilterTypeCompanyRevenue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [tempMinRevenue, setTempMinRevenue] = useState(null);
  const allFiltersSelector = useSelector((state) => state.allFilters);
  const [value, setValue] = useState([
    parseInt(parseInt(allFiltersSelector?.payload?.min_revenue)) || 0,
    parseInt(parseInt(allFiltersSelector?.payload?.max_revenue)) || 200,
  ]);

  if (tempMinRevenue !== allFiltersSelector?.payload?.min_revenue) {
    setTempMinRevenue(allFiltersSelector?.payload?.min_revenue);
    setValue([
      parseInt(parseInt(allFiltersSelector?.payload?.min_revenue)) || 0,
      parseInt(parseInt(allFiltersSelector?.payload?.max_revenue)) || 200,
    ]);
  }

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  const handleChange = (e, index) => {
    let res = [];
    if (e?.target?.value <= 1000) {
      index === 0
        ? (res = [e?.target?.value, value[1]])
        : (res = [value[0], e?.target?.value]);
      setValue(res);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      allFilters({
        min_revenue: parseInt(value[0]),
        max_revenue: parseInt(value[1]),
      })
    );

    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...allFiltersSelector?.payload,
        min_revenue: parseInt(value[0]),
        max_revenue: parseInt(value[1]),
      }).toString(),
    });
  };

  const handleClearFilter = () => {
    const query = JSON.parse(JSON.stringify(allFiltersSelector?.payload));
    delete query?.min_revenue;
    delete query?.max_revenue;

    dispatch(allFilters(query, true));
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        ...query,
      }).toString(),
    });
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.filterContainer}>
        <Box sx={styles.sliderContainer}>
          <Box
            sx={{ ...styles.jobTitleHead, columnGap: "15px", marginBottom: "10px" }}
          >
            <label style={styles.sliderLabel}>
              {value[0]}M <strong>USD</strong>
            </label>

            <Slider
              sx={styles.slider}
              value={value}
              onChange={handleChange1}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              disableSwap
              min={0}
              max={1000}
            />
            <label style={styles.sliderLabel}>
              {value[1]}M <strong>USD</strong>
            </label>
          </Box>

          <Box sx={styles.containRevenueInputs}>
            <InputField
              size={"xs34"}
              value={value[0]}
              placeholder="0M USD"

              onChange={(e) => handleChange(e, 0)}
            />
            <InputField
              size={"xs34"}
              value={value[1]}
              placeholder="1000M USD"
              onChange={(e) => handleChange(e, 1)}
            />
          </Box>
          <Box
            sx={styles.containBtn}
            onSubmit={handleSubmit}
            component="form"
          >
            {/* <ResetBtn message="Clear" onClick={handleClearFilter} /> */}
            {allFiltersSelector?.payload?.max_revenue ? (
              // <ResetBtn message="Clear" onClick={handleClearFilter} />
              <div>
                <Button
                  label={STRINGS.CANCEL}
                  size={"xs28"}
                  variant={"ghost"}
                  onClick={handleClearFilter}
                />
              </div>
            ) : null}

            <div>
              <Button label={STRINGS.APPLY} size={"xs28"} variant={"primary"} />
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterTypeCompanyRevenue;

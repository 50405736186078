import {
  ALL_FILTERS_FAIL,
  ALL_FILTERS_SUCCESS,
  FILTER_HEADER_COMPANY_FAIL,
  FILTER_HEADER_COMPANY_REQUEST,
  FILTER_HEADER_COMPANY_SUCCESS,
  FILTER_HEADER_SEARCH_FAIL,
  FILTER_HEADER_SEARCH_INDUSTRY_FAIL,
  FILTER_HEADER_SEARCH_INDUSTRY_SUCCESS,
  FILTER_HEADER_SEARCH_JOBTITLE_FAIL,
  FILTER_HEADER_SEARCH_JOBTITLE_REQUEST,
  FILTER_HEADER_SEARCH_JOBTITLE_SUCCESS,
  FILTER_HEADER_SEARCH_REQUEST,
  FILTER_HEADER_SEARCH_SUCCESS,
  FILTER_HEADER_SENIORITY_REQUEST,
  FILTER_HEADER_SENIORITY_SUCCESS,
  FILTER_HEADER_SENIORITY_FAIL,
  FILTER_HEADER_EMAIL_VERIFICATION_REQUEST,
  FILTER_HEADER_EMAIL_VERIFICATION_SUCCESS,
  FILTER_HEADER_EMAIL_VERIFICATION_FAIL,
  FILTER_HEADER_COMPANY_KEYWORDS_REQUEST,
  FILTER_HEADER_COMPANY_KEYWORDS_SUCCESS,
  FILTER_HEADER_COMPANY_KEYWORDS_FAIL,
  FILTER_HEADER_COMPANY_TECHNOLOGIES_REQUEST,
  FILTER_HEADER_COMPANY_TECHNOLOGIES_SUCCESS,
  FILTER_HEADER_COMPANY_TECHNOLOGIES_FAIL,
  FILTER_HEADER_COMPANY_SEO_REQUEST,
  FILTER_HEADER_COMPANY_SEO_SUCCESS,
  FILTER_HEADER_COMPANY_SEO_FAIL,
  FILTER_HEADER_PERSON_CITY_REQUEST,
  FILTER_HEADER_PERSON_CITY_SUCCESS,
  FILTER_HEADER_PERSON_CITY_FAIL,
  FILTER_HEADER_PERSON_STATE_REQUEST,
  FILTER_HEADER_PERSON_STATE_SUCCESS,
  FILTER_HEADER_PERSON_STATE_FAIL,
  FILTER_HEADER_PERSON_COUNTRY_REQUEST,
  FILTER_HEADER_PERSON_COUNTRY_SUCCESS,
  FILTER_HEADER_PERSON_COUNTRY_FAIL,
  FILTER_HEADER_COMPANY_CITY_REQUEST,
  FILTER_HEADER_COMPANY_CITY_SUCCESS,
  FILTER_HEADER_COMPANY_CITY_FAIL,
  FILTER_HEADER_COMPANY_STATE_REQUEST,
  FILTER_HEADER_COMPANY_STATE_SUCCESS,
  FILTER_HEADER_COMPANY_STATE_FAIL,
  FILTER_HEADER_COMPANY_COUNTRY_REQUEST,
  FILTER_HEADER_COMPANY_COUNTRY_SUCCESS,
  FILTER_HEADER_COMPANY_COUNTRY_FAIL,
  FILTER_HEADER_COMPANY_NAME_REQUEST,
  FILTER_HEADER_COMPANY_NAME_SUCCESS,
  FILTER_HEADER_COMPANY_NAME_FAIL,
  FILTER_HEADER_ACCOUNT_LIST_REQUEST,
  FILTER_HEADER_ACCOUNT_LIST_SUCCESS,
  FILTER_HEADER_ACCOUNT_LIST_FAIL,
  FILTER_HEADER_CONTACT_LIST_REQUEST,
  FILTER_HEADER_CONTACT_LIST_SUCCESS,
  FILTER_HEADER_CONTACT_LIST_FAIL
} from "../constants/filterConstants";
import { EMITTER_ERROR } from "../../utils/constants";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { getRequest } from "../../Apis";
import { LOGIN_PATH } from "../../utils/constants/routes";

export const filterHeaderSearch = (url, query, navigate) => async (dispatch) => {

  try {
    dispatch({ type: FILTER_HEADER_SEARCH_REQUEST });
    let response = await getRequest( navigate , `/${url}?${query}`);
    dispatch({
      type: FILTER_HEADER_SEARCH_SUCCESS,
      payload: response?.data,
    });

  } catch (err) {
    if( err?.status === 401 ){
      navigate( LOGIN_PATH );
    }
    err?.status >= 500 &&
      EventEmitter.emit(
        EMITTER_ERROR,
        err?.data?.message || err?.message || "server error"
      );
    dispatch({
      type: FILTER_HEADER_SEARCH_FAIL,
      payload: err?.data,
    });
  }
};


export const filterHeaderSearchJobTitle = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_SEARCH_JOBTITLE_REQUEST });
    
    dispatch({
      type: FILTER_HEADER_SEARCH_JOBTITLE_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    err?.response?.status >= 500 &&
      EventEmitter.emit(
        EMITTER_ERROR,
        err?.response?.data?.message || err?.message || "server error"
      );
    dispatch({
      type: FILTER_HEADER_SEARCH_JOBTITLE_FAIL,
      payload: err?.response?.data,
    });
  }
};

export const filterHeaderSearchIndustry = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: FILTER_HEADER_SEARCH_INDUSTRY_REQUEST });
    dispatch({
      type: FILTER_HEADER_SEARCH_INDUSTRY_SUCCESS,
      payload: payload,
    });

   

  } catch (err) {
    err?.response?.status >= 500 &&
      EventEmitter.emit(
        EMITTER_ERROR,
        err?.response?.data?.message || err?.message || "server error"
      );
    dispatch({
      type: FILTER_HEADER_SEARCH_INDUSTRY_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderSearchCompany = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_COMPANY_REQUEST });
    dispatch({
      type: FILTER_HEADER_COMPANY_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_COMPANY_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderSearchSeniority = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_SENIORITY_REQUEST });
    dispatch({
      type: FILTER_HEADER_SENIORITY_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_SENIORITY_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderEmailVerification = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_EMAIL_VERIFICATION_REQUEST });
    dispatch({
      type: FILTER_HEADER_EMAIL_VERIFICATION_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_EMAIL_VERIFICATION_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderCompanyName = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_COMPANY_NAME_REQUEST });
    dispatch({
      type: FILTER_HEADER_COMPANY_NAME_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_COMPANY_NAME_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderCompanyKeywords = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_COMPANY_KEYWORDS_REQUEST });
    dispatch({
      type: FILTER_HEADER_COMPANY_KEYWORDS_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_COMPANY_KEYWORDS_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderCompanyTechnologies = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_COMPANY_TECHNOLOGIES_REQUEST });
    dispatch({
      type: FILTER_HEADER_COMPANY_TECHNOLOGIES_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_COMPANY_TECHNOLOGIES_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderCompanySeo = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_COMPANY_SEO_REQUEST });
    dispatch({
      type: FILTER_HEADER_COMPANY_SEO_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_COMPANY_SEO_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderPersonCity = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_PERSON_CITY_REQUEST });
    dispatch({
      type: FILTER_HEADER_PERSON_CITY_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_PERSON_CITY_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderPersonState = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_PERSON_STATE_REQUEST });
    dispatch({
      type: FILTER_HEADER_PERSON_STATE_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_PERSON_STATE_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderPersonCountry = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_PERSON_COUNTRY_REQUEST });
    dispatch({
      type: FILTER_HEADER_PERSON_COUNTRY_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_PERSON_COUNTRY_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderCompanyCity = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_COMPANY_CITY_REQUEST });
    dispatch({
      type: FILTER_HEADER_COMPANY_CITY_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_COMPANY_CITY_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderCompanyState = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_COMPANY_STATE_REQUEST });
    dispatch({
      type: FILTER_HEADER_COMPANY_STATE_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_COMPANY_STATE_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderCompanyCountry = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_COMPANY_COUNTRY_REQUEST });
    dispatch({
      type: FILTER_HEADER_COMPANY_COUNTRY_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_COMPANY_COUNTRY_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderAccountList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_ACCOUNT_LIST_REQUEST });
    dispatch({
      type: FILTER_HEADER_ACCOUNT_LIST_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_ACCOUNT_LIST_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const filterHeaderContactList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FILTER_HEADER_CONTACT_LIST_REQUEST });
    dispatch({
      type: FILTER_HEADER_CONTACT_LIST_SUCCESS,
      payload: payload,
    });

  } catch (err) {
    dispatch({
      type: FILTER_HEADER_CONTACT_LIST_FAIL,
      payload: err?.response?.data,
    });
  }
}

export const allFilters = (query, clearAll=false) => async (dispatch, getState) => {
  // console.log("QUERRY",query)
  try {
    // dispatch({ type: ALL_FILTERS_REQUEST });
    if(clearAll){
      dispatch({
        type: ALL_FILTERS_SUCCESS,
        payload: query,
      });
    }else{
      const payload = {...getState().allFilters?.payload, ...query};
      // for( let i in payload ){
      //   // console.log('eachhhhhh', i, payload[i], payload?.[i]?.length);
      //   if(  payload?.[i]?.length === 0 ){
      //     delete payload[i];
      //   }
      // }
      dispatch({
        type: ALL_FILTERS_SUCCESS,
        payload: payload,
      });
    }

  } catch (err) {
    dispatch({
      type: ALL_FILTERS_FAIL,
      payload: err?.response?.data,
    });
  }

}

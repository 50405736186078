import {
    BUSINESS_TABLE_DATA_FETCH_ERROR,
    BUSINESS_TABLE_DATA_FETCH_REQUEST,
    BUSINESS_TABLE_DATA_FETCH_SUCCESS,
  } from "../constants/businessConstant";

export const businessListReducer = (state = {}, action) => {
    switch (action.type) {
      case BUSINESS_TABLE_DATA_FETCH_REQUEST:
        return { loading: true };
  
      case BUSINESS_TABLE_DATA_FETCH_SUCCESS:
        return { loading: false, columns: action.payload, totalRecords: action.totalRecords };
  
      case BUSINESS_TABLE_DATA_FETCH_ERROR:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  }; 
export const CONTACT_LIST_CREATE_RESET = "CONTACT_LIST_CREATE_RESET"
export const CONTACT_LIST_CREATE_REQUEST = "CONTACT_LIST_CREATE_REQUEST"
export const CONTACT_LIST_CREATE_SUCCESS = "CONTACT_LIST_CREATE_SUCCESS"
export const CONTACT_LIST_CREATE_FAIL = "CONTACT_LIST_CREATE_FAIL"

export const GET_CONTACT_LIST_REQUEST = "GET_CONTACT_LIST_REQUEST"
export const GET_CONTACT_LIST_SUCCESS = "GET_CONTACT_LIST_SUCCESS"
export const GET_CONTACT_LIST_FAIL = "GET_CONTACT_LIST_FAIL"

export const GET_EARLY_ACCESS_USER_LIST_REQUEST = "GET_EARLY_ACCESS_USER_LIST_REQUEST"
export const GET_EARLY_ACCESS_USER_LIST_SUCCESS = "GET_EARLY_ACCESS_USER_LIST_SUCCESS"
export const GET_EARLY_ACCESS_USER_LIST_FAIL = "GET_EARLY_ACCESS_USER_LIST_FAIL"
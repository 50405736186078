import {
  EmailVerification,
  ForgotPasswordScreen,
  LoginScreen,
  LoginToken,
   
  UpdatePasswordScreen,
} from "../screens";
import {
  EMAIL_VERIFICATION_PATH,
  FORGOT_PASSWORD_MAIL_SEND_PATH,
  FORGOT_PASSWORD_PATH,
  LOGIN_PATH,
  LOGIN_PATH_WITH_EMAIL,
  HOME_PATH,
   
} from "../utils/constants/routes";

export const authRoutes = [
  {
    path: HOME_PATH,
    component: LoginToken,
  },
  {
    path: LOGIN_PATH,
    component: LoginScreen,
  },
  {
    path: LOGIN_PATH_WITH_EMAIL,
    component: LoginScreen,
  },
 
  {
    path: FORGOT_PASSWORD_PATH,
    component: ForgotPasswordScreen,
  },
  {
    path: EMAIL_VERIFICATION_PATH,
    component: EmailVerification,
  },
  {
    path: FORGOT_PASSWORD_MAIL_SEND_PATH,
    component: UpdatePasswordScreen,
  },
];

import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import {
  ACTIONS,
  BASE,
  BORDER,
  BRAND,
  GREY,
  PRIMARY,
} from "../../utils/constants/colors";
import { fontSize, fontWeight, ICON_LABELS } from "../../utils/constants/UI";
import { borderRadius, SCROLL_BAR } from "../../utils/constants/UI/uiConstants";
import IconComponent from "../Icon/IconComponent";
import TypographyInline from "../Typography/TypographyInline";

const SIZE_MAPPING = {
  primary: {
    backgroundColor: GREY.QUATINARY,
  },
  error: {
    backgroundColor: BRAND.PRIMARY,
  },
  alert: {
    backgroundColor: BASE.WHITE,
  },
  todo: {
    background: "linear-gradient(270deg, #222124 0%, #322A78 100%)",
  },
  call: {
    background:
      "linear-gradient(270deg, #222124 0%, #0A6C2A 100%, #1B4729 100%)",
  },
};

const CustomModal = ({
  open,
  close,
  header,
  children,
  footer,
  variant,
  width,
  isBackground,
  textTransform,
  ...props
}) => {
  const styles = {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "64px",
      maxHeight: "calc(100% - 128px)",
      outline: "none",
      position:"relative"
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: variant === "alert" ? "20px" : "16px",
      backgroundColor: SIZE_MAPPING[variant || "primary"]?.backgroundColor,
      borderBottom: !variant === "alert" && `1px solid ${BORDER.DEFAULT}`,
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
      gap: "4px",
      width: width || "26.042vw",
    },
    iconContainer: {},
    iconStyle: {
      cursor: "pointer",
      fontSize: `${fontSize.XL} !important`,
      color: variant === "error" ? BASE.WHITE : GREY.TERTIARY,
      "&:hover": {
        backgroundColor: variant === "error" ? PRIMARY.W_50 : ACTIONS.DISABLED,
        borderRadius: borderRadius.primary,
      },
    },
    bodyContainer: {
      backgroundColor: isBackground ? "#F8F8F8" : BASE.WHITE,
      padding: props?.remove_padding ? "0px" : "16px",
      width: width || "26.042vw",
      borderRadius:
        footer?.left === undefined &&
        footer?.right === undefined &&
        `${borderRadius.none} ${borderRadius.none} ${borderRadius.primary} ${borderRadius.primary}`,
    },
    footerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
      backgroundColor: BASE.WHITE,
      borderRadius: `${borderRadius.none} ${borderRadius.none} ${borderRadius.primary} ${borderRadius.primary}`,
      borderTop: `1px solid ${ACTIONS.DEFAULT}`,
      width: width || "26.042vw",
    },
    alertIcon: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "10px",
      gap: "10px",
      borderRadius: borderRadius.secondary,
      border: `1px solid ${BORDER.DEFAULT}`,
    },
    headerLeftContainer: {},
    headerLeftUpperContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "8px",
      textTransform: textTransform ? textTransform:  "capitalize",
    },
    greadientHeaderLeftUpperContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "8px",
    },
    stepLabelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "3px 12px",
      borderRadius: "27px",
      backgroundColor: header?.steps?.bgcolor,
    },
    gradientHeaderContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      padding: "16px",
      background: SIZE_MAPPING[header?.steps?.id]?.background,
      borderBottom: `1px solid ${BORDER.DEFAULT}`,
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
      gap: "4px",
      width: width || "26.042vw",
    },
    gradientHeaderLeftContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    dateTimeRangeContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "4px",
    },
    gradientRightUpperContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "16px",
    },
  };

  const classes = styles;

  return (
    <Modal open={open || false} onClose={props?.onClose} {...props}>
      <div id="modal" key="modal" style={classes.mainContainer}>
        {/* Header */}
        {props?.is_gradient ? (
          // Gradient Header
          <header style={classes.gradientHeaderContainer}>
            <div style={classes.gradientHeaderLeftContainer}>
              <div style={classes.greadientHeaderLeftUpperContainer}>
                {header?.steps && (
                  <div style={classes.stepLabelContainer}>
                    <TypographyInline
                    
                      key={header?.steps?.id}
                      color={BASE.WHITE}
                      fontWeight={fontWeight.MEDIUM}
                      label={header?.steps?.label}
                      size={"xs"}
                    />
                  </div>
                )}
                <TypographyInline
                  key={header?.heading?.id}
                  size={"md"}
                  color={BASE.WHITE}
                  fontWeight={fontWeight.SEMIBOLD}
                  label={header?.heading?.label}
                />
              </div>
              <div style={classes.gradientRightUpperContainer}>
                <TypographyInline
                  label={header?.heading?.cross_icon_label}
                  color={BASE.WHITE}
                  fontWeight={fontWeight.SEMIBOLD}
                  size={"sm"}
                />
                <div style={classes.iconContainer}>
                  <IconComponent
                    iconLabel={ICON_LABELS.CLOSE_BUTTON}
                    style={classes.iconStyle}
                    onClick={props?.onClose || close}
                  />
                </div>
              </div>
            </div>
            <TypographyInline
              key={header?.description?.id}
              size={"xl"}
              color={BASE.WHITE}
              fontWeight={fontWeight.SEMIBOLD}
              label={header?.description?.label}
            />
            <div style={classes.dateTimeRangeContainer}>
              <IconComponent
                iconLabel={ICON_LABELS.ACCESS_TIME}
                fontSize={fontSize.MD}
                color={BORDER.HOVER}
              />
              <TypographyInline
                color={BORDER.HOVER}
                fontWeight={fontWeight.REGULAR}
                size={"sm"}
                label={header?.description?.timeLabel}
              />
            </div>
          </header>
        ) : (
          // Non-gradient Header
          <header style={classes.headerContainer}>
            {variant === "alert" ? (
              <div style={classes.alertIcon}>
                <IconComponent
                  iconLabel={header?.alertIcon || "mail_lock"}
                  color={GREY.QUATINARY}
                  fontSize={"24px"}
                />
              </div>
            ) : (
              <div style={classes.headerLeftContainer}>
                <div style={classes.headerLeftUpperContainer}>
                  {header?.steps && (
                    <div style={classes.stepLabelContainer}>
                      <TypographyInline
                        key={header?.steps?.id}
                        color={BASE.WHITE}
                        fontWeight={fontWeight.MEDIUM}
                        label={header?.steps?.label}
                        size={"xs"}
                      />
                    </div>
                  )}
                  <TypographyInline
                    key={header?.heading?.id}
                    size={"md"}
                    color={BASE.WHITE}
                    fontWeight={fontWeight.SEMIBOLD}
                    label={header?.heading?.label}
                  />
                </div>
                {header?.description && (
                  <TypographyInline
                    key={header?.description?.id}
                    size={"sm"}
                    color={GREY.TERTIARY}
                    fontWeight={fontWeight.REGULAR}
                    label={header?.description?.label}
                  />
                )}
              </div>
            )}
            <div style={classes.iconContainer}>
              <IconComponent
                id={"close_button"}
                iconLabel={ICON_LABELS.CLOSE_BUTTON}
                style={classes.iconStyle}
                onClick={props?.onClose || close}
              />
            </div>
          </header>
        )}
        {/* Header */}

        {/* Body */}
        <div id="modal_body" style={classes.bodyContainer}>
          {children && children}
        </div>
        {/* Body */}

        {/* Footer */}
        {footer && (
          <div style={classes.footerContainer}>
            {footer?.left && footer?.left}
            {footer?.right && footer?.right}
          </div>
        )}
        {/* Footer */}
      </div>
    </Modal>
  );
};

export default CustomModal;

// ------------- NOTE ----------------
// If there is need to add color or any other attribute in button then please use SIZE_MAPPING object.
// Add key into object and use it with props according to size or variant.
// Props description :-
// open :- Open state for Modal.
// close :- Close state for Modal.
// onClose :- This is also close state of modal but it will also close modal when we click outside the modal.
// is_gradient: If you want header having gradient backgournd and UI of this is also diffrent it has croos icon label and also includes time stamp as a description.
// Pass steps key into the header object if you want label before Heading of the modal.
// Header gradient backgrougna is depend upon step label.
// header :- This is an object type prop having all details of header. It should have fixed key as below :-
// header: {
//     heading: {
//         id: "head",
//         label: "Heading"
//         cross_icon_label: "Task 2/2" (pass only if you want label with the cross icon on the right of header.)
//     },
//     description: {
//         id: "desc",
//         label: "Sub_heading - for brief description",
//         timeLabel: "14 March 2020, 15:30 - 16:30 PM", (pass only in the case of task modals as this is only suppoted having gradient backgroung modal.)
//     },
// ************* Add alret icon only if you want icon on the left side of modal header. ***************
//     alertIcon: {
//         id: "mail_lock",
//         label: "mail_lock"
//     },
// ************* Add steps only if you want step label on the left side of modal header main heading. ***************
//     steps: {
//        id: "step",
//        label: "Call",
//        bgcolor: SUCCESS.W_500
//     },
//
// }
// children :- This is children prop for body of the modal. It contains html you want to display in the body section.
// footer :- This is an object type prop having html of left and right side of the footer. It should have fixed key as below :-
// footer : {
//  left: leftData,
//  right: rightData
// }
// variant :- There are three values for variant like primary, error and alert.
// width :- This shold be passed by you in viewport and please refer figma for width of the modal and that mentioned size is for 1920 viewport.

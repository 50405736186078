export const STRINGS = {
    GET_URL:"Get URL",
    LOGIN_IN_ACCOUNT: "Login to your account",
    FORGOT_PASSWORD:"Forgot password?",
    LOGIN:"Log In",
    EMAIL:"Email",
    ENTER_EMAIL:"Enter Email",
    PASSWORD:"Password",
    ENTER_PASSWORD:"Enter Password",
    


    
}
export const MESSAGE_INVALID_NAME_LENGTH = "This is a required field.";
export const MESSAGE_INVALID_PASSWORD =
  "Password should be greater than or equal to 8.";
export const MESSAGE_INVALID_EMAIL_PATTERN = "Enter a valid Email.";
export const MESSAGE_INVALID_EMAIL_LENGTH = "Email field is required.";
export const MESSAGE_INVALID_PASSWORD_LENGTH =
  "Password field is required.";
  export const MESSAGE_INVALID_CONFIRM_PASSWORD_LENGTH =
  " Confirm Password field is required.";
export const MESSAGE_INVALID_CONF_PASSWORD_LENGTH =
  "Confirm Password should be greater than or equal to 8.";
export const MESSAGE_INVALID_NEW_PASSWORD_LENGTH =
  "New Password should be greater than or equal to 8.";
export const MESSAGE_INVALID_DOMAIN = "Please enter a valid subdomain.";
export const MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH =
  "Password and Confirm Password do not match.";
export const MESSAGE_PASSWORD_NEW_PASSWORD_MISMATCH =
  "Password and Confirm password should be same.";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong.";
export const NOT_AVAILABLE = "Not Available";

export const UNAUTHORIZED_USER_MESSAGE = "Unauthorized User";

export const INAVLID_DATE_RANGE = "End date should be greater than the Start date";


export const DATA_SHOULD_NOT_SAME = "Data should be different";
export const FLAGTOOLTIP = "Flag this item as inappropriate";
export const MESSAGE_INVALID_URL = "Please enter a valid URL";
export const MESSAGE_REQUIRED_FIELD = "This is a required field.";
export const DYNAMIC_FIELD_FORMAT =
  "Only _,- and alphanumeric values are allowed.";
  export const MESSAGE_INVALID_NUMBER = "Please enter Valid Phone Number";

  export const IMAGE_UPLOAD_SIZE_WARNING = "Image size too big, max 500KB Allowed.";
export const INVALID_IMAGE_TYPE = "Invalid image type.";
export const UPLOADING_IMAGE = "Uploading Image...";
export const IMAGE_UPLOADED_MESSAGE = "Image Uploaded Successfully.";
export const DUPLICATE_STAGE_NAME = "Duplicate Stage name is not allowed";
import { CONTACT_TABLE_DATA_FETCH_ERROR, CONTACT_TABLE_DATA_FETCH_REQUEST, CONTACT_TABLE_DATA_FETCH_SUCCESS } from "../constants/contactConstant";

export const contactlistReducer = (state = {}, action) => {
    switch (action.type) {
      case CONTACT_TABLE_DATA_FETCH_REQUEST:
        return { loading: true };
  
      case CONTACT_TABLE_DATA_FETCH_SUCCESS:
        return { loading: false, columns: action.payload, totalRecords: action.totalRecords };
  
      case CONTACT_TABLE_DATA_FETCH_ERROR:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  }; 
import { authEndpoints } from "../../utils/constants/httpConstants";
import {
  CONTENT_TABLE_DATA_FETCH_ERROR,
  CONTENT_TABLE_DATA_FETCH_REQUEST,
  CONTENT_TABLE_DATA_FETCH_SUCCESS,
} from "../constants/contentConstant";
import { postRequest } from "../../Apis";

export const contentListData =  (sentQuery, navigate) => async (dispatch) => {
    try {
      dispatch({ type: CONTENT_TABLE_DATA_FETCH_REQUEST });
      const response = await postRequest(
        navigate,
        authEndpoints.contentList,
        sentQuery,
       
      );
      dispatch({
        type: CONTENT_TABLE_DATA_FETCH_SUCCESS,
        payload: response?.data?.data,
        totalRecords: response?.data?.total_records,
      });
  
      
    } catch (err) {
      if (err?.status === 401) {
        
      }
    
      dispatch({ type: CONTENT_TABLE_DATA_FETCH_ERROR, payload: err });
    }
  }

export const QUARANTINEPREAPPROVE_GET_REQUEST = "QUARANTINEPREAPPROVE_GET_REQUEST"
export const QUARANTINEPREAPPROVE_GET_SUCCESS = "QUARANTINEPREAPPROVE_GET_SUCCESS"
export const QUARANTINEPREAPPROVE_GET_FAIL = "QUARANTINEPREAPPROVE_GET_FAIL"

export const SELECTED_QUARANTINEPREAPPROVE_GET_REQUEST = "SELECTED_QUARANTINEPREAPPROVE_GET_REQUEST"
export const SELECTED_QUARANTINEPREAPPROVE_GET_SUCCESS = "SELECTED_QUARANTINEPREAPPROVE_GET_SUCCESS"
export const SELECTED_QUARANTINEPREAPPROVE_GET_FAIL = "SELECTED_QUARANTINEPREAPPROVE_GET_FAIL"

export const PREQUARANTINEPREAPPROVE_GET_REQUEST = "PREQUARANTINEPREAPPROVE_GET_REQUEST"
export const PREQUARANTINEPREAPPROVE_GET_SUCCESS = "PREQUARANTINEPREAPPROVE_GET_SUCCESS"
export const PREQUARANTINEPREAPPROVE_GET_FAIL = "PREQUARANTINEPREAPPROVE_GET_FAIL"




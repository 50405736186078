import React, { useState } from "react";
import { STRINGS } from "../strings";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Styles } from "../Styles";
import TypographyInline from "../../../components/Typography/TypographyInline";
import { GREY } from "../../../utils/constants/colors";
import { fontWeight } from "../../../utils/constants/UI";
import { Button } from "../../../components/Buttons";
import CustomModal from "../../../components/Modals/CustomModal";
import { authEndpoints } from "../../../utils/constants/httpConstants";
import { postRequest } from "../../../Apis";
import { contentListData } from "../../../redux/actions/contentAction";
import EventEmitter from "../../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../../utils/constants";
import { accountListdata } from "../../../redux/actions/accountAction";
import { putRequest } from "../../../Apis/apiWrapper";

const StatusConfirmationModal = ({ open, setOpen, data={}, value="" }) => {
    console.log("DF",data,value)
  const classes= Styles()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const deleteRecord = async () => {

    try{
        setLoading(true);
        const response = await putRequest(
          navigate,
          authEndpoints.statusApprove,
          {
            _id:data?._id,
            status:value
          }
        );
        EventEmitter.emit(EMITTER_SUCCESS, response?.data?.message);
        dispatch(accountListdata({ page: 1 }, navigate))
        setOpen(!open);
        setLoading(false);
    }   
    catch(err){
       
    }
    finally{
      setLoading(false);
    } 
  };
  const modalHeaderData = {
    heading: {
      id: STRINGS.USER_STATUS,
      label: STRINGS.USER_STATUS,
    },
  };
  const modalBodyData = (
    <div className={classes.modalBodyContainer}>
      <TypographyInline
        size={"sm"}
        isEllipses={true}
        color={GREY.SECONDARY}
        fontWeight={fontWeight.REGULAR}
        label={`${STRINGS.STATUS_CONFIRM}`}
      />
    </div>
  );
  const modalFooterLeftData = <div></div>;
  const modalFooterRightData = (
    <div className={classes.rightFoot}>
      <Button
        label={STRINGS.CONFIRM}
        size={"sm36"}
        variant={"primary"}
        onClick={deleteRecord}
        isLoading={loading}
      />
    </div>
  );
  return (
   
    <CustomModal
      open={open}
      variant={"error"}
      close={() => setOpen(false)}
      header={modalHeaderData}
      children={modalBodyData}
      footer={{
        left: modalFooterLeftData,
        right: modalFooterRightData,
      }}
    />
  );
};

export default StatusConfirmationModal;

import { makeStyles } from "@mui/styles";
import { commonStyles } from "../../theme/globalStyles";
import { LOGIN_BACKGROUND } from "../../utils/constants/assets";


export const styles = makeStyles({
    container:{
        display:"flex",
        "@media (max-width: 768px)":{
            flexDirection:"column"
        }
    },
    leftContainer:{
        width:"60%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        padding:"4vh 10vw",
        background:"linear-gradient(120deg, #FFF1F1, transparent 40%)",
        "@media (max-width: 768px)":{
            width:"100%",
            height:"100vh"
        }
    },
    rightContainer:{
        width:"100%",
        background:`url(${ LOGIN_BACKGROUND }) 0 0 no-repeat #ffeeee`,
        height:"100vh",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        backgroundSize:"cover",
        backgroundBlendMode:"multiply"
    },
    title: commonStyles.largeTitle ,
    para:commonStyles.largePara,
    iconPara:{
        fontWeight:"300",
        color:"#212833",
        alignSelf:'center',
        fontSize:"clamp(0.9rem,1vw,2.7rem)",
    },
    eachIconContainer:{
        display:"flex"
    },
    iconsContainer:{
        display:"flex",
        justifyContent:"center",
        paddingTop:"0.5rem"
    },
    analyticsImage:{
        width:'75%',
        height:"100%",
        objectFit:"cover",
        marginTop:"1rem"
    },
    containAnalyticsImage:{
        display:"flex",
        justifyContent:"center"
    },
    logo:{
        width:"clamp(80px,10vw,200px)",
        height:"clamp(80px,10vh,200px)",
        objectFit:"contain"
    },
    reachIqTitle:{
        fontWeight:"700",
        color:"#212833",
        alignSelf:'center',
        fontSize:"clamp(0.9rem,1.4vw,2.7rem)"
    },
    containLogo:{
        display:"flex",
        justifyContent:"center"
    },
    termsPara:{
        fontSize:"clamp(14px,1vw,42px)",
        color:"#626B7E",
        fontWeight:"300",
        textAlign:'center'
    },
    socialAuthBtn:{
        background:"white",
        border:"1px solid #D3DAE3",
        display:"flex",
        alignItems:'center',
        fontWeight:"400",
        fontSize:"clamp(0.8rem,0.8vw,2.5rem)",
        borderRadius:"6px",
        width:"100%",
        padding:"clamp(1rem,1vw,3rem)",
        justifyContent:"center"
    },
    socialAuthIcon:{
        width:"clamp(1rem,1.5vw,3rem)",
        height:'clamp(1rem,1.5vh,3rem)',
        marginRight:"5px",
        objectFit:"contain"
    },
    emailSignIn:{
        color:"#ABB4BD",
        fontWeight:"400",
        fontSize:"clamp(0.7rem,0.7vw,2.4rem)",
        textAlign:'center',
       
    },
    containEmailSignIn:{
        paddingTop:"1rem"
    },
    containEachInput:{
        background:"#fcfcfd",
        display:"flex",
        flexDirection:'column',
        justifyContent:"center",
        padding:"0.5rem 1rem",
        border:"1px solid #D3DAE3",
        borderRadius:"8px",
        marginTop:"1rem"
    },
    eachInput:{
        border:"none",
        background:"#fcfcfd",
        fontWeight:"700",
        fontSize:'clamp(14px,1vw,42px)',
        width:"100%"
    },
    domainInput:{
        border:"none",
        background:"#fcfcfd",
        fontWeight:"700",
        fontSize:'clamp(14px,1vw,42px)',
        width:"100%",
        padding:"6px 8px",
        borderRadius:"5px 0px 0 5px"
    },
    domainText:{
        color:"#495057",
        alignSelf:'center',
        padding:" 0.6rem",
        height:"100%",
        fontWeight:"500",
        fontSize:"14px",
        borderLeft:"1px solid #d3dae3"
    },
    containDomainInput:{
        display: "flex",
        justifyContent: "space-betnween",
        background: "#e9ecef",
        border: "1px solid #d3dae3",
        borderRadius: "5px 0px 0 5px",
        borderRight: "none",
        marignTop: "2px",
      },
    eachInputTitle:{
        color:"#ABB4BD",
        fontWeight:"400",
        fontSize:'clamp(14px,0.8vw,42px)'
    },
    containForgotPassword:{
        display:"flex",
        justifyContent:"flex-end"
    },
    forgotPassword:{
        border:'none',
        color:"#ff4848",
        background:"white",
        fontWeight:"400",
        fontSize:"clamp(14px,1vw,42px)",
        cursor:"pointer"
    },
    loginBtn:commonStyles.redButtonWithShadow,
    flex:{
        display:'flex',
        justifyContent:'center'
    },
    flexBwtween:{
        display:"flex",
        justifyContent:"space-between"
    },
    noAccountText:{
        color:"#6E6D7A",
        fontWeight:"400",
        fontSize:"clamp(14px,1vw,42px)",
        cursor:"pointer"
    },
    authToggle:{
        border:'none',
        color:"#ff4848",
        background:"unset",
        fontWeight:"400",
        fontSize:"clamp(14px,1vw,42px)",
        cursor:"pointer"
    },
    containPasswordInput1:{
        background:"#fcfcfd",
        padding:"0.5rem ",
        border:"1px solid #D3DAE3",
        borderRadius:"8px",
        width:'95%',
        marginTop:"1rem"
    },
    containPasswordInput2:{
        background:"#fcfcfd",
        border:"1px solid #D3DAE3",
        borderRadius:"8px",
        width:'99%',
        padding:"0.5rem",
        marginTop:"1rem"

    },
    containLoginBtn:{
        display:"flex",
        justifyContent:'center',
        
    }
})




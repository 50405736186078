import {
  DYNAMIC_FIELD_FORMAT,
  MESSAGE_INVALID_CONFIRM_PASSWORD_LENGTH,
  MESSAGE_INVALID_CONF_PASSWORD_LENGTH,
  MESSAGE_INVALID_DOMAIN,
  MESSAGE_INVALID_EMAIL_LENGTH,
  MESSAGE_INVALID_EMAIL_PATTERN,
  MESSAGE_INVALID_NAME_LENGTH,
  MESSAGE_INVALID_NEW_PASSWORD_LENGTH,
  MESSAGE_INVALID_NUMBER,
  MESSAGE_INVALID_PASSWORD,
  MESSAGE_INVALID_PASSWORD_LENGTH,
  MESSAGE_INVALID_URL,
  MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH,
  MESSAGE_PASSWORD_NEW_PASSWORD_MISMATCH,
  MESSAGE_REQUIRED_FIELD,
} from "../constants/messages";

export const emailValidation = (email) => {
  if (!email.length) {
    return { isValid: false, message: MESSAGE_INVALID_EMAIL_LENGTH };
  }
  let filter =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  return filter.test(email)
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_EMAIL_PATTERN };
};


export const passwordValidation = (password) => {
  if (!password.length) {
    return { isValid: false, message: MESSAGE_INVALID_PASSWORD_LENGTH };
  }

  if (password?.length >= 8) {
    return { isValid: true, message: "" };
  } else {
    return { isValid: false, message: MESSAGE_INVALID_PASSWORD };
  }
};


export const confirmPasswordvalidation = (pass, confPass) => {
  if (!confPass.length) {
    return { isValid: false, message: MESSAGE_INVALID_CONFIRM_PASSWORD_LENGTH };
  }
  if (confPass === pass) {
    if (confPass?.length >= 8) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: MESSAGE_INVALID_NEW_PASSWORD_LENGTH };
    }
  } else {
    return {
      isValid: false,
      message: MESSAGE_PASSWORD_NEW_PASSWORD_MISMATCH,
    };
  }
};

export const newPasswordvalidation = (pass, confPass) => {
  if (!confPass.length) {
    return { isValid: false, message: MESSAGE_INVALID_NEW_PASSWORD_LENGTH };
  }
  if (confPass !== pass) {
    if (confPass?.length >= 8) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: MESSAGE_INVALID_PASSWORD_LENGTH };
    }
  } else {
    return {
      isValid: false,
      message: "",
    };
  }
};


export const nameValidation = (name) => {
  return name?.length
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};


export const dateValidation = (date) => {
  return date?.length  ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};


export const domainValidation = (domain) => {

  // subdomain regex
  let filter =   /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/

  if (!domain.length) {
    return { isValid: false, message: MESSAGE_INVALID_DOMAIN };
  }

  return filter.test(domain)
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_DOMAIN };

};

export const domainValidationForBlacklist = (domain) => {
  if (!domain.length) {
    return { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
  }

  const domain_split = domain?.split(".");
  if (domain_split?.length) {
  }

  return domain_split?.length >= 2
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_DOMAIN };
};

export const urlValidation = (url) => {
  if (url?.length) {
    let urlRegex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    return urlRegex.test(url)
      ? { isValid: true, message: "" }
      : { isValid: false, message: MESSAGE_INVALID_URL };
  } else return { isValid: false, message: MESSAGE_REQUIRED_FIELD };
};

export const numberValidation = (number) => {
  if (number?.length === 0) {
    return { isValid: false, message: MESSAGE_REQUIRED_FIELD };
  } else {
    if (number?.length >= 3) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: MESSAGE_INVALID_NUMBER };
    }
  }
  // return number?.length === 0
  //   ? { isValid: false, message: MESSAGE_REQUIRED_FIELD }
  //   : number?.length >= 6
  //   ? { isValid: true, message: "" }
  //   : { isValid: false, message: MESSAGE_INVALID_NUMBER };
};

export const dynamicFieldNameValidation = (name) => {
  const regex = /^[a-zA-Z0-9!^\)\( _-]+$/g;
  if (name?.length) {
    if (regex.test(name)) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: DYNAMIC_FIELD_FORMAT };
    }
  }
  return { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};

export const fromToDateValidation = (fromDate, toDate) => {
  if (Date.parse(fromDate) > Date.parse(toDate)) {
    return { isValid: false, message: "Invalid Date Range" };
  }
  return { isValid: true, message: "" };
};

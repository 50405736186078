import React, { Suspense, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  EMITTER_ERROR,
  EMITTER_INFO,
  EMITTER_SUCCESS,
  EMITTER_WARNING,
  SNACKBAR_CONFIG as config,
} from "../utils/constants";
import CustomEventEmitter from "../utils/emitter/EventEmitter";
import DashboardLayout from "../components/Sidebar/DashboardLayout";
import { HOME_PATH } from "../utils/constants/routes";
import { LoginToken, InvalidRouteScreen } from "../screens";
import { FullScreenLoader } from "../components";
import { authRoutes } from "./authRoutes";
import { userRoutes } from "./userRoutes";
import { SNACKBAR_CLOSE_ICON } from "../utils/constants/assets";
import { SnackbarCloseIcon } from "../components/Snackbar";
import axios from "axios";
import { authEndpoints } from "../utils/constants/httpConstants";

const Router = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = window.location?.pathname?.trim()?.split("/")[1];
  const navigate = useNavigate();

  const GetToken = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios
          .post(authEndpoints.checktoken, {
            _token: token,
          })

          .then((res) => {
            resolve(res.data.status);
          });
      } catch (e) {
        reject(false);
      }
    });
  };

  useEffect(() => {
    CustomEventEmitter.on(EMITTER_SUCCESS, (message) => {
      enqueueSnackbar(message, {
        variant: "success",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
        action: (key) => (
          <SnackbarCloseIcon
            src={SNACKBAR_CLOSE_ICON}
            onClick={() => {
              closeSnackbar(key);
            }}
          />
        ),
      });
    });
    CustomEventEmitter.on(EMITTER_ERROR, (message) => {
      enqueueSnackbar(message, {
        variant: "error",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
        action: (key) => (
          <SnackbarCloseIcon
            src={SNACKBAR_CLOSE_ICON}
            onClick={() => {
              closeSnackbar(key);
            }}
          />
        ),
      });
    });
    CustomEventEmitter.on(EMITTER_INFO, (message) => {
      enqueueSnackbar(message, {
        variant: "info",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
      });
    });
    CustomEventEmitter.on(EMITTER_WARNING, (message) => {
      enqueueSnackbar(message, {
        variant: "warning",
        preventDuplicate: true,
        anchorOrigin: config.snackBarPlacement,
      });
    });
    //for alert messages - ends
    return () => {
      CustomEventEmitter.removeEventListener("alert_success");
      CustomEventEmitter.removeEventListener("alert_error");
      CustomEventEmitter.removeEventListener("alert_info");
      CustomEventEmitter.removeEventListener("alert_warning");
    };
  }, [enqueueSnackbar, closeSnackbar]);

  return (
    <Suspense fallback={<FullScreenLoader />}>
      {/* <BrowserRouter> */}
      <Routes>
        {/* Base Route, starting of the application */}
        <Route path={HOME_PATH} element={<LoginToken />} />

        {/* All Auth routes  */}
        {authRoutes.map(({ path, component: Component }, id) => (
          <Route path={'/:id' + path} element={<Component />} key={id} exact />
        ))}

        {/* All Authorized, user routes */}
        {userRoutes.map(({ path, component: Component }, id) => {
          return (
            <Route
              path={'/:id' + path}
              element={
                <DashboardLayout>
                  <Component />
                </DashboardLayout>
              }
              key={id}
              exact
            />
          );
        })}

        {/* default error route */}
        <Route path="*" element={<InvalidRouteScreen />} />
      </Routes>
      {/* </BrowserRouter> */}
    </Suspense>
  );
};

export default Router;

import { Fade, Menu } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { BASE, BRAND, GREY, PRIMARY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import {
  fontSize,
  SCROLL_BAR,
  SHADOW,
} from "../../utils/constants/UI/uiConstants";
import IconComponent from "../Icon/IconComponent";
import Typography from "../Typography/Typography";
import { innerLeftBorder } from "../../utils/helpers";

const styles = makeStyles({
  paperMenu: {
    width: "9.479vw",
    // marginTop: "-13px",
    // marginLeft: "-81px",
    ...SCROLL_BAR,
  },
  dropdownValues: {
    // height: "100vh",
    position: "relative",
    ...SCROLL_BAR,
  },
  defaultItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "4px",
    textTransform: "capitalize",
    padding: "8px 10px",
    "&:hover": {
      backgroundColor: BASE.BACKGROUND_LIGHT,
      // borderLeft: `2px solid ${BRAND.PRIMARY}`,
      boxShadow: innerLeftBorder(BRAND.PRIMARY),
    },
  },
  paperMenuShadow: {
    boxShadow: `${SHADOW.xl} !important`,
  },
  menuItem: {
    cursor: "pointer",
  },
  settingLead:{
    width: "14.8vw",
    marginTop: "3px",
    marginLeft: "13px",
    ...SCROLL_BAR,
    "@media (max-width:1470px)": {
      width: "13.5vw",
    },
  },
});

const CustomMenu = ({ items, rowData = {}, headData = {}, ...props }) => {
  const classes = styles();

  return (
    <Menu
      onClose={props?.onClose}
      anchorEl={props?.anchor}
      open={props?.open}
      className={classes.dropdownValues}
      PaperProps={{ classes: classes.paperMenuShadow }}
      classes={{ paper: props?.paperMenuClass ? classes.settingLead : classes.paperMenu }}
      id="fade-menu"
      MenuListProps={{
        "aria-labelledby": "fade-button",
      }}
    >
      {props?.isLoading ? (
        <div className={classes.defaultItem}>
          <Typography
            label={"Loading..."}
            color={GREY.SECONDARY}
            fontWeight={fontWeight.REGULAR}
          />
        </div>
      ) : (
        <div className={classes.menuItem}>
          {items.map((item, index) => {
            return (
              item?.isVisible !== false && (
                <div
                  className={classes.defaultItem}
                  key={index}
                  onClick={() => {
                    props?.onClose();
                    item?.function(rowData, headData, item?.label);
                  }}
                >
                  <IconComponent
                    color={
                      props?.iconColor || item?.variant === "error"
                        ? PRIMARY.W_500
                        : item?.color
                        ? item?.color
                        : GREY.SECONDARY
                    }
                    fontSize={fontSize.MD}
                    iconLabel={item?.iconLabel}
                  />
                  <Typography
                    label={item?.label}
                    size={"sm"}
                    fontWeight={fontWeight.REGULAR}
                    color={
                      item?.variant === "error" ? PRIMARY.W_500 : GREY.SECONDARY
                    }
                  />
                </div>
              )
            );
          })}
        </div>
      )}
    </Menu>
  );
};

export default CustomMenu;

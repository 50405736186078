import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { styles } from "./styles";
import { FUTUNEAR_IMAGE, LOGIN_REACHIQ_LOGO, MAIL_IMAGE } from "../../utils/constants/assets";
import { emailValidation } from "../../utils/validations/inputValidations";
import ErrorMessage from "../../components/Messages/ErrorMessage";
import {
  Anchor,
  CircularLoader,
  CustomTextField,
  RightsTag,
  SuccessMessage,
} from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { resetPasswordMailSend } from "../../redux/actions/authActions";
import ThankYouScreen from "../DefaultScreens/ThankYouScreen";
import { PRIVACY_POLICY_LINK } from "../../utils/constants";
import { Button } from "../../components/Buttons";
import { GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import TypographyInline from "../../components/Typography/TypographyInline";
import InputField from "../../components/InputFields/InputField";

const ForgotPasswordScreen = () => {
  const classes = styles();
  const mailSendSelector = useSelector((state) => state.resetPasswordMailSend);
  const { loading, error, payload } = mailSendSelector;
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [validations, setValidations] = useState({ email: "" });
  const [showThankYou, setShowThankYou] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validatedEmail = emailValidation(email);
    if (validatedEmail.isValid) {
      setValidations({ email: "" });
      //dispatch here for email
      dispatch(resetPasswordMailSend(email));
    } else {
      setValidations({ email: validatedEmail.message });
    }
  };

  useEffect(() => {
    if (payload) {
      setShowThankYou(true);
      // setEmail("");
    }
  }, [payload]);

  return showThankYou ? (
    <ThankYouScreen email={email} image={MAIL_IMAGE} resend={true} />
  ) : (
    <Box className={classes.container}>
      <Box className={classes.topContainer}>
        <Box className={classes.containLogo}>
          <img
            src={FUTUNEAR_IMAGE}
            alt=""
            className={classes.reachiq_logo}
          />
        </Box>
        <Box className={classes.modal} component="form" onSubmit={handleSubmit}>
          <h2 className={classes.resetTitle}>Reset Your Password</h2>
          <Box className={classes.containInstructions}>
            <p className={classes.policyPara}>
              {" "}
              Please enter your email address below.{" "}
            </p>

            <p className={classes.policyPara}>
              {" "}
              We will send you instructions to reset your password.{" "}
            </p>
          </Box>
          <TypographyInline
            size={"sm"}
            color={GREY.QUATINARY}
            fontWeight={fontWeight.MEDIUM}
            label={"Email"}
          />
          <div className={classes.m4Top}>
            <InputField
              value={email}
              size={"md40"}
              variant={validations.email ? "error" : "default"}
              errormessage={validations.email}
              placeholder={"Enter email"}
              onChange={(e) => handleEmail(e)}
            />
          </div>
          {error?.message ? (
            <ErrorMessage message={error?.message} marginBottom="0" />
          ) : null}
          {payload?.message ? (
            <SuccessMessage message={payload?.message} marginBottom="0" />
          ) : null}

          <Box className={classes.btnContainer}>
            <div style={{ width: "140px", marginTop: "12px" }}>
              <Button
                variant={"primary"}
                label={"Send"}
                size={"md40"}
                isLoading={loading}
              />
            </div>
          </Box>
        </Box>
      </Box>
      <Box className={classes.bottomContainer}>
        <Box className={classes.flex}>
          <RightsTag className={classes.termsPara} />
          <Anchor
            href={PRIVACY_POLICY_LINK}
            className={classes.privacyLink}
            target="_blank"
          >
            Privacy and Terms.
          </Anchor>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPasswordScreen;

import { USER_DATA_REQUEST, USER_DATA_SUCCESS, USER_DATA_FAIL } from "../constants/userdataConstant";

 

export const userdataReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_DATA_REQUEST:
        return { loading: true };
  
      case USER_DATA_SUCCESS:
        return { loading: false,  columns: action.payload, totalRecords: action.totalRecords};
  
      case USER_DATA_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
import { makeStyles } from "@mui/styles";
import React from "react";
import {
  BASE,
  BORDER,
  BRAND,
  GREY,
  PRIMARY,
} from "../../utils/constants/colors";
import { fontSize } from "../../utils/constants/UI";
import { borderRadius } from "../../utils/constants/UI/uiConstants";
import IconComponent from "../Icon/IconComponent";

const styles = makeStyles({
  iconContainer: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px",
    boxShadow: `inset -1px 0 0 ${BORDER.DEFAULT}, inset 0 -1px 0 ${BORDER.DEFAULT}, inset 1px 0 0 ${BORDER.DEFAULT}, inset 0 1px 0 ${BORDER.DEFAULT}`,
    borderRadius: borderRadius.primary,
    backgroundColor: (prop) =>
      prop?.props?.background ? PRIMARY.W_500 : BASE.WHITE,
    cursor: (prop) => (!prop?.props?.disabled ? "pointer" : "not-allowed"),
    "& #iconComponent": {
      color: (prop) => prop?.props?.disabled && BORDER.DEFAULT,
    },
    "&:hover": {
      boxShadow: (prop) =>
        !prop?.props?.disabled &&
        `inset -1px 0 0 ${BRAND.PRIMARY}, inset 0 -1px 0 ${BRAND.PRIMARY}, inset 1px 0 0 ${BRAND.PRIMARY}, inset 0 1px 0 ${BRAND.PRIMARY}`,
    },
    "&:active": {
      backgroundColor: (prop) =>
        prop?.props?.background ? PRIMARY.W_500 : BASE.WHITE,
      color: (prop) => !prop?.props?.disabled && BASE.WHITE,
      "& #iconComponent": {
        color: (prop) => !prop?.props?.disabled && BASE.WHITE,
      },
    },
  },
});

const FlagButton = ({ iconLabel, ...props }) => {
  const classes = styles({ props });
  return (
    <div id="iconContainer" className={classes.iconContainer} {...props}>
      <IconComponent
        id="iconComponent"
        iconLabel={iconLabel}
        fontSize={fontSize.MD}
        color={props.background ? BASE.WHITE : GREY.TERTIARY}
      />
    </div>
  );
};

export default FlagButton;

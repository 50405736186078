export const NOTIFICATION_Accordion = {
  boxShadow: "none",
  borderRadius: "2",
  marginBottom: "1rem",
  border: "none",
  "&::before": {
    border: "none",
    background: "unset"
  },
};

export const ADD_FILTER_Accordion = {
  boxShadow: "none",
  borderRadius: "2",
  border: "none",
  "&::before": {
    border: "none",
    background: "unset"
  },
  margin: "0",
  marginBottom: "1rem"
}

export const INVISIBLE_SCROLL_BAR = {
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track ": {
    background: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "none",
    borderRadius: "10px",
  },
}

export const CUSTOM_SCROLL_BAR = {
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track ": {
    background: "#d3dae3",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#ff4848",
    borderRadius: "10px",
  },
};

export const TABLE_SCROLL_BAR = {
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
    height: "6px"
  },
  "&::-webkit-scrollbar-track ": {
    innerHeight: "5px",
    outerHeight: "5px",
    background: "#D3DAE3",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#626B7E",
    borderRadius: "10px",
  },


};
export const COLORS = {
  RED_800: "rgb(255, 233, 233)",
  RED_100: "rgb(255, 72, 72)",
  BLACK: "#000000",
  WHITE: "#FFFFFF",
};
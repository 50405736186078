import { makeStyles } from "@mui/styles";
import { commonStyles } from "../../theme/globalStyles";
import { SIGNUP_BG_IMAGE } from "../../utils/constants/assets";

export const styles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  leftContainer: {
    background: `url(${SIGNUP_BG_IMAGE}) 0 0 no-repeat #f7f9fb`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
   
  },
  card: {
    background: "#fff",
    borderRadius: "12px",
    padding: "1.5rem",
    
    maxWidth: "480px",
    margin: "auto",
    boxShadow:"0px 3px 6px rgba(98, 107, 126, 0.16)",
  },
  mTop:{
    marginTop:"8px"
  },
  m4Top:{
    marginTop:"4px"
  },
  logo: {
    width: "clamp(150px,13.021vw,300px)",
    marginBottom: "2rem",
  },

  containLogo: {
    display: "flex",
    justifyContent: "center",
  },

  emailSignIn: {
    color: "#ABB4BD",
    fontWeight: "400",
    fontSize: "clamp(0.8rem,0.8vw,2.4rem)",
    textAlign: "center",
    position: "relative",
  },
  signInSpan: {
    background: "white",
    zIndex: 2,
    padding: "0 5px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "100%",
      borderBottom: "1px solid #ddd",
      top: "55%",
      left: 0,
      zIndex: -1,
    },
  },
  containEmailSignIn: {
    paddingTop: "1rem",
  },

  containEachInput: {
    background: "#fcfcfd",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0.5rem 1rem",
    border: "1px solid #D3DAE3",
    borderRadius: "8px",
    marginTop: "1rem",
  },
  eachInput: {
    border: "none",
    background: "#fcfcfd",
    fontWeight: "700",
    fontSize: "clamp(14px,1vw,42px)",
  },
  eachInputTitle: {
    color: "#ABB4BD",
    fontWeight: "400",
    fontSize: "clamp(14px,1vw,42px)",
  },
  containForgotPassword: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",
  },
  forgotPassword: {
    border: "none",
    color: "#1F83E3",
    background: "white",
    fontWeight: "400",
    fontSize: "clamp(13px,0.781vw,18px)",
    cursor: "pointer",
  },
  loginBtn: commonStyles.redButtonWithShadow,
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  noAccountText: {
    color: "#6E6D7A",
    fontWeight: "400",
    fontSize: "clamp(13px,0.92vw,42px)",
    cursor: "pointer",
  },
  authToggle: {
    border: "none",
    color: "#ff4848",
    background: "unset",
    fontWeight: "400",
    fontSize: "clamp(13px,0.92vw,42px)",
    cursor: "pointer",
    textDecoration: "none",
  },
  containLoginBtn: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1.2rem",
  },
  containPolicy: {
    display: "flex",
    justifyContent: "center",
  },
  privacyLink: {
    fontSize: "clamp(12px,0.8vw,36px)",
    fontWeight: "500",
    textDecoration: "none",
    paddingLeft: "4px",
    whiteSpace: "nowrap",
    color: "#626B7E",
    transition: "100ms",
    "&:hover": {
      color: "#ff4848",
    },
    
  },
  login:{
    textAlign:"center",
    fontWeight:"600",
    fontSize: "clamp(1rem, 1.25vw, 2rem)",
    color: "#1D1D1F",
    margin:"10px 0 25px"
  }
});

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { verifyEmailwithToken } from '../../Apis/authApis';
import { axios } from "../../config";
import { authEndpoints } from "../../utils/constants/httpConstants";
import {
  CircularLoader,
  CustomTextField,
  FullScreenLoader,
  ImageIcon,
} from "../../components";
import EventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_ERROR, LOCAL_STORAGE_ADVANCE_EXPORT } from "../../utils/constants";
import { makeStyles } from "@mui/styles";
import {
  FUTUNEAR_IMAGE,
  LOGIN_ANALYTICS_IMAGE,
  LOGIN_BACKGROUND,
  LOGIN_CROSS_ICON,
  LOGIN_REACHIQ_LOGO,
  SIGNUP_BG_IMAGE,
} from "../../utils/constants/assets";
import { commonStyles } from "../../theme/globalStyles";
import {
  confirmPasswordvalidation,
  passwordValidation,
} from "../../utils/validations";
import ErrorMessage from "../../components/Messages/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/actions/authActions";
import { FORGOT_PASSWORD_PATH, LOGIN_PATH } from "../../utils/constants/routes";
import TypographyInline from "../../components/Typography/TypographyInline";
import { GREY } from "../../utils/constants/colors";
import { fontWeight } from "../../utils/constants/UI";
import InputField from "../../components/InputFields/InputField";
import { Button } from "../../components/Buttons";

const styles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  leftContainer: {
    background: `url(${SIGNUP_BG_IMAGE}) 0 0 no-repeat #f7f9fb`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%"

  },
  card: {
    background: "#fff",
    borderRadius: "12px",
    padding: "1.5rem",

    maxWidth: "480px",
    margin: "auto",
    boxShadow: "0px 3px 6px rgba(98, 107, 126, 0.16)",
  },
  logo: {
    width: "clamp(150px,13.021vw,300px)",
    marginBottom: "3rem",
  },
  title: commonStyles.largeTitle,
  para: commonStyles.largePara,
  iconPara: {
    fontWeight: "300",
    color: "#212833",
    alignSelf: "center",
    fontSize: "clamp(0.9rem,1vw,2.7rem)",
  },
  eachIconContainer: {
    display: "flex",
  },
  mTop:{
    marginTop:"8px"
  },
  m4Top:{
    marginTop:"4px"
  },
  iconsContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "0.5rem",
  },
  analyticsImage: {
    width: "75%",
    height: "100%",
    objectFit: "cover",
    marginTop: "1rem",
  },
  containAnalyticsImage: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    width: "clamp(80px,10vw,200px)",
    height: "clamp(80px,10vh,200px)",
    objectFit: "contain",
  },
  containLogo: {
    display: "flex",
    justifyContent: "center",
  },
  termsPara: {
    fontSize: "12px",
    color: "#626B7E",
    fontWeight: "300",
    textAlign: "center",
    marginTop: "25px"
  },

  containEachInput: {
    background: "#fcfcfd",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0.5rem 1rem",
    border: "1px solid #D3DAE3",
    borderRadius: "8px",
    marginTop: "1rem",
  },
  eachInput: {
    border: "none",
    background: "#fcfcfd",
    fontWeight: "700",
    fontSize: "clamp(14px,1vw,42px)",
  },
  eachInputTitle: {
    color: "#ABB4BD",
    fontWeight: "400",
    fontSize: "clamp(14px,1vw,42px)",
  },

  loginBtn: commonStyles.redButtonWithShadow,
  flex: {
    display: "flex",
    justifyContent: "center",
  },

  containLoginBtn: {
    display: "flex",
    justifyContent: "center",
  },
  loginText: {
    color: "#ff4848",
    textAlign: "end",
    fontWeight: "500",
    fontSize: "14px",
  },
});

const UpdatePasswordScreen = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = location?.pathname?.trim()?.split("/")[1];

  const resetPasswordSelector = useSelector((state) => state.resetPassword);
  const { loading, payload, error } = resetPasswordSelector;
  const classes = styles();
  const params = useParams();
  const [checking, setChecking] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validations, setValidations] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    // console.log("password",password,confirmPassword);
    const validatedPassword = passwordValidation(password);
    const validatedComfirmPassword = confirmPasswordvalidation(
      password,
      confirmPassword
    );

    if (validatedPassword.isValid && validatedComfirmPassword.isValid) {
      setValidations({ password: "", confirmPassword: "" });
      const formdata = {
        verification_token: params.id,
        password: password,
      };
      await dispatch(resetPassword(formdata));
      // navigate(LOGIN_PATH);
    } else {
      setValidations({
        password: validatedPassword.message,
        confirmPassword: validatedComfirmPassword.message,
      });
    }
  };


  useEffect(() => {
    if (payload) {
      localStorage.setItem(LOCAL_STORAGE_ADVANCE_EXPORT, payload?.data?.data?.advance_export || false)
      setPassword("");
      setConfirmPassword("");
      navigate(`/${token}${LOGIN_PATH}`)
    }
    if (error) {

      setValidations({
        password: error?.data?.errors?.password || "",

        confirmPassord: error?.data?.errors?.password || "",
      })

    }
    if (!password.length) {
      axios
        .post(authEndpoints.verifyTokenForResetPassword, {
          verification_token: params.id,
        })
        .then((response) => {
          // EventEmitter.emit(
          //     EMITTER_SUCCESS,response?.data?.message
          //   );
        })
        .catch((err) => {
          EventEmitter.emit(
            EMITTER_ERROR,
            err?.response?.data?.message
          );
          navigate(FORGOT_PASSWORD_PATH);
        })
        .finally(() => {
          setChecking(false);
        });
    }
  }, [payload, error]);
  //Get Token for redirect path to the login
  const GetToken = (token) => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios
          .post(authEndpoints.checktoken, {
            _token: token,
          })

          .then((res) => {
            // console.log("resresresres", res);
            resolve(res.data.status);
          });
      } catch (e) {
        reject(false);
      }
    });
  };
  useEffect(() => {
    GetToken(token)
      .then((res) => { })
      .catch((err) => {
        navigate("/");
      });
  }, []);
  return checking ? (
    <FullScreenLoader />
  ) : (
    <Box component="main" className={classes.container}>
      <Box className={classes.leftContainer}>
        <Box sx={{ width: 1 }}>
          <div className={classes.containLogo}>
            <img src={FUTUNEAR_IMAGE} className={classes.logo} alt="" />
          </div>
          {/* Main auth form here */}
          <Box className={classes.card}>
            <Box component="form" onSubmit={handleUpdatePassword}>
              <TypographyInline
                size={"sm"}
                color={GREY.QUATINARY}
                fontWeight={fontWeight.MEDIUM}
                label={"Password"}
              />
              <div className={classes.m4Top}>
                <InputField
                  value={password}
                  size={"md40"}
                  type="password"
                  variant={validations.password ? "error" : "default"}
                  errormessage={validations.password}
                  placeholder={"Enter Password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className={classes.mTop}>
                <TypographyInline
                  size={"sm"}
                  color={GREY.QUATINARY}
                  fontWeight={fontWeight.MEDIUM}
                  label={"Confirm Password"}
                />
                <div className={classes.m4Top}>
                  <InputField
                    value={confirmPassword}
                    size={"md40"}
                    type="password"
                    variant={validations.confirmPassword ? "error" : "default"}
                    errormessage={validations.confirmPassword}
                    placeholder={"Confirm Password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              {/* <CustomTextField
                // sx={{marginTop:"1rem"}}
                fullWidth
                label="Password"
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {validations.password && (
                <ErrorMessage message={validations.password} />
              )}

              <CustomTextField
                sx={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}
                fullWidth
                label="Confirm Password"
                variant="outlined"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {validations.confirmPassword && (
                <ErrorMessage message={validations.confirmPassword} />
              )} */}

              <Box className={classes.containLoginBtn}>
              <div style={{ width: "140px", marginTop: "12px" }}>
              <Button
                variant={"primary"}
                label={"Reset Password"}
                size={"md40"}
                isLoading={loading}
              />
            </div>
                {/* {loading ? (
                  <CircularLoader />
                ) : (
                  <button className={classes.loginBtn}>Reset Password</button>
                )} */}
              </Box>
            </Box>
          </Box>
          {/* Main auth form here */}
          <div>
            <p className={classes.termsPara}>
              ©{(new Date().getFullYear())} ReachIQ. All Rights Reserved. Privacy and Terms </p>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdatePasswordScreen;

import {
  PEOPLE_BASIC_DETAILS_REQUEST,
  PEOPLE_BASIC_DETAILS_FAIL,
  PEOPLE_BASIC_DETAILS_SUCCESS,
  PEOPLE_USER_DETAILS_REQUEST,
  PEOPLE_USER_DETAILS_SUCCESS,
  PEOPLE_USER_DETAILS_FAIL,
} from "../constants/peopleConstants";

export const peopleBasicDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PEOPLE_BASIC_DETAILS_REQUEST:
      return { loading: true };

    case PEOPLE_BASIC_DETAILS_SUCCESS:
      return { loading: false, payload: action.payload };

    case PEOPLE_BASIC_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const peopleUserDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PEOPLE_USER_DETAILS_REQUEST:
      return { loading: true };

    case PEOPLE_USER_DETAILS_SUCCESS:
      return { loading: false, payload: action.payload, error: null };

    case PEOPLE_USER_DETAILS_FAIL:
      return { loading: false, error: action.payload, payload: null };

    default:
      return state;
  }
};

import React from "react";
import { Box } from "@mui/material";
import { styles } from "./styles";
import { CustomDropDown, RedCheckBox } from "../../../components";
import {
  DROPDOWN_PROFILE_TASK_DUEDATE,
  DROPDOWN_PROFILE_TASK_DUETIME,
  DROPDOWN_PROFILE_TASK_REMINDER,
} from "../../../utils/constants";

const Task = () => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <Box className={classes.componentContainer}>
        <h3 className={classes.title}>Defaults</h3>
        <p className={classes.callingPara}>
          Set preferences for task creation.
        </p>
        <Box className={classes.containAllSelect}>
          <Box className={classes.containEachSelect}>
            <h4 className={classes.inputTitle}>Due Date</h4>
            <div className={ classes.dropdownContain }   >
              <CustomDropDown
                items={DROPDOWN_PROFILE_TASK_DUEDATE}
                backgroundColor="#f9fafb"
                fontWeight="400"
              />
            </div>
          </Box>
          <Box className={classes.containEachSelect}>
            <h4 className={classes.inputTitle}>Due Time</h4>
            <div className={ classes.dropdownContain } >
              <CustomDropDown
                items={DROPDOWN_PROFILE_TASK_DUETIME}
                backgroundColor="#f9fafb"
                fontWeight="400"
              />
            </div>
          </Box>
          <Box className={classes.containEachSelect}>
            <h4 className={classes.inputTitle}>Reminder</h4>
            <div className={ classes.dropdownContain } >
              <CustomDropDown
                items={DROPDOWN_PROFILE_TASK_REMINDER}
                backgroundColor="#f9fafb"
                fontWeight="400"
              />
            </div>
          </Box>
        </Box>

        <Box className={classes.taskBottom}></Box>
        <h3 className={classes.title}>Defaults</h3>
        <p className={classes.callingPara}>
          Set preferences for task creation.
        </p>
        <Box className={classes.flex}>
          <RedCheckBox />
          <p className={classes.checkBoxText}>
            Get prompted to create a follow up task every time you complete a
            task from a list view
          </p>
        </Box>
      </Box>
    </Box>
  );
};
export default Task;

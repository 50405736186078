import React, { Fragment, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import CustomIcon from "../Icon/Icon";
import { SidebarTooltip } from "../Tooltip/SidebarTooltip";
import { useLocation, useNavigate } from "react-router-dom";
import { HandleClearAllFilterFromStore } from "../../redux/store/storeHelper";
import { useDispatch } from "react-redux";

const styles = makeStyles({
  coloredContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0.417vw 0",
    backgroundColor: "#d9eafa",
    margin: "0.2rem 0",
    borderBottom: "2px solid #1F83E3",
    cursor: "pointer",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0.417vw 0",
    margin: "0.2rem 0",
    borderBottom: "2px solid white",
    cursor: "pointer",
  },
  itemText: {
    color: "#626b7f",
    fontSize: "clamp(11px,0.625vw,14px)",
   
    textAlign:"center",
    marginTop:5,
    display:"flex"
    
  },
  itemIcon: {
    color: "#626b7f",
  },
  coloredIcon: {
    color: "#1F83E3",
  },
  coloredText: {
    color: "#1F83E3",
    fontSize: "clamp(12px,0.729vw,16px)",
    
    textAlign:"center"
  },
  toolTipPara: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#626B7E",
    alignSelf: "center",
    margin: "0 5px",
    cursor: "pointer",
  },
  redTooltipPara: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#ff4848",
    alignSelf: "center",
    margin: "0 5px",
    cursor: "pointer",
  },
  icon: {
    color: "#626B7E",
  },
  flex: {
    display: "flex",
    borderBottom: "1px solid #D3DAE3",
    padding: "0.6rem 1rem",
  },
});

const SidebarItem = ({ item, selected }) => {

  const classes = styles();
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [hovered, setHovered] = useState("");
  const token = location?.pathname?.trim()?.split("/")[1];
  
  const handleHover = (each) => {
    setHovered(each.title);
  };

  const handleHoverLeave = () => {
    setHovered("");
  };

  const handleClickTooltipElements = (each) => {
    HandleClearAllFilterFromStore( dispatch, location, navigate )
    each?.toolTipPath && navigate( '/'+token + each?.toolTipPath );
  }
  
  const toolTipTitle = (
    <div>
      {item?.toolTipElements?.map((each, index) => {
        return (
          <div
            onMouseEnter={() => handleHover(each)}
            onMouseLeave={handleHoverLeave}
            onClick={()=> handleClickTooltipElements( each ) }
            key={each.title}
            className={classes.flex}
            style={{
              border:
                item?.toolTipElements?.length === index + 1 ? "none" : null,
            }}
          >
            <each.icon className={ (hovered === each.title || '/' + token + each.toolTipPath === location.pathname ) ? classes.coloredIcon : classes.icon} size="large" />
            <p
              className={
                (hovered === each.title || '/' + token + each.toolTipPath === location.pathname)
                  ? classes.redTooltipPara
                  : classes.toolTipPara
              }
            >
              {each.title}
            </p>
          </div>
        );
      })}
    </div>
  );

  return (
    <Fragment>
      <SidebarTooltip title={toolTipTitle} placement="right">
        <Box
          className={
            selected ? classes.coloredContainer : classes.itemContainer
          }
        >
          <item.icon
            className={selected ? classes.coloredIcon : classes.itemIcon}
            style={{fontSize:"clamp(18px,1.250vw, 30px)"}}
          />
          <p
            className={selected ? classes.coloredText : classes.itemText}
            style={{
              paddingLeft: item?.toolTipElements?.length ? "0.5rem" : null,
            }}
          >
            {item.text}
            {item?.toolTipElements?.length ? (
              <CustomIcon
                name="fa fa-chevron-right"
                color={selected ? "#ff4848" : "#626B7E"}
                fontSize={8}
              />
            ) : null}
          </p>
        </Box>
      </SidebarTooltip>
    </Fragment>
  );
};

export default SidebarItem;

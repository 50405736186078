import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { DRAWER_CONTENT } from "../../utils/constants";
import { FUTUNEAR_LOGO } from "../../utils/constants/assets";
import {
  DASHBOARD_PATH,
  LOGIN_PATH,
  PROFILE_PATH,
} from "../../utils/constants/routes";
import SidebarItem from "./SidebarItem";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import ImageIcon from "../Icon/ImageIcon";
import { SidebarTooltip } from "../Tooltip/SidebarTooltip";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin, logoutUser } from "../../redux/actions/authActions";
import { InitialsIcon } from "..";
import { HandleClearAllFilterFromStore } from "../../redux/store/storeHelper";

const styles = makeStyles({
  sidebarContainer: {
    display: "flex",
  },
  drawer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "1vh 0 5vh 0",
    width: "6rem",
    borderRight: "1px solid #d3dae3",
    height: "100vh",
    position: "fixed",
    background:"#fff"
  },
  userImage: {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
  },
  userContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    bottom: 25,
    width: "100%",
    cursor: "pointer",
  },
  userName: {
    paddingTop: "5px",
    color: "#626b7f",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "center",
  },
  containIcon: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    background: "#fff",
    cursor: "pointer",
  },
  childrenContainer: {
    width: "calc(100% - 6rem)",
    background: "#f7f9fb",
    marginLeft: "6rem",
    position:"relative"
  },
  toolTipPara: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#626B7E",
    alignSelf: "center",
    margin: "0 5px",
  },
  icon: {
    color: "#626B7E",
    
  },
  coloredIcon: {
    color: "#1F83E3",
  },
  redTooltipPara: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#1F83E3",
    alignSelf: "center",
    margin: "0 5px",
    cursor: "pointer",
  },
  flex: {
    display: "flex",
    borderBottom: "1px solid #D3DAE3",
    padding: "0.6rem 1rem",
    cursor: "pointer",
  },
  navContainer: {
    overflowX: "hidden",
    background: "white",
    paddingTop: "1.302vw",
    overflowY:"auto",
  },
});

export default function DashboardLayout({ children }) {
  const dispatch = useDispatch();
  const classes = styles();
  const location = useLocation();
  const token = location?.pathname?.trim()?.split("/")[1];
  // console.log("token", token);
  const navigate = useNavigate();
  const userDetailsSelector = useSelector((state) => state?.userDetails);
  const userDetails = userDetailsSelector?.payload;
  const [profileHovered, setProfileHovered] = useState("");

  const handleSelection = (item) => {
    if (!item?.toolTipElements?.length) {
      // console.log("inside");
      HandleClearAllFilterFromStore( dispatch, location, navigate )
      item?.path && navigate('/'+token+item.path);
    }
    // setSelected(item);
  };

  const checkSelected = (items) => {
    if (items?.length) {
      const paths = items?.map((item) => '/'+token+item?.toolTipPath);
      // console.log('in here');
      return paths?.indexOf(location.pathname) === -1 ? false : true;
    }
    return false;
  };

  const handleProfileNavigation = async () => {
    // setSelected({});
    navigate('/'+token+PROFILE_PATH);
  };

  const handleLogout = async (each) => {
    if (each?.title === "Logout") {
      await dispatch(logoutUser());
      await dispatch(checkLogin("", null));
      // console.log("${token}${LOGIN_PATH}", `${token}${LOGIN_PATH}`);
      navigate('/'+token+`${LOGIN_PATH}`);
    }

    if (each?.title === "Ayush Goyal") {
      handleProfileNavigation();
    }
  };

  const handleHover = (each) => {
    setProfileHovered(each.title);
  };

  const handleHoverLeave = () => {
    setProfileHovered("");
  };

  // const toolTipTitle = (
  //   <div>
  //     {PROFILE_HOVER_CONTENT.map((each, index) => {
  //       return (
  //         <div
  //           onMouseEnter={() => handleHover(each)}
  //           onMouseLeave={handleHoverLeave}
  //           onClick={() => handleLogout(each)}
  //           key={each.title}
  //           className={classes.flex}
  //           style={{
  //             border:
  //               PROFILE_HOVER_CONTENT.length === index + 1 ? "none" : null,
  //           }}
  //         >
  //           <each.icon
  //             className={
  //               profileHovered === each.title
  //                 ? classes.coloredIcon
  //                 : classes.icon
  //             }
  //             size="large"
  //           />
  //           <p
  //             className={
  //               profileHovered === each.title
  //                 ? classes.redTooltipPara
  //                 : classes.toolTipPara
  //             }
  //           >
  //             {each.title}
  //           </p>
  //         </div>
  //       );
  //     })}
  //   </div>
  // );

  // console.log(' location.pathname', location.pathname);

  return (
    <Box className={classes.sidebarContainer}>
      <Box className={classes.drawer}>
        <Box>
          <Box
            className={classes.containIcon}
            onClick={() => navigate('/'+token+DASHBOARD_PATH)}
          >
            <ImageIcon image={FUTUNEAR_LOGO} width="40px" height="40px" />
          </Box>

          <Box className={classes.navContainer}>
            <List>
              {DRAWER_CONTENT.map((item, index) => {
                // console.log('itempath','/' + token + item.path, location.pathname);
                return  <div key={index} onClick={() => handleSelection(item)}>
                  <SidebarItem
                    item={item}
                    // selected={selected?.text === item.text ? true : false}
                    selected={
                      location.pathname === '/' + token + item.path ||
                      checkSelected(item?.toolTipElements)
                    }
                  />
                </div>
                })}
            </List>
          </Box>
        </Box>

        
      </Box>
      <Box component="main" className={classes.childrenContainer}>
        {children}
      </Box>
    </Box>
  );
}

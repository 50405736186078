import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
// import { allFilters } from "../../redux/actions/filterActions";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomIcon from "../Icon/Icon";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  justifyContent: "center",
});

const styles = makeStyles({
  navaigationBtn: {
    padding: "10px",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    cursor: "pointer",
    background: "white"
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center"
  },
  selectedDot: {
    color: "#ff4848",
    borderRadius: "50%",
    border: "none",
    background: "#FFE9E9",
    width: "32px",
    height: "32px",
    margin: "0 5px ",
    cursor: "pointer",
    fontWeight: "700"
  },
  normalDot: {
    background: "white",
    border: "none",
    color: "#212833",
    margin: "0 8px",
    cursor: "pointer",
    fontWeight: "700"
  },
  selectTag: {
    margin: "5px 10px 5px 10px"
  },
  nav: {
    borderTop: "1px solid #D3DAE3",
    padding: "2px 0"
  },
  MenuItem: {
    fontWeight: "400"
  }
});

const getPaginationCount = (records, recordPerPage = 25) => {
  return Math.ceil(records / recordPerPage);
};

export default function CommonPagination({ defaultPage, totalRecords, recordPerPage, action, prevQuery }) {


  const count = totalRecords ? getPaginationCount(totalRecords, recordPerPage) : 1;
  const classes = styles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [age, setAge] = React.useState(recordPerPage || 25);
  const handleChange = (event) => {
    setAge(event.target.value);
    dispatch(action({ ...prevQuery, size: event?.target?.value, page: 0 }, navigate));

  };

  const { items } = usePagination({
    count: count,
    defaultPage: defaultPage || 1,
    boundaryCount: 2,
  });

  const handleChangePage = (page, event) => {
    if (event) {
      event();
    }
    dispatch(action({ ...prevQuery, page: page - 1 }, navigate));
  };


  return (
    <nav className={classes.nav} >
      <List>
        {/* here dropdown */}

        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <button
                type="button"
                // className={classes.countBtn}
                style={{
                  fontWeight: selected ? "bold" : undefined,
                  color: selected ? "#ff4848" : undefined,
                }}
                className={selected ? classes.selectedDot : classes.normalDot}
                {...item}
                onClick={() => handleChangePage(page)}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button
                type="button"
                {...item}
                style={{
                  cursor:
                    type === "previous" && defaultPage === 1
                      ? "no-drop"
                      : undefined,
                }}
                className={classes.navaigationBtn}
                onClick={() => handleChangePage(page, item?.onClick)}
              >
                {type === "previous" ? (
                  <CustomIcon
                    name="fa fa-chevron-left"
                    color="#626b7e"
                    fontSize="12px"
                  />
                ) : (
                  <CustomIcon
                    name="fa fa-chevron-right"
                    color="#626b7e"
                    fontSize="12px"
                  />
                )}
              </button>
            );
          }

          return (
            <li key={index} className={classes.flexAlignCenter} >
              {children}
            </li>
          );
        })}
        <FormControl size="small" >
          <Select
            className={classes.selectTag}
            value={age}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={10} > <p className={classes.MenuItem} >10</p> </MenuItem>
            <MenuItem value={25}><p className={classes.MenuItem} >25</p></MenuItem>
            <MenuItem value={50}><p className={classes.MenuItem} >50</p></MenuItem>
            <MenuItem value={100}><p className={classes.MenuItem} >100</p></MenuItem>
          </Select>
        </FormControl>
      </List>
    </nav>
  );
}


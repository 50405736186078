import { COLUMNS_KEYS_VALUES, EMAIL_ENCODING_SALT, MONTH_INDEX } from "../constants";

// Dummy data create for table
export function createData(
  name,
  jobTitle,
  company,
  contactLocation,
  employees,
  industry,
  keywords,
  email,
  associatedComapny
) {
  return {
    name: name,
    jobTitle: jobTitle,
    company: company,
    contactLocation: contactLocation,
    employees: employees,
    industry: industry,
    keywords: keywords,
    email: email,
    actions: "Save",
    associatedComapny: associatedComapny,
  };
}

export const generateRandomColor = () => {
  const colors = [
    "#7954F6",
    "#3ACAF9",
    "#F6DF5D",
    "#11D69F",
    "#EE3962",
    "#F77F53",
    "#663E70",
  ];
  const index = Math.floor(Math.random(0, 100) * colors.length);
  return colors[index];
};

export const dragArrayIndexes = (array, sourceIndex, destinationIndex) => {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    if (i !== sourceIndex) {
      result.push(array[i]);
    }
    if (i === destinationIndex) {
      result.push(array[sourceIndex]);
    }
  }
  return result;
};

const decipher = () => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(EMAIL_ENCODING_SALT).reduce((a, b) => a ^ b, code);
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
};

export const decryptEmail = (text) => {
  const myDecipher = decipher();
  return myDecipher(text);
};

export const removeObjFromArray = (arr, obj) => {
  let filtered = arr.filter(function (el) {
    return el.id !== obj.id;
  });
  return filtered;
};

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export const filterSelectedIndexes = (array, type = "index") => {
  let result1 = array?.filter((item) => item.isChecked);
  return result1.map((item) => item[type]);
};

export const filterTableName = (firstName, lastName) => {
  const fullName = firstName + " " + lastName;
  return `${fullName.slice(0, 20)}${fullName.length >= 21 ? "..." : ""}`;
};

export const createCompanyURL = (website) => {
  try {
    const URL1 = new URL(website);
    const hostname = URL1.hostname;
    const companyHost = hostname.split("www.")[1];
    const companyURL = `https://logo.clearbit.com/${companyHost}`;

    return companyURL;
  } catch (error) {
    return website;
  }
};

export const innerBorder = (color) => {
  return `inset -1px 0 0 ${color}, inset 0 -1px 0 ${color}, inset 1px 0 0 ${color}, inset 0 1px 0 ${color}`;
};

export const innerLeftBorder = (color, width = "2px") => {
  return `inset ${width} 0px 0px 0px ${color}`;
};

export const innerTopBorder = (color, width = "1px") => {
  return `inset 0px ${width} 0px 0px ${color}`;
};

export const innerBottomBorder = (color, width = "1px") => {
  return `inset 0px -${width} 0px 0px ${color}`;
};
export const filterQuery = (query) => {
  let filtered = {};
  for (let i in query) {
    if (i === "min_revenue") {
      filtered[i] = Number(query[i]);
    } else if (i === "max_revenue") {
      filtered[i] = Number(query[i]);
    } else if (i === "page") {
      filtered[i] = Number(query[i]);
    }else if(i==="sort"){
      filtered[i]= query[i]
    }
     else if (i === "limit") {
      filtered[i] = Number(query[i]);
    } else if (i === "search") {
      filtered[i] = (query[i]);
    }
    else if (i === "employee_number" && query[i]?.length ) {
      filtered[i] = query[i]?.map(item => Number(item));
    }

    else if (
      
      (i === "all_tenant_user_id" && query[i]?.length) ||
      (i === "transaction" && query[i]?.length) ||
      (i === "description" && query[i]?.length) ||
      (i === "type" && query[i]?.length) ||
      (i === "from_date" && query[i]?.length) ||
      (i === "to_date" && query[i]?.length) ||
      (i === "tenant_id" && query[i]?.length) ||
      (i === "available_credits" && query[i]?.length) ||
      (i === "company_name" && query[i]?.length) ||
      (i === "email" && query[i]?.length) ||
      (i === "name" && query[i]?.length) ||
      (i === "first_name" && query[i]?.length) ||
      (i === "sort" && query[i]?.length) ||
      (i === "contact_lists" && query[i]?.length) ||
      (i === "e_verify_max_date" && query[i]?.length) ||
      (i === "e_verify_min_date" && query[i]?.length) ||
      (i === "direction" && query[i]?.length) ||
      (i === "country" && query[i]?.length) ||
      (i === "state" && query[i]?.length) ||
      (i === "city" && query[i]?.length) ||
      (i === "status" && query[i]?.length)
    ) {
      filtered[i] = query[i];
    } else if (query[i]?.length) {
      filtered[i] = [...new Set(query[i])];
    }
  }
  return JSON.parse(JSON.stringify(filtered));
};

export function debounceHelper(func, delay) {
  let timer;

  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}


export const sortArrayWithValue = (array, key) => {
  return array?.sort( (a,b)=> a[key] - b[key] )
}

export const findSortedValue = (value, items) => {
  return items?.find((item) => item.id === value);
};

export const filterItemsForSorting = (items, value) => {
  if (!value) {
    return items;
  }
  return items?.filter((item) => item.id !== value);
};

export const csvDownload = (data, name, delimiter) => {
  const items = data;
  const filename = name || `contact_export.csv`;
  const d = delimiter || `,`;

  const header = Array.from(
    new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], []))
  );
  let csv = items.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName] || "")).join(d)
  );
  csv.unshift(header.join(d));
  csv = csv.join("\r\n");

  const blob = new Blob([csv], {
    type: "text/plain;charset=utf-8",
  });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
    return;
  }
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = filename;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
}

export const generateExportData = (data) => {
  // const removedIdArray = data?.map(({ _id, ...rest }) => {
  //   return rest;
  // });
  const newArray = [];
  // console.log(1);
  for (let i = 0; i < data?.length; i++) {
    // console.log(2, i);
    let eachObj = {};
    for (let j in data[i]) {
      // console.log(
      //   "eachhhh",
      //   COLUMNS_KEYS_VALUES[j],
      //   "-----",
      //   j,
      //   "-----",
      //   data[i][j]
      // );
      eachObj[COLUMNS_KEYS_VALUES[j]] = data[i][j];
      // newArray.push({ [COLUMNS_KEYS_VALUES[j]]: data[i][j] });
    }
    newArray.push(eachObj);
    eachObj = {};
  }
  // console.log(4);

  // console.log("removedIdArray", newArray);
  return newArray;
};

export const getSelectedColumns = (items) => {
  const columns = items?.filter((item) => item.isChecked)?.map((item) => item.id)
  return [...columns, 'last_name'];
};

export const filterUserName = (name) => {
  return name?.length > 15 ? `${name?.slice(0,15)}...` : name;
}

export const  disbaleFiled = [
"company_name",
"annual_revenue",
"company_address",
"company_city",
"company_country",
"company_facebook_url",
"company_linkedin_url",
"company_phone_number",
"company_state",
"company_technologies",
"company_twitter_url",
"company_website",
"contact_linkedin_url",
"seo_description",
"number_of_employees",
"industry",
"company_keywords"]

export const getErrorMessage = (errors, commonError) => {
  console.log("errors", errors);
  for (let i in errors) {
    if (errors[i]?.length) {
      return errors[i];
    }
  }
  return commonError || "No Error Message from Server";
};

export const JSONtoQueryString = (JSON) => {
  return (
    "?" +
    Object.keys(JSON)
      .map((key) => key + "=" + JSON[key])
      .join("&")
  );
};

export const textEllipsesHandler = (value, sliceLength) => {
  let result;
  if (typeof value === "string") {
    result = value?.slice(0, sliceLength);
    if (value.length > sliceLength) {
      result += "...";
    }
    return result;
  }
  return value;
};
export const timeStampToDate = (timeStamp) => {
  let tempDateTime = new Date(timeStamp);
  let month = MONTH_INDEX[tempDateTime.getMonth()];
  let date = tempDateTime.getDate();
  let year = tempDateTime.getFullYear();
  let dateTime = `${month} ${date}, ${year}`;
  return dateTime;
};
export const timeStampToDateTime = (timeStamp) => {
  let tempDateTime = new Date(timeStamp);
  let month = MONTH_INDEX[tempDateTime.getMonth()] || "--";
  let date = tempDateTime.getDate() || "--";
  let year = tempDateTime.getFullYear() || "--";
  let time =
    tempDateTime.toTimeString().split(" ")[0].split(":")[0] +
    ":" +
    tempDateTime.toTimeString().split(" ")[0].split(":")[1];
  let dateTime = `${month} ${date}, ${year}, ${time}`;
  return dateTime;
};
export const timeStampToTime = (timeStamp) => {
  let tempDateTime = new Date(timeStamp);
  let time =
    tempDateTime.toTimeString().split(" ")[0].split(":")[0] +
    ":" +
    tempDateTime.toTimeString().split(" ")[0].split(":")[1];
  let dateTime = `${time}`;
  return dateTime;
};

export const getSelectedColumnsCompany = (items) => {
  const columns = items
    ?.filter((item) => item.isChecked)
    ?.filter((item) => item.companyField)
    ?.map((item) => item.id);
  return [...columns];
};
export const reorderArrayByKeys = (arrayOfObjects, keyOrder) => {
  const reorderedArray = arrayOfObjects.map((obj) => {
    const reorderedObject = {};
    keyOrder.forEach((key) => {
      if (obj.hasOwnProperty(key)) {
        reorderedObject[key] = obj[key];
      }
    });
    return reorderedObject;
  });
  return reorderedArray;
};

export const processContactNumberFormat = (country, number) => {
  const valid_countries = ["united states", "us", "canada"];

  try {
    if (!valid_countries.includes(country.toLowerCase().trim())) {
      return number.replace(/["'`,]/g, "");
    } else {
      // Process contact number here.

      number = number.replace("+1", "");
      // Remove any non-digit characters from the phone number
      number = number.replace(/\D/g, "");
      // Format the phone number
      const formattedPhoneNumber = `(${number.slice(0, 3)}) ${number.slice(
        3,
        6
      )}-${number.slice(6)}`;
      return formattedPhoneNumber;
    }
  } catch (error) {
    return number;
  }
};

export const match_import_fields = (item, peopleArray, companyArray) => {
  console.log({ item }, { peopleArray }, { companyArray });
  const split_regex = /\-|\_|\ /;
  const temp_item = item?.trim()?.toLowerCase();
  const string = temp_item?.split(split_regex)?.join("");

  for (let i = 0; i < peopleArray?.length; i++) {
    const each_processed_element = peopleArray?.[i]?.["id"]
      ?.trim()
      ?.toLowerCase()
      ?.split(split_regex)
      .join("");
    if (string === each_processed_element) {
      return {
        property_type: "people",
        property_value: peopleArray?.[i]?.["id"],
      };
    }
  }

  for (let i = 0; i < companyArray?.length; i++) {
    const each_processed_element = companyArray?.[i]?.["id"]
      ?.trim()
      ?.toLowerCase()
      ?.split(split_regex)
      .join("");
    if (string === each_processed_element) {
      return {
        property_type: "company",
        property_value: companyArray?.[i]?.["id"],
      };
    }
  }

  return { property_value: "", property_type: "people" };
};

export const Logger = (logValue, message = "Logger Log ") => {
  return process.env.NODE_ENV === "development"
    ? console.log(message, logValue)
    : null;
};

export const match_import_fields_csv = (item, peopleArray, companyArray) => {
  console.log({ item }, { peopleArray }, { companyArray });
  const split_regex = /\-|\_|\ /;
  const temp_item = item?.trim()?.toLowerCase();
  const string = temp_item?.split(split_regex)?.join("");

  for (let i = 0; i < peopleArray?.length; i++) {
    const each_processed_element = peopleArray?.[i]?.["id"]
      ?.trim()
      ?.toLowerCase()
      ?.split(split_regex)
      .join("");
    if (string === each_processed_element) {
      return {
        property_type: "people",
        property_value: peopleArray?.[i]?.["id"]?.toLowerCase(),
      };
    }
  }

  for (let i = 0; i < companyArray?.length; i++) {
    const each_processed_element = companyArray?.[i]?.["id"]
      ?.trim()
      ?.toLowerCase()
      ?.split(split_regex)
      .join("");
    if (string === each_processed_element) {
      return {
        property_type: "company",
        property_value: companyArray?.[i]?.["id"],
      };
    }
  }

  return { property_value: "", property_type: "people" };
};

export const removeTagStyleFromHtml = (html) => {
  let temp_body = html;
  temp_body = temp_body.replaceAll("4px 8px", "0px");
  temp_body = temp_body.replaceAll("small", "inherit");
  temp_body = temp_body.replaceAll("rgb(255, 233, 233)", "transparent");
  temp_body = temp_body.replaceAll("rgb(255, 72, 72)", "inherit");
  // temp_body = temp_body.replaceAll("rgb(255, 233, 233)", "rgb(255,255,255)");
  // temp_body = temp_body.replaceAll("rgb(255, 72, 72)", "rgb(0,0,0)");
  return temp_body;
};

export const addTagStyleInHtml = (html) => {
  if (!html?.length) {
    return "";
  }

  function getPosition(string, subString, index) {
    return string?.includes(subString)
      ? string.split(subString, index).join(subString).length
      : -1;
  }

  let all_tags_fetched = false;
  let current_span_tag = 1;
  while (!all_tags_fetched) {
    const start_occurence = getPosition(html, "<span", current_span_tag);
    if (start_occurence === html.length || start_occurence === -1) {
      all_tags_fetched = true;
    } else {
      let parsed_tag;
      const end_occurence = getPosition(html, "</span>", current_span_tag);
      let each_tag = html?.slice(start_occurence, end_occurence);
      if (each_tag?.includes("{{") && each_tag?.includes("}}")) {
        // this tag is valid.
        parsed_tag = each_tag?.replace("transparent", "rgb(255, 233, 233)");
        console.log("ParsedTag________________", parsed_tag);
        parsed_tag = parsed_tag?.replace("inherit", "rgb(255, 72, 72)");
        parsed_tag = parsed_tag?.replace("0px", "4px 8px");
        html = html.replace(each_tag, parsed_tag);
      }
      // do this in last.
      current_span_tag += 1;
    }
  }
  return html;
};


export function replaceKeyInArrayOfObj(array, oldKey, newKey) {
  let newArr = array.map((obj) => {
    const newObj = {};
    for (const key in obj) {
      if (key === oldKey) {
        newObj[newKey] = obj[key];
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
  return newArr;
}
export const IMAGE_BASE_URL =
  "https://reachiq-email-template-images-new.s3.amazonaws.com/assets";
//Icons

export const REACHIQ_ICON = IMAGE_BASE_URL + "/assets/icons/REACHIQ_ICON.svg";
export const EDIT_COLUMNS_ICON = IMAGE_BASE_URL + "/assets/icons/Columns.svg";
export const CHECK_ICON = IMAGE_BASE_URL + "/assets/icons/Check.svg";
export const CHECK_ORANGE_ICON =
  IMAGE_BASE_URL + "/assets/icons/check_orange.svg";
export const SORT_BY_ICON = IMAGE_BASE_URL + "/assets/icons/SortByIcon.svg";
export const POLYGON_ICON = IMAGE_BASE_URL + "/assets/icons/Polygon.svg";
export const CROWN_ICON = IMAGE_BASE_URL + "/assets/icons/crown.svg";
export const EMAIL_ICON = IMAGE_BASE_URL + "/assets/icons/email.svg";
export const PHONE_ICON = IMAGE_BASE_URL + "/assets/icons/phone.svg";
export const TECHNOLOGY_ICON = IMAGE_BASE_URL + "/assets/icons/technology.svg";
export const RESET_ICON = IMAGE_BASE_URL + "/assets/icons/reset.svg";
export const USERS_ICON = IMAGE_BASE_URL + "/assets/icons/users.svg";
export const KEY_ICON = IMAGE_BASE_URL + "/assets/icons/key.svg";
export const LOGIN_CROSS_ICON =
  IMAGE_BASE_URL + "/assets/icons/login-cross.svg";
export const GOOGLE_ICON = IMAGE_BASE_URL + "/assets/icons/google.svg";
export const USER_ICON = IMAGE_BASE_URL + "/assets/icons/UserIcon.svg";
export const EDIT_ICON = IMAGE_BASE_URL + "/assets/icons/edit.svg";
export const THEME_ICON = IMAGE_BASE_URL + "/assets/icons/theme.svg";
export const EMAIL_LOCK_ICON =
  IMAGE_BASE_URL + "/assets/icons/email_lock_icon.svg";
export const SNACKBAR_SUCCESS_ICON =
  IMAGE_BASE_URL + "/assets/icons/snackbar_success_icon.svg";
export const SNACKBAR_CLOSE_ICON =
  IMAGE_BASE_URL + "/assets/icons/snackbar_close_icon.svg";
export const SNACKBAR_ERROR_ICON =
  IMAGE_BASE_URL + "/assets/icons/snackbar_error_icon.svg";
export const MAKE_DEFAULT_ICON =
  IMAGE_BASE_URL + "/assets/icons/make_default.svg";
export const MAKE_DEFAULT_WHITE_ICON =
  IMAGE_BASE_URL + "/assets/icons/make_default_white.svg";
export const BOOKMARK_ICON = IMAGE_BASE_URL + "/assets/icons/bookmark_icon.svg";
export const VERIFIED_USER_ICON =
  IMAGE_BASE_URL + "/assets/icons/verified_user.svg";

//Images

export const USER_IMAGE = IMAGE_BASE_URL + "/assets/images/user.jpg";
export const DARK_THEME_IMAGE = IMAGE_BASE_URL + "/assets/images/darkTheme.png";
export const LIGHT_THEME_IMAGE =
  IMAGE_BASE_URL + "/assets/images/lightTheme.png";
export const AUTO_THEME_IMAGE = IMAGE_BASE_URL + "/assets/images/autoTheme.png";
export const LOGIN_ANALYTICS_IMAGE =
  IMAGE_BASE_URL + "/assets/images/login-analytics.png";
export const LOGIN_BACKGROUND =
  IMAGE_BASE_URL + "/assets/images/revo-login-bg.svg";
export const LOGIN_REACHIQ_LOGO =
  IMAGE_BASE_URL + "/assets/images/login_reachiq.svg";
export const USA_FLAG = IMAGE_BASE_URL + "/assets/images/usa-flag.png";
export const INDIA_FLAG = IMAGE_BASE_URL + "/assets/images/india-flag.jpeg";
export const DONE_IMAGE = IMAGE_BASE_URL + "/assets/images/done.png";
export const MAIL_IMAGE = IMAGE_BASE_URL + "/assets/images/mail.png";
export const SEARCH_IMAGE = IMAGE_BASE_URL + "/assets/images/search-icon.svg";
export const DUMMY_COMPANY_IMAGE =
  IMAGE_BASE_URL + "/assets/images/dummy_company.svg";
export const VIEWS_ACTION_IMAGE =
  IMAGE_BASE_URL + "/assets/images/viewsAction.svg";
export const NO_RECORD_IMAGE = IMAGE_BASE_URL + "/assets/images/noRecord.svg";
export const SEQUENCE_IMAGE =
  IMAGE_BASE_URL + "/assets/images/sequence_image.svg";
export const ADD_STEPS_IMAGE =
  IMAGE_BASE_URL + "/assets/images/steps_image.svg";
export const AUTOMATED_MAIL_IMAGE =
  IMAGE_BASE_URL + "/assets/images/automated_mail.svg";
export const AUTOMATED_CALL_IMAGE =
  IMAGE_BASE_URL + "/assets/images/automated_call.svg";
export const AUTOMATED_TODO_IMAGE =
  IMAGE_BASE_URL + "/assets/images/automated_todo.svg";

export const BLUE_TOY = IMAGE_BASE_URL + "/assets/images/bluetoy.svg";

// gifs

export const DONE_GIF = IMAGE_BASE_URL + "/assets/gifs/done.gif";

// Pending Images

export const CAMPAIGN_IMAGE = "/assets/images/campaign_image.svg";
export const SIGNUP_BG_IMAGE = "/assets/images/signup-bg.svg";
export const DASHBOARD_BG_IMAGE = "/assets/images/dashboard-bg.svg";
export const DASHBOARD_EMPTY_IMAGE = "/assets/images/emptydashboard.svg";
export const CHART_IMAGE = "/assets/images/chart-img.svg";
export const NOTIFICATION_IMAGE = "/assets/images/notification-status.svg";
export const DOWNARROW_IMAGE = "/assets/images/downarrow.svg";
export const UPARROW_IMAGE = "/assets/images/uparrow.svg";
export const TOTAL_IMAGE = "/assets/images/coin.svg";
export const REACHIQ_ICON_NEW = "/assets/images/reachiqlogo.svg";
export const WIDGET_CORNER = "/assets/images/widgetCorner.svg";
export const CALENDAR = "/assets/images/CalendarPreview.svg";
export const MICROSOFT_ICON = "/assets/icons/microsoft.svg";
export const PIE_CHART_IMAGE = "/assets/images/pieChart.svg";
export const LINE_CHART_IMAGE = "/assets/images/lineChart.svg";
export const VBAR_CHART_IMAGE = "/assets/images/vBarChart.svg";
export const HBAR_CHART_IMAGE = "/assets/images/hBarChart.svg";
export const TABLE_CHART_IMAGE = "/assets/images/tableChart.svg";
export const BOX_CHART_IMAGE = "/assets/images/boxChart.svg";
export const PIECHART_DISABLED = "/assets/images/Piechart_Disabled.svg";
export const SELECTED_CHECKBOX = "/assets/images/selected_checkbox.svg";
export const PARTIAL_CHECKBOX = "/assets/images/partial_checkbox.svg";
export const PARTIAL_HOVERED_CHECKBOX =
  "/assets/images/partial_hovered_checkbox.svg";
export const SELECTED_DISABLED_CHECKBOX =
  "/assets/images/selected_disabled_checkbox.svg";
export const DEFAULT_CHECKBOX = "/assets/images/default_checkbox.svg";
export const DEFAULT_DISABLED_CHECKBOX =
  "/assets/images/default_disabled_checkbox.svg";
export const HOVERED_CHECKBOX = "/assets/images/hovered_checkbox.svg";
export const NO_SORT = "/assets/images/no_sort.svg";
export const ASCENDING_SORT = "/assets/images/ascending.svg";
export const DESCENDING_SORT = "/assets/images/descending.svg";
export const LINKEDIN_IMAGE = "/assets/images/linkedin.svg";
export const SELECTED_SELECTED_COLUMNS =
  "/assets/images/selected_selected_column.svg";
export const SELECTED_ALL_COLUMNS = "/assets/images/selected_all_column.svg";
export const SELECTED_COLUMNS = "/assets/images/selected_column.svg";
export const ALL_COLUMNS = "/assets/images/all_column.svg";
export const CROSS_ICON = "/assets/icons/cross_icon.svg";
export const PIN_ICON = "/assets/icons/pin_icon.svg";
export const SEARCH_PROSPECTS_IMAGE = "/assets/images/search_image.svg";
export const SAD_FACE = "/assets/images/SAD_FACE.svg";
export const CSV = "/assets/icons/csv.svg";
export const OOPS_IMAGE = "/assets/images/oop.svg";
export const TEMPLATE_PREVIEW = "/assets/images/tempPrev.svg";
export const PEOPLE_IMAGE = "/assets/images/PeopleImage.svg";
export const COMPANY_IMAGE = "/assets/images/CompanyImage.svg";
export const FUTUNEAR_IMAGE = "/assets/images/future-near.svg";
export const FUTUNEAR_LOGO = "/assets/images/futLogo.svg";


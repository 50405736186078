import { Breadcrumbs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BRAND, GREY } from "../../utils/constants/colors";
import { fontSize, fontWeight, ICON_LABELS } from "../../utils/constants/UI";
import IconComponent from "../Icon/IconComponent";
import TypographyInline from "../Typography/TypographyInline";

const styles = {
  headerLink: {
    textDecoration: "none",
  },
};

const CustomBreadcrumbs = ({ items }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  useEffect(() => {
    setBreadcrumbs([]);
    items.forEach((ele, index) => {
      if (ele?.path) {
        setBreadcrumbs((oldArray) => [
          ...oldArray,
          <Link
            key={index}
            id={ele?.id}
            to={ele?.path}
            style={styles.headerLink}
          >
            <TypographyInline
              size={"md"}
              label={ele?.label}
              color={GREY.TERTIARY}
              fontWeight={fontWeight.REGULAR}
            />
          </Link>,
        ]);
      } else {
        setBreadcrumbs((oldArray) => [
          ...oldArray,
          <TypographyInline
            key={index}
            color={BRAND.SECONDARY}
            fontWeight={fontWeight.MEDIUM}
            label={ele.label}
            size="md"
          />,
        ]);
      }
    });
  }, [items]);

  return (
    <Breadcrumbs
      sx={{
        "& ol": {
          gap: '4px'
        },
        "& .MuiBreadcrumbs-separator": {
          margin: "0px !important",
        }
      }}
      separator={
        <IconComponent
          iconLabel={ICON_LABELS.NAVIGATE_NEXT}
          fontSize={fontSize.XS}
          color={GREY.SECONDARY}
        />
      }
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;

// ------------- NOTE ----------------
// Props Description:-
// items :- It should be array of object and every object should have three keys - id, label and path. If you want to display only label then don't pass path in the object only pass id and label for that.

import { makeStyles } from "@mui/styles";
import { SHADOW, borderRadius } from "../../utils/constants/UI/uiConstants";
import { BORDER, GREY } from "../../utils/constants/colors";

export const styles = makeStyles({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "red !important",
      border: "1px solid red",
    },
  },
  inputContainer: {
    width: "100%",
    margin: "4px 0px",
  },
  input: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
    borderRadius: borderRadius.primary,
    padding: "0px 10px !important",
    width: "100% !important",
    height: "40px !important",
    border: `1px solid ${BORDER.HOVER} !important`,
    "&:focus": {
      boxShadow: SHADOW.input,
      border: `1px solid ${GREY.PRIMARY} !important`,
    },
    "&:hover": {
      border: `1px solid ${GREY.TERTIARY} !important`,
    },
  },
  Test: {
    width: "100% !important",
    border: `1px solid ${BORDER.HOVER}`,
    borderRadius: "2px",
    display: "flex",
    alignItems: "center",
  },
  input1: {
    padding: "12px 6px !important",
    height: "36px !important",
    width: "100% !important",
    // padding: "1.5rem 1rem !important",
  },
  Button: {
    paddingTop: "1rem",
    width: "150px",
  },
  listBox: {
    // width: "83%",
    //upali
    position: "relative !important",
    border: "none !important",
  },
  marginTop: {
    marginTop: "1rem",
  },
  inviteBtn: {
    background: "#ff4848",
    border: "none",
    color: "white",
    width: "10vw",
    padding: "1rem 2.5rem",
    marginTop: "1rem",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
  },
});

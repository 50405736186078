import React from "react";

const RedCheckBox = ({ shadow, partial, ...props }) => {
  return (
    <div className="check-box-group" style={{ alignSelf: "center" }}>
      <label
        style={{ margin: props.noMargin && 0 }}
        className={partial ? "partial-checkbox" : "custom-checkbox"}
        tab-index="0"
        aria-label="Checkbox Label"
      >
        <input
          type="checkbox"
          {...props}
          onChange={props?.onChange ? props?.onChange : () => {}}
        />
        <span
          className="checkmark"
          style={{
            boxShadow: shadow ? "0px 3px 6px #0000001A" : "unset",
            cursor: props.cursor ? props.cursor : "pointer",
          }}
        ></span>
      </label>
    </div>
  );
};

export default RedCheckBox;

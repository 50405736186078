import React, { useState } from 'react';
import AvatarName from '../Avatar/AvatarName';
import AvatarOutlined from '../Avatar/AvatarOutlined';
import { createCompanyURL } from '../../utils/helpers';
import Image from '../Image/Image';
import { DUMMY_COMPANY_IMAGE } from '../../utils/constants/assets';

const AvatarNameWithLink = ({ row }) => {

    const [error, setError] = useState(false);

    return error ? <AvatarName
            name={
                row?.first_name?.length && row?.last_name?.length
                    ? `${row?.first_name} ${row?.last_name}`
                    : "--"
            }
            size={"small"} 
        /> 
        : 
        <AvatarOutlined>
        <Image
            src={createCompanyURL(row["company_website"] || DUMMY_COMPANY_IMAGE )}
            style={{
                height: "18px",
                width: "18px"
            }}
            onError={() => setError(true)}
            alt=""
        />
    </AvatarOutlined>

}

export default AvatarNameWithLink;
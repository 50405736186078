export const initialQueryPayload = {
  job_title: [],
  job_title_not: [],
  industry: [],
  industry_not: [],
  employee_number: [],
  seniority: [],
  company_keywords: [],
  company_keywords_not: [],
  company_technologies: [],
  company_technologies_not: [],
  seo_description: [],
  seo_description_not: [],
  email_verification: [],
  exist_fields: [],
  not_exist_fields: [],
  company_ids: [],
  company_ids_not: [],
  page: 1,
  limit: 25
};

export const quarantineinitialQueryPayload = {
  quarantine_job_title: [],
  quarantine_job_title_not: [],
  quarantine_industry: [],
  quarantine_industry_not: [],
  quarantine_employee_number: [],
  quarantine_seniority: [],
  quarantine_company_keywords: [],
  quarantine_company_keywords_not: [],
  quarantine_company_technologies: [],
  quarantine_company_technologies_not: [],
  quarantine_seo_description: [],
  quarantine_seo_description_not: [],
  quarantine_email_verification: [],
  equarantine_xist_fields: [],
  quarantine_not_exist_fields: [],
  quarantine_company_ids: [],
  quarantine_company_ids_not: [],
};

export const stringQueryList = [
  "min_revenue",
  "max_revenue",
  "name",
  "sort",
  "direction",
  "page",
  "limit",
  "e_verify_max_date",
  "e_verify_min_date",
  'new_contacts',
  'search',
  'contact_lists'
];

export const filterHeaderJobTitle = {
  payload: {
    anyOf: false,
    notAnyOf: false,
    isKnown: false,
    isUnknown: false,
  },
};

export const filterHeaderIndustry = {
  payload: {
    anyOf: false,
    notAnyOf: false,
    isKnown: false,
    isUnknown: false,
  },
};

export const initialFilterPayload = {
  payload: {
    anyOf: false,
    notAnyOf: false,
    isKnown: false,
    isUnknown: false,
  },
};

export const quarantinefilterHeaderJobTitle = {
  payload: {
    anyOf: false,
    notAnyOf: false,
    isKnown: false,
    isUnknown: false,
  },
};

export const quarantinefilterHeaderIndustry = {
  payload: {
    anyOf: false,
    notAnyOf: false,
    isKnown: false,
    isUnknown: false,
  },
};

export const quarantineinitialFilterPayload = {
  payload: {
    anyOf: false,
    notAnyOf: false,
    isKnown: false,
    isUnknown: false,
  },
};

import { makeStyles } from "@mui/styles";
export const styles = makeStyles({
  header: {
    background: "#fff",
    borderBottom: "1px solid #d3dae3",
    padding: "1rem 1.25rem",
    display: "flex",
    justifyContent: "space-between",
    color: "#626b7e",
    alignItems: "center",
  },
  navbar: {
    display: "flex",
    alignItems: "center",
  },
});

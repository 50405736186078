import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Stack } from "@mui/material";
const SkeletonLoader = ({
  type = "text",
  skeletonWidth = "100%",
  skeletonHeight = "100%",
  stackWidth = "100%",
  stackHeight = "100%",
}) => {
  return (
    <Stack spacing={1} sx={{ width: stackWidth, height: stackHeight }}>
      <Skeleton variant={type} width={skeletonWidth} height={skeletonHeight} />
    </Stack>
  );
};

export default SkeletonLoader;

import { makeStyles } from "@mui/styles";
import { IMAGE_BASE_URL } from "../../utils/constants/assets";
import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import { BASE, PRIMARY } from "../../utils/constants/colors";
import { SHADOW } from "../../utils/constants/UI";
import { SCROLL_BAR } from "../../utils/constants/UI/uiConstants";

export const Styles = makeStyles({
  container: {
    backgroundColor: "#f9fafb",
    padding: 0,
    height: "100vh",
    overflow: "hidden",
  },
  root: {
    position: "relative",
  },
  // tableBoxheder:{
  //   width: "100%",
  //   padding: "16px",
  //   paddingBottom: "5px",
  //   display:"flex",
  //   justifyContent:"end"
  // },
  tableBoxheder: {
    // width: "100%",
    padding: "16px",
    // paddingBottom: "5px",
    background: "#FFFFFF",
    display: "flex",
    gap: "22px",
    alignItems: "center",
    justifyContent:"space-between",
    margin: "16px 16px 0 16px",
  },
  searchData: {
    width: "21.615vw",
  },
  displayFlex: {
    display: "flex",
    // alignItems:"center"
  },
  filterStyle: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  avatarFlex: {
    display: "flex",
    alignItems: "center",
    gap: ".833vw",
  },
  avatarFlex1: {
    display: "flex",
    alignItems: "center",
    gap: ".700vw",
  },
  companyIcon: {
    objectFit: "fill",
    height: "100%",
    width: "100%",
    borderRadius: "50%",
  },
  searchAndTableContainer: {
    height: "calc(100vh - 160px)",
    margin: "0px 16px",
    // paddingTop:"16px",
  },
  tableStyle: {
    height: "100%",
    background: BASE.WHITE,
    boxShadow: SHADOW.xl,
  },
  tableBox: {
    width: "100%",
    padding: "16px",
    paddingTop: "0px",
  },
  tablehead: {
    display: "flex",
    alignItems: "center",
  },
  tableContainer: {
    maxWidth: "100%",
    position: "relative",
    height: "calc(100vh - 225px)",
    borderRadius: "6px",

    ...TABLE_SCROLL_BAR,
  },
  toolTipContainerdd: {
    width: "50px",
    alignItems: "center",
    boxShadow: "0px 9px 66px #00000029",
    padding: "0.35em 0.65em",
    borderRadius: "5px",
    fontSize: "0.75em",
    marginBottom: "15px",
  },
  toolTipContainer: {
    width: "450px",
    background: "white",
    boxShadow: "0px 9px 66px #00000029",
    padding: "0.5rem 0",
    borderRadius: "5px",

    marginBottom: "15px",
  },
  width: {
    width: "initial!important",
    maxWidth: "450px!important",
  },

  formgroup: {
    fontSize: "12px",
    padding: "15px 15px 0",
    color: "#212529",
  },
  accountuserlabel: {
    color: "#626b7e",
    fontSize: "clamp(12px,.75vw,1rem)",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: "400",
  },
  accountuservalue: {
    marginTop: "12px",
    fontSize: "clamp(13px,.75vw,2rem)",
    color: "#212833",
    display: "block",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
  },
  fw500: {
    fontWeight: 500,
  },
  color212833: {
    color: "#212833",
  },
  block: {
    display: "block",
    margin: "5px 0",
    color: "#626b7e",
  },
  flag: {
    color: "#626b7e",
    width: "20px!important",
  },
  newdrp: {
    width: "146px",
    height: "33px",
  },
  me4: {
    marginRight: "1.25vw",
  },
  flbg: {
    background: "#f7f9fb",
    borderRadius: "6px",
    padding: "0.5rem 1rem",
  },
  font12: {
    fontSize: "clamp(12px,.677vw,14px)",
  },
  inlineflex: {
    display: "inline-flex",
    alignItems: "center",
  },
  link: {
    color: "#ff4848",
  },
  toolTipContainerd: {
    width: "150px",

    boxShadow: "0px 9px 66px #00000029",
    padding: "0.5rem 0",
    borderRadius: "5px",
    fontSize: 14,
    marginBottom: "15px",
  },
  width: {
    width: "initial!important",
    maxWidth: "450px!important",
  },
  tableHeadings: {
    color: "#102F51",
    fontWeight: "600",
    fontSize: "clamp(12px,0.729vw,16px)",
    background: "white",
    alignSelf: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  dataText: {
    color: "#626B7E",
    fontWeight: "500",
    fontSize: "clamp(12px,0.729vw,16px)",
    alignSelf: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  loading: {
    position: "fixed",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -40%)",
  },
  // actives: {
  //   color: "#389810",
  //   background: `url('${IMAGE_BASE_URL}/assets/icons/Polygon.svg') no-repeat 95% rgba(56, 152, 16, 0.1)`,
  // },
  // blocked: {
  //   color: "#ff4848",
  //   background: `url('${IMAGE_BASE_URL}/assets/icons/Polygon.svg') no-repeat 95% rgba(255, 72, 72,  0.1)`,
  // },
  // notapproved: {
  //   color: "#FFA033",
  //   background: `url('${IMAGE_BASE_URL}/assets/icons/Polygon.svg') no-repeat 95% rgba(255,243,226,1)`,
  // },
  // inactive: {
  //   color: "#1570FF",
  //   background: `url('${IMAGE_BASE_URL}/assets/icons/Polygon.svg') no-repeat 95% rgba(211,218,227,1)`,
  // },
  actives: {
    color: "#389810",
    background: `url('${IMAGE_BASE_URL}/assets/icons/Polygon.svg') no-repeat 95% rgba(56, 152, 16, 0.1)`,
  },
  blocked: {
    color: "#ff4848",
    background: `url('${IMAGE_BASE_URL}/assets/icons/Polygon.svg') no-repeat 95% rgba(255, 72, 72,  0.1)`,
  },
  notapproved: {
    color: "#FFA033",
    background: `url('${IMAGE_BASE_URL}/assets/icons/Polygon.svg') no-repeat 95% rgba(255,243,226,1)`,
  },
  inactive: {
    color: "#1570FF",
    background: `url('${IMAGE_BASE_URL}/assets/icons/Polygon.svg') no-repeat 95% rgba(211,218,227,1)`,
  },
  icons: {
    marginLeft: "5px",

    "@media (max-width: 1322px)": {
      margin: "0px",
    },
  },
  activestatus: {
    "@media (max-width: 1322px)": {
      margin: "0px",
    },
  },
  initalCol: {
    display: "flex",
    padding: "1rem 0",
    alignItems: "center",
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
  paddingleft: {
    paddingLeft: "14px",
    color: "#102F51",
    fontWeight: "600",
    fontSize: "clamp(12px,0.729vw,16px)",
  },
  detailcontainer: {
    padding: "1.5rem",
    height: "calc(100vh - 120px)",

    ...TABLE_SCROLL_BAR,
  },
  mainDiv: {
    padding: "16px 0",
    // marginLeft:"16.250vw",
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    "@media (max-width: 1470px)": {
      margin: "0px 8vw",
    },
  },
  leftDiv: {
    width: "600px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    height: "calc(100vh - 120px)",
    overflow: "auto",
    ...SCROLL_BAR,
  },
  leftDiv1: {
    width: "600px",
    display: "flex",
    flexDirection: "column",
    // justifyContent:"space-between",
    gap: "12px",
    height: "calc(100vh - 110px)",
    overflow: "auto",
    ...SCROLL_BAR,
  },
  backgroundWite: {
    backgroundColor: "#FFFFFF",
    borderRadius:"6px",
    boxShadow:"0px 1px 1px 0px #18181C0A"
  },
  padding12: {
    padding: "12px",
  },
  disCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #DBDBDB",
    height: "28px",
    width: "28px",
    borderRadius: "6px",
  },
  cursorP: {
    cursor: "pointer",
  },
  iconFlgSTyle: {
    border: "1px solid #DBDBDB",
    height: "28px",
    width: "28px",
    padding: "6px",
    marginLeft: "4px",
    borderRadius: "6px",
    cursor: "pointer",
    backgroundColor: PRIMARY.W_500,
  },
  iconFlgSTyle1: {
    border: "1px solid #DBDBDB",
    height: "28px",
    width: "28px",
    padding: "6px",
    marginLeft: "4px",
    borderRadius: "6px",
    cursor: "pointer",
    backgroundColor: BASE.WHITE,
  },
  iconFlgSTyle2: {
    border: "1px solid #DBDBDB",
    height: "28px",
    width: "28px",
    padding: "6px",
    marginLeft: "4px",
    borderRadius: "6px",
    cursor: "pointer",
    backgroundColor: BASE.WHITE,
  },
  leftHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #EFEFEF",
    paddingBottom: "12px",
  },
  leftBody: {
    paddingTop: "12px",
    display: "flex",
    gap: "50px",
  },
  leftMidBody: {
    paddingTop: "12px",
    display: "flex",
    justifyContent: "space-between",
    // gap:"50px",
  },
  innerLeftVal: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  lastDiv: {
    display: "flex",
  },
  leftLastInnerDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    borderRight: "1px solid #EFEFEF",
    paddingRight: "60px",
  },
  disAlignCen: {
    alignItems: "center",
  },
  rightLastInnerDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    // borderRight:"1px solid #EFEFEF",
    paddingLeft: "40px",
  },
  bottomDiv: {
    backgroundColor: "#F8F8F8",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "12px",
    borderRadius: "6px",
  },
  userInfoDiv: {
    backgroundColor: "#F8F8F8",
    padding: "16px",
    borderRadius: "6px",
  },
  userHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #EFEFEF",
    paddingBottom: "12px",
  },
  userBody: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "12px",
  },
  borderRad: {
    borderRadius: "6px",
    boxShadow:
      "0px 0px 16px rgba(24, 24, 28, 0.12), 0px 12px 8px rgba(24, 24, 28, 0.02)",
  },
  borderBottomFix: {
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    boxShadow:
      "inset -1px 0 0 #EFEFEF, inset 0 -1px 0 #EFEFEF, inset 1px 0 0 #EFEFEF, inset 0 1px 0 #EFEFEF",
    borderBottom: "1px solid #E6E6E6",
    padding: "12px 0px 12px 12px",
  },
  gapAc: {
    gap: "4px",
  },
  acctSt: {
    padding: "8px 12px",
  },
  padLeft:{
    paddingLeft:"4px"
  },
  textTransF:{
    textTransform:"capitalize"
  },
  filterFooter:{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    gap: "8px",
    padding:"12px 12px 12px 0",
    borderTop:"1px solid #E6E6E6",
  },
  filterBody1:{
    padding:"12px 12px",
    borderBottomLeftRadius:"6px",
    borderBottomRightRadius:"6px",
    height:"calc(368px - 199px)",
    ...SCROLL_BAR
  },
  locationDisplay:{
    display:"flex",
    flexDirection:"column",
    gap:"20px"
  },
  displayFlexCol:{
    display:"flex",
    flexDirection:"column",
    gap:"4px",
  },
  dialogHd:{
    display:"flex",
    alignItems:"center",
    gap:"8px",
    paddingBottom:"12px"
  },
  searchAndTableContainer1: {
    height: "calc(100vh - 200px)",
    "@media (max-width:1470px)": {
      height: "calc(100vh - 215px)",
    },
  },
  modalBodyContainer:{
    // padding:"16px",
  },
  tableStyle1: {
    height: "100%",
    background: BASE.WHITE,
    boxShadow: SHADOW.sm,
    //border:"1px solid #E6E6E6",
    borderRadius:"6px"
    
  },
  dialogFooter:{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    gap: "10px",
  },
  rowDaata: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
  },
  bodyPaddingContent:{
    display:"flex",
    flexDirection:"column",
    gap:"8px",
    background:"#FFFF",
    padding:"16px",
    borderRadius:"8px"
  },
  horizontalFlex: {
    display: "flex",
    gap: ".833vw",
    width: "100%",
  },
  verticalFlex: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "50%"
  },
  verticalFlex1: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  htmlEditor: {
    backgrounColor: "#ffff",
    border: "1px solid #e6e6e6",
    width: "100%",
    borderRadius: "6px"
  },
  rightFoot:{
    display:"flex",
    gap:"4px"
  },
  headerPreview: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    paddingBottom: "16px",
    // height:"250px"
  },
  displayData: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    width: "100%",
  },
  widthData: {
    width: "15%"
  },
  widthData1: {
    width: "80%"
  },
  widthData12: {
    width: "80%",
    display:"flex",
    gap:"8px",
    flexWrap:"wrap"
  },
  docContainer: {
    height:"100px",
    width:"160px",
    borderRadius:"6px",
    border:"1px solid #DCDCDC",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    // gap:"2px"
  },
  linkTextStyle:{
    color:"#5A5C60",
    fontSize:"14px",
  }

});
import {
  APPLY_COLUMN_UPDATE_FAIL,
  APPLY_COLUMN_UPDATE_REQUEST,
  APPLY_COLUMN_UPDATE_SUCCESS,
  COLUMNS_UPDATE_FAIL,
  COLUMNS_UPDATE_REQUEST,
  COLUMNS_UPDATE_SUCCESS,
  TABLE_DATA_FETCH_FAIL,
  TABLE_DATA_FETCH_REQUEST,
  TABLE_DATA_FETCH_SUCCESS,
  TABLE_ENDPOINT_CHANGE_FAIL,
  TABLE_ENDPOINT_CHANGE_REQUEST,
  TABLE_ENDPOINT_CHANGE_SUCCESS,
} from "../constants/tableConstants";

// import { tempHeadCells } from "../../utils/constants";


export const columnUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COLUMNS_UPDATE_REQUEST:
      return { loading: true };

    case COLUMNS_UPDATE_SUCCESS:
      return { loading: false, columns: action.payload };

    case COLUMNS_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const ApplyUpdateColumnReducer = (state = {}, action) => {
  switch (action.type) {
    case APPLY_COLUMN_UPDATE_REQUEST:
      return { loading: true };

    case APPLY_COLUMN_UPDATE_SUCCESS:
      return { loading: false, columns: action.payload };

    case APPLY_COLUMN_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const tableDataFetchReducer = (state = {}, action) => {
  switch (action.type) {
    case TABLE_DATA_FETCH_REQUEST:
      return { loading: true };

    case TABLE_DATA_FETCH_SUCCESS:
      return { loading: false, columns: action.payload, totalRecords: action.totalRecords };

    case TABLE_DATA_FETCH_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const tableEndpointChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case TABLE_ENDPOINT_CHANGE_REQUEST:
      return { loading: true };

    case TABLE_ENDPOINT_CHANGE_SUCCESS:
      return { loading: false, payload: action.payload };

    case TABLE_ENDPOINT_CHANGE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};



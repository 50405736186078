import React from "react";
import { makeStyles } from "@mui/styles";
import ImageIcon from "../Icon/ImageIcon";

const styles = makeStyles({
  button: {
    backgroundColor: "white",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    fontWeight: "400",
    padding: "0.25rem 1rem",
    display: "flex",
    fontSize: "14px",
    margin: "5px 10px",
    color: "#626b7e",
    cursor: "pointer",
    alignItems: "center",
    whiteSpace:"nowrap"
  },
  icon: {
    marginRight: ".2rem",
  },
  secondIcon: {
    alignSelf: "center",
  },
});

const TableSaveBtn = ({ message, icon, secondIcon, ...props }) => {
  const classes = styles();

  return (
    <button className={classes.button}  {...props} >
      {icon && <ImageIcon image={icon} />}
      {message}
      {secondIcon ? <ImageIcon image={secondIcon} /> : null}
     
    </button>
  );
};

export default TableSaveBtn;

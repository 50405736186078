import { REQUEST_CREDIT_REQUEST, REQUEST_CREDIT_SUCCESS, REQUEST_CREDIT_ERROR } from "../constants/requestcreditConstant";

export const requestcreditReducer = (state = {}, action) => {
    switch (action.type) {
      case REQUEST_CREDIT_REQUEST:
        return { loading: true };
  
      case REQUEST_CREDIT_SUCCESS:
        return { loading: false, columns: action.payload, totalRecords: action.totalRecords };
  
      case REQUEST_CREDIT_ERROR:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
export const QUARANTINE_LIST_GET_REQUEST = "QUARANTINE_LIST_GET_REQUEST"
export const QUARANTINE_LIST_GET_SUCCESS = "QUARANTINE_LIST_GET_SUCCESS"
export const QUARANTINE_LIST_GET_FAIL = "QUARANTINE_LIST_GET_FAIL"

export const SELECTED_QUARANTINE_LIST_REQUEST = "SELECTED_QUARANTINE_LIST_REQUEST"
export const SELECTED_QUARANTINE_LIST_SUCCESS = "SELECTED_QUARANTINE_LIST_SUCCESS"
export const SELECTED_QUARANTINE_LIST_FAIL = "SELECTED_QUARANTINE_LIST_FAIL"


export const QUARANTINE_DETAILS_REQUEST = "QUARANTINE_DETAILS_REQUEST"
export const QUARANTINE_DETAILS_SUCCESS = "QUARANTINE_DETAILS_SUCCESS"
export const QUARANTINE_DETAILS_FAIL = "QUARANTINE_DETAILS_FAIL"

import React from "react";
import { Box } from "@mui/material";


const InvalidRouteScreen = () => {
    return <Box>
       <p > Invalid route, please go home.</p>
    </Box>
}

export default InvalidRouteScreen;
export const PLATEFORM_USER_TABLE_DATA_FETCH_ERROR =
  "PLATEFORM_USER_TABLE_DATA_FETCH_ERROR";
export const PLATEFORM_USER_TABLE_DATA_FETCH_REQUEST =
  "PLATEFORM_USER_TABLE_DATA_FETCH_REQUEST";
export const PLATEFORM_USER_TABLE_DATA_FETCH_SUCCESS =
  "PLATEFORM_USER_TABLE_DATA_FETCH_SUCCESS";

export const PLUGIN_USER_TABLE_DATA_FETCH_ERROR =
  "PLUGIN_USER_TABLE_DATA_FETCH_ERROR";
export const PLUGIN_USER_TABLE_DATA_FETCH_REQUEST =
  "PLUGIN_USER_TABLE_DATA_FETCH_REQUEST";
export const PLUGIN_USER_TABLE_DATA_FETCH_SUCCESS =
  "PLUGIN_USER_TABLE_DATA_FETCH_SUCCESS";

  export const USER_DEATIL_DATA_FETCH_REQUEST = "USER_DEATIL_DATA_FETCH_REQUEST";
  export const USER_DETAIL_DATA_FETCH_SUCCESS = "const USER_DETAIL_DATA_FETCH_SUCCESS";
  export const USER_DETAIL_DATA_FETCH_ERROR = "USER_DETAIL_DATA_FETCH_ERROR";



  export const PLATEFORM_USER_TABLE_DATA_UPDATE_ERROR =
  "PLATEFORM_USER_TABLE_DATA_UPDATE_ERROR";
export const PLATEFORM_USER_TABLE_DATA_UPDATE_REQUEST =
  "PLATEFORM_USER_TABLE_DATA_UPDATE_REQUEST";
export const PLATEFORM_USER_TABLE_DATA_UPDATE_SUCCESS =
  "PLATEFORM_USER_TABLE_DATA_UPDATE_SUCCESS";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const styles = makeStyles({
  arrow: {
    color: "white !important",
  },
  popper:{
    margin:0,
    zIndex:'20 !important',
    '& .MuiTooltip-tooltip': {
      margin: "0 !important"
    },
  },
  highIndexPopper:{
    margin:0,
    zIndex:'1300 !important',
    '& .MuiTooltip-tooltip': {
      margin: "0 !important"
    },
  }

});

export const CustomizedTooltip = styled(({ className, noMarginTop = false, hightZIndex=false , ...props}) => {
  const classes = styles();

  return (
    <Tooltip
      
      {...props}
      componentsProps={{ tooltip: { className: className } }}
      classes={{ arrow: classes.arrow, popper: hightZIndex ? classes.highIndexPopper : noMarginTop ? classes.popper : null  }}
    />
  );
})(`
      color: white;
      background-color: transparent;
      font-size: 1.5em;
      padding: 0;
      z-index:1;
      width:120%;
      padding-bottom:1rem;
      border-radius:6px;
  `);

import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const styles = makeStyles({

    textfield: {
        background:"#D3DAE312",
        margin:"0.8rem 0 0 0",
        fontWeight:"400",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#D3DAE3",
          borderRadius:"6px"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#D3DAE3"
        },
        "& .MuiOutlinedInput-input": {
          color: "#626b7e"
        },
        "&:hover .MuiOutlinedInput-input": {
          color: "#626b7e"
        },
        // imput value
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: "#1D2029",
        },
        "& .MuiInputLabel-outlined": {
          color: "#626b7e"
        },
        //onselect label
        "& .MuiInputLabel-outlined.Mui-focused": {
          color: "#ABB4BD",
        },
         // on select border
         "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ff4848",
            borderWidth:"0.1rem"
          },
      },
})

const FilterTextField = ({ background, ...props }) => {

    const classes = styles()

    return <TextField
    { ...props } 
    placeholder="Enter Name"
    className={ classes.textfield }
    inputProps={{ style: { fontSize: "13px", color:"#212833", padding:"12px" } }} // font size of input text
    InputLabelProps={{ style: { fontSize: "13px" } }}
    sx={{ background: background ? background : null  }}
    
    
  />
}

export default FilterTextField;
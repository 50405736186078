import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { TEMP_COMPANY_URL } from "../../utils/constants";
import CustomIcon from "../Icon/Icon";
import InitialsIcon from "../Icon/InitialsIcon";
import { useSelector } from "react-redux";
import TableSaveBtn from "../Buttons/TableSaveBtn";
import ImageIcon from "../Icon/ImageIcon";
import { USER_IMAGE } from "../../utils/constants/assets";
import { RedCheckBox } from "..";
import { TABLE_SCROLL_BAR } from "../../utils/constants/styles";
import CommonPagination from "./CommonPagination";

const styles = makeStyles({
  container: {
    padding: "1rem",
    paddingTop: "0",
  },
  paperContainer: {
    width: "100%",
    marginBottom: 2,
    overflowX: "auto",
    boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.07)",
  },
  tableContainer: {
    height: "calc(100vh - 26rem)",
    // maxWidth: "92vw",
    position: "relative",
    ...TABLE_SCROLL_BAR,
  },
  tableHeadings: {
    color: "#0F2F51",
    fontWeight: "600",
    fontSize: "14px",
    background: "white",
    alignSelf: "center",
    whiteSpace: "nowrap",
    textTransform: "capitalize",
  },
  dataText: {
    color: "#626B7E",
    textTransform: "capitalize",
    fontWeight: "400",
    fontSize: "14px",
    alignSelf: "center",
    textOverflow: "ellipsis",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  email: {
    color: "#626B7E",
    fontWeight: "400",
    fontSize: "12px",
    alignSelf: "center",
    textOverflow: "ellipsis",
    paddingLeft: "5px",
  },
  dataTextName: {
    color: "#102F51",
    fontWeight: "600",
    fontSize: "14px",
    alignSelf: "center",
    paddingLeft: "5px",
    cursor: "pointer",
    letterSpacing: "0.5px",
    whiteSpace: "nowrap",
  },
  stepsTxt: {
    color: "#626B7E",
    background: "#F7F9FB",
    padding: "12px 1rem",
    borderRadius: "8px",
    whiteSpace: "nowrap",
    fontSize: "12px",
  },
  headIcons: {
    cursor: "pointer",
    paddingLeft: "5px",
    color: "#626B7E",
    alignSelf: "center",
  },
  selectedIcons: {
    alignSelf: "center",
    margin: "5px",
    color: "#626B7E",
  },
  date: {
    color: "#626B7E",
    fontSize: "12px",
    paddingLeft: "5px",
  },
  comapnyImage: {
    width: "30px",
    height: "30px",
    objectFit: "contain",
    padding: "2px",
    border: "1px solid #D1D1D1",
    borderRadius: "50%",
    marginRight: "5px",
    alignSelf: "center",
  },
  whiteBackground: {
    background: "white",
  },
  nameHeader: {
    display: "flex",
    padding: "1rem",
    boxShadow: "2px 1px 6px #0000000F",
  },
  otherHeader: {
    display: "flex",
    padding: "0 1rem",
    justifyContent: "space-between",
  },
  otherHeader1: {
    display: "flex",
    padding: "1rem",
    justifyContent: "center",
  },

  flex: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const classes = styles();
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows } =
    props;
  const getContactListSelector = useSelector((state) => state.getContactList);

  const createSortHandler = (property) => (event) => {};

  return (
    <TableHead>
      <TableRow sx={{ boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.07)" }}>
        {rows.map((headCell) => {
          return headCell.isChecked ? (
            <TableCell
              key={headCell.id}
              className={classes.tableHeadings}
              align={"left"}
              sx={{ padding: 0, minWidth: headCell.minWidth }}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <div
                className={
                  headCell.id === "list_name"
                    ? classes.nameHeader
                    : classes.otherHeader1
                }
              >
                {headCell.id === "list_name" && (
                  <RedCheckBox
                    disabled={true}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                )}
                <div
                  className={classes.flex}
                  onClick={createSortHandler(headCell.id)}
                >
                  <p className={classes.tableHeadings}>{headCell.label}</p>
                  {headCell?.label === "Status" ||
                  headCell?.label === "Actions" ? null : (
                    <CustomIcon
                      name={
                        headCell?.id ===
                        getContactListSelector?.payload?.query?.sort
                          ? `fa fa-sort-${getContactListSelector?.payload?.query?.order}`
                          : "fa fa-sort"
                      }
                    />
                  )}
                </div>
              </div>
            </TableCell>
          ) : null;
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const CommonTable = ({
  heads,
  data,
  imageTpye,
  singleAction,
  pagination = true,
  tableContainerHeight,
}) => {
  const classes = styles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [columns] = useState(heads);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <Box className={classes.container}>
      <Paper className={classes.paperContainer}>
        <TableContainer
          className={classes.tableContainer}
          sx={{ height: tableContainerHeight }}
        >
          <Table
            stickyHeader={true}
            sx={{
              overflowX: "auto",
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              rows={columns}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let dataColumns = columns;

                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      {dataColumns.map((column) => {
                        const value = row[column.id];
                        if (column.id === "name") {
                          return column.isChecked ? (
                            <TableCell
                              key={column.id}
                              sx={{ padding: 0 }}
                              className={[
                                classes.sticky,
                                !isItemSelected && classes.whiteBackground,
                              ]}
                            >
                              <div className={classes.otherHeader}>
                                <RedCheckBox
                                  onClick={(event) =>
                                    handleClick(event, row.name)
                                  }
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                                {imageTpye === "initials" ? (
                                  <InitialsIcon
                                    name={value}
                                    color="#ff4848"
                                    background="#FFE9E9"
                                  />
                                ) : (
                                  <ImageIcon
                                    image={USER_IMAGE}
                                    width="25px"
                                    height="25px"
                                    borderRadius="20px"
                                  />
                                )}

                                <p className={classes.dataTextName}>{value}</p>
                              </div>
                            </TableCell>
                          ) : null;
                        } else if (column.id === "action") {
                          return column.isChecked ? (
                            <TableCell
                              align="center"
                              sx={{
                                padding: "0.8rem 0rem",
                                display: "flex",
                                justifyContent: "center",
                              }}
                              key={column.id}
                            >
                              <TableSaveBtn message={`${singleAction || ""}`} />
                            </TableCell>
                          ) : null;
                        } else if (column.id === "company") {
                          return column.isChecked ? (
                            <TableCell key={column.id}>
                              <div style={{ display: "flex", width: "150px" }}>
                                <img
                                  src={TEMP_COMPANY_URL}
                                  className={classes.comapnyImage}
                                  alt="Company logo"
                                />
                                <p className={classes.dataText}>{value}</p>
                              </div>
                            </TableCell>
                          ) : null;
                        } else if (column?.type === "date") {
                          const date = new Date(value);
                          const date_string =
                            date?.toLocaleDateString() +
                            " " +
                            date?.toLocaleTimeString();
                          return column.isChecked ? (
                            <TableCell key={column.id}>
                              <p className={classes.dataText}>
                                {date_string || ""}
                              </p>
                            </TableCell>
                          ) : null;
                        } else if (column.isChecked) {
                          return (
                            <TableCell
                              key={column.id}
                              sx={{ minWidth: column.minWidth }}
                            >
                              <p className={classes.dataText}>{value}</p>
                            </TableCell>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {pagination && (
          <CommonPagination
            style={{
              paddingRight: "40%",
              color: "#6E6D7A",
              fontWeight: "400",
            }}
            component="div"
            totalRecords={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
};

export default React.memo(CommonTable);

import { makeStyles } from "@mui/styles";
import { BACKGROUND_COLOR } from "../../../../theme/colors";
import { INVISIBLE_SCROLL_BAR } from "../../../../utils/constants/styles";
// import {  INVISIBLE_SCROLL_BAR } from "../../../utils/constants/styles";

export const styles = makeStyles({
  flex: {
    display: "flex",
  },
  flexMobile: {
    display: "flex",
    paddingRight: "1rem",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      paddingRight: 0,
    },
  },

  container: {
    margin: "1.5rem 1rem",
    borderRadius: "6px",
    // width: "68vw",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  title: {
    fontWeight: "600",
    color: "#102F51",
    fontSize: "20px",
    alignSelf: "center",
  },
  security: {
    background: "white",
    padding: "1.5rem",
    //height: "calc(100vh - 7rem)",
    overflow: "scroll",
    ...INVISIBLE_SCROLL_BAR,
  },
  subTitle: {
    color: "#102F51",
    fontWeight: "700",
    fontSize: "15px",
    alignSelf: "center",
  },
  containEachSecurity: {
    padding: "1.2rem",
    border: "1px solid #D3DAE3",
    borderRadius: "8px",
    margin: "1rem 0",
    width: "48%",
    marginRight: "1rem",
    height: "auto",

    "@media (max-width: 768px)": {
      width: "100%",
      margin: "1rem 0",
    },
  },
  deleteAccountBox: {
    padding: "1.2rem",
    border: "1px solid #D3DAE3",
    borderRadius: "8px",
    margin: "1rem 0",
    width: "97.8%",
    marginRight: "1rem",
    height: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  securityInput: {
    margin: "1rem 0",
    display: "block",
    width: "100%",
    background: "#f9fafb",
    border: "1px solid #d3dae3",
    borderRadius: "6px",
    padding: "0.9rem",
    fontWeight: "300",
    fontSize: "14px",
  },
  keysInput: {
    margin: "1rem 0",
    display: "block",
    width: "100%",
    border: "1px solid #d3dae3",
    borderRadius: "6px",
    padding: "0.9rem",
    fontWeight: "300",
    fontSize: "14px",
  },
  sucurityBtn: {
    background: "white",
    padding: "0.7rem 1.5rem",
    border: "1px solid #ff4848",
    color: "#ff4848",
    borderRadius: "6px",
    cursor: "pointer",
    "&:hover": {
      transition: "300ms",
      background: "#ff4848",
      color: "white",
    },
  },
  securityPara: {
    fontWeight: "300",
    letterSpacing: "0.32px",
    color: "#626b7e",
    fontSize: "14px",
    lineHeight: "1.5",
  },
  containSecurityBoxes: {
    display: "flex",
    flexWrap: "wrap",
  },
  securityPhoneBox: {
    marginRight: "0",
  },
  logoutBtn: {
     width:"100px"
  },
  date: {
    color: "#626b7e",
    fontSize: "10px",
    alignSelf: "center",
  },
  containPasswordheading: {
    display: "flex",
    justifyContent: "space-between",
  },

  // Notifications Styles

  notificationSubTitle: {
    color: "#102F51",
    fontSize: "14px",
    fontWeight: "700",
  },
  notificationPara: {
    fontWeight: "300",
    fontSize: "13px",
    width: "100%",
    lineHeight: "1.5",
    margin: "0.5rem 0",
  },
  containGetNotified: {
    padding: "1.5rem 0",
    width: "50%",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  containEmailSend: {
    background: "#f9fafb",
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 1.5rem",
    borderRadius: "6px",
    "@media (max-width: 768px)": {
      width: "100%",
      padding: "1rem",
    },
  },
  containEmail: {
    alignSelf: "center",
  },
  notifications: {
    background: "white",
    padding: "1rem 1.5rem",
    margin: "1.5rem 0",
    "@media (max-width: 768px)": {
      background: "unset",
    },
  },
  notificationAction: {
    fontSize: "12px",
    fontWeight: "600",
    padding: "0 1rem",
    borderRight: "1px solid #d3dae3",
    color: "#0B1534",
    alignSelf: "center",
    cursor: "pointer",
  },
  selectedNotificationAction: {
    fontSize: "12px",
    fontWeight: "600",
    padding: "0 1rem",
    color: "#ff4848",
    alignSelf: "center",
  },
  notificationTopics: {
    border: "1px solid #D3DAE3",
    borderRadius: "6px",
    padding: "0.5rem",
    display: "flex",
    justifyContent: "space-between",
  },
  notificationInput: {
    border: "none",
    background: BACKGROUND_COLOR,
    width: "100%",
    paddingLeft: "0.5rem",
    fontWeight: "300",
  },
  containNotificationInput: {
    background: BACKGROUND_COLOR,
    width: "20vw",
    border: "1px solid #d3dae3",
    borderRadius: "4px",
    padding: "0.5rem",
    display: "flex",
    justifyContent: "flex-start",
  },
  containNotificationTopics: {
    padding: "1rem",
    background: BACKGROUND_COLOR,
    borderRadius: "4px",
    height: "35vh",
    overflow: "scroll",
    ...INVISIBLE_SCROLL_BAR,
  },

  // Account Default Styles
  accrdionDescription: {
    color: "#616A7D",
    fontWeight: "300",
    fontSize: "14px",
    borderTop: "1px solid #F3F5F7",
    padding: "0.8rem 1rem",
  },
  accountDefaultPara: {
    fontWeight: "400",
    color: "#626B7E",
    fontSize: "14px",
    alignSelf: "center",
    background: "#F9FAFB",
    padding: "0.8rem 1.5rem",
    borderRadius: "4px",
  },
  accountDefaultFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  accountDefaultContainer: {
    background: "white",
    padding: "1rem",
    borderRadius: "4px",
    height: "82vh",
    overflow: "scroll",
    "@media (max-width: 768px)": {
      background: "unset",
    },
    ...INVISIBLE_SCROLL_BAR,
  },
  containInputs: {
    padding: "1rem 0",
    display: "flex",
    flexWrap: "wrap",
  },
  inputTitle: {
    fontWeight: "300",
    fontSize: "13px",
    color: "#102F51",
    letterSpacing: "0.6px",
    alignSelf: "center",
    marginRight: "1rem",
  },
  accountDefaultInput: {
    background: "white",
    border: "1px solid #D3DAE3",
    padding: "1rem",
    borderRadius: "4px",
    width: "100%",
    margin: "1rem 0",
    fontWeight: "400",
    fontSize: "12px",
  },
  containInputTitle: {
    display: "flex",
  },
  inputIcon: {
    color: "#ff4848",
    width: "16px",
    height: "16px",
  },
  eachInputBox: {
    width: "30vw",
    marginRight: "1rem",
    "@media (max-width: 768px)": {
      width: "80vw !important",
    },
  },
  redText: {
    color: "#ff4848",
    cursor: "pointer",
  },
  containKeysInput: {
    // padding: "1rem 0 0 0",
    // width: "50%",
    marginBottom:"25px"
  },
});

export const STRINGS = {
    INVITE_USER: "Invite User",
    IS_EQUAL_TO: "is equal to",
    IS_NOT_EQUAL_TO: "is not equal to",
    REVENUE: "Revenue",
    IS_BETWEEN: "is between",
    CANCEL:"Cancel",
    UPDATE:"Update",
    USER_BELONG:"Users Belongs to different company"
  };
  
import { CONTENT_TABLE_DATA_FETCH_ERROR, CONTENT_TABLE_DATA_FETCH_REQUEST, CONTENT_TABLE_DATA_FETCH_SUCCESS } from "../constants/contentConstant";

export const contentlistReducer = (state = {}, action) => {
    switch (action.type) {
      case CONTENT_TABLE_DATA_FETCH_REQUEST:
        return { loading: true };
  
      case CONTENT_TABLE_DATA_FETCH_SUCCESS:
        return { loading: false, columns: action.payload, totalRecords: action.totalRecords };
  
      case CONTENT_TABLE_DATA_FETCH_ERROR:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  }; 
import React from "react";


import {
  DASHBOARD_PATH,
  PROFILE_PATH,
  CONTENT_PATH,
  USER_PATH,
  EVENT_PATH,
  CONTACT_PATH,
  TECHNOLOGY_PATH,
  TECHNOLOGY_WITHIN_PATH,
  COMPANY_SCREEN_PATH,
  BUSINESS_PATH,
} from "../utils/constants/routes";
import BusinessScreen from "../screens/BusinessScreen/BusinessScreen";

const ProfileScreen = React.lazy(() =>
  import("../screens/ProfileScreen/ProfileScreen")
);
const DashboardScreen = React.lazy(() =>
  import("../screens/DashobardScreen/DashboardScreen")
);
const ContentScreen = React.lazy(() =>
  import("../screens/Content/ContentScreen")
);

const ContactScreen = React.lazy(() => import("../screens/Contact/ContactScreen"));

const EventScreen = React.lazy(() =>
  import("../screens/Event/EventScreen")
);

const UserScreen = React.lazy(() =>
  import("../screens/User/UserScreen")
)

const TechnologyScreen = React.lazy(() =>
  import("../screens/Technology/TechnologyScreen")
)
const CompanyScreen = React.lazy(() =>
  import("../screens/Company/CompanyScreen")
)

export const userRoutes = [
  {
    path: PROFILE_PATH,
    component: ProfileScreen,
  },
  {
    path: DASHBOARD_PATH,
    component: DashboardScreen,
  },
  {
    path: USER_PATH,
    component: UserScreen,
  },
  {
    path: CONTENT_PATH,
    component: ContentScreen,
  },
  {
    path: EVENT_PATH,
    component: EventScreen,
  },
  {
    path: CONTACT_PATH,
    component: ContactScreen,
  },
  {
    path: TECHNOLOGY_PATH,
    component: TechnologyScreen
  },
  {
    path: TECHNOLOGY_WITHIN_PATH,
    component: TechnologyScreen
  },
  {
    path: COMPANY_SCREEN_PATH,
    component: CompanyScreen
  },
  {
    path: BUSINESS_PATH,
    component: BusinessScreen
  },
];

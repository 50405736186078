import { combineReducers } from "redux";
import {
  columnUpdateReducer,
  ApplyUpdateColumnReducer,
  tableDataFetchReducer,
  tableEndpointChangeReducer,
} from "./tableReducer";
import { modeChangeReducer } from "./modeReducer";
import {
  paginationDataReducer,
  tableFiltersReducer,
} from "./customTableReducer";
import {
  allFiltersReducer,
  filterHeaderAccountListReducer,
  filterHeaderCompanyCityReducer,
  filterHeaderCompanyCountryReducer,
  filterHeaderCompanyKeywordsReducer,
  filterHeaderCompanyNameReducer,
  filterHeaderCompanyReducer,
  filterHeaderCompanySeoReducer,
  filterHeaderCompanyStateReducer,
  filterHeaderCompanyTechnologiesReducer,
  filterHeaderEmailVerificationReducer,
  filterHeaderIndustryReducer,
  filterHeaderJobTitleReducer,
  filterHeaderPersonCityReducer,
  filterHeaderPersonCountryReducer,
  filterHeaderPersonStateReducer,
  filterHeaderSearcheReducer,
  filterHeaderSeniorityReducer,
  filterHeaderContactListReducer,
} from "./filterReducer";

import { editReducer, getMeetingLinkReducer } from "./editorReducer";



import {
  checkLoginReducer,
  invitedUsersListReducer,
  loginReducer,
  tokenReducer,
  logoutReducer,
  registerReducer,
  resetPasswordMailSendReducer,
  resetPasswordReducer,
  usersListReducer,
  verifyDomainReducer,
  userDetailsReducer,
  updateprofileReducer,
} from "./authReducer";
import {
  peopleBasicDetailsReducer,
  peopleUserDetailsReducer,
} from "./peopleReducer";
import { createContactListReducer, getContactListReducer, getEarlyUserReducer } from "./listReducer";
import { companyBasicDetailsReducer, companyDetailsReducer } from "./companyReducer";
import { getAllStepsReducer } from "./stepsReducer";

import { getSequenceListReducer } from "./listTableReducer";
import {
  campaignsListFetchReducer,
  selectedCampaignDetailsReducer,
} from "./campaignReducer";
import { getImportCsvReducer, taskSearchReducer } from "./csvReducer";
import { getFolderListReducer } from "./listFolderReducer";
import {
  plateformuserReducer,
  pluginuserReducer,
  userdetailInfoDataReducer,
} from "./usersReducer";
import { accountlistReducer, companyinfodataReducer } from "./accountReducer";
import {contentlistReducer} from "./contentReducer";
import {technologylistReducer} from "./technologyReducer";
import { eventlistReducer } from "./eventReducer";
import { businessListReducer } from "./businessReducer";
import { contactlistReducer } from "./contactReducer";
import { requestcreditReducer } from "./requestcreditReducer";
import { templateListReducer } from "./templateListReducer";
import { userdataReducer } from "./userdataReducer";
import { creditInfoReducer, pluginreferalReducer } from "./creditinfoReducer";
import { QuarantineBasicDetail, quarantineUserDetailsReducer } from "./QuarantineReducer";
import { QuarantinePreApproveDetail, prequarantineUserDetailsReducer } from "./QuarantinePreApproveReducer";
import {
  chartDataReducer,
  currentDashboardReducer,
  dashboardReducer,
  dashboardWidgetsReducer,
  widgetCoordinatesReducer,
} from "./dashboardReducer";
// All combined reducers here.
export const reducers = combineReducers({
  userDetails: userDetailsReducer,
  columnUpdate: columnUpdateReducer,
  ApplyUpdateColumn: ApplyUpdateColumnReducer,
  modeChange: modeChangeReducer,
  register: registerReducer,
  login: loginReducer,
  token: tokenReducer,
  resetPassword: resetPasswordReducer,
  logout: logoutReducer,
  checkLogin: checkLoginReducer,
  verifyDomain: verifyDomainReducer,
  resetPasswordMailSend: resetPasswordMailSendReducer,
  filterHeaderSearch: filterHeaderSearcheReducer,
  filterHeaderJobTitle: filterHeaderJobTitleReducer,
  filterHeaderIndustry: filterHeaderIndustryReducer,
  filterHeaderEmailVerification: filterHeaderEmailVerificationReducer,
  tableDataFetch: tableDataFetchReducer,
  peopleBasicDetails: peopleBasicDetailsReducer,
  companyBasicDetails: companyBasicDetailsReducer,
  companyDetails: companyDetailsReducer,
  allFilters: allFiltersReducer,
  filterHeaderCompany: filterHeaderCompanyReducer,
  filterHeaderSeniority: filterHeaderSeniorityReducer,
  filterHeaderCompanyName: filterHeaderCompanyNameReducer,
  filterHeaderCompanyKeywords: filterHeaderCompanyKeywordsReducer,
  filterHeaderCompanyTechnologies: filterHeaderCompanyTechnologiesReducer,
  filterHeaderCompanySeo: filterHeaderCompanySeoReducer,
  filterHeaderPersonCity: filterHeaderPersonCityReducer,
  filterHeaderPersonState: filterHeaderPersonStateReducer,
  filterHeaderPersonCountry: filterHeaderPersonCountryReducer,
  filterHeaderCompanyCity: filterHeaderCompanyCityReducer,
  filterHeaderCompanyState: filterHeaderCompanyStateReducer,
  filterHeaderCompanyCountry: filterHeaderCompanyCountryReducer,
  filterHeaderAccountList: filterHeaderAccountListReducer,
  filterHeaderContactList: filterHeaderContactListReducer,
  peopleUserDetails: peopleUserDetailsReducer,

  tableEndpointChange: tableEndpointChangeReducer,
  // List reducers.
  createContactList: createContactListReducer,
  getContactList: getContactListReducer,

  // steps reducers
  getAllSteps: getAllStepsReducer,
  // Get List Reducer
  getSequenceList: getSequenceListReducer,
  // Campaigns reducers
  campaignsListFetch: campaignsListFetchReducer,
  selectedCampaignDetails: selectedCampaignDetailsReducer,
  //Quarantine reducers
  quarantineFetch: QuarantineBasicDetail,
  quarantineUserDetails: quarantineUserDetailsReducer,
  //QuarantinePreApprove reducers
  quarantinePreApproveFetch: QuarantinePreApproveDetail,
  prequarantineUserDetails: prequarantineUserDetailsReducer,

  // profile reducers
  invitedUsersList: invitedUsersListReducer,
  usersList: usersListReducer,
  businesslist: businessListReducer,
  // Views reducers
  getFolderList: getFolderListReducer,
  plateformusers: plateformuserReducer,
  pluginusers: pluginuserReducer,
  accountlist: accountlistReducer,
  contentlist: contentlistReducer,
  technologylist:technologylistReducer,
  contactlist: contactlistReducer,
  eventlist: eventlistReducer,
  userdetailInfo: userdetailInfoDataReducer,
  companyinfodata: companyinfodataReducer,
  requestcredit: requestcreditReducer,
  templateList: templateListReducer,
  userdata: userdataReducer,
  creditinfo: creditInfoReducer,
  pluginreferal: pluginreferalReducer,
  updateprofile: updateprofileReducer,
  getearlyuserlist: getEarlyUserReducer,
  //dashboard reducers
  getDashboardDetails: dashboardReducer,
  getDashboardWidgetDetails: dashboardWidgetsReducer,
  getChartData: chartDataReducer,
  getWidgetCoordinates: widgetCoordinatesReducer,
  getCurrentDashboard: currentDashboardReducer,

  // Custom Table Query Data
  getPaginationQuery: paginationDataReducer,
  getTableFilters: tableFiltersReducer,

  //editor Reducer
  editorData: editReducer,
  getMeetingLink: getMeetingLinkReducer,

  //CSV
  getImportCsv: getImportCsvReducer,
  getTaskContactList: taskSearchReducer,
});



import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useParams, useNavigate } from "react-router-dom";
import { CircularLoader } from "../../components";
import { axios } from "../../config";
import ErrorMessage from "../../components/Messages/ErrorMessage";
import CustomEventEmitter from "../../utils/emitter/EventEmitter";
import { EMITTER_SUCCESS } from "../../utils/constants";
import { HOME_PATH, LOGIN_PATH } from "../../utils/constants/routes";
import { HOST_URL, protocol } from "../../utils/constants/httpConstants";
import { makeStyles } from "@mui/styles";

const styles = makeStyles({
    box:{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    button:{
      background: "white",
      border: "1px solid #ff4848",
      padding: "0.5rem 2rem",
      color: "#ff4848",
      borderRadius: "4px",
      fontWeight: "600",
      cursor: "pointer",
    }
})

const EmailVerification = () => {

  const classes = styles();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = params?.id;

  const createUrl = (domain, email) => {
    let subDomain = domain?.trim().split(".")[0];
    let redirectedUrl = `${protocol}${subDomain}.${HOST_URL}${LOGIN_PATH}/${email}`;
    window.location.href = redirectedUrl;
  };

  useEffect(() => {
    axios
      .post("/auth/verify-email", { verification_token: token })
      .then((res) => {
        // console.log('response email---', res?.data?.data?.email);
        setTimeout(() => {
          createUrl(res?.data?.data?.tenant_url, res?.data?.data?.email);
        }, 1500);

        CustomEventEmitter.emit(
          EMITTER_SUCCESS,
          "Email id verified successfully, redirecting to login page."
        );

      })
      .catch((error) => {
        setLoading(false);

        setError(error.response);
      })
      
  }, [navigate, token]);

  return (
    <Box
      className={ classes.box }
    >
      {loading && <CircularLoader />}
      {error && (
        <Box>
          <ErrorMessage message={error.data.message} />
          <button
            className={ classes.button }
            onClick={() => navigate(HOME_PATH)}
          >
            Go Home
          </button>
        </Box>
      )}
    </Box>
  );
};

export default EmailVerification;
